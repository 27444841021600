import React from "react";
import _ from "lodash";

// Components
import MUSelect from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
import Label from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
import useStyles from "./style";
import InputError from '../input-error';

const Select = ({
    disabled,
    onChange,
    label,
    name,
    value,
    error,
    inputLabelProps,
    children,
    required,
    options,
    focused,
    size,
    placeholder,
    displayEmpty,
    ...props
}) => {
    const classes = useStyles();

    return (
        <div style={{ padding: '8px 0px' }}>
            {label && (
                <Label
                    required={required}
                    error={error}
                    className={classes.label}
                    focused={error}
                >
                    {label}
                </Label>
            )}

            <MUSelect
                disabled={disabled}
                onChange={onChange}
                className={classes.root}
                name={name}
                value={value}
                error={error}
                variant="outlined"
                required={required}
                style={{
                    ...props.style,
                    width: "100%",
                    height: size === "small" ? 40 : "inherit",
                }}
                displayEmpty={displayEmpty}
                MenuProps={{
                    classes: {
                        paper: classes.menuPaper
                    }
                }}
                {...props}
            >
                <MenuItem style={{ display: 'none' }} disabled={true} value="">
                    <span className={classes.placeholderTxt}>
                        {placeholder}
                    </span>
                </MenuItem>

                {!_.isEmpty(options) &&
                    _.map(options, (o, i) => {
                        return (
                            <MenuItem value={o.value} key={i}>
                                {o.label}
                            </MenuItem>
                        );
                    })
                }
            </MUSelect>

            <InputError error={error} label={label}/>

            {children}
        </div>
    );
};

Select.defaultProps = {
    onChange: () => {},
    label: null,
    options: [],
    required: false,
    multiple: false,
    size: "small",
    displayEmpty: true,
    placeholder: 'Select One'
};

export default Select;
