import React from 'react';
import _ from 'lodash';

// Components
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUCheckBox from '@material-ui/core/Checkbox';
import useStyles from './style';

const CheckBox = ({ onChange, alignment, options, labelPlacement, styleLabel,...props }) => {
    const cx = useStyles();

    return (
        <FormGroup row={alignment === 'horizontal' ? true : false}>
            {_.map(options, (o, i) => {
                return (
                    <FormControlLabel
                        className={cx.label}
                        key={i}
                        label={o.label}
                        style={styleLabel}
                        labelPlacement={labelPlacement}
                        control={(
                            <MUCheckBox
                                name={o.name}
                                checked={o.checked}
                                onChange={onChange}
                                {...o}
                            />
                        )}
                    />
                )
            })}
        </FormGroup>
    )
}

CheckBox.defaultProps = {
    alignment: 'vertical',
    options: [],
    labelPlacement: 'end',
    onChange: () => {}
};

export default CheckBox;