import axios from 'axios';
import Session from 'services/local/session';

const login = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/login`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}


const renewToken = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/jwt/refresh`;
    const { accessToken } = Session.getToken();
    const { refreshToken } = Session.getToken();
  
    return await axios({
        method: 'POST',
        url: url,
        data: { 'refreshToken': refreshToken.jwt }
    })
        .then(response => {
            return response?.data;
        })
        .catch(err => err.response?.data);
}

const logout = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/logout`;
    const { accessToken } = Session.getToken();

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: `Bearer ${accessToken.jwt}`,
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const checkStatus = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/email/verify`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const verifyOtp = async (payload) => {

    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/email/verifyotp`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const passwordSetting = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/password/first`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}


const passwordReSetting = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/password/reset`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const resendEmail = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/auth/email/verify/resend`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}


export default {
    login,
    checkStatus,
    logout,
    verifyOtp,
    passwordSetting,
    resendEmail,
    passwordReSetting,
    renewToken
};