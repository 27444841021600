import _ from 'lodash';

import masterClass from '../apis/master-class';
import normalize from '../../utils/normalizers';

export const CLASSES_FETCH = 'CLASSES_FETCH';
export const CLASSES_FETCH_FAILED = 'CLASSES_FETCH_FAILED';
export const CLASSES_FETCH_SUCCESS = 'CLASSES_FETCH_SUCCESS';

export const CLASSES_CREATE = 'CLASSES_CREATE';
export const CLASSES_CREATE_FAILED = 'CLASSES_CREATE_FAILED';
export const CLASSES_CREATE_SUCCESS = 'CLASSES_CREATE_SUCCESS';


export const RESET_STATUS = 'RESET_STATUS';

export const CLASSES_UPDATE = 'CLASSES_UPDATE';
export const CLASSES_UPDATE_FAILED = 'CLASSES_UPDATE_FAILED';
export const CLASSES_UPDATE_SUCCESS = 'CLASSES_UPDATE_SUCCESS';


const dev = process.env.NODE_ENV === 'development';


const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createClass = (data) => async (dispatch) => {
    try {
        dispatch({ type: CLASSES_CREATE });
        const res = await masterClass.create(data);
        if (!res?.success) {
            return dispatch({
                type: CLASSES_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: CLASSES_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: CLASSES_CREATE_FAILED,
            error: err
        });
    }
}

const updateClass = (data) => async (dispatch) => {
    try {
        dispatch({ type: CLASSES_UPDATE });
        const res = await masterClass.update(data);
        if (!res?.success) {
            return dispatch({
                type: CLASSES_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: CLASSES_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: CLASSES_UPDATE_FAILED,
            error: err
        });
    }
}

const getClasses = (data) => async (dispatch) => {
    try {
        dispatch({ type: CLASSES_FETCH });
        const res = await masterClass.getClasses(data);
        if (!res?.success) {
            return dispatch({
                type: CLASSES_FETCH_FAILED,
                error: res
            });
        }
        return dispatch({ type: CLASSES_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: CLASSES_FETCH_FAILED,
            error: err
        });
    }
}

export default {
    createClass,
    getClasses,
    updateClass,
    resetStatus
}