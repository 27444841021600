import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from 'components/common/button';
// import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from '@material-ui/core/Grid';
import eduAPI from "../../../api/api";
import { Input, Select } from '../../common/form-unit';
import useToaster from 'hooks/use-toaster';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const ITEM_HEIGHT = 48;

const Classes = ({ status, resErr, onCreate, onUpdate, onReset, getClasses }) => {

    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const { t } = useTranslation();
    const initialError = {};
    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [Years, setYears] = useState([])
    const [Teachers, setTeachers] = useState([])
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedRowObj, SetSelectedRow] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedIndex, setSelIndex] = useState();
    const [rows, setData] = useState([])
    const [acedCal, setAcademicCal] = useState([]);
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canCreateClass = (uniquePermissions || []).includes('v_master_class_create');
    const canEdit = (uniquePermissions || []).includes('v_master_class_update');
    const [fetchStatus, setFetchStatus] = useState(true)

    const initialInput = {
        "calendarID": "",
        "name": "",
        "level": "",
        "teacherID": "",
        "year": "",
        "semester": "",
        "academicCalendar": "",
        "orgID": sessionObj.orgID
    };

    const [input, setInput] = useState(initialInput);


    useEffect(() => {
        if (sessionObj.orgID && fetchStatus) {
            setFetchStatus(false)
            fetchClasses();
            fetchYears();
            fetchTeachers();
            setToastMsg("")
        }
    }, [sessionObj]);

    useEffect(() => {
        if (input.name && input.level && input.year && input.academicCalendar && input.teacherID) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [input])


    useEffect(() => {
        if (status.update == 'failed') {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
            onReset();
        }
        if (status.create == "failed") {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
            onReset();
        }
    }, [resErr])

    useEffect(() => {
        setButtonDisabled(false)
        if (status.fetch === 'success') {
            fetchClasses();
            setInput(initialInput);
            onReset();
        } else if (status.create === 'success' || status.update === 'success') {
            handleClose();
            handleCloseEdit();
            fetchClasses();
            setInput(initialInput);
            let msg = status.create == 'success' ? "Class Created Successfully.." : "Class Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster()
            onReset();
        }
    }, [status]);


    const handleOpen = () => {
        setInput(initialInput);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const onChangeAcedmicCal = (e) => {
        setInput({ ...input, academicCalendar: e.target.value })
    }

    const handleOpenEdit = () => {
        let data = rows[selectedIndex];
        SetSelectedRow(rows[selectedIndex])
        let obj = {
            name: data.classData.name,
            level: data.classData.level,
            year: data.calendarData.year,
            teacherID: data.teacherData.teacherID
        }
        editTimeAcademicCalData(data.calendarData.year)
        setTimeout(() => {
            obj.academicCalendar = data.calendarData.calendarID
            setInput(obj)
        }, 1000);
        handleCloseMenu()
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setInput(initialInput)
    };

    const fetchYears = async () => {
        let yearsArray = [];
        try {
            const result = await eduAPI.get(`/calendar/populateYears?orgID=${sessionObj.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data.map(item => {
                    yearsArray.push({
                        'label': item,
                        'value': item,
                    })
                });
                setTimeout(() => {
                    setYears(yearsArray)
                }, 2000);
            });
        } catch (error) {
            console.error("fetch sem years Records error", error);
        }
    }

    const onChangeYears = async (e) => {
        setInput({ ...input, year: e.target.value })
        let acedmicCalArray = [];
        try {
            await eduAPI.get(`/calendar/populateCalendarName?orgID=${sessionObj.orgID}&year=${e.target.value}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data.map(item => {
                    acedmicCalArray.push({
                        'label': item.name,
                        'value': item.calendarID,
                    })
                });
                setTimeout(() => {
                    setAcademicCal(acedmicCalArray)
                }, 1000);
            });
        } catch (error) {
            console.error("fetch acedemic calednar Records error", error);
        }
    }

    const editTimeAcademicCalData = async (val) => {
        let acedmicCalArray = [];
        try {
            await eduAPI.get(`/calendar/populateCalendarName?orgID=${sessionObj.orgID}&year=${val}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data.map(item => {
                    acedmicCalArray.push({
                        'label': item.name,
                        'value': item.calendarID,
                    })
                });
                setTimeout(() => {
                    setAcademicCal(acedmicCalArray)
                }, 500);
            });
        } catch (error) {
            console.error("fetch acedemic calednar Records error", error);
        }
    }

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onSubmit = () => {
        if (buttonDisabled) {
            let value = {};
            value.calendarID = input.academicCalendar;
            value.name = input.name;
            value.level = input.level;
            value.teacherID = input.teacherID;
            value.orgID = sessionObj.orgID;
            onCreate(value);
        }
    }

    const onUpdateClass = () => {
        if (buttonDisabled) {
            let backupValues = selectedRowObj;
            let value = {};
            value.classID = backupValues.classData.classID;
            value.calendarID = input.academicCalendar;;
            value.name = input.name;
            value.code = backupValues.classData.code;
            value.level = input.level;
            value.teacherID = input.teacherID;
            onUpdate(value)
        }
    }

    const fetchClasses = async () => {
        try {
            await eduAPI.get(`/class/byOrg/${sessionObj.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                setData(data.data.data);
                onReset();
            });
        } catch (error) {
            console.error("fetch classes error", error);
        }
    }

    const fetchTeachers = async () => {
        try {
            await eduAPI.get(`/profile/type?profileType=TEACHER&orgID=${sessionObj.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data = data.data.data.map((item) => {
                    item.label = item.firstName + ' ' + item.lastName;
                    item.value = item.profileID
                    return item;
                });
                const teachersList = data.data.data;
                const sortedTeachers = teachersList.sort((teacherA,teacherB)=>{return (teacherA.firstName).localeCompare(teacherB.firstName)})
                setTeachers(sortedTeachers);
            });
        } catch (error) {
            console.error("fetch techers error", error);
        }
    }




    return (

        <>
            <Toaster />

            <div style={{ marginTop: '-5%' }}>

                <div className={classes.headerContainer}>
                    {canCreateClass ? <div className={classes.buttonContainer}>
                        {/* <Button 
                    variant="contained" 
                    className={classes.inputButton} 
                    onClick={handleOpen}
                    >
                        <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} />
            Add Class
        </Button> */}
                        <Button
                            // variant="contained"
                            // className={classes.inputButton}
                            label={t('action.add_class')}
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            type="primary"
                            size="large"
                            style={{ width: '170px' }}
                            onClick={handleOpen} />



                    </div> : ''}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={[classes.paper, classes.modalHeight].join(' ')} >

                                <h2 id="transition-modal-title" className={classes.popupTitle}>New Class</h2>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Class: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="class"
                                            required={true}
                                            error={input.name === ""}
                                            value={input.name || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, name: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Level: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="level"
                                            required={true}
                                            value={input.level || ''}
                                            error={input.level === ""}
                                            options={[
                                                { label: 'Primary', value: 'Primary' },
                                                { label: 'Secondary', value: 'Secondary' }
                                            ]}
                                            onChange={(e) => {
                                                setInput({ ...input, level: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Year: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="year"
                                            required={true}
                                            error={input.year === ""}
                                            value={input.year || ''}
                                            options={Years}
                                            onChange={(e) => onChangeYears(e)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Academic Calendar: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="academicCalendar"
                                            required={true}
                                            error={input.academicCalendar === ""}
                                            value={input.academicCalendar || ''}
                                            options={acedCal}
                                            onChange={(e) => onChangeAcedmicCal(e)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Class Teacher: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="teacher"
                                            required={true}
                                            error={input.teacherID === ""}
                                            value={input.teacherID || ''}
                                            options={Teachers}
                                            onChange={(e) => {
                                                setInput({ ...input, teacherID: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button
                                        label="Done"
                                        size="large"
                                        type="primary"
                                        style={{ width: '140px' }}
                                        // className={classes.buttonSubmit} 
                                        onClick={onSubmit}>Done
                                    </Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    <div>

                    </div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleCloseEdit}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openEdit}>
                            <div className={[classes.paper, classes.modalHeight].join(' ')} >
                                <h2 id="transition-modal-title" className={classes.popupTitle}>Edit Class</h2>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Class: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="class"
                                            required={true}
                                            error={input.name === ""}
                                            value={input.name || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, name: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Level: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="level"
                                            required={true}
                                            error={input.level === ""}
                                            value={input.level || ''}
                                            options={[
                                                { label: 'Primary', value: 'Primary' },
                                                { label: 'Secondary', value: 'Secondary' }
                                            ]}
                                            onChange={(e) => {
                                                setInput({ ...input, level: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Year: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="year"
                                            required={true}
                                            error={input.year === ""}
                                            value={input.year || ''}
                                            options={Years}
                                            onChange={(e) => onChangeYears(e)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Academic Calendar: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="academicCalendar"
                                            required={true}
                                            error={input.academicCalendar === ""}
                                            value={input.academicCalendar || ''}
                                            options={acedCal}
                                            onChange={(e) => onChangeAcedmicCal(e)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Class Teacher: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="teacher"
                                            required={true}
                                            error={input.teacherID === ""}
                                            value={input.teacherID || ''}
                                            options={Teachers}
                                            onChange={(e) => {
                                                setInput({ ...input, teacherID: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button
                                        label="Done"
                                        size="large"
                                        type="primary"
                                        style={{ width: '140px' }}
                                        // className={classes.buttonSubmit} 
                                        onClick={onUpdateClass}>Done</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>

                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.titles}>Class</TableCell>
                                <TableCell align="left" className={classes.titles}>Class Code</TableCell>
                                <TableCell align="left" className={classes.titles}>Level</TableCell>
                                <TableCell align="left" className={classes.titles}>Year</TableCell>
                                <TableCell align="left" className={classes.titles}>Academic Calendar</TableCell>
                                <TableCell align="left" className={classes.titles}>Class Teacher</TableCell>
                                <TableCell align="left" className={classes.titles}></TableCell>
                            </TableRow>
                        </TableHead>

                        {
                            rows.length ?
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" className={classes.dataText}>{row.classData?.name}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.classData?.code}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.classData?.level}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.calendarData?.year}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.calendarData?.name}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.teacherData ? row.teacherData.firstName + '' + row.teacherData.lastName : '-'}</TableCell>
                                            <TableCell align="center">
                                                <div>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => { handleClick(event, index) }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id={index}
                                                        key={index}
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={openMenu}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 3.5,
                                                                width: "20ch",
                                                            }
                                                        }}
                                                    >
                                                        {canEdit &&
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    handleOpenEdit();
                                                                }}
                                                            >
                                                                {'Edit'}
                                                            </MenuItem>
                                                        }
                                                    </Menu>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                :
                                <div style={{ textAlign: 'center', fontSize: '20px', margin: '10px 0px' }}>
                                    Records Empty
                                </div>}
                    </Table>
                </TableContainer>
            </div >
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    popupTitle: {
        marginBottom: '10px'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#25335A',
        fontSize: 18,
        textAlign: 'center'
    },
    asterik: {
        color: 'red'
    },
    breadcrum: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '5%',
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        color: 'purple'

    },
    container: {
        marginTop: '5%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {

    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '1%',
        marginTop: '1%',
        width: '100%'
    },
    button: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        width: "auto !important",
        //textTransform: 'capitalize !important'

    },
    buttonSubmit: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 35,
        padding: '0 30px',
        width: "100",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,

    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '30%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
    ,
    modalHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 10px)'
    },
    inputButton: {
        display: 'flex',
        maxHeight: 50,
        width: 150,
        borderRadius: 7,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',

    }
}));

export default Classes;
