import { isEmpty, omitBy, isNull, isUndefined } from 'lodash';

const normalizer = (data) => {
    if (isEmpty(data)) {
        return {};
    }

    return omitBy(omitBy({
        contactType: data.type,
        countryCode: data.countryCode,
        contactNumber: data.number,
        fullNumber: `${data.countryCode}${data.number}`
    }, isNull), isUndefined);
}

export default normalizer;