import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
    border: 1,
    borderColor: 'black',
    borderRadius: 3,
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  mainContainer: {
    padding: '20px 0px'
  }
});
