import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DepartmentAction from 'redux/actions/department';

// Components
import View from './departments-assign.view';

const mapStateToProps = (state) => {
    return {
        status: state?.department?.status,
        resErr: state?.department?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const departmentAction = bindActionCreators(DepartmentAction, dispatch);

    return {
        onResetStatus$: () => {
            departmentAction.resetStatus();
        },
        onAssign$: (data) => {
            departmentAction.assignDepartment(data);
        }
    }
}


const Container = ({ status, resErr, onResetStatus$, onAssign$, profileID, onCloseModal, onFetchProfile }) => {
    useEffect(() => {
        if (status.assign === 'success' && profileID) {
            onFetchProfile(profileID);
        }
    }, [status, profileID]);
    
    const onAssign = (input) => {
        onAssign$({ ...input, profiles: [profileID] });
    }

    return (
        <View 
            status={status} 
            onAssign={onAssign} 
            onCloseModal={onCloseModal} 
            onResetStatus={onResetStatus$}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);