import _ from 'lodash';
import Session from 'services/local/session';

import profile from '../apis/profile';
import file from '../apis/file';
import normalize from '../../utils/normalizers';
// import fileBase64 from 'json/attachment.json';

export const RESET_STATUS = 'RESET_STATUS';

export const RESET_DATA = 'RESET_DATA';

export const PROFILES_FETCH = 'PROFILES_FETCH';
export const PROFILES_FETCH_FAILED = 'PROFILES_FETCH_FAILED';
export const PROFILES_FETCH_SUCCESS = 'PROFILES_FETCH_SUCCESS';

export const PROFILE_FETCH = 'PROFILE_FETCH';
export const PROFILE_FETCH_FAILED = 'PROFILE_FETCH_FAILED';
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';

export const PROFILE_CREATE = 'PROFILE_CREATE';
export const PROFILE_CREATE_FAILED = 'PROFILE_CREATE_FAILED';
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS';

export const STUDENTS_ENROLL = 'STUDENTS_ENROLL';
export const STUDENTS_ENROLL_SUCCESS = 'STUDENTS_ENROLL_SUCCESS';
export const STUDENTS_ENROLL_FAILED = 'STUDENTS_ENROLL_FAILED';

export const PROFILE_SEARCH = 'PROFILE_SEARCH';
export const PROFILE_SEARCH_SUCCESS = 'PROFILE_SEARCH_SUCCESS';
export const PROFILE_SEARCH_FAILED = 'PROFILE_SEARCH_FAILED';

export const STUDENTS_UNENROLLED_FETCH = 'STUDENTS_UNENROLLED_FETCH';
export const STUDENTS_UNENROLLED_FETCH_SUCCESS = 'STUDENTS_UNENROLLED_FETCH_SUCCESS';
export const STUDENTS_UNENROLLED_FETCH_FAILED = 'STUDENTS_UNENROLLED_FETCH_FAILED';

export const STUDENTS_ENROLLED_FETCH = 'STUDENTS_ENROLLED_FETCH';
export const STUDENTS_ENROLLED_FETCH_SUCCESS = 'STUDENTS_ENROLLED_FETCH_SUCCESS';
export const STUDENTS_ENROLLED_FETCH_FAILED = 'STUDENTS_ENROLLED_FETCH_FAILED';

export const STUDENTS_TRANSFER = 'STUDENTS_TRANSFER';
export const STUDENTS_TRANSFER_SUCCESS = 'STUDENTS_TRANSFER_SUCCESS';
export const STUDENTS_TRANSFER_FAILED = 'STUDENTS_TRANSFER_FAILED';

export const TEACHERS_UNASSIGNED_FETCH = 'TEACHERS_UNASSIGNED_FETCH';
export const TEACHERS_UNASSIGNED_FETCH_SUCCESS = 'TEACHERS_UNASSIGNED_FETCH_SUCCESS';
export const TEACHERS_UNASSIGNED_FETCH_FAILED = 'TEACHERS_UNASSIGNED_FETCH_FAILED';

export const TEACHERS_ASSIGNED_FETCH = 'TEACHERS_ASSIGNED_FETCH';
export const TEACHERS_ASSIGNED_FETCH_SUCCESS = 'TEACHERS_ASSIGNED_FETCH_SUCCESS';
export const TEACHERS_ASSIGNED_FETCH_FAILED = 'TEACHERS_ASSIGNED_FETCH_FAILED';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const STATUS_UPDATE = 'STATUS_UPDATE';
export const STATUS_UPDATE_SUCCESS = 'STATUS_UPDATE_SUCCESS';
export const STATUS_UPDATE_FAILED = 'STATUS_UPDATE_FAILED';

export const ACADEMICSTATUS_UPDATE = 'ACADEMICSTATUS_UPDATE';
export const ACADEMICSTATUS_UPDATE_SUCCESS = 'ACADEMICSTATUS_UPDATE_SUCCESS';
export const ACADEMICSTATUS_UPDATE_FAILED = 'ACADEMICSTATUS_UPDATE_FAILED';

export const ENROLLED_STUDENT_FILTER = 'ENROLLED_STUDENT_FILTER';
export const ENROLLED_STUDENT_FILTER_SUCCESS = 'ENROLLED_STUDENT_FILTER_SUCCESS';
export const ENROLLED_STUDENT_FILTER_FAILED = 'ENROLLED_STUDENT_FILTER_FAILED';

const dev = process.env.NODE_ENV === 'development';

const resetStatus = () => async (dispatch) => { dispatch({ type: RESET_STATUS }); }

const resetData = () => async (dispatch) => { dispatch({ type: RESET_DATA }); }

const createAdminProfile = (data, orgID) => async (dispatch) => { //for admin
    try {
        dispatch({ type: PROFILE_CREATE });

        const res = await profile.create(data);
        if (!res?.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: res.message
            });
        }

        return dispatch({ type: PROFILE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILE_CREATE_FAILED,
            error: err
        });
    }
}


const createProfile = (data, orgID) => async (dispatch) => { // for student and parent creation
    const { profile: sessProfile } = Session.getProfile();

    try {
        dispatch({ type: PROFILE_CREATE });

        // ammendment data
        for (const key in data) {
            /* 
                "key" will be student|father|mother|guardianOne|guardianTwo
                because i send data param as
                {
                    student: { ...data.student },
                    mother: { ...data.mother },
                    ...etc2
                }
            */
            data[key].profileStatus = 'ACTIVE';
            data[key].orgID = orgID;
            data[key].primaryAddress = true;
            data[key].addressType = 'HOME';

            if (key === 'student') {
                data[key].profileType = 'STUDENT';
                data[key].groupType = 'STUDENT';
                data[key].code = {
                    inputCode: data[key].studentCode,
                    primary: data[key].studentCode ? true : false
                };

                data[key].emergencyContact = {
                    emergencyContactType: 'MOBILE',
                    emergencyCountryCode: data[key]?.countryCode,
                    emergencyContactNumber: data[key]?.contactNumber,
                    emergencyContactName: data[key]?.contactName,
                    emergencyContactRelationship: data[key]?.contactRelationship
                }
            }

            if (key === 'father' || key === 'mother') {
                data[key].profileType = _.upperCase(key);
                data[key].groupType = 'PARENT';
                data[key].phone = {
                    contactType: 'MOBILE',
                    countryCode: data[key]?.countryCode,
                    contactNumber: data[key]?.contactNumber
                }

                data[key].email = {
                    emailAddress: data[key].emailAddress
                }
            }

            if (key === 'guardianOne' || key === 'guardianTwo') {
                data[key].profileType = 'GUARDIAN';
                data[key].groupType = 'GUARDIAN';
                data[key].phone = {
                    contactType: 'MOBILE',
                    countryCode: data[key]?.countryCode,
                    contactNumber: data[key]?.contactNumber
                }

                data[key].email = {
                    emailAddress: data[key].emailAddress
                }
            }
        }

        let profiles = Object.values(data);
        profiles = _.map(profiles, o => { return normalize.server.profile(o); });
        profiles = [{ orgID }, ...profiles];

        const res = await profile.create(profiles);
        if (!res?.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: res?.message,
                    data: res?.data
                }
            });
        }

        // Handle file upload
        const formData = new FormData();

        if (res.data?.createdID && res.success) {
            formData.append('orgID', orgID);
            formData.append('type', 'PROFILES');
            formData.append('profileID', sessProfile._key);
            formData.append('objectID', res.data?.createdID);

            let profileImg = [data.student?.profileImg];
            if (data.student?.profileImg) {
                _.forEach(profileImg, (o) => {
                    formData.append('profile_picture', o);
                });
            }

            if (!_.isEmpty(data.student?.attachments)) {
                _.forEach(data.student?.attachments, (o) => {
                    formData.append('profile_attachments', o);
                });
            }
        }

        const resFile = await file.upload(formData);
        if (!resFile.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: resFile?.message,
                    data: resFile?.data
                }
            });
        }

        return dispatch({ type: PROFILE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILE_CREATE_FAILED,
            error: err
        });
    }
}

const createStudentWithExistingParent = (data, orgID) => async (dispatch) => {
    const { profile: sessProfile } = Session.getProfile();

    try {
        dispatch({ type: PROFILE_CREATE });

        // ammendment data
        let newData = {};
        for (const key in data) {
            newData[key] = {};

            if (key === 'student') {
                newData[key] = { ...data[key] };

                newData[key].profileStatus = 'ACTIVE';
                newData[key].primaryAddress = true;
                newData[key].orgID = orgID;
                newData[key].profileType = 'STUDENT';
                newData[key].groupType = 'STUDENT';
                newData[key].addressType = 'HOME';
                newData[key].code = {
                    inputCode: data[key].profileCode,
                    primary: data[key].profileCode ? true : false
                };

                newData[key].emergencyContact = {
                    emergencyContactType: 'MOBILE',
                    // countryCode: data[key]?.countryCode,
                    // contactNumber: data[key]?.contactNumber,
                    // contactName: data[key]?.contactName,
                    // contactRelationship: data[key]?.contactRelationship
                }
            }

            if (key === 'father' || key === 'mother' || key === 'guardianOne' || key === 'guardianTwo') {
                newData[key] = {};
                newData[key].profileType = _.upperCase(key);
                newData[key].profileID = data[key].profileID
            }
        }

        let profiles = Object.values(newData);
        profiles = _.map(profiles, o => { return normalize.server.profile(o); });
        profiles = _.map(profiles, o => {
            if (o.type !== 'STUDENT') {
                const data = _.cloneDeep(o);
                delete data.addresses;

                return data;
            }

            return o
        });
        profiles = [{ orgID }, ...profiles];

        const res = await profile.create(profiles);
        if (!res?.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: res?.message,
                    data: res?.data
                }
            });
        }

        const formData = new FormData();

        if (res.success && !_.isUndefined(res.data?.createdID)) {
            formData.append('orgID', orgID);
            formData.append('type', 'PROFILES');
            formData.append('profileID', sessProfile._key);
            formData.append('objectID', res.data?.createdID);

            let profileImg = [data.student?.profileImg];
            if (data.student?.profileImg) {
                _.forEach(profileImg, (o) => {
                    formData.append('profile_picture', o);
                });
            }

            if (!_.isEmpty(data.student?.attachments)) {
                _.forEach(data.student?.attachments, (o) => {
                    formData.append('profile_attachments', o);
                });
            }
        }

        const resFile = await file.upload(formData);
        if (!resFile.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: resFile?.message,
                    data: resFile?.data
                }
            });
        }

        return dispatch({ type: PROFILE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILE_CREATE_FAILED,
            error: err
        });
    }
}

const createTeacher = (data, orgID) => async (dispatch) => {
    const { profile: sessProfile } = Session.getProfile();

    try {
        dispatch({ type: PROFILE_CREATE });

        // ammendment data
        data.profileStatus = 'ACTIVE';
        data.orgID = orgID;

        data.primaryAddress = true;
        data.addressType = 'HOME';

        data.profileType = data.profileType ? data.profileType : 'TEACHER'; // profile type
        data.groupType = data.profileType ? data.profileType : 'TEACHER';
        data.phone = {
            contactType: 'MOBILE',
            countryCode: data?.countryCode,
            contactNumber: data?.contactNumber
        };

        data.code = {
            inputCode: data.profileCode,
            primary: !_.isUndefined(data.profileCode) ? true : false
        };

        data.emergencyContact = {
            emergencyContactType: 'MOBILE',
            // countryCode: data?.countryCode,
            // contactNumber: data?.contactNumber,
            // contactName: data?.contactName,
            // contactRelationship: data?.contactRelationship
        };

        const profileData = normalize.server.profile(data);

        if (profileData.type === 'ADMIN') {
            profileData.groups = profileData.groups ? profileData.groups : []
        }
        const payload = [{ orgID: data.orgID }, { ...profileData }];

        const res = await profile.create(payload);
        if (!res.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: res?.message,
                    data: res?.data
                }
            });
        }

        const formData = new FormData();

        if (res.success && !_.isUndefined(res.data?.createdID)) {
            formData.append('orgID', orgID);
            formData.append('type', 'PROFILES');
            formData.append('profileID', sessProfile._key);
            formData.append('objectID', res.data?.createdID);

            let profileImg = [data?.profileImg];
            if (data?.profileImg) {
                _.forEach(profileImg, (o) => {
                    formData.append('profile_picture', o);
                });
            }

            if (!_.isEmpty(data?.attachments)) {
                _.forEach(data?.attachments, (o) => {
                    formData.append('profile_attachments', o);
                });
            }
        }

        const resFile = await file.upload(formData);
        if (!resFile.success) {
            return dispatch({
                type: PROFILE_CREATE_FAILED,
                error: {
                    message: resFile?.message,
                    data: resFile?.data
                }
            });
        }

        return dispatch({ type: PROFILE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILE_CREATE_FAILED,
            error: err
        });
    }
}

const getProfiles = () => async (dispatch) => {
    try {
        dispatch({ type: PROFILES_FETCH });

        const res = await profile.get();
        if (!res.success) {
            return dispatch({
                type: PROFILES_FETCH_FAILED,
                error: res.message
            });
        }

        return dispatch({
            type: PROFILES_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILES_FETCH_FAILED,
            error: err
        });
    }
}

const getProfilesByType = (profileType, orgID) => async (dispatch) => {
    try {
        dispatch({ type: PROFILES_FETCH });

        const res = await profile.getType(profileType, orgID);
        if (!res.success) {
            return dispatch({
                type: PROFILES_FETCH_FAILED,
                error: res.message
            });
        }

        return dispatch({
            type: PROFILES_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: PROFILES_FETCH_FAILED,
            error: err
        });
    }
}

const enrollStudent = (data) => async (dispatch) => {
    try {
        dispatch({ type: STUDENTS_ENROLL });

        const res = await profile.enroll(data);
        if (!res.success) {
            return dispatch({
                type: STUDENTS_ENROLL_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: STUDENTS_ENROLL_SUCCESS,
            payload: []
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: STUDENTS_ENROLL_FAILED,
            error: err
        });
    }
}

const getUnenrolledStudents = (orgID, year) => async (dispatch) => {
    try {
        dispatch({ type: STUDENTS_UNENROLLED_FETCH });

        const res = await profile.getUnenrolled(orgID, year);
        if (!res.success) {
            return dispatch({
                type: STUDENTS_UNENROLLED_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: STUDENTS_UNENROLLED_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: STUDENTS_UNENROLLED_FETCH_FAILED,
            error: err
        });
    }
}

const getEnrolledStudents = (orgID, year) => async (dispatch) => {
    try {
        dispatch({ type: STUDENTS_ENROLLED_FETCH });

        const res = await profile.getEnrolled(orgID, year);
        if (!res.success) {
            return dispatch({
                type: STUDENTS_ENROLLED_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: STUDENTS_ENROLLED_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: STUDENTS_ENROLLED_FETCH_FAILED,
            error: err
        });
    }
}

const searchExistingParent = (searchTxt) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_SEARCH });

        const org = Session.getOrg();
        const res = await profile.searchParent({
            searchStr: searchTxt,
            orgID: org.orgID,
            searchField: 'EMAIL'
        });

        if (!res.success) {
            return dispatch({
                type: PROFILE_SEARCH_FAILED,
                error: res.message
            });
        }

        let result = [];
        _.map(res.data, o => result.push(normalize.client.profile(o)));

        dispatch({
            type: PROFILE_SEARCH_SUCCESS,
            payload: result
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_SEARCH_FAILED,
            error: err
        });
    }
}

const getProfileByID = (profileID) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_FETCH });

        const res = await profile.getByID(profileID);
        if (!res.success) {
            return dispatch({
                type: PROFILE_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: PROFILE_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_FETCH_FAILED,
            error: err
        });
    }
}

const transferStudent = (data) => async (dispatch) => {
    try {
        dispatch({ type: STUDENTS_TRANSFER });

        const res = await profile.transferStudent(data);
        if (!res.success) {
            return dispatch({
                type: STUDENTS_TRANSFER_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: STUDENTS_TRANSFER_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: STUDENTS_TRANSFER_FAILED,
            error: err
        });
    }
}

const getProfileDetails = (profileID) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_FETCH });

        const res = await profile.getProfileDetails(profileID);
        if (!res.success) {
            return dispatch({
                type: PROFILE_FETCH_FAILED,
                error: res.message
            });
        }

        const profileData = normalize.client.profile(res.data.profile);

        let guardians = [];
        if (!_.isEmpty(res.data?.guardians)) {
            _.map(res.data.guardians, (o) => {
                guardians.push(normalize.client.profile(o));
            });
        }

        let departments = [];
        if (!_.isEmpty(res.data?.departments)) {
            _.map(res.data.departments, (o) => {
                departments.push(normalize.client.department(o));
            });
        }

        let attachments;
        if (!_.isEmpty(res.data?.attachments)) {
            attachments = _.omitBy({
                ...res.data?.attachments,
                profile_picture: _.first(res.data?.attachments?.profile_picture)
            }, _.isEmpty);
        }

        const payload = {
            ...res.data,
            guardians,
            departments,
            attachments,
            profile: profileData,
        };

        dispatch({ type: PROFILE_FETCH_SUCCESS, payload });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_FETCH_FAILED,
            error: err
        });
    }
}

const getUnassignedTeachers = (orgID, year) => async (dispatch) => {
    try {
        dispatch({ type: TEACHERS_UNASSIGNED_FETCH });

        const res = await profile.getUnassigned(orgID, year);
        if (!res.success) {
            return dispatch({
                type: TEACHERS_UNASSIGNED_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: TEACHERS_UNASSIGNED_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: TEACHERS_UNASSIGNED_FETCH_FAILED,
            error: err
        });
    }
}

const getAssignedTeachers = (orgID, year) => async (dispatch) => {
    try {
        dispatch({ type: TEACHERS_ASSIGNED_FETCH });

        const res = await profile.getAssigned(orgID, year);
        if (!res.success) {
            return dispatch({
                type: TEACHERS_ASSIGNED_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: TEACHERS_ASSIGNED_FETCH_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: TEACHERS_ASSIGNED_FETCH_FAILED,
            error: err
        });
    }
}

const updateAdminProfile = (data, orgID) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_UPDATE });
        const res = await profile.update(data);
        if (!res.success) {
            return dispatch({
                type: PROFILE_UPDATE_FAILED,
                payload: res.message
            });
        }
        dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_UPDATE_FAILED,
            error: err
        });
    }
}

const updateProfile = (data, orgID) => async (dispatch) => {
    const { profile: sessProfile } = Session.getProfile();

    try {
        dispatch({ type: PROFILE_UPDATE });

        let profiles = Object.values(data);
        profiles = _.map(profiles, o => {
            const profData = _.cloneDeep(normalize.server.profile(o));
            if (profData.groups) {
                delete profData.groups;
            }

            return profData;
            // return normalize.server.profile(o)
        });
        profiles = [{ orgID }, ...profiles];

        const res = await profile.update(profiles);
        if (!res.success) {
            return dispatch({
                type: PROFILE_UPDATE_FAILED,
                error: {
                    message: res?.message,
                    data: res?.data
                }
            });
        }

        // Handle file upload
        const formData = new FormData();

        if (res.success) {
            formData.append('orgID', orgID);
            formData.append('type', 'PROFILES');
            formData.append('profileID', sessProfile._key);
            formData.append('objectID', _.get(data.student, 'profileID'));

            const profileImg = data.student?.profileImg instanceof File ? [data.student?.profileImg] : null;
            if (profileImg) {
                _.forEach(profileImg, (o) => {
                    formData.append('profile_picture', o);
                });
            }

            if (!_.isEmpty(data.student?.attachments)) {
                _.forEach(data.student?.attachments, (o) => {
                    formData.append('profile_attachments', o);
                });
            }

            if (!_.isEmpty(data.student?.removedDocumentIDs)) {
                _.forEach(data.student?.removedDocumentIDs, (o) => {
                    formData.append('fileKeysToDelete[]', o);
                });
            }
        }

        const resFile = await file.upload(formData);
        if (!resFile.success) {
            return dispatch({
                type: PROFILE_UPDATE_FAILED,
                error: {
                    message: resFile.message,
                    data: resFile.data
                }
            });
        }

        dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_UPDATE_FAILED,
            error: err
        });
    }
}

const updateTeacherProfile = (data, orgID) => async (dispatch) => {
    const { profile: sessProfile } = Session.getProfile();

    try {
        dispatch({ type: PROFILE_UPDATE });

        const profileData = normalize.server.profile(data);

        delete profileData.groups;

        const res = await profile.update([{ orgID }, profileData]);
        if (!res.success) {
            return dispatch({
                type: PROFILE_UPDATE_FAILED,
                error: {
                    message: res?.message,
                    data: res?.data
                }
            });
        }

        const formData = new FormData();

        if (res.success) {
            formData.append('orgID', orgID);
            formData.append('type', 'PROFILES');
            formData.append('profileID', sessProfile._key);
            formData.append('objectID', data.profileID);

            const profileImg = data?.profileImg instanceof File ? [data?.profileImg] : null;
            if (profileImg) {
                _.forEach(profileImg, (o) => {
                    formData.append('profile_picture', o);
                });
            }

            if (!_.isEmpty(data?.attachments)) {
                _.forEach(data?.attachments, (o) => {
                    formData.append('profile_attachments', o);
                });
            }

            if (!_.isEmpty(data?.removedDocumentIDs)) {
                _.forEach(data?.removedDocumentIDs, (o) => {
                    formData.append('fileKeysToDelete[]', o);
                });
            }
        }

        const resFile = await file.upload(formData);
        if (!resFile.success) {
            return dispatch({
                type: PROFILE_UPDATE_FAILED,
                error: {
                    message: resFile.message,
                    data: resFile.data
                }
            });
        }

        dispatch({
            type: PROFILE_UPDATE_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: PROFILE_UPDATE_FAILED,
            error: err
        });
    }
}

const filterEnrolledStudent = (data, query) => async (dispatch) => {
    try {
        dispatch({ type: ENROLLED_STUDENT_FILTER });

    }
    catch (err) {
        dispatch({
            type: ENROLLED_STUDENT_FILTER_FAILED,
            error: err
        });
    }
}


const updateStatusToActive = (data) => async (dispatch) => {
    try {
        dispatch({ type: STATUS_UPDATE });
        const res = await profile.activeStatus(data);
        if (!res.success) {
            return dispatch({
                type: STATUS_UPDATE_FAILED,
                payload: res
            });
        }
        dispatch({ type: STATUS_UPDATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: STATUS_UPDATE_FAILED,
            error: err
        });
    }
}




const updateStatusToInactive = (data) => async (dispatch) => {
    try {
        dispatch({ type: STATUS_UPDATE });
        const res = await profile.inActiveStatus(data);
        if (!res.success) {
            return dispatch({
                type: STATUS_UPDATE_FAILED,
                payload: res.message
            });
        }
        dispatch({ type: STATUS_UPDATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        dispatch({
            type: STATUS_UPDATE_FAILED,
            error: err
        });
    }
}


const updateAcademicStatus = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACADEMICSTATUS_UPDATE });
        const res = await profile.academicStatus(data);
        if (!res.success) {
            return dispatch({
                type: ACADEMICSTATUS_UPDATE_FAILED,
                payload: res
            });
        }
        dispatch({ type: ACADEMICSTATUS_UPDATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: ACADEMICSTATUS_UPDATE_FAILED,
            error: err
        });
    }
}

export default {
    createProfile,
    createTeacher,
    getProfiles,
    getProfilesByType,
    enrollStudent,
    getUnenrolledStudents,
    searchExistingParent,
    resetStatus,
    getProfileByID,
    getEnrolledStudents,
    transferStudent,
    createStudentWithExistingParent,
    getProfileDetails,
    getUnassignedTeachers,
    getAssignedTeachers,
    updateProfile,
    resetData,
    updateTeacherProfile,
    createAdminProfile,
    updateAdminProfile,
    updateStatusToActive,
    updateStatusToInactive,
    updateAcademicStatus
}