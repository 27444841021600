import React from 'react';

import AnnouncementViewPage from 'components/announcement/announcement-view';

const AnnouncementView = () => {
    return (
        <AnnouncementViewPage />
    )
};

export default AnnouncementView;