import React, { useCallback, useState } from 'react';

// Components
import Toast from 'components/common/toaster';

const useToaster = ({ message }) => {
    const [open, setOpen] = useState(false);
    
    const onOpenToaster = () => {
        setOpen(true);
    }

    const onCloseToaster = () => {
        setOpen(false);
    }

    const Toaster = useCallback(() => {
        return (
            <Toast 
                open={open} 
                message={message} 
                onHandleClose={onCloseToaster}
            />
        )
    }, [open]);

    return { Toaster, onOpenToaster, onCloseToaster }
}

export default useToaster;