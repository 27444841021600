import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';

// Component
import { Select } from '../../common/form-unit';

const SelectClass = ({ status, resErr, classes, onChange, ...props }) => {
    const [value, setValue] = useState('');

    const onHandleChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    return (
        <Fragment>
            <Select
                value={value}
                onChange={onHandleChange}
                options={!_.isEmpty(classes) && status.fetch === 'success' && _.map(classes, o => {
                    return { label: o.classData?.name, value: o.classData?.classID }
                })}
                {...props}
            />
        </Fragment>
    )
}

SelectClass.defaultProps = {
    onChange: () => {}
}

export default SelectClass
