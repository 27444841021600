import axios from 'axios';
import Session from 'services/local/session';


const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/announcement`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const upload = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/file`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/announcement`;

    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err.response?.data);
            return err.response?.data
        })
}


const getByID = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/announcement`;

    return await axios({
        method: 'GET',
        url: url,
        params: {
            subjectID: id
        },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const deleteA = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/announcement/delete`;

    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}


export default {
    create,
    update,
    getByID,
    deleteA,
    upload
};