import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        orgID: data.orgID,
        groupType: data.type
    }, _.isNull), _.isUndefined);   
}

export default component;