import React, { Fragment } from 'react';

import ButtonMUI from '@material-ui/core/Button';
import Loader from '../loader';
import useStyles from './style';

const Button = ({ 
    variant, 
    type, 
    isLoading, 
    startIcon, 
    disabled, 
    fullWidth, 
    size, 
    onClick, 
    label,
    ...props 
}) => {
    const styles = useStyles();
    const loaderColor = type === 'secondary' ? 'white' : 'inherit';

    return (
        <Fragment>
            <ButtonMUI
                classes={{
                    root: styles.root,
                    outlined: styles.outlined,
                    containedPrimary: styles.bgPrimary,
                    containedSecondary: styles.bgSecondary,
                    outlinedPrimary: styles.outlinePrimary
                }}
                color={type}
                startIcon={startIcon}
                endIcon={isLoading && <Loader color={loaderColor} size="15px"/>}
                disabled={disabled}
                size={size}
                variant={variant}
                onClick={onClick}
                {...props}
            >
                {isLoading ? 'Loading' : label}
            </ButtonMUI>
        </Fragment>
    )
}

Button.defaultProps = {
    isLoading: false,
    type: 'default',
    disabled: false,
    fullWidth: true,
    size: 'medium',
    variant: 'contained',
    onClick: () => {},
    label: null
}

export default Button
