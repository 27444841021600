import React, { Fragment } from 'react'

// Component
import Input from '../input';

const TextArea = ({ label, error, onChange, value, name, children, rows, rowsMax, required, ...props }) => {
    return (
        <Fragment>
            <Input
                label={label}
                name={name}
                required={required}
                error={error}
                value={value}
                onChange={onChange}
                multiline={true}
                rows={rows}
                rowsMax={rowsMax}
                {...props}
            />

            {children}          
        </Fragment>
    )
}

export default TextArea;