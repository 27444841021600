import Joi from 'joi';

/* ------------ GENERAL ------------ */
export const isoDate = Joi.date().iso();

export const dataUri = Joi.string().dataUri();

export const base64 = Joi.string().base64();

export const uuid = Joi.string().guid({ version: 'uuidv4' });

/* ------------ ACCOUNT ------------ */
const emailRegex = /(?!.*[.]$)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
export const email = Joi.string().pattern(emailRegex);

const sha256Regex = /^[A-Fa-f0-9]{64}$/;
export const password = Joi.string().pattern(sha256Regex);

/* ------------ PROFILE ------------ */
export const profileStatus = Joi.string().valid('ACTIVE', 'IN_ACTIVE');

export const code = Joi.string()
    .regex(/^[A-Z0-9-_]+$/i)
    .max(20)
    .default(null)
    .allow(null)
    .description('studentCode or teacherCode');

export const profileType = Joi.string().valid('FATHER', 'MOTHER', 'GUARDIAN', 'STUDENT', 'TEACHER');

export const name = Joi.string().pattern(/^[a-z'@ ]+$/i).max(50);

export const dob = isoDate;

export const gender = Joi.string().valid('MALE', 'FEMALE');

export const identificationType = Joi.string().valid('PASSPORT', 'NRIC');

export const nricNo = Joi.string()
    .pattern(/(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})/i);   

export const passportNo = Joi.string()
    .pattern(/^[A-Z0-9]+$/)
    .min(5);

export const issueDate = isoDate;

export const expiryDate = isoDate.greater(Joi.ref('issueDate'));

export const placeOfIssue = Joi.string().max(50);

export const nationality = Joi.string().pattern(/^[a-z]{2}$/i);

export const religion = Joi.string().valid('ISLAM', 'BUDDHIST', 'HINDU', 'CHRISTIAN', 'SIKH', 'OTHER');

export const race = Joi.string().valid('MALAY', 'CHINESE', 'INDIAN', 'BUMIPUTRA', 'PUNJABI', 'OTHER');

export const medicalCondition = Joi.string().pattern(/^[a-z-\.,\/ \']{1,100}$/i);

export const priorSchool = Joi.string().pattern(/^[a-z ]+$/i);

export const addressType = Joi.string().valid('HOME', 'OFFICE');

export const street = Joi.string()
    .description('the street address')
    .pattern(/^[a-z0-9\.,:\/ -@]+$/i)
    .allow('')
    .max(100);

export const postcode = Joi.string()
    .description('address postal code')
    .pattern(/^[a-z0-9]+$/i)
    .max(30);

export const city_state = Joi.string()
    .pattern(/^[a-z0-9\.,:\/ -@]+$/i)
    .max(50);

export const country = Joi.string().pattern(/^[a-z]{2}$/i);

export const primaryAddress = Joi.bool();

export const groupType = Joi.string().valid('PARENT', 'GUARDIAN', 'STUDENT', 'TEACHER');

export const futureUse = Joi.bool();

export const phoneType = Joi.string().valid('MOBILE', 'HOME');

export const countryCode = Joi.string()
    .pattern(/^[0-9]+$/)
    .min(1)
    .max(7);

export const contactNumber = Joi.string()
    .pattern(/^[0-9]+$/)
    .min(4)
    .max(12); 

export const fullNumber = contactNumber.custom((value, helper) => {
    const code = Joi.ref('countryCode');
    const num = Joi.ref('number');

    if (!value === code + num) {
        return helper.message('Fullnumber must match countryCode and number');
    }
    else {
        return value;
    }
});

export const relationship = Joi.string().pattern(/^[A-Z ]+$/i);

/* ------------ DEPARTMENT ------------ */
export const departmentName = Joi.string()
    .pattern(/^[a-zA-Z0-9 \-\(\)]+$/i)
    .max(80);

export const departmentStatus = Joi.string().valid("Active", "Inactive");

/* ------------ MESSAGE ------------ */
export const messageTemplateContent = Joi.string().max(150);