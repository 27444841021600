import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_FAILED,
    LOGOUT_SUCCESS,
    TOKEN_RENEW,
    TOKEN_RENEW_SUCCESS,
    TOKEN_RENEW_FAILED,
    RESET_STATUS,
    OTP_VERIFY,
    OTP_VERIFY_FAILED,
    OTP_VERIFY_SUCCESS,
    USER_STATUS,
    USER_STATUS_FAILED,
    USER_STATUS_SUCCESS,
    PASWORD_SET_STATUS,
    PASWORD_SET_STATUS_SUCCESS,
    PASWORD_SET_STATUS_FAILED,
    RESEND,
    RESEND_FAILED,
    RESEND_SUCCESS,
} from '../actions/auth';

const initialStatus = {
    login: 'idle',
    logout: 'idle',
    userStatus: 'idle',
    otpStatus: 'idle',
    passwordStatus: 'idle',
    resendEmail: 'idle',
    renew: 'idle'
};

const initialState = {
    data: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }


        case PASWORD_SET_STATUS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, passwordStatus: 'loading' }
            };
        }

        case PASWORD_SET_STATUS_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, passwordStatus: 'success', data: action.data }
            };
        }

        case PASWORD_SET_STATUS_FAILED: {
            return {
                ...state,
                error: { login: action.error },
                status: { ...state.status, passwordStatus: 'failed' }
            };
        }


        case OTP_VERIFY: {
            return {
                ...state,
                error: {},
                status: { ...state.status, otpStatus: 'loading' }
            };
        }

        case OTP_VERIFY_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, otpStatus: 'success', data: action.data }
            };
        }

        case OTP_VERIFY_FAILED: {
            return {
                ...state,
                error: { login: action.error },
                status: { ...state.status, otpStatus: 'failed' }
            };
        }

        case USER_STATUS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, userStatus: 'loading' }
            };
        }

        case USER_STATUS_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, userStatus: 'success', data: action.data, emailHash: action.emailHash }
            };
        }

        case USER_STATUS_FAILED: {
            return {
                ...state,
                error: { login: action.error },
                status: { ...state.status, userStatus: 'failed' }
            };
        }


        case LOGIN: {
            return {
                ...state,
                error: {},
                status: { ...state.status, login: 'loading' }
            };
        }

        case LOGIN_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, login: 'success', data: action.payload }
            };
        }

        case LOGIN_FAILED: {
            return {
                ...state,
                error: { login: action.error },
                status: { ...state.status, login: 'failed' }
            };
        }



        case RESEND: {
            return {
                ...state,
                error: {},
                status: { ...state.status, resendEmail: 'loading' }
            };
        }

        case RESEND_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, resendEmail: 'success', data: action.data, emailHash: action.emailHash }
            };
        }

        case RESEND_FAILED: {
            return {
                ...state,
                error: { resendEmail: action.error },
                status: { ...state.status, resendEmail: 'failed' }
            };
        }

        case LOGOUT: {
            return {
                ...state,
                error: {},
                status: { ...state.status, logout: 'loading' }
            };
        }

        case LOGOUT_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, logout: 'success' }
            };
        }

        case LOGOUT_FAILED: {
            return {
                ...state,
                error: { logout: action.error },
                status: { ...state.status, logout: 'failed' }
            };
        }

        case TOKEN_RENEW: {
            return {
                ...state,
                error: {},
                status: { ...state.status, renew: 'loading' }
            };
        }

        case TOKEN_RENEW_SUCCESS: {
            return {
                ...state,
                token: action.payload,
                error: {},
                status: { ...state.status, renew: 'success' }
            };
        }

        case TOKEN_RENEW_FAILED: {
            return {
                ...state,
                token: {},
                error: { renew: action.error },
                status: { ...state.status, renew: 'failed' }
            };
        }

        default:
            return state;
    }
}

export default reducer;