import _ from 'lodash';
// import base from './base';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    return _.omitBy(_.omitBy({
        // ...base(data),
        departmentID: data.key || data.departmentID,
        orgID: data.orgID,
        name: data.departmentName,
        status: data.departmentStatus,
        headOfDepartmentID: data.headOfDepartmentID
    }, _.isNull), _.isUndefined);
}

export default normalizer;