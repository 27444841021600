import React, { Component, Fragment } from 'react';  
import { Route } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Layout from '../components/layout';  
  
const DashboardLayout = ({children, ...rest}) => {  
    const classes = useStyles();
    const theme = useTheme();
  return (  
    <div className = {classes.root}>
            <Layout/>
             <main className = {classes.content}>
                <div className = {classes.toolbar}/>
                {children}
            </main>

        </div >
  )  
}  
  
const DashboardLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <DashboardLayout>  
          <Component {...matchProps} />  
      </DashboardLayout>  
    )} />  
  )  
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: { 
        flexGrow: 1,
        padding: theme.spacing(3),
      },
}))
  
export default DashboardLayoutRoute;  