import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//Components
import { Input, DatePicker } from '../common/form-unit';
import Button from 'components/common/button';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FlatList from 'flatlist-react';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import eduAPI from "../../../src/api/api";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Session from 'services/local/session';
import useToaster from 'hooks/use-toaster';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { uniq } from 'lodash';
import useSession from 'hooks/use-session';

const ITEM_HEIGHT = 48;
const Academic = ({ status, resErr, onCreate, onUpdate, onReset }) => {

    const { t } = useTranslation();
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [semesters, setSemesters] = useState([
        // { semester: 1 },
    ]);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [rerender, setRerender] = useState(false);

    const [selectedIndex, setSelIndex] = useState();
    const [DATA, setData] = useState([]);

    let initialInputs = {
        year: moment().format("YYYY"),
        name: "",
        startDate: "",
        endDate: "",
        createdAt: moment().format('DD-MM-YYYY'),
        semesters: [{ startDate: moment().format('DD-MM-YYYY'), endDate: moment().format('DD-MM-YYYY') }],
    }
    const [inputs, setInputs] = useState(initialInputs);
    const [openEdit, setOpenEdit] = React.useState(false);

    const [clashInfo, setClashInfo] = useState({})
    const [selectedRowObj, SetSelectedRow] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const session = useSession();
    const uniquePermissions = uniq(session.permissions);
    const canEditCalendar = (uniquePermissions || []).includes('v_calendar_update');
    const canCreateCalendar = (uniquePermissions || []).includes('v_calendar_create');

    const handleClose = () => {
        setOpen(false);
        setInputs(initialInputs)
        setAnchorEl(null);
    }

    const handleOpen = () => {
        setSemesters([{ semester: 1 }])
        SetSelectedRow()
        setInputs(initialInputs)
        setTimeout(() => {
            setOpen(true);
        }, 100);
    }

    const handleClick = (event, index) => {
        setInputs(initialInputs)
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
        setSemesters([])
    };

    const handleOpenEdit = () => {

        setInputs(initialInputs)
        let selObj = DATA[selectedIndex];
        SetSelectedRow(DATA[selectedIndex])

        let semestersz = []
        selObj.semesters.find((data) => {
            addSemester()
            semestersz.push(
                { startDate: data.startDate, endDate: data.endDate }
            )
        })

        let year = selObj.year.length > 5 ? selObj.year.split('-')[0] : selObj.year;

        let obj = {
            name: selObj.name,
            year: year,
            semesters: semestersz
        }


        setTimeout(() => {
            setInputs(obj)
        }, 1000);


        handleCloseMenu()
        setOpenEdit(true);

    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        if (resErr?.create?.message === 'There are clashes with one or more existing calendars') {
            setClashInfo(resErr?.create?.data);
            setTimeout(() => {
                openClashPopup();
                onReset();
            }, 1000);
        } else {
            setToastMsg("Error: " + resErr.create?.message)
            if (resErr.create?.message !== undefined) {
                onOpenToaster();
                onReset();
            }
        }
        if (resErr?.update?.message === 'There are clashes with one or more existing calendars') {
            setClashInfo(resErr?.update?.data);
            setTimeout(() => {
                openClashPopup();
                onReset();
            }, 1000);
        } else {
            setToastMsg("Error: " + resErr.update?.message)
            if (resErr.update?.message !== undefined) {
                onOpenToaster();
                onReset();
            }
        }
    }, [resErr])

    useEffect(() => {
        if (status.create == 'success' || status.update === 'success') {
            let msg = status.create == 'success' ? "Academic Calendar Created Successfully.." : "Academic Calendar Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster()
            handleClose();
            fetchRecords();
            onReset();
            setOpenEdit(false);
        }
    }, [status]);

    useEffect(() => {
        if (inputs.year && inputs.name) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [inputs])


    const onACCreate = () => {
        if (buttonDisabled) {
            let value = {
                "orgID": session.orgID,
                "year": inputs.year,
                "name": inputs.name,
                //"allowClash": true,
                "semesters": inputs.semesters
            }
            onCreate(value);
        }
    }

    const fetchRecords = async () => {
        try {
            const result = await eduAPI.get(`/calendar/byOrg/${session.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                setData(data.data.data);
            });
        } catch (error) {
            console.error("fetch Ac Records error", error);
        }
    }
    const [fetchStatus, setFetchStatus] = useState(true)
    useEffect(() => {
        if (session.orgID && fetchStatus) {
            async function fetch() {
                setFetchStatus(false)
                await fetchRecords();
                setSemesters([]);
            }
            fetch();
        }

    }, [session]);





    const semesterStartSet = (e, data) => {
        let input = inputs;
        input.semesters[data - 1].startDate = moment(e.date).format('DD-MM-YYYY');
        setInputs(input);
        setRerender(!rerender);

    }

    const semesterEndSet = (e, data) => {
        let input = inputs;
        input.semesters[data - 1].endDate = moment(e.date).format('DD-MM-YYYY');
        setInputs(input);
        setRerender(!rerender);


    }

    const addSemester = () => {
        let sems = semesters;
        let input = inputs;
        input.semesters.push({ startDate: moment().format('DD-MM-YYYY'), endDate: moment().format('DD-MM-YYYY') })
        sems.push({ semester: semesters.length + 1 });
        setSemesters(sems);
        setInputs(input);
        setRerender(!rerender);
    }

    const renderSemesterInput = (data) => {
        return (
            <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                <Grid item xs={4}>
                    <Typography className={classes.modalLabelStyle}>Semester {data.semester} <span className={classes.asterik}>*</span></Typography>

                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        name="startDate"
                        placeholder='Start Date'
                        disablePast={true}
                        value={moment(inputs.semesters[data.semester - 1]?.startDate, 'DD-MM-YYYY') || moment()}
                        onChange={(e) => { semesterStartSet(e, data.semester) }}


                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        name="endDate"
                        placeholder='End Date'
                        disablePast={true}
                        value={moment(inputs.semesters[data.semester - 1]?.endDate, 'DD-MM-YYYY') || moment()}
                        onChange={(e) => { semesterEndSet(e, data.semester) }}

                    />
                </Grid>
            </Grid>
        )
    }


    const [openClash, setOpenClash] = React.useState(false);

    const handleCloseClash = () => {
        setOpenClash(false);
    };
    const openClashPopup = () => {
        setOpen(false);
        setOpenClash(true);
    }

    const closeClashPopup = () => {
        setOpenClash(false);
        setInputs(initialInputs)
        setClashInfo({})
    }

    const [clashchecked, setClashchecked] = useState(false)

    const clashChange = () => {
        setClashchecked(!clashchecked)
    }

    const onClashSubmit = () => {
        if (clashchecked) {
            let value = {
                "orgID": session.orgID,
                "year": inputs.year,
                "name": inputs.name,
                "allowClash": true,
                "semesters": inputs.semesters
            }
            onCreate(value);
            setTimeout(() => {
                closeClashPopup()
            }, 1000);
        }
    }

    const onUpdateClashSubmit = () => {
        if (clashchecked) {
            let value = {
                "calendarID": selectedRowObj.calendarID,
                "year": inputs.year,
                "name": inputs.name,
                "allowClash": true,
                "semesters": inputs.semesters
            }
            onUpdate(value);
            setTimeout(() => {
                closeClashPopup()
            }, 1000);
        }
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setInputs(initialInputs)
    };

    const onUpdateSubmit = () => {
        let value = {
            "calendarID": selectedRowObj.calendarID,
            "year": inputs.year,
            "name": inputs.name,
            "semesters": inputs.semesters
        }
        onUpdate(value);
    }



    return (
        <>
            <Toaster />
            <div>

                {canCreateCalendar && <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                    <Button
                        // variant="contained"
                        className={classes.inputButton}
                        label={t('action.new_calendar')}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        type="primary"
                        size="large"
                        onClick={handleOpen}>
                        {/* <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} /> */}
                        New Calendar
                    </Button>

                </div>}

                <div className={classes.topContainer}>
                    {/* <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="large" />} style={{ marginLeft: 10, marginTop: 10, paddingTop: 20, paddingBottom: 20, width: '100%' }}>
                        <Link style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: 400, color: 'black' }} >
                            Academic Setup
                        </Link>
                        <Link style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: 600, color: 'black' }}>
                            Academic Calendar
                        </Link>

                    </Breadcrumbs> */}
                    {/* <div className={classes.inputButtonContainer}> */}

                </div>



                <div className={classes.listContainer}>

                    {/* <div className={classes.buttonContainer}>
                    <Button>
                        <img src={process.env.PUBLIC_URL + "/sort.svg"} alt='sort' className={classes.iconStyle} />
                        Sort
                    </Button>
                    <Button>
                        <img src={process.env.PUBLIC_URL + "/filter.svg"} alt='filter' className={classes.iconStyle} />
                        Filter
                </Button>
                </div> */}


                    <Paper>

                        <Grid container style={{ marginBottom: '20px', padding: '10px' }}>

                            <div className={classes.buttonContainer} style={{ marginBottom: '20px' }}>
                                <Button label="Academic Calendars"
                                    style={{
                                        fontWeight: 'bold',
                                        color: "#25335a",
                                        textTransform: 'capitalize',
                                        fontSize: '18px',
                                        background: 'transparent'
                                    }}>
                                </Button>
                                {/* <Button>
                                <img src={process.env.PUBLIC_URL + "/sort.svg"} alt='sort' className={classes.iconStyle} />
        Sort
    </Button>
                            <Button>
                                <img src={process.env.PUBLIC_URL + "/filter.svg"} alt='filter' className={classes.iconStyle} />
        Filter
    </Button> */}
                            </div>

                        </Grid>
                        <TableContainer component={Paper} className={classes.tableContainer} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.titles}>Name</TableCell>
                                        <TableCell align="left" className={classes.titles}>Year</TableCell>
                                        <TableCell align="left" className={classes.titles}>Semester</TableCell>
                                        <TableCell align="left" className={classes.titles}>Start Date</TableCell>
                                        <TableCell align="left" className={classes.titles}>End Date</TableCell>
                                        <TableCell align="left" className={classes.titles}>Updated On</TableCell>
                                        <TableCell align="left" className={classes.titles}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {DATA.map((row, index) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>{row.year}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.semesters.map((data) => (
                                                    <div>{data.no}</div>
                                                ))}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.semesters.map((data) => (
                                                    <div>{data.startDate}</div>
                                                ))}

                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.semesters.map((data) => (
                                                    <div>{data.endDate}</div>
                                                ))}
                                            </TableCell>

                                            <TableCell align='center'>
                                                {row.updatedAt ? moment(row.updatedAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').add(8, 'hours').format('DD-MM-YYYY') : '-'}
                                            </TableCell>

                                            <TableCell align="center">
                                                <div>
                                                    {canEditCalendar && <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => { handleClick(event, index) }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>}
                                                    <Menu
                                                        id="long-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={openMenu}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: "20ch",
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem
                                                            key={index}
                                                            onClick={() => {
                                                                handleOpenEdit(index);
                                                            }}
                                                        >
                                                            {'Edit'}
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    {/* Edit Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleCloseEdit}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openEdit} style={{ marginTop: '10%', marginBottom: '10%' }}>
                            <div className={[classes.paper, classes.modalViewHeight].join(' ')} >
                                <h2 id="transition-modal-title">Edit Academic Calendar</h2>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.modalLabelStyle}>Name: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="class"
                                            required={true}
                                            error={inputs.name === ""}
                                            value={inputs.name || ''}
                                            onChange={(e) => {
                                                setInputs({ ...inputs, name: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4} >
                                        <Typography className={classes.modalLabelStyle}>Start Year: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            name="year"
                                            required={true}
                                            error={inputs.year === ""}
                                            value={inputs.year || '2021'}
                                            views={["year"]}
                                            format='YYYY'
                                            disablePast={true}
                                            onChange={(e) => {
                                                setInputs({ ...inputs, year: e.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <FlatList
                                    list={semesters}
                                    renderItem={renderSemesterInput}
                                    renderWhenEmpty={() => <div>No Data!</div>}

                                />
                                <div>
                                    <Button
                                        className={classes.addSemesterButton}
                                        label={t('action.add_semester')}
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        onClick={addSemester}>
                                        <AddCircleOutlineIcon className={classes.semesterIconStyle} /> Add Semester</Button>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%', marginBottom: '5%' }}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{ width: '160px' }}
                                        // className={classes.updateBtn}
                                        label={t('action.update')}
                                        onClick={onUpdateSubmit} /><br></br>
                                </div>
                            </div>
                        </Fade>
                    </Modal>


                    {/* Clash Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openClash}
                        onClose={handleCloseClash}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openClash}>
                            <div className={classes.paper} style={{ width: '40%', fontFamily: 'Poppins' }}>
                                <div id="transition-modal-title" className={classes.popupTitle} style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Poppins', textDecoration: 'underline' }}>CONFIRM ACADEMIC CALENDAR</div>
                                <div style={{ textAlign: 'center', fontSize: '18px', fontFamily: 'Poppins' }}>The dates entered is coinciding with another Academic Calendar created : <span style={{ color: "#FDB714" }}>{inputs.name}</span></div>
                                {clashInfo ?
                                    <div>
                                        <Grid container item xs={12} spacing={2} style={{ marginTop: '3%' }}>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.new?.name}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.new?.year}
                                            </Grid>
                                            <Grid item xs={3}>
                                                Semester {clashInfo?.clashingSems?.new?.sem?.no}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.new?.sem?.startDate} to {clashInfo?.clashingSems?.new?.sem?.endDate}
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} spacing={2} style={{ marginBottom: '2%' }}>
                                            <Grid item xs={12} style={{ textAlign: 'center', color: "#FF0000", fontFamily: 'Poppins' }}>
                                                is coinciding with
                                            </Grid>
                                        </Grid>


                                        <Grid container item xs={12} spacing={2} style={{ marginTop: '0%' }}>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.exist?.name}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.exist?.year}
                                            </Grid>
                                            <Grid item xs={3}>
                                                Semester {clashInfo?.clashingSems?.exist?.sem?.no}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {clashInfo?.clashingSems?.exist?.sem?.startDate} to {clashInfo?.clashingSems?.exist?.sem?.endDate}
                                            </Grid>
                                        </Grid>


                                        <Grid container item xs={12} spacing={2} style={{ marginTop: '0%', textAlign: 'center' }}>
                                            <Grid item xs={12} style={{ marginTop: '2%', marginBottom: '3%', textAlign: 'left' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={clashchecked}
                                                            onChange={clashChange}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="I’m aware of this"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {selectedRowObj ?
                                                    <Button className={classes.button2} label={t('action.update_academic_calendar')} style={{ marginLeft: '0%' }} onClick={onUpdateClashSubmit}>
                                                        Update Academic Calendar</Button> :
                                                    <Button className={classes.button2} label={t('action.create_academic_calendar')} style={{ marginLeft: '0%' }} onClick={onClashSubmit}>
                                                        Create Academic Calendar</Button>}

                                            </Grid>
                                            <Grid item xs={12} className={classes.lineDecor} onClick={closeClashPopup}>
                                                <div>DON’T Create , return to Academic Calendar Page</div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : null}
                            </div>
                        </Fade>
                    </Modal>

                    {/* Add Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >

                        <Fade in={open} style={{ marginTop: '10%', marginBottom: '10%' }}>
                            <div className={[classes.paper, classes.modalViewHeight].join(' ')} >
                                <h2 id="transition-modal-title" className={classes.popupTitle}>New Academic Year</h2>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography className={classes.modalLabelStyle}>Name: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="class"
                                            required={true}
                                            error={inputs.name === ""}
                                            value={inputs.name || ''}
                                            onChange={(e) => {
                                                setInputs({ ...inputs, name: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4} >
                                        <Typography className={classes.modalLabelStyle}>Start Year: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            name="year"
                                            required={true}
                                            error={inputs.year === ""}
                                            value={inputs.year || '2021'}
                                            views={["year"]}
                                            format='YYYY'
                                            disablePast={true}
                                            onChange={(e) => {
                                                setInputs({ ...inputs, year: e.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <FlatList
                                    list={semesters}
                                    renderItem={renderSemesterInput}
                                    renderWhenEmpty={() => <div>No Data!</div>}

                                />
                                <div>
                                    <Button
                                        className={classes.addSemesterButton}
                                        label={t('action.add_semester')}
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        onClick={addSemester}>
                                        <AddCircleOutlineIcon
                                            className={classes.semesterIconStyle} /> Add Semester</Button>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{ width: '160px' }}
                                        // className={classes.buttonSubmit} 
                                        label={t('action.done')}
                                        onClick={onACCreate}
                                    >Done</Button><br></br>
                                </div>



                            </div>
                        </Fade>
                    </Modal>




                </div>
            </div >
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    addSemesterButton: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        color: 'white',
        maxHeight: 36,
        maxWidth: 131,
        height: 50,
        width: 150,
        fontFamily: 'Poppins',
        fontSize: 10,
        fontWeight: 600


    },
    lineDecor: {
        color: 'rgb(1, 114, 177)',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center'
    },
    semesterIconStyle: {
        paddingRight: 5
    },
    modalLabelStyle: {
        paddingTop: 15
    },
    buttonSubmit: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 35,
        padding: '0 30px',
        width: "100",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,
    },
    updateBtn: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        // boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        // height: 35,
        padding: '0 30px',
        width: "160px",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '45%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    asterik: {
        color: 'red'
    },
    popupTitle: {
        marginBottom: '10px'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    listContainer: {

        borderTopStyle: 'solid',
        borderTopWidth: '5px',
        borderTopColor: 'rgba(255, 113, 113, 1)',
        borderRadius: 10
    },
    iconStyle: {
        marginRight: '5%'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '1%',

    },
    modalViewHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 1px)'
    },

    titleContainer: {
        marginLeft: '2%'
    },
    dataContainer: {
        marginLeft: '7%'
    },

    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#9FA2B4',
        fontSize: 14,
        textAlign: 'center'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },

    dataSubText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
    },
    input: {
        width: '65%',
        height: '50%',
        paddingBottom: '5%',
        paddintTop: '5%',
        fontSize: 12
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '1%',
        paddingLeft: '1%'

    },
    inputButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        paddingTop: 20,
        paddingRight: 20,
    },
    inputButton: {
        // display: 'flex',
        // maxHeight: 50,
        width: 180,
        // borderRadius: 7,
        // fontSize: 14,
        // fontFamily: 'Poppins',
        // fontWeight: 500,
        // display: 'flex',
        // background: '#FDB714',

    },

    button2: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 5,
        color: 'black',
        padding: '10px 30px'
    },


}));

export default Academic;