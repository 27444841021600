import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';


// Assets
import ProductLogo from '../../../assets/img/loginFormIcon.png'
import LoginImg from '../../../assets/img/loginBg.png';

// Componennts
import Grid from "@material-ui/core/Grid";
import Button from '../../common/button';
import { makeStyles } from '@material-ui/core/styles';
import AlertBox from '../../common/alert-box';
import { useLocation } from "react-router-dom";

const ResendEmailForm = ({ status, resErr, resendEmail }) => {
    const styles = useStyles();
    const history = useHistory();
    const [resendEmailStatus, setResendEmailStatus] = useState('');

    useEffect(() => {
        if (status.resendEmail == 'success') {
            setResendEmailStatus('OTP was resent successfully...')
        }
    }, [status]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const paramField = query.get('email');
    useEffect(() => {
        let obj = {
            emailHash: paramField,
            emailType: 'RESEND_OTP'
        }
        resendEmail(obj)
    }, [paramField])

    return (
        <div className={styles.container}>
            {/* <div className={styles.left__col}>
                <img
                    className={styles.backgroundImage}
                    alt="LoginBg Image"
                    src={LoginImg}
                />
            </div> */}
            <div className={styles.right__col}>
                <Grid
                    className={styles.wrapper__inner}
                    container={true}
                    justify="center"
                    alignItems="center"
                    direction="row"
                >
                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '1em', display: 'flex', justifyContent: 'center' }}>
                        <img alt="Product Logo" src={ProductLogo} />
                    </Grid>

                    <Grid
                        item={true}
                        lg={12} md={12} sm={12} xs={12}
                        style={{ padding: '1.5em 0' }}
                    >
                        <h2 style={{ color: '#424241' }}>Resend Email</h2>
                    </Grid>

                    <Grid
                        container={true}
                        item={true}
                        lg={12} md={12} sm={12} xs={12}
                    >
                        {!_.isUndefined(resendEmailStatus) &&
                            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                                <AlertBox
                                    message={resendEmailStatus}
                                    type="success"
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    left__col: {
        width: '120%',

        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    right__col: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        padding: '1em 2em',
        margin: 'auto',
        '@media screen and (max-width: 1024px)': {
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'center'
        }
    },
    wrapper__inner: {
        width: '80%',

        '@media screen and (max-width: 1024px)': {
            width: '100%'
        },

        '@media screen and (min-width: 1600px)': {
            width: '60%'
        }
    },
    backgroundImage: {
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundSize: 'cover',
    }
});

export default ResendEmailForm;