import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
//import GlobalStyles from '../styles/globalStyles';
import LoginImg from '../assets/img/image2.png';

import PaaswordSettingForm from '../components/auth-form/reset-password';

const ResetPasswordPage = () => {
    const styles = useStyles();

    const [password, setPassword] = useState('');
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Password Setting</title>
            </Helmet>
            <PaaswordSettingForm />


        </div>
    )
}

const useStyles = makeStyles({
    button: {
        background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 3,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 48,
        padding: '0 30px',
        width: "100%"

    },
    passwordStrengthBar: {
        marginTop: '2%'
    },
    passwordContainer: {
        width: '70%'
    },
    inputTitleContainer: {
        marginTop: '20%'
    },
    titleCotainer: {
        marginLeft: '10%'
    },
    container: {
        height: window.height,
        width: window.width,
        display: "flex",
        flexDirection: 'row'
    },
    img: {
        display: "flex",
        width: '50%',
        padding: 0,
        marginLeft: '-1%',
        marginTop: '-1%'
    },

    inputContainer: {
        marginTop: '5%',
        marginLeft: '10%',
        width: '100%',
        height: '100%'

    },

    title: {
        fontWeight: 'bold',
        fontSize: 48,
        fontFamily: 'Poppins',
    },

    input: {
        fontFamily: 'Poppins',
    }

});

export default ResetPasswordPage;