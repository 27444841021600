import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import useSession from 'hooks/use-session';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Components
import useStyles from './style';

import TeacherDetails from '../teacher-details';

import Button from '../../common/button';
import Loader from '../../common/loader';
import DataGrid from '../../common/data-grid';
import AlertBox from '../../common/alert-box';
import { Select, InputSearch, DatePicker } from '../../common/form-unit';
import SelectAvailableClass from '../../common/select-available-class';
import Modal from '../../common/modal';
import SelectYear from '../../common/select-year';
import Card from 'components/common/card';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar';
import Session from 'services/local/session';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// import Skeleton from '@material-ui/lab/Skeleton';
import eduAPI from "../../../api/api";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import LinkMaterial from "@material-ui/core/Link";

const View = ({ status, teachers, resErr, assignedCount, onFetch, totalCount, avilableYears, onReset }) => {
    const history = useHistory();
    const session = useSession();
    const cls = useStyles();
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear().toString();

    const initialFilter = { selectedYear: currentYear };
    const initialLoading = false;
    const initialModal = { open: false, content: null };

    const [filter, setFilter] = useState(initialFilter);
    const [loading, setLoading] = useState(initialLoading);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [modal, setModal] = useState(initialModal);
    const [backupTeachers, setBackUpTeachers] = useState([])
    const [teachersList, setTeachersList] = useState([]);
    const [clear, setClear] = useState(false)
    const [deptOptions, setDept] = useState([])
    // useEffect(() => {
    //     setTeachersList(teachers)
    //     setBackUpTeachers(teachers);
    // }, [teachers?.length > 0])

    const [avlYears, setAvlYears] = useState([])

    const SearchData = () => {

        let teachersListAray = backupTeachers.filter(item => {
            return (
                (filter.department === undefined ? true : item.departments.find(element => element.departmentID == filter.department)) &&
                (filter.gender !== undefined ? (item.gender.toLowerCase() == filter.gender.toLowerCase()) : true) &&
                (filter.status !== undefined ? (item.status.toLowerCase() == filter.status.toLowerCase()) : true) &&
                (filter.teacherName !== undefined ? ((item.firstName.toLowerCase() + item.lastName.toLowerCase()).indexOf(filter.teacherName.toLowerCase()) > -1) : true)
            );
        })

        setClear(true);
        setTeachersList([]);
        setTeachersList(teachersListAray);


        // console.log(filter)
        // if (filter.gender && filter.status && filter.teacherName && filter.department) {
        //     let teachersListAray = backupTeachers.filter(item => {
        //         let departmentStatus = item.departments.find(element => element.departmentID == filter.department);
        //         return (
        //             (departmentStatus !== undefined) &&
        //             (item.gender.toLowerCase() == filter.gender.toLowerCase()) &&
        //             (item.status.toLowerCase() == filter.status.toLowerCase()) &&
        //             (item.firstName.toLowerCase() + item.lastName.toLowerCase()).indexOf(filter.teacherName.toLowerCase()) > -1
        //         );
        //     })
        //     setClear(true)
        //     setTeachersList([])
        //     setTeachersList(teachersListAray)
        // }
    }

    useEffect(() => {
        setAvlYears(avilableYears)
    }, [avilableYears])

    useEffect(() => {
        SearchData();
    }, [filter.gender])

    useEffect(() => {
        SearchData();
    }, [filter.status])

    useEffect(() => {
        SearchData();
    }, [filter.department])

    useEffect(() => {
        SearchData();
    }, [filter.teacherName])




    useEffect(() => {
        if (status.fetchUnassigned === 'success') {
            const sortedTeachers = teachers.sort((teacherA, teacherB)=>{return teacherA.firstName.localeCompare(teacherB.firstName)});
            setTeachersList(sortedTeachers);
            setBackUpTeachers(sortedTeachers);
            fetchDepartments();
        }
    }, [status])

    useEffect(() => {
        if (session.orgID && filter.selectedYear) {
            onFetch(session.orgID, filter.selectedYear)
        }
    }, [session.orgID, filter.selectedYear]);

    useEffect(() => {
        if (status.fetchUnenrolled === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status.fetchUnenrolled]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setFilter({ ...filter, [name]: value });
    }

    const onSelectCheckBox = (ids = []) => {
        setSelectedIDs(ids);
    }

    const onCloseModal = () => {
        // setModal({ ...modal, open: false });
        // onFetch(session.orgID, filter.selectedYear);
        // onReset();

        setModal({ ...modal, open: false });
        setTeachersList([])
        setBackUpTeachers([]);
        setTimeout(() => {
            onFetch(session.orgID, filter.selectedYear);
            setTimeout(() => {
                onReset();
            }, 1000);
        }, 100);
    }

    const onMenuClick = ({ type, data }) => {
        switch (type) {
            case 'EDIT':
                setModal({
                    ...modal,
                    open: true,
                    content: <TeacherDetails profileID={data} onCloseModal={onCloseModal} />
                });

                break;

            default:
                break;
        }
    }

    const columns = [
        {
            field: "fullName",
            headerName: "Name",
            width: 250,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    // <span className={cls.name} onClick={() => onMenuClick({ type: 'EDIT', data: params.row.teacherID })}>
                    //     {params.row.firstName + ' ' + params.row.lastName}
                    // </span>
                    <ListItem>
                        <ListItemAvatar>
                            {params.row.profile_picture ? <Avatar>
                                <img src={params.row.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                            </Avatar> : <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{params.row.firstName.charAt(0)}</Avatar>}

                        </ListItemAvatar>
                        <ListItemText className={cls.name} primary={params.row.firstName + ' ' + params.row.lastName} secondary="" onClick={() => onMenuClick({ type: 'EDIT', data: params.row.teacherID })} />
                    </ListItem>
                )
            },
        },
        {
            field: "teacherID",
            headerName: "Teacher ID",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>
                        {params.row?.codes?.input ?? params.row?.codes?.generated}
                    </span>
                )
            }
        },
        {
            field: "departments",
            headerName: "Departments",
            width: 300,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.departments.map((val, index) => (
                            <span>{val.name}{params.row.departments.length - 1 == index ? '' : ','} </span>
                        ))}
                    </div>
                )
            }
        },
        {
            field: "gender",
            headerName: "Gender",
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{_.upperFirst(_.lowerCase(params.value))}</span>
                )
            }
        },
        {
            field: "year",
            headerName: "Year",
            disableColumnMenu: true,
            renderCell: (params) => { // TODO put dash for now
                return <span>{`-`}</span>
            }
        },
        {
            field: "phone",
            headerName: "Mobile No.",
            width: 120,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{params.row?.phone?.fullNumber}</span>
            }
        },
        {
            field: "emailAddress",
            headerName: "Email Address",
            disableColumnMenu: true,
            width: 180
        },
        {
            field: "status",
            headerName: "Status",
            // width: 180,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{_.upperFirst(_.toLower(params.row?.status))}</span>
            }
        }
    ];

    if (loading) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px" />
            </div>
        )
    }



    const reset = () => {
        let obj = {
            gender: '',
            status: '',
            class: '',
            teacherName: ''
        }
        setFilter(obj);
        setClear(false)
        setTeachersList(backupTeachers)
    }

    let headersData = {
        headers: {
            Authorization: Session.BearerToken(),
        }
    }

    const fetchDepartments = () => {
        try {
            eduAPI.get(`/department/byOrg?orgID=${session.orgID}`, headersData).then((data) => {
                data.data.data = data.data.data.map((item) => {
                    item.value = item.departmentID;
                    item.label = item.name;
                    return item;
                })
                setDept(data.data.data);
            });
        } catch (error) {
            console.error("Fetch Dept Error", error);
        }
    }

    const setYearForAssignedList = (event) => {
        setFilter({ ...filter, selectedYear: event.target.value })
    }


    return (
        <Fragment>
            {/* <span className={cls.title}>Teachers</span> */}

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -30,
                    marginBottom: 10,
                    marginLeft: -30,
                }}
            >
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }}
                >
                    Account
                </LinkMaterial>
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/teachers"
                >
                    Teachers
                </LinkMaterial>
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/teachers/unassigned"
                >
                    Unassigned
                </LinkMaterial>

            </Breadcrumbs>


            <Grid container={true} spacing={2} alignItems="center" style={{ marginTop: '1em' }}>
                <Grid item={true}>
                    <Card
                        title={t('title.total_teachers')}
                        body={t('txt.registered')}
                        count={totalCount}
                        type="success"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_primary')}
                        body={t('txt.teachers')}
                        count={0}
                        type="info"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_secondary')}
                        body={t('txt.teachers')}
                        count={0}
                        type="warning"
                    />
                </Grid>

                <Grid item={true} style={{ marginLeft: 'auto' }}>
                    <Button
                        label={t('action.new_teacher')}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        type="primary"
                        size="large"
                        onClick={() => history.push('/account/teachers/create')}
                    />
                </Grid>
            </Grid>

            <AlertBox
                type="success"
                style={{ marginTop: '1em' }}
                icon={false}
                message={(
                    <Fragment>
                        <span>There are currently <b>{assignedCount}</b> assigned teachers.</span>
                        <Link
                            style={{ marginLeft: '3px', color: '#5C7356' }}
                            to="/account/teachers"
                        >
                            View now
                        </Link>
                    </Fragment>
                )}
            />

            {/* <Breadcrumbs
                separator={<NavigateNextIcon style={{ color: '#0880AE' }} size="small" />}
                style={{ marginTop: '1em' }}
            >
                <span className={cls.link}>
                    Account
                </span>
                <NavLink className={cls.link} to="/account/teachers">
                    Teachers
                </NavLink>
                <NavLink className={cls.link} activeStyle={{ fontWeight: 600 }} to="/account/teachers/unassigned">
                    Unassigned
                </NavLink>
            </Breadcrumbs> */}

            <div className={cls.table__wrapper}>
                <span className={cls.title} style={{ fontSize: 18 }}>
                    Unassigned Teachers {`(${teachers?.length})`}
                </span>

                <Grid container={true} spacing={1}>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Year</label> */}
                        {/* <SelectYear
                            onChange={(value) => setFilter({ ...filter, selectedYear: value })}
                        /> */}
                        <Select
                            placeholder="By Year"
                            name="Year"
                            value={filter.selectedYear}
                            onChange={(value) => setYearForAssignedList(value)}
                            options={
                                avlYears.map((year) => {
                                    return { value: year, label: year };
                                })
                            }
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={2} sm={12} xs={12}>
                        {/* <label>Department</label> */}
                        <Select
                            displayEmpty={false}
                            name="department"
                            placeholder="By Departments"
                            value={filter.department || ''}
                            options={[{ label: <em>All</em>, value: undefined }, ...deptOptions]}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Gender</label> */}
                        <Select
                            displayEmpty={false}
                            name="gender"
                            value={filter.gender || ''}
                            placeholder="By Gender"
                            onChange={onChange}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Status</label> */}
                        <Select
                            displayEmpty={false}
                            name="status"
                            value={filter.status || ''}
                            placeholder="By Status"
                            onChange={onChange}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Active', value: 'ACTIVE' },
                                { label: 'Inactive', value: 'INACTIVE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        {/* <label>Search by Name</label> */}
                        <InputSearch
                            name="teacherName"
                            value={filter.teacherName || ''}
                            placeholder="Search by Teacher Name or ID"
                            onChange={onChange}
                            style={{ backgroundColor: '#F7F7F7' }}
                        />
                    </Grid>
                    <Grid item lg={1} style={{ textAlign: 'left' }}>
                        <br></br>
                        <IconButton
                            color="#FFC000"
                        >
                            <SearchOutlinedIcon
                                onClick={SearchData}
                                style={{
                                    color: "black",
                                    background: 'rgba(253, 183, 20, 1)',
                                    padding: 7,
                                    width: 55,
                                    height: 40,
                                    borderRadius: 4,
                                    marginTop: -24,
                                    marginLeft: -21,
                                }} />
                        </IconButton>

                    </Grid>
                    {/* <Grid item lg={1} style={{ textAlign: 'left' }}>
                        <br></br>
                        {clear ?
                            <IconButton
                                color="#FFC000"
                            >
                                <ClearIcon
                                    onClick={reset}
                                    style={{
                                        color: "black",
                                        background: 'linear-gradient(213.06deg, rgba(255, 192, 0, 0.8) 4.6%, rgba(255, 138, 0, 0.8) 81.54%)',
                                        width: 55,
                                        height: 40,
                                        borderRadius: 4
                                    }} />
                            </IconButton>
                            : ''}
                    </Grid> */}

                </Grid>

                {/* <Grid container={true} spacing={1}>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <SelectYear
                            onChange={(value) => setFilter({ ...filter, selectedYear: value })}
                        />
                    </Grid>

                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        <Select
                            name="department"
                            placeholder="Select Department"
                            value={filter.department || ''}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Select
                            name="gender"
                            value={filter.gender || ''}
                            onChange={onChange}
                            options={[
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Select
                            name="status"
                            value={filter.status || ''}
                            onChange={onChange}
                            options={[
                                { label: 'Active', value: 'ACTIVE' },
                                { label: 'Inactive', value: 'INACTIVE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        <InputSearch
                            name="search"
                            value={filter.search || ''}
                            placeholder="Search Teacher by Name or ID"
                            onChange={onChange}
                        />
                    </Grid>
                </Grid> */}

                <Grid container={true}>
                    <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                        <DataGrid
                            rows={teachersList || []}
                            getRowId={(row) => row.teacherID}
                            columns={columns}
                            checkboxSelection={false}
                            onSelectionModelChange={({ selectionModel }) => onSelectCheckBox(selectionModel)}
                        />
                    </Grid>
                </Grid>
            </div>

            {modal.content &&
                <Modal
                    showFooter={false}
                    open={modal.open}
                    onHandleClose={onCloseModal}
                    fullScreen={true}
                    maxWidth="lg"
                    scroll="body"
                >
                    {modal.content}
                </Modal>
            }
        </Fragment>
    )
}

export default View;