import profile from './profile';
import auth from './auth';
import department from './department';
import message from './message';

export default {
    profile,
    auth,
    department,
    message
}