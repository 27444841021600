import { createApi, fetchBaseQuery } from '@rtk-incubator/rtk-query';
import _ from 'lodash';
import Session from 'services/local/session';
import normalize from 'utils/normalizers';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/`,
    prepareHeaders: (headers, { getState }) => {
        if (Session.BearerToken()) {
            headers.set('Authorization', Session.BearerToken());
        }
        return headers;
    },
});

export const departmentApi = createApi({
    reducerPath: 'departmentsV2',
    baseQuery: baseQuery,
    entityTypes: ['Departments'],
    endpoints: (build) => ({
        getDepartments: build.query({
            query: (orgID) => {
                if (orgID) { return `department/byOrg?orgID=${orgID}` }
            },
            transformResponse: (res) => {
                let departments = [];
                _.map(res.data, (o) => {
                    const profile = normalize.client.profile(o?.headOfDepartment);
                    const department = normalize.client.department({
                        ...o,
                        headOfDepartmentID: profile.profileID,
                        headOfDepartmentName: profile.fullName
                    });

                    departments.push(department);
                });

                return departments;
            }
        })
    })
});

export const { useGetDepartmentsQuery } = departmentApi;