import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, isEmpty } from 'lodash';

// Components
import { Select } from 'components/common/form-unit';

const SelectDepartment = ({ departments, status, onSelect, ...props }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');

    const onHandleChange = (e) => {
        setValue(e.target.value);
        onSelect(e.target.value);
    }

    return (
        <Fragment>
            <Select
                label={t('label.department')}
                required={true}
                value={value}
                onChange={onHandleChange}
                options={!isEmpty(departments) && status.fetch === 'success' && map(departments, o => {
                    return { label: o.departmentName, value: o.departmentID }
                })}
                {...props}
            />
        </Fragment>
    )
}

export default SelectDepartment;