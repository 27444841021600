import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ type, color, size, thickness, value, ...props }) => {
    return (
        <CircularProgress
            variant={type}
            color={color}
            size={size}
            thickness={thickness}
            value={value}
            {...props}
        />
    )
}

Loader.defaultProps = {
    type: 'indeterminate',
    // color: 'white'
}

export default Loader;