import axios from 'axios';
import Session from 'services/local/session';

const getClasses = async (year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/byOrg/${Session.getOrg().orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        params: { year },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}


const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}


export default {
    getClasses,
    update,
    create
};