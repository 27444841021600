import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import useSession from 'hooks/use-session';
import CalendarAction from 'redux/actions/acedemic-calendar';

// Components
import View from './select-year.view';

const mapStateToProps = (state) => {
    return {
        status: state.acedemicCalendar?.status, 
        resErr: state.acedemicCalendar?.error,
        availableYears: state.acedemicCalendar?.availableYears
    };
}

const mapDispatchToProps = (dispatch) => {
    const calendarActions = bindActionCreators(CalendarAction, dispatch);

    return {
        onFetch$: (orgID) => {
            calendarActions.getAvailableYears(orgID);
        }
    }
}

const Container = ({ data, error, errorLabel, onChange, status, resErr, availableYears, onFetch$ }) => {
    const session = useSession();

    useEffect(() => {
        if (session.orgID) {
            onFetch$(session.orgID);
        }
    }, [session.orgID]);

    return (
        <View 
            data={data}
            error={error}
            errorLabel={errorLabel}
            status={status.fetchYears}
            resErr={resErr}
            availableYears={availableYears}
            onChange={onChange}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);