import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        },
        marginBottom: '.8em'
    }
}));

export default useStyles