import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import useGlobalStyles from '../styles/globalStyles';
import EnrolledList from '../components/student/enrolled-list';

const StudentPage = () => {
    const style = useGlobalStyles();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Helmet>
                <title>{t('title.enrolled_students')}</title>
            </Helmet>

            <div className={style.mainContainer}>
                <EnrolledList/>
            </div>
        </Fragment>
    )
}

export default StudentPage
