import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import announcementAction from '../../../redux/actions/announcement';
import View from './announcement-create.view';


const mapStateToProps = (state) => {
    return {
        status: state.announcement?.status,
        error: state.announcement?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const AnnouncementActions = bindActionCreators(announcementAction, dispatch);

    return {
        onReset$: () => {
            AnnouncementActions.resetStatus();
        },
        onCreate$: (data) => {
            AnnouncementActions.createAnnouncement(data);
        },
        onUpdation$: (data) => {
            AnnouncementActions.updateAnnouncement(data);
        },
        onUploadFile$: (data) => {
            AnnouncementActions.uploadFiles(data);
        }
    }
}

const Container = ({ status, error, onReset$, onCreate$, onUpdation$, onUploadFile$ }) => {
    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdation = (data) => {
        onUpdation$(data);
    }

    const onUploadFile = (data) => {
        onUploadFile$(data);
    }

    // useEffect(() => {
    //     if (status.create === 'success' || status.update === 'success') {
    //         onReset$();
    //     }
    // }, [status]);
    return (
        <View status={status} resErr={error} onCreate={onCreate} onUpdation={onUpdation} onReset={onReset$} onUploadFile={onUploadFile} />
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Container);
