import React from 'react';

// Components
import { KeyboardDatePicker, DatePicker as MUDatePicker } from '@material-ui/pickers';
import Label from '@material-ui/core/InputLabel';
import InputError from '../input-error';

import useStyles from './style';

const DatePicker = ({ 
    value, 
    label, 
    name, 
    required, 
    error, 
    onChange, 
    format, 
    children, 
    focused,
    disablePast,
    disableFuture,
    placeholder,
    views,
    mode,
    ...props 
}) => {
    const classes = useStyles();
    
    return (
        <div style={{ padding: '8px 0px' }}>
            {label && 
                <Label required={required} error={error} className={classes.label} focused={error}>
                    {label}
                </Label>
            }
            
            {mode === 'keyboard' && 
                <KeyboardDatePicker
                    className={classes.datePicker}
                    views={views}
                    focused={error}
                    name={name}
                    value={value}
                    error={error}
                    required={required}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    format={format}
                    placeholder={placeholder}
                    onChange={(date, value) => onChange({ date, value, name })}
                    {...props}
                />
            }

            {mode === 'default' &&
                <MUDatePicker
                    views={views}
                    focused={error}
                    name={name}
                    value={value}
                    error={error}
                    required={required}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    format={format}
                    placeholder={placeholder}
                    onChange={(date, value) => onChange({ date, value, name })}
                    {...props}
                />
            }

            <InputError label={label} error={error}/>

            {children}
        </div>
    )
}

DatePicker.defaultProps = {
    onChange: () => {},
    format: 'DD/MM/YYYY',
    label: null,
    required: false,
    fullWidth: true,
    variant: 'inline',
    inputVariant: 'outlined',
    autoOk: true,
    size: 'small',
    placeholder: null,
    mode: 'keyboard',
    disableToolbar: true
}

export default DatePicker;
