import { isEmpty, omitBy, isNull, isUndefined } from 'lodash';

import phoneNumber from './phone-number';

const component = (data) => {
    if (isEmpty(data)) {
        return {};
    }

    const phoneData = !isEmpty(phoneNumber(data)) ? phoneNumber(data) : null;

    return omitBy(omitBy({
        emergencyContactType: phoneData.contactType,
        emergencyContactCountryCode: phoneData.countryCode,
        emergencyContactNumber: phoneData.contactNumber,
        emergencyContactFullNumber: phoneData.fullNumber,
        emergencyContactName: data.name,
        emergencyContactRelationship: data.relationship
    }, isNull), isUndefined);
}

export default component