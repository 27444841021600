import './styles/_global.css';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NavProvider from "./context/NavContext";

//Layouts
import LoginLayoutRoute from "./Layouts/loginLayout";
import MainLayoutRoute from "./Layouts/mainLayout";

//Components
import FirstTimeLogin from './pages/firstTimeLogin'
import Login from "./pages/login";
import Verification from "./pages/verification";
import Overview from "./pages/overview";
import Timetable from "./pages/timetable";
import TimetableTemplate from './pages/timetable-template';
import TimetablePublish from './pages/timetable-publish';
import Attendance from "./pages/attendance";
import Announcement from "./pages/announcement";
import AnnouncementCreate from "./pages/announcement-create";
import AnnouncementView from "./pages/announcement-view";
import Fees from "./pages/fees";
import Discipline from "./pages/discipline";
import Messages from "./pages/messages";
import Results from "./pages/results";
import Calendar from "./pages/calendar";
// import Students from "./pages/student";
// import Teachers from "./pages/teacher";
import CreateTeacher from 'pages/account/teacher/teacher-create';
import UpdateTeacher from 'pages/account/teacher/teacher-update'
import Draft from "./pages/draft";
import MasterList from "./pages/masterList";
import Newtimetable from "./pages/newtimetable";
import NewStudentForm from "./pages/account/student/student";
import CreateStudent from 'pages/account/student/create-student';
import UpdateStudent from 'pages/account/student/update-student';
import StudentEnrol from "./pages/studentEnrol";
import SetMessages from "./pages/master-list/messages";
import ProfileComplete from "./pages/profileComplete";
import AcademicCalendar from "./pages/academic-calendar";
import ErrorPage from './pages/404';

import EnrolledStudentPage from './pages/enrolledStudentV2';
import UnEnrolledStudentPage from './pages/unenrolledStudentV2';

import AssignedTeacherPage from './pages/assignedTeacher';
import UnassignedTeacherPage from './pages/unassignedTeacher';

import RolesListPage from "./pages/system-admin/roles";
import NewRolesListPage from "./pages/system-admin/new-role";
import GroupListPage from './pages/system-admin/groups';

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ProfileContext from "./context/ProfileContext";
import TimetableContextStore from "./context/TimetableContext";

import SystemAdminUsers from './pages/system-admin/users';
import SystemAdminUserPage from './pages/system-admin/new-user';
import SystemAdminUpdateUserPage from './pages/system-admin/update-user';
import ReserVerificationPage from './pages/reset-verification';
import ResetPasswordPage from './pages/reset-password';

import TimeTableViewTemplatePage from './pages/timetable-template-view';
import TimeTableEditTemplatePage from './pages/timetable-template-edit';

import ResendEmailPage from './pages/resend-email';

import SchoolPage from './pages/super-admin/school'

/*
  --------
    TODO 
  --------
  1. Implement permission matrix
  2. You define path based on the access, if dont have access then dont define the path, otherwise define
*/

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <NavProvider>
        <ProfileContext>
          <TimetableContextStore>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>

                <LoginLayoutRoute path="/login" component={Login} />

                <LoginLayoutRoute path="/verification" component={Verification} />
                <LoginLayoutRoute path="/password-setting" component={FirstTimeLogin} />

                <LoginLayoutRoute path="/reset-verification" component={ReserVerificationPage} />
                <LoginLayoutRoute path="/password-resetting" component={ResetPasswordPage} />
                <LoginLayoutRoute path="/resend-email" component={ResendEmailPage} />




                <MainLayoutRoute path="/overview" component={Overview} />
                <MainLayoutRoute path="/timetable" component={Timetable} />
                <MainLayoutRoute path="/timetable-template" component={TimetableTemplate} />
                <MainLayoutRoute path="/timetable-view" component={TimeTableViewTemplatePage} />
                <MainLayoutRoute path="/timetable-edit" component={TimeTableEditTemplatePage} />

                <MainLayoutRoute path="/timetable-publish" component={TimetablePublish} />
                <MainLayoutRoute path="/attendance" component={Attendance} />
                <MainLayoutRoute path="/announcement" component={Announcement} />
                <MainLayoutRoute path="/announcement_create" component={AnnouncementCreate} />
                <MainLayoutRoute path="/announcement_view" component={AnnouncementView} />
                <MainLayoutRoute path="/fees" component={Fees} />
                <MainLayoutRoute path="/discipline" component={Discipline} />
                <MainLayoutRoute path="/messages" component={Messages} />
                <MainLayoutRoute path="/results" component={Results} />
                <MainLayoutRoute path="/calendar" component={Calendar} />

                <MainLayoutRoute exact={true} path="/account/students/create" component={CreateStudent} />
                <MainLayoutRoute exact={true} path="/account/students/update/:studentID" component={UpdateStudent} />
                <MainLayoutRoute exact={true} path="/account/students" component={EnrolledStudentPage} />
                <MainLayoutRoute exact={true} path="/account/students/unenrolled" component={UnEnrolledStudentPage} />

                <MainLayoutRoute exact={true} path="/account/teachers/create" component={CreateTeacher} />
                <MainLayoutRoute exact={true} path="/account/teachers/update/:teacherID" component={UpdateTeacher} />
                <MainLayoutRoute exact={true} path="/account/teachers" component={AssignedTeacherPage} />
                <MainLayoutRoute exact={true} path="/account/teachers/unassigned" component={UnassignedTeacherPage} />

                <MainLayoutRoute path="/account/drafts" component={Draft} />
                <MainLayoutRoute path="/masterlist" component={MasterList} />
                <MainLayoutRoute path="/create" component={Newtimetable} />
                {/* <MainLayoutRoute path="/student/enrol" component={StudentEnrol} /> */}
                <MainLayoutRoute path="/set-messages" component={SetMessages} />
                <MainLayoutRoute path="/academic_calendar" component={AcademicCalendar} />

                <MainLayoutRoute path="/complete" component={ProfileComplete} />
                <MainLayoutRoute path="/systemadmin/roles" component={RolesListPage} />
                <MainLayoutRoute path="/systemadmin/newrole" component={NewRolesListPage} />
                <MainLayoutRoute path="/systemadmin/groups" component={GroupListPage} />
                <MainLayoutRoute exact={true} path="/systemadmin/users" component={SystemAdminUsers} />
                <MainLayoutRoute exact={true} path="/systemadmin/users/create" component={SystemAdminUserPage} />
                <MainLayoutRoute exact={true} path="/systemadmin/users/update/:studentID" component={SystemAdminUpdateUserPage} />

                <MainLayoutRoute path="/superadmin/school" component={SchoolPage} />

                {/* Handling unknown route AKA Error   page */}
                <Route path="*">
                  <ErrorPage />
                </Route>
              </Switch>
            </Router>
          </TimetableContextStore>
        </ProfileContext>
      </NavProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
