import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileAction from '../../../redux/actions/profile';
import CalendarAction from 'redux/actions/acedemic-calendar';
import useSession from 'hooks/use-session';
// Components
// import LoginForm from './login-form.view';
import View from './unenrolled-list.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        students: state.profile?.unenrolled,
        enrolledCount: state.profile?.enrolled.length,
        unenrolledCount: state.profile?.unenrolled.length,
        totalCount: state.profile?.enrolled.length + state.profile?.unenrolled.length,
        availableYears: state.acedemicCalendar?.availableYears
    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);
    const calendarActions = bindActionCreators(CalendarAction, dispatch);

    return {
        onFetch$: (orgID, year) => {
            profileActions.getUnenrolledStudents(orgID, year);
            profileActions.getEnrolledStudents(orgID, year);
        },
        onEnroll$: (data) => {
            profileActions.enrollStudent(data);
        },
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetchYears$: (orgID) => {
            calendarActions.getAvailableYears(orgID);
        }
    }
}

const Container = ({ status, error, students,
    enrolledCount, unenrolledCount, totalCount, onFetch$, onEnroll$,
    onReset$,
    onFetchYears$,
    availableYears }) => {
    useEffect(() => {
        onReset$();
    }, []);

    const onEnroll = (data) => {
        onEnroll$(data);
    }
    const session = useSession();

    useEffect(() => {
        if (session.orgID) {
            onFetchYears$(session.orgID)
        }
    }, [session.orgID]);

    return (
        <View
            status={status}
            resErr={error}
            students={students}
            enrolledCount={enrolledCount}
            unenrolledCount={unenrolledCount}
            totalCount={totalCount}
            onEnroll={onEnroll}
            onFetch={onFetch$}
            onReset={onReset$}
            avilableYears={availableYears}
            avilableClasses={[]}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);