import {

    RESET_STATUS,

    ANNOUNCEMENT_CREATE,
    ANNOUNCEMENT_CREATE_FAILED,
    ANNOUNCEMENT_CREATE_SUCCESS,

    ANNOUNCEMENT_UPLOAD,
    ANNOUNCEMENT_UPLOAD_FAILED,
    ANNOUNCEMENT_UPLOAD_SUCCESS,

    ANNOUNCEMENT_UPDATE,
    ANNOUNCEMENT_UPDATE_FAILED,
    ANNOUNCEMENT_UPDATE_SUCCESS,

    ANNOUNCEMENT_DELETE,
    ANNOUNCEMENT_DELETE_FAILED,
    ANNOUNCEMENT_DELETE_SUCCESS,

    ANNOUNCEMENTS_FETCH,
    ANNOUNCEMENTS_FETCH_FAILED,
    ANNOUNCEMENTS_FETCH_SUCCESS,


} from '../actions/announcement';

import _ from 'lodash';

const initialStatus = {
    create: 'idle',
    fetch: 'idle',
    fetchByID: 'idle',
    update: 'idle',
    delete: 'idle',
    upload: 'idle'
};

const initialState = {
    list: [],
    data: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {

    switch (action.type) {


        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }


        case ANNOUNCEMENT_UPLOAD: {
            return {
                ...state,
                error: {},
                status: { ...state.status, upload: 'loading' }
            };
        }

        case ANNOUNCEMENT_UPLOAD_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, upload: 'success' }
            };
        }

        case ANNOUNCEMENT_UPLOAD_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, upload: 'failed' }
            };
        }


        case ANNOUNCEMENT_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case ANNOUNCEMENT_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success', response: action.response }
            };
        }

        case ANNOUNCEMENT_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed', delete: 'idle', }
            };
        }

        case ANNOUNCEMENTS_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading', update: 'idle', delete: 'idle', create: 'idle' }
            };
        }

        case ANNOUNCEMENTS_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                list: action.payload,
                status: { ...state.status, fetch: 'success', update: 'idle', delete: 'idle', create: 'idle' }
            };
        }

        case ANNOUNCEMENTS_FETCH_FAILED: {
            return {
                ...state,
                list: [],
                error: { fetch: action.error },
                status: { ...state.status, fetch: 'failed', update: 'idle', delete: 'idle', create: 'idle' }
            }
        }


        case ANNOUNCEMENT_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading' }
            };
        }

        case ANNOUNCEMENT_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed' }
            }
        }

        case ANNOUNCEMENT_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success' }
            };
        }


        case ANNOUNCEMENT_DELETE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, delete: 'loading' }
            };
        }

        case ANNOUNCEMENT_DELETE_FAILED: {
            return {
                ...state,
                error: { delete: action.error },
                status: { ...state.status, delete: 'failed' }
            }
        }

        case ANNOUNCEMENT_DELETE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, delete: 'success' }
            };
        }

        default:
            return state;
    }
}

export default reducer;