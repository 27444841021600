import { isEmpty, omitBy, isUndefined, isNull } from 'lodash';

const normalizer = (data) => {
    if (isEmpty(data)) {
        return {};
    }

    let fullNumber;
    if (!isUndefined(data.countryCode) && !isUndefined(data.contactNumber)) {
        fullNumber = `${data.countryCode}${data.contactNumber}`;
    }

    return omitBy(omitBy({
        type: data.contactType,
        countryCode: data.countryCode,
        number: data.contactNumber,
        fullNumber
    }, isNull), isUndefined);
}

export default normalizer;