import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileAction from 'redux/actions/profile';
import CalendarAction from 'redux/actions/acedemic-calendar';
import useSession from 'hooks/use-session';


// Components
import View from './assigned-list.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        teachers: state.profile?.assigned,
        unassignedCount: state.profile?.unassigned.length,
        assignedCount: state.profile?.assigned.length,
        totalCount: state.profile?.unassigned.length + state.profile?.assigned.length,
        availableYears: state.acedemicCalendar?.availableYears

    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);
    const calendarActions = bindActionCreators(CalendarAction, dispatch);

    return {
        onFetch$: (orgID, year) => {
            profileActions.getAssignedTeachers(orgID, year);
            profileActions.getUnassignedTeachers(orgID, year);
        },
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetchYears$: (orgID) => {
            calendarActions.getAvailableYears(orgID);
        }
    }
}

const Container = ({ status, error, teachers, unassignedCount, totalCount, onFetch$, onFetchYears$,
    availableYears, onReset$ }) => {

    const session = useSession();

    useEffect(() => {
        if (session.orgID) {
            onFetchYears$(session.orgID)
        }
    }, [session.orgID]);

    return (
        <View
            status={status}
            resErr={error}
            teachers={teachers}
            onFetch={onFetch$}
            unassignedCount={unassignedCount}
            totalCount={totalCount}
            onReset={onReset$}
            avilableYears={availableYears}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);