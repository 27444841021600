import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        addressType: data.type,
        street1: data.street1,
        street2: data.street2,
        postcode: data.postcode,
        state: data.state,
        city: data.city,
        country: data.country,
        primaryAddress: data.primary
    }, _.isNull), _.isUndefined);
}

export default component;