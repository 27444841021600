import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import { AutoComplete } from '../../common/form-unit';

const SearchParent = ({ status, resErr, result, onSearch, onReset, onSelect }) => {
    const { t } = useTranslation();
    
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const delayedSearch = useCallback(debounce(txt => onSearch(txt), 800), []);

    useEffect(() => {
        if (status.search === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status.search]);

    useEffect(() => {
        if (!isEmpty(result)) {
            setOptions(result);
        }
        
        if (status.search === 'failed') {
            setOptions([]);
        }
    }, [result, status.search]);

    // useEffect(() => {
    //     if (onSelect) {
    //         onSelect(result || null);
    //     }
    // }, [result]);

    useEffect(() => {
        if (inputValue !== selectedValue?.emailAddress) {
            delayedSearch(inputValue);
        }
    }, [inputValue, selectedValue]);

    const onInputChange = (_, newValue, reason) => {
        setInputValue(newValue);

        if (reason === 'clear') {
            onSelect(null);
        }
    }   

    const onSelectChange = (_, newValue) => {
        setSelectedValue(newValue);
        onSelect(newValue);
    }

    const getOptionSelected = (option, value) => {
        return option._key === value._key;
    }

    const getOptionLabel = (option) => {
        return `${option.emailAddress}` || ''
    }
    
    return (
        <React.Fragment>
            <AutoComplete   
                placeholder={t('placeholder.search_existing_parent')}
                value={selectedValue || null}
                inputValue={inputValue || ''}
                onInputChange={onInputChange}
                onChange={onSelectChange}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                options={options}
                filterOptions={(options, state) => options} //! Need to put this for search based call API
                loading={loading}
            />
        </React.Fragment>
    )
}

export default SearchParent;