import React , { createContext, useState  }from 'react';

export const Navcontext = createContext({});

const NavContext = ({ children }) => {
    const [state, setState] = useState({ 
        // showModal: false,
        registrationComplete: true
    })

    const setGlobalState = (key = null, value) => {
        setState({ ...state, [key]: value });
    }

    return (
        <Navcontext.Provider value={{ ...state, setGlobalState }}>
            {children}
        </Navcontext.Provider>
    )
};

export default NavContext;