import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, map, omitBy, isNil } from 'lodash';

// Services
import { useMarkStudentAttendanceMutation } from 'services/api/attendance';

// Components
import Menu from 'components/common/menu';
import Button from 'components/common/button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im';

const PresentList = ({ data, shouldRefetch }) => {
    const { t } = useTranslation();
    const [onUpdate$, { isLoading: isUpdating, isSuccess }] = useMarkStudentAttendanceMutation();

    useEffect(() => {
        if (isSuccess) {
            shouldRefetch(true);
        }
        else {
            shouldRefetch(false);
        }
    }, [isSuccess]);

    const onUpdate = (initData) => {
        let studentData = [];
        studentData.push(omitBy({ studentID: initData?.ID, status: 'PRESENT' }, isNil));

        const payload = omitBy({
            attendanceID: initData.attendanceID,
            students: !isEmpty(studentData) ? studentData : null
        }, isNil);

        onUpdate$(payload);
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <span style={{ color: '#FF0000' }}>{t('label.absent')}</span>
                        </TableCell>
                        <TableCell colSpan={2}>{t('label.submitted')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isEmpty(data) && (
                        <TableRow>
                            <TableCell align="center" colSpan={2}>
                                {t('txt.no_data')}
                            </TableCell>
                        </TableRow>
                    )}

                    {!isEmpty(data) && map(data, (o, i) => {
                        const reasonType = o.reason?.content?.type;
                        const reasonStatus = o.reason?.status;

                        return (
                            <TableRow key={i}>
                                <TableCell>{o.name}</TableCell>
                                <TableCell>
                                    {reasonStatus === 'PENDING' && reasonType === 'TEXT' &&
                                        <Button label={t('label.reason')}/>
                                    }

                                    {reasonStatus === 'PENDING' && reasonType === 'FILE' &&
                                        <Button label={t('label.image')}/>
                                    }

                                    {reasonStatus === 'APPROVED' && 
                                        <AiOutlineCheckCircle fontSize="22px" color="#3C8636"/>
                                    }

                                    {reasonStatus === 'REJECTED' &&
                                        <ImCancelCircle fontSize="22px" color="red"/>
                                    }

                                    {reasonStatus === undefined &&
                                        <span style={{ display: 'block', marginTop: i > 0 && '.5em' }}>
                                            -
                                        </span>
                                    }
                                </TableCell>
                                <TableCell>
                                    <Menu
                                        actionElement={(onOpenMenu) => (
                                            <IconButton size="small" onClick={(e) => { onOpenMenu(e, i); }}>
                                                <MoreIcon fontSize="small"/>
                                            </IconButton>
                                        )}
                                        items={[
                                            { 
                                                content: t('label.mark_student_present'), 
                                                onClick: () => { 
                                                    onUpdate(o); 
                                                } 
                                            }
                                        ]}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PresentList
