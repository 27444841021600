import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonComponent from 'components/common/button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FlatList from 'flatlist-react';
import Grid from '@material-ui/core/Grid';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import eduAPI from "../../../api/api";
import { Input, Select, DatePicker, AutoComplete } from '../../common/form-unit';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useToaster from 'hooks/use-toaster';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const TimetableTemplate = ({ status, resErr, onCreate, onUpdate, template }) => {
    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }
    const sessionObj = useSession();

    const initialInput = {
        "templateName": "",
        "startTime": "09:00",
        "startDay": "",
        "numberOfPeriods": "",
        "orgID": sessionObj.orgID,
        "numberOfDays": 0,
        "durationOfPeriods": ""
    }
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const [duration, setDuration] = useState();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [input, setInput] = useState(initialInput);
    const openMenu = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedRowObj, SetSelectedRow] = useState();
    const startDayList = [
        { label: 'MONDAY', value: 'MONDAY' },
        { label: 'TUESDAY', value: 'TUESDAY' },
        { label: 'WEDNESDAY', value: 'WEDNESDAY' },
        { label: 'THURSDAY', value: 'THURSDAY' },
        { label: 'FRIDAY', value: 'FRIDAY' },
        { label: 'SATURDAY', value: 'SATURDAY' },
        { label: 'SUNDAY', value: 'SUNDAY' }
    ]
    const [data, setData] = useState([]);
    const [selectedIndex, setSelIndex] = useState();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canEdit = (uniquePermissions || []).includes('v_master_ttTemplate_update');
    const canCreateTimetableTemplate = (uniquePermissions || []).includes('v_master_ttTemplate_create');
    const [fetchStatus, setFetchStatus] = useState(true)

    useEffect(() => {
        if (sessionObj.orgID && fetchStatus) {
            fetchTableList();
            setFetchStatus(false)
            setInput({ ...input, orgID: sessionObj.orgID })
        }
    }, [sessionObj])

    useEffect(() => {
        if (status.update == 'failed') {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
        }
        if (status.create == "failed") {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
        }
    }, [resErr])

    useEffect(() => {
        if (status.fetch === 'success') {
            fetchTableList();
        } else if (status.create === 'success' || status.update === 'success') {
            handleClose();
            fetchTableList();
            handleCloseEdit();
            let msg = status.create == 'success' ? "Template Created Successfully.." : "Template Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster()
        } else {

        }
    }, [status]);


    const fetchTableList = async () => {
        try {
            await eduAPI.get(`/timetable-template/byOrg/${sessionObj.orgID}`, headersData).then((data) => {
                setData(data.data.data);
            });
        } catch (error) {
            console.error("fetch Template error", error);
        }
    }

    const classes = useStyles();

    const onSubmit = () => {
        onCreate(input)
    }

    const onUpdateFuction = () => {
        let obj = {};
        obj = input;
        obj.templateID = selectedRowObj.templateID
        onUpdate(input)

    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleOpenEdit = () => {
        let selRowObj = data[selectedIndex];
        SetSelectedRow(data[selectedIndex])
        let obj = {
            "templateName": selRowObj.templateName,
            "startTime": selRowObj.startTime,
            "startDay": selRowObj.startDay,
            "numberOfPeriods": selRowObj.numberOfPeriods,
            "numberOfDays": selRowObj.numberOfDays,
            "durationOfPeriods": selRowObj.durationOfPeriods

        }
        setInput(obj)
        handleCloseMenu()
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setInput(initialInput)
    }


    const renderItem = (data) => {
        return (
            <div>
                <Divider />

                <Grid container>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.templateName}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.startTime}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.startDay}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.numberOfDays}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.numberOfPeriods}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} className={classes.dataText}>
                        {data.durationOfPeriods}
                    </Grid>

                </Grid>
            </div>
        )
    };
    return (
        <>
            <Toaster></Toaster>

            <Paper>
                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <h2 id="transition-modal-title">New Template</h2>


                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Template: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="template"
                                            required={true}
                                            value={input.templateName || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, templateName: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Start Time: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>

                                        <Input
                                            id="time"
                                            type="time"
                                            defaultValue="00:30"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                            value={input.startTime || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, startTime: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Start Day: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="teacher"
                                            required={true}
                                            value={input.startDay || ''}
                                            options={startDayList}
                                            onChange={(e) => {
                                                setInput({ ...input, startDay: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>No of Days: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="No of Days"
                                            required={true}
                                            value={input.numberOfDays || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, numberOfDays: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>No of Periods: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="noOfPeroids"
                                            required={true}
                                            value={input.numberOfPeriods || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, numberOfPeriods: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Duration of Periods: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="durationOfPeroids"
                                            required={true}
                                            value={input.durationOfPeriods || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, durationOfPeriods: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button className={classes.buttonSubmit} onClick={onSubmit}>Done</Button>
                                </div>

                            </div>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleCloseEdit}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openEdit}>
                            <div className={[classes.paper, classes.modalHeight].join(' ')} >
                                <h2 id="transition-modal-title" className={classes.popupTitle}>Edit Template</h2>

                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Template: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="template"
                                            required={true}
                                            value={input.templateName || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, templateName: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Start Time: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>

                                        <Input
                                            id="time"
                                            type="time"
                                            defaultValue="00:30"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                            value={input.startTime || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, startTime: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Start Day: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="teacher"
                                            required={true}
                                            value={input.startDay || ''}
                                            options={startDayList}
                                            onChange={(e) => {
                                                setInput({ ...input, startDay: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>No of Days: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="No of Days"
                                            required={true}
                                            value={input.numberOfDays || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, numberOfDays: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>No of Periods: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="noOfPeroids"
                                            required={true}
                                            value={input.numberOfPeriods || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, numberOfPeriods: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Duration of Periods: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="durationOfPeroids"
                                            required={true}
                                            value={input.durationOfPeriods || ''}
                                            onChange={(e) => {
                                                setInput({ ...input, durationOfPeriods: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button className={classes.buttonSubmit} onClick={onUpdateFuction}>Update</Button>
                                </div>

                            </div>
                        </Fade>
                    </Modal>

                    <div className={classes.durationContainer}>
                        {/* 
                    <Typography className={classes.durationText}>Duration of Periods</Typography>
                    <div className={classes.inputContainer}>
                        <Input
                            name="duration"
                            placeholder="eg 30"
                            required={true}
                            value={input.durationOfPeriods || ''}
                            onChange={(e) => setInput({ ...input, durationOfPeriods: e.target.value })}
                        />
                    </div>
                    <Typography className={classes.durationText}>Minutes</Typography> */}
                        {canCreateTimetableTemplate ?
                            <div className={classes.buttonContainer}>
                                {/* <Button 
                        variant="contained" 
                        className={classes.inputButton} 
                        onClick={handleOpen}>
                        <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} />
                                    Add Template
                                </Button> */}

                                <ButtonComponent
                                    label="Add Template"
                                    startIcon={<AddCircleOutlineOutlinedIcon />}
                                    type="primary"
                                    size="large"
                                    onClick={handleOpen} />
                            </div>
                            : ''}


                    </div>

                    {/* <Grid container className={classes.titleContainer} >
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>Template</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>StartTime</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>Start Day</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>No of Days</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>No of Peroids</Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography className={classes.titles}>Duration of Peroids</Typography>
                    </Grid>
                </Grid> */}

                    {/* <FlatList
                    list={data}
                    renderItem={renderItem}
                    renderWhenEmpty={() => <div>No Data!</div>}
                /> */}


                    <TableContainer component={Paper} className={classes.tableContainer} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.titles}>Template</TableCell>
                                    <TableCell align="left" className={classes.titles}>Start Time</TableCell>
                                    <TableCell align="left" className={classes.titles}>Start Day</TableCell>
                                    <TableCell align="left" className={classes.titles}>No.of Days</TableCell>
                                    <TableCell align="left" className={classes.titles}>No.of Periods</TableCell>
                                    <TableCell align="left" className={classes.titles}>Duration of Peroids</TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                data.length ?
                                    <TableBody>
                                        {data.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" className={classes.dataText}>{row.templateName}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.startTime}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.startDay}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.numberOfDays}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.numberOfPeriods}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.durationOfPeriods}</TableCell>
                                                <TableCell align="center">
                                                    <div>
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => { handleClick(event, index) }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id={index}
                                                            key={index}
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={openMenu}
                                                            onClose={handleClose}
                                                            PaperProps={{
                                                                elevation: 1,
                                                                style: {
                                                                    maxHeight: 48 * 3.5,
                                                                    width: "20ch",
                                                                }
                                                            }}
                                                        >
                                                            {canEdit ?
                                                                <MenuItem
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleOpenEdit();
                                                                    }}
                                                                >
                                                                    {'Edit'}
                                                                </MenuItem>
                                                                : ''}
                                                        </Menu>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    :
                                    <TableRow style={{ textAlign: 'center', fontSize: '20px', margin: '10px 0px' }}>

                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>


                                        <TableCell> Records Empty</TableCell>

                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>}
                        </Table>
                    </TableContainer>

                </div>
            </Paper>
        </>

    )
};

const useStyles = makeStyles((theme) => ({
    asterik: {
        color: 'red'
    },
    inputContainer: {
        minWidth: 90,
        marginLeft: '1%',
        marginRight: '1%',
    },
    durationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '1%',
        paddingLeft: '1%',
        paddingRight: '1%'

    },
    durationText: {
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: 400,
        minWidth: 200,

    },
    // button: {
    //     background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
    //     border: 5,
    //     borderWidth: 15,
    //     borderColor: '#25335A',
    //     borderRadius: 15,
    //     //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //     color: 'white',
    //     height: 60,
    //     padding: '0 30px',
    // },
    inputButton: {
        boxShadow: 'none',
        display: 'flex',
        maxHeight: 50,
        width: 178,
        height: 40,
        borderRadius: 5,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FDB714',
            opacity: 0.7
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#25335A',
        fontSize: 18,
        textAlign: 'center'
    },
    titleContainer: {
        paddingTop: '5%'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    buttonSubmit: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        // boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 40,
        padding: '0 30px',
        width: "140px",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,
        textTransform: 'none',
    },

    updateBtn: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 50,
        padding: '0 15px',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '30%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

export default TimetableTemplate;
