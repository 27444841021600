import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#FDB714',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#FDB714',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid #a8a7a7`,
        backgroundColor: '#ddd',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

class CustomTable extends React.Component {

    render() {
        return (
            <TableRow style={{ padding: '0px' }}>
                {this.props.tabType != 'col2' ?
                    <TableCell style={{ fontWeight: 500, fontSize: 16, color: '#424241', fontFamily: 'Poppins' }}>
                        {this.props.rowItem.sub ? this.props.rowItem.sub : this.props.rowItem.module}
                    </TableCell>
                    : ''}
                <TableCell style={{ fontSize: 14, color: '#424241', fontFamily: 'Poppins' }}>
                    <div>{this.props.rowItem.name}</div>
                </TableCell>
                <TableCell style={{ fontSize: 14, color: '#424241', fontFamily: 'Poppins' }}>
                    <div>
                        <IOSSwitch
                            onChange={(event) => { this.props.handleChange(this.props.index) }}
                            checked={this.props.rowItem.permission}
                            name="switch"
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    }
}

export default CustomTable;


