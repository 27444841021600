import axios from "axios";

const localURL = "http://localhost:8000/v1";
const testURL = "http://110.4.41.173:6832/v1";

const isDev = false;

export default axios.create({
  // baseURL: isDev ? localURL : testURL,
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
});
