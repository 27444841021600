import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthAction from 'redux/actions/auth';

// Components
import ResetPasswordVerificationForm from './reset-verification-form.view';
import { parseJWT } from 'helpers';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const authActions = bindActionCreators(AuthAction, dispatch);

    return {
        resendEmail$: (payload) => {
            authActions.resendEmail(payload);
        },
        verifyOtp$: (payload) => {
            payload.verifyType = "RESET_PASSWORD";
            authActions.verifyOtp(payload);
        },
        onReset$: () => {
            authActions.resetStatus();
        },
    }
}

const Container = ({ status, error, onReset$, resendEmail$, verifyOtp$ }) => {
    const history = useHistory();

    const resendEmail = (data) => {
        resendEmail$(data);
    }

    const verifyOtp = (data) => {
        verifyOtp$(data)
    }

    return (
        <ResetPasswordVerificationForm
            verifyOtp={verifyOtp}
            onReset={onReset$}
            resendEmail={resendEmail}
            status={status}
            resErr={error.login} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
