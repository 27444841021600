import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileAction from 'redux/actions/profile';
import departmentActions from 'redux/actions/department';
// Components
import View from './teacher-details.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        profile: state.profile?.data,
        deptStatus: state.department?.status,
        deptError: state.department?.error
    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);
    const DepartmentActions = bindActionCreators(departmentActions, dispatch);

    return {
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetch$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        },
        inActiveStatus$: (data) => {
            profileActions.updateStatusToInactive(data);
        },
        activeStatus$: (data) => {
            profileActions.updateStatusToActive(data);
        },
        unAssignDept$: (obj) => {
            DepartmentActions.unAssignDepartments(obj)
        }
    }
}

// const Container = ({ status, error, profiles, onFetch$ }) => {
const Container = ({
    profileID,
    status,
    error,
    profile,
    onFetch$,
    onReset$,
    unAssignDept$,
    activeStatus$,
    onCloseModal,
    inActiveStatus$,
    deptStatus,
    deptError
}) => {
    useEffect(() => {
        if (profileID) { onFetch$(profileID); }
    }, [profileID]);

    const inActiveStatus = (data) => {
        inActiveStatus$(data)
    }

    const activeStatus = (data) => {
        activeStatus$(data)
    }

    const unAssignDept = (data) => {
        unAssignDept$(data)
    }


    useEffect(() => {
        if (deptStatus.unassign == 'success') { onFetch$(profileID); }
    }, [deptStatus]);


    return (
        <View
            activeStatus={activeStatus}
            inActiveStatus={inActiveStatus}
            data={profile}
            status={status}
            onCloseModalPopup={onCloseModal}
            resErr={error}
            onReset={onReset$}
            unAssignDept={unAssignDept}
            deptStatus={deptStatus}
            deptError={deptError}
            onFetch={onFetch$} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);