import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({ 
    paperFullScreen: {
        backgroundColor: '#EBECF5'
    },
    paper: {
        // minWidth: 500,
        // borderRadius: '15px',
        // minHeight: 500,
        width: '100%',
        padding: '5px',
    },
    modal__title: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // minHeight: '50px'
    },
    closeBtn: {
        position: 'absolute',
        top: 10,
        right: 10
        // marginLeft: 'auto'
        // position: 'absolute',
        // top: '50%',
        // right: 10,
        // transform: 'translateY(-50%)'
    }
}));

export default useStyles;