import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    success: {
        backgroundColor: '#DEF2D6',
        border: '1px solid #57B43F',
        color: '#5C7356'
    },
    error: {
        backgroundColor: '#FFE2E5',
        border: '1px solid #F64E60',
        color: '#F64E60'
    },
    info: {
        
    }
}));

export default useStyles;