import axios from 'axios';
import Session from 'services/local/session';

const getTable = async (payload) => {
    const orgID = Session.getOrg()?.orgID;
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/byOrg/${orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timetable-template`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const updateTimeTable = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timetable-template`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const publish = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable/publishDraft`
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error: ', err);
            return err.response?.data
        })
}

const ttTemplate = async (payload) => {
    const orgID = Session.getOrg()?.orgID;
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timetable-template/byOrg/${orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }

    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            // console.log('error: ', error);
            return error.response?.data;
        })
}

const createTimeTable = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const fetchTimeTables = async (payload) => {
    const orgID = Session.getOrg()?.orgID;
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable/byOrg/${orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }

    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            // console.log('error: ', error);
            return error.response?.data;
        })
}


export default {
    getTable,
    create,
    ttTemplate, updateTimeTable,
    update,
    publish,
    createTimeTable,
    fetchTimeTables
};