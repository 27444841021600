import _ from 'lodash';

import timeTable from '../apis/time-table';

export const TIMETABLE_FETCH = 'TIMETABLE_FETCH';
export const TIMETABLE_FETCH_FAILED = 'TIMETABLE_FETCH_FAILED';
export const TIMETABLE_FETCH_SUCCESS = 'TIMETABLE_FETCH_SUCCESS';


export const TIMETABLE_TIMETABLE_FETCH = 'TIMETABLE_TIMETABLE_FETCH';
export const TIMETABLE_TIMETABLE_FETCH_FAILED = 'TIMETABLE_TIMETABLE_FETCH_FAILED';
export const TIMETABLE_TIMETABLE_FETCH_SUCCESS = 'TIMETABLE_TIMETABLE_FETCH_SUCCESS';


export const TIMETABLE_CREATE = 'TIMETABLE_CREATE';
export const TIMETABLE_CREATE_FAILED = 'TIMETABLE_CREATE_FAILED';
export const TIMETABLE_CREATE_SUCCESS = 'TIMETABLE_CREATE_SUCCESS';


export const TIMETABLE_UPDATE = 'TIMETABLE_UPDATE';
export const TIMETABLE_UPDATE_FAILED = 'TIMETABLE_UPDATE_FAILED';
export const TIMETABLE_UPDATE_SUCCESS = 'TIMETABLE_UPDATE_SUCCESS';

export const TIMETABLE_PUBLISH = 'TIMETABLE_PUBLISH';
export const TIMETABLE_PUBLISH_FAILED = 'TIMETABLE_PUBLISH_FAILED';
export const TIMETABLE_PUBLISH_SUCCESS = 'TIMETABLE_PUBLISH_SUCCESS';

export const RESET_STATUS = 'RESET_STATUS';

const dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createTimeTable = (data) => async (dispatch) => { // for timetable creation
    try {
        dispatch({ type: TIMETABLE_CREATE });
        const res = await timeTable.createTimeTable(data);
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: TIMETABLE_CREATE_SUCCESS, payload: res.data });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_CREATE_FAILED,
            error: err
        });
    }
};

const publishTimeTable = (data) => async (dispatch) => {
    try {
        dispatch({ type: TIMETABLE_PUBLISH });
        const res = await timeTable.publish(data);
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_PUBLISH_FAILED,
                error: res
            });
        };
        return dispatch({ type: TIMETABLE_PUBLISH_SUCCESS });
    }
    catch (err) {
        if (dev) {
            console.log(err);
        };
        dispatch({
            type: TIMETABLE_PUBLISH_FAILED,
            error: err
        });
    };
};

const updateTimeTable = (data) => async (dispatch) => {
    try {
        dispatch({ type: TIMETABLE_UPDATE });
        const res = await timeTable.updateTimeTable(data);
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: TIMETABLE_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_UPDATE_FAILED,
            error: err
        });
    }
}

const getTableList = () => async (dispatch) => {
    try {
        dispatch({ type: TIMETABLE_FETCH });
        const res = await timeTable.ttTemplate();
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_FETCH_FAILED,
                error: {}
            });
        }

        return dispatch({ type: TIMETABLE_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_FETCH_FAILED,
            error: err
        });
    }
}

const createTemplate = (data) => async (dispatch) => { // for timetable creation
    try {
        dispatch({ type: TIMETABLE_CREATE });
        const res = await timeTable.create(data);
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: TIMETABLE_CREATE_SUCCESS, payload: res });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_CREATE_FAILED,
            error: err
        });
    }
};



const updateTemplate = (data) => async (dispatch) => { // for acedemic calendar creation
    try {
        dispatch({ type: TIMETABLE_UPDATE });
        const res = await timeTable.update(data);
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: TIMETABLE_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_UPDATE_FAILED,
            error: err
        });
    }
}



const getTimeTableList = () => async (dispatch) => {
    try {
        dispatch({ type: TIMETABLE_TIMETABLE_FETCH });
        const res = await timeTable.fetchTimeTables();
        if (!res?.success) {
            return dispatch({
                type: TIMETABLE_TIMETABLE_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: TIMETABLE_TIMETABLE_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: TIMETABLE_TIMETABLE_FETCH_FAILED,
            error: err
        });
    }
}





export default {
    createTimeTable,
    getTableList,
    updateTimeTable,
    publishTimeTable,
    createTemplate,
    updateTemplate,
    resetStatus,
    getTimeTableList
}