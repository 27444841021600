import _ from 'lodash';
import component from './component';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    return _.omitBy(_.omitBy({
        emailHash: data.emailHash,
        passwordHash: data.passwordHash
    }, _.isNull), _.isUndefined);
}

export default normalizer;