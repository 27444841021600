import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthAction from 'redux/actions/auth';

// Components
import VerificationForm from './verification-form.view';
import { parseJWT } from 'helpers';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const authActions = bindActionCreators(AuthAction, dispatch);

    return {
        checkStatus$: (payload) => {
            authActions.checkStatus(payload);
        },
        verifyOtp$: (payload) => {
            authActions.verifyOtp(payload);
        },
        onReset$: () => {
            authActions.resetStatus();
        },
    }
}

const Container = ({ status, error, onReset$, checkStatus$, verifyOtp$ }) => {
    const history = useHistory();

    // useEffect(() => {
    //     if (status.login === 'success') {
    //         history.push('/account/students');
    //     }
    // }, [status.login]);

    const checkStatus = (data) => {
        checkStatus$(data);
    }

    const verifyOtp = (data) => {
        verifyOtp$(data)
    }

    return (
        <VerificationForm
            verifyOtp={verifyOtp}
            onReset={onReset$}
            checkStatus={checkStatus}
            status={status}
            resErr={error.login} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
