import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px'
    },
    title: {
        color: '#25335A'
    },
    clearTxt: {
        textDecoration: 'underline',
        cursor: 'pointer',

        '&:hover': {
            color: 'rgba(0, 0, 0, 0.7)'
        }
    }
}))

export default useStyles;