import _ from 'lodash';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    return _.omitBy(_.omitBy({
        key: data._key,
        id: data._id,
        rev: data._rev,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt
    }, _.isNull), _.isUndefined);
}

export default normalizer;