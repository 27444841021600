import React from 'react';

// Components
import Input from '../input';

const InputSearch = ({ ...props }) => {
    return (
        <Input
            type="search"
            {...props}
        />
    )
}

export default InputSearch
