import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
 
// Components
import useGlobalStyles from '../styles/globalStyles';

import AttendanceList from 'components/attendance/attendance-list';

const AttendancePage = () => {
    const style = useGlobalStyles();
    const { t } = useTranslation();

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Fragment>
            <Helmet>
                <title>{t('title.attendance')}</title>
            </Helmet>

            <Breadcrumbs 
               aria-label="breadcrumb"
               separator={<NavigateNextIcon fontSize="large" style={{color:"#FDB714"}} />}
               style={{ 
                   paddingLeft: '50px',
                   paddingTop: 10,
                   paddingBottom: 10,
                   backgroundColor: 'white',
                   marginTop: -10,
                   marginBottom: 10,
                   marginLeft:-30,
                   marginRight:-30,
                }}
           >
                <Link 
               style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: 600,
                color: "black",
                textDecoration: 'none'
               }}
                >
                    Attendance
                    </Link>
            </Breadcrumbs>

            <div className={style.mainContainer}>
                <AttendanceList />
            </div>
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
        boxShadow: 'none',
        marginTop: '-10px'
        // height: '80px'
      },
      breadcrumb:{
        fontWeight: 'bold',
        color: '#424241',
      },
}));

export default AttendancePage;