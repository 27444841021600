import {
    CLASSES_CREATE,
    CLASSES_CREATE_SUCCESS,
    CLASSES_CREATE_FAILED,

    RESET_STATUS,

    CLASSES_UPDATE,
    CLASSES_UPDATE_SUCCESS,
    CLASSES_UPDATE_FAILED,

    CLASSES_FETCH,
    CLASSES_FETCH_FAILED,
    CLASSES_FETCH_SUCCESS
} from '../actions/master-class';

const initialStatus = {
    create: 'idle',
    fetch: 'idle',
    update: 'idle'
};

const initialState = {
    list: [],
    data: [],
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }
        case CLASSES_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case CLASSES_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case CLASSES_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success' }
            };
        }


        case CLASSES_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case CLASSES_UPDATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case CLASSES_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success' }
            };
        }


        case CLASSES_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case CLASSES_FETCH_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, fetch: 'failed' }
            }
        }

        case CLASSES_FETCH_SUCCESS: {

            return {
                ...state,
                error: {},
                status: {
                    ...state.status, fetch: 'success', data: state.data
                },
                data: action.payload
                // status: {

                //     ...state.status, fetch: 'success', data: [{ "classData": { "calendarID": "45de4435-9cb3-4465-a4bb-a915548b415b", "code": "796d1b", "createdAt": "2021-02-28T14:12:26.723Z", "level": "Primary", "name": "first", "updatedAt": null, "classID": "efb658de-abb0-47b0-9232-0abd2a84979a" }, "calendarData": { "_key": "45de4435-9cb3-4465-a4bb-a915548b415b", "semesterName": "1", "year": "2022" }, "teacherData": null }]
                // }
            };
        }

        default:
            return state;
    }
}

export default reducer;