import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AcademicCaledarAction from '../../redux/actions/acedemic-calendar';
import View from './acedemic-calendar.view';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';


const mapStateToProps = (state) => {
    return {
        status: state.acedemicCalendar?.status,
        error: state.acedemicCalendar?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const AcademicCaledarActions = bindActionCreators(AcademicCaledarAction, dispatch);

    return {
        onCreate$: (data) => {
            AcademicCaledarActions.createacCalendar(data);
        }
        ,
        onUpdate$: (data) => {
            AcademicCaledarActions.updateacCalendar(data);
        }
        ,
        getACrecords$: (data) => {
            AcademicCaledarActions.getACrecords(data);
        },
        onReset$: () => {
            AcademicCaledarActions.resetStatus();
        },
    }
}

const Container = ({ status, error, onCreate$, onUpdate$, onReset$ }) => {

    const session = useSession();
    const uniquePermissions = uniq(session.permissions);
    const canViewCalendar = (uniquePermissions || []).includes('v_calendar_web');

    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdate = (data) => {
        onUpdate$(data);
    }

    useEffect(() => {
        onReset$();
    }, []);
    return (
        canViewCalendar&&<View status={status} resErr={error} onCreate={onCreate} onUpdate={onUpdate} onReset={onReset$} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);