import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {

    },
    card: {
        padding: '20px',
        borderRadius: '10px'
    },
    card__title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        letterSpacing: '.5px',
        fontSize: 20,

        '& span[data-type="important"]': {
            color: '#B42C30',
        },
    },
    card__body: {
        padding: '10px 0',
    },
    list__container: {

    },
    list__title: {
        fontSize: 15,
        color: 'var(--title-color)',
    },
    list__content: {
        '& span': { 
            // fontWeight: 600,
        },

        '& span[data-type="important"]': {
            color: '#B42C30',
            // fontWeight: 600
        },

        '& [data-type="information"]': {
            color: '#7C9CBF'
        }
    }
}));

export default useStyles;