import axios from 'axios';
import Session from 'services/local/session';

const upload = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/file`;
    // const { accessToken } = Session.getToken();
    // const org = Session.getOrg();

    return await axios({
        method: 'POST',
        url: url,
        data,
        // params: { orgID: org.orgID },
        // headers: { 'authorization': `Bearer ${accessToken?.jwt}` }
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

export default {
    upload
};