import React, { Fragment, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// Components
import ProfileForm from "components/profile/teacher-form-v2";

const StudentPage = () => {
    const { t } = useTranslation();
    const containerStyle = {
        borderTop: "5px solid #1BC55F",
        borderRadius: 5,
    };

    return (
        <Fragment>
            <Helmet>
                <title>{t('title.update_teacher')}</title>
            </Helmet>

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{color:"#FDB714"}} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -10,
                    marginBottom: 10,
                    marginLeft:-30,
                    marginRight:-30,
                }}
            >
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }}
                >
                    Account
                </Link>
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/teachers"
                >
                    Teachers
                </Link>
                <Link href="/account/teachers/update/:teacherID" style={{
                    fontSize: 20,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    color: "rgba(124, 123, 123, 1)",
                }}>
                    Update Teacher Registration
                </Link>
            </Breadcrumbs>

            <div style={containerStyle}>

                <ProfileForm />
            </div>
        </Fragment>
    );
};

export default StudentPage;
