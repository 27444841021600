import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';

import GroupList from '../../components/system-admin/groups/list';

const Groups = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
               <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" style={{color:"#FDB714"}} />}
                    style={{ 
                        paddingLeft: '50px',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'white',
                        marginTop: -10,
                        marginBottom: 10,
                        marginLeft:-30,
                        marginRight:-30,
                     }}
                >
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            color: "black",
                            textDecoration: 'none'
                        }}
                    >
                        System Admin
                    </Link>
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            color: "rgba(124, 123, 123, 1)",
                        }}
                        href="/systemadmin/groups"
                    >
                        Groups
                    </Link>
                </Breadcrumbs>

            <Helmet>
                <title>Groups</title>
            </Helmet>

            <div className={classes.subContainer}>
                <GroupList />
            </div>

        </div>
    );
};


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: '0.5%',
    },

    subContainer: {
        marginTop: '1%',
        borderRadius: 10
    }
}));


export default Groups;

