import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import LoginForm from '../components/auth-form/login-form';

const LoginPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Login</title>
            </Helmet>

            <LoginForm/>
        </Fragment>
    )
}

export default LoginPage;