import React, { useState, Fragment } from 'react'

// Components
import useStyles from './style';

import MUAutoComplete from '@material-ui/lab/Autocomplete';
import Label from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import Input from '../input';
import Loader from '../../loader';
import InputError from '../input-error';

const AutoComplete = ({ 
    label, 
    inputValue,
    value,
    name,
    required, 
    error, 
    errorLabel,
    options, 
    onChange,
    getOptionLabel, 
    getOptionSelected,
    renderInput, 
    renderOption,
    loading,
    onInputChange,
    placeholder,
    filterOptions,
    ...props 
}) => {
    const classes = useStyles(); 
    // const [open, setOpen] = useState(false); // Controllable Select

    return (
        <div>
            {label && 
                <Label required={required} error={error} className={classes.label}>
                    {label}
                </Label>
            }

            <MUAutoComplete
                // open={open}
                // onOpen={() => setOpen(true)}
                // onClose={() => setOpen(false)}
                disableClearable={false}
                inputValue={inputValue}
                value={value}
                options={options}
                onChange={onChange}
                onInputChange={onInputChange}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                renderOption={renderOption}
                loading={loading}
                filterOptions={filterOptions}
                renderInput={(params) => (
                    <Input
                        {...params}
                        InputProps={{ 
                            ...params.InputProps, 
                            endAdornment: (
                                <React.Fragment>
                                    {loading && <Loader size="20px"/>}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            placeholder: placeholder
                        }}
                    />
                )}
                {...props}
            />

            <InputError style={{ marginTop: -5 }} label={label || errorLabel} error={error}/>

            {props.children}
        </div>
    )
}

AutoComplete.defaultProps = {
    label: null,
    required: false,
    error: undefined,
    options: [],
    onChange: () => {},
    onInputChange: () => {},
    autoHighlight: true,
    loading: false,
    placeholder: 'Select One',
    filterOption: (options, state) => options
}

export default AutoComplete
