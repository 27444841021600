import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileActions from 'redux/actions/profile';

// Components
import View from './search.parent.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        result: state.profile?.searchResult
    }
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileActions, dispatch);

    return {
        onSearch$: (param) => {
            profileActions.searchExistingParent(param);
        },
        onResetStatus$: () => {
            profileActions.resetStatus();
        }
    }
}

const Container = ({ status, error, result, onSearch$, onResetStatus$, onSelect }) => {
    const onSearch = (input) => {
        onSearch$(input);
    }

    const onReset = () => {
        onResetStatus$();
    }

    return (
        <View 
            status={status} 
            resErr={error} 
            result={result} 
            onSearch={onSearch}
            onReset={onReset}
            onSelect={onSelect}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
