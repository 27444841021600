import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { isEmpty, isUndefined, map } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { Input, Select, DatePicker, AutoComplete } from '../../components/common/form-unit';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CompactPicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import attendance from '../attendance';


const options = ["Edit"];

const ITEM_HEIGHT = 48;

const Messages = ({ history }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenEdit = (i, index) => {
        setOpenEdit(true);
    };


    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const createData = (type, messageFor, message) => {
        return { type, messageFor, message };
    }

    const rows = [
        createData('Attendance', 'Warning 1', 'Dear Parents, [name] has to attend school regularly. This message will serve as a first warning '),
        createData('Attendance', 'Warning 2', 'Dear Parents, [name] has to attend school regularly. This message will serve as a second warning '),
        createData('Attendance', 'Warning 3', 'Dear Parents, [name] has to attend school regularly. This message will serve as a third and final warning '),
        createData('Attendance ', 'Reason Approved', 'The MC submitted for [date] has been approved. Please submit any relevent information to the Class Teacher.'),
        createData('Attendance ', 'MC Approved', 'The MC submitted for [date] has been approved. Please submit the copy of the MC to the Class Teacher.'),
    ];


    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Message For</TableCell>
                        <TableCell align="left">Messages</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={row.name}>
                            <TableCell align="left">{row.type}</TableCell>
                            <TableCell align="left">{row.messageFor}</TableCell>
                            <TableCell align="left">{row.message}</TableCell>

                            <TableCell align="center">

                                <div>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={openMenu}
                                        onClose={handleClose}
                                        PaperProps={{
                                            elevation: 1,
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: "20ch",
                                            }
                                        }}
                                    >

                                        <MenuItem
                                            key={index}
                                            onClick={() => {
                                                handleOpenEdit(index)
                                                handleCloseMenu()
                                            }}
                                        >
                                            Edit
                                        </MenuItem>

                                    </Menu>

                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        className={classes.modal}
                                        open={openEdit}
                                        onClose={handleCloseEdit}
                                        closeAfterTransition
                                        // BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500
                                        }}
                                    >
                                        <Fade in={openEdit}>
                                            <div className={classes.paper}>
                                                <h2 id="transition-modal-title">Edit</h2>

                                                <Grid container item xs={22} spacing={2}>
                                                    <Grid item xs={4}>
                                                        <Typography>Type: </Typography>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Input
                                                            name="type"
                                                        // required={true}

                                                        // onChange={(e) => { setSubject(e.target.value); }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={22} spacing={2} style={{ marginTop: '5%' }}>
                                                    <Grid item xs={4}>
                                                        <Typography>Message For: </Typography>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Input
                                                            name="messageFor"
                                                        // required={true}

                                                        // onChange={(e) => { setSubject(e.target.value); }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={20} spacing={2} style={{ marginTop: '5%' }}>
                                                    <Grid item xs={4}>
                                                        <Typography>Message: <span className={classes.asterik}>*</span></Typography>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Input
                                                            name="message"
                                                            required={true}

                                                        // onChange={(e) => { setSubject(e.target.value); }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                                    <Button className={classes.buttonSubmit}>Done</Button>
                                                </div>


                                            </div>
                                        </Fade>
                                    </Modal>
                                </div>


                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

const useStyles = makeStyles((theme) => ({
    asterik: {
        color: 'red'
    },
    breadcrum: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '5%',
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        color: 'purple'

    },
    container: {
        marginTop: '5%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {
        marginLeft: '1%'
    },
    button: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        width: "10%",
        marginLeft: '90%',
    },
    buttonSubmit: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 50,
        padding: '0 30px',
        width: 100,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '30%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

export default Messages;