import React from 'react';
import View from './list.view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SystemAdminRoleAction from '../../../redux/actions/system-admin/system-admin-role';
import ProfileAction from 'redux/actions/profile';
const mapStateToProps = (state) => {
    return {
        status: state.systemAdminRole?.status,
        error: state.systemAdminRole?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const SystemAdminRoleActions = bindActionCreators(SystemAdminRoleAction, dispatch);
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onReset$: () => {
            profileActions.resetStatus();
        },
        onCreate$: (data) => {
            SystemAdminRoleActions.getUsers(data);
        },

        onUpdate$: (data) => {
            SystemAdminRoleActions.getUsers(data);
        },

        onConfirmAssign$: (data) => {
            SystemAdminRoleActions.confirmAssign(data);
        }
    }
}

const Container = ({ status, error, onCreate$, onReset$, onUpdate$, onConfirmAssign$ }) => {
    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdate = (data) => {
        onUpdate$(data)
    }
    const onConfirmAssign = (data) => {
        onConfirmAssign$(data)
    }
    return (
        <View status={status} onReset={onReset$} resErr={error} onCreate={onCreate} onUpdate={onUpdate} onConfirmAssign={onConfirmAssign} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);;