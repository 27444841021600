import {
    RESET_STATUS,

    ACRECORDS_CREATE,
    ACRECORDS_CREATE_SUCCESS,
    ACRECORDS_CREATE_FAILED,

    ACRECORDS_UPDATE,
    ACRECORDS_UPDATE_SUCCESS,
    ACRECORDS_UPDATE_FAILED,

    ACRECORDS_FETCH,
    ACRECORDS_FETCH_FAILED,
    ACRECORDS_FETCH_SUCCESS,

    AVAILABLE_CALENDAR_YEARS_FETCH,
    AVAILABLE_CALENDAR_YEARS_FETCH_FAILED,
    AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS,

    CLASSES_BASED_ON_YEAR_FETCH,
    CLASSES_BASED_ON_YEAR_FETCH_FAILED,
    CLASSES_BASED_ON_YEAR_FETCH_SUCCESS,

    CLASSES_BASED_ON_SEMESTER_FETCH,
    CLASSES_BASED_ON_SEMESTER_FETCH_FAILED,
    CLASSES_BASED_ON_SEMESTER_FETCH_SUCCESS,


} from '../actions/acedemic-calendar';

const initialStatus = {
    create: 'idle',
    fetch: 'idle',
    fetchYears: 'idle',
    fetchClasses: 'idle',
    fetchSemesters: 'idle',
};

const initialState = {
    list: [],
    availableYears: [],
    availableClasses: [],
    availableSemesters: [],
    data: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }

        case ACRECORDS_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading', update: 'idle' }
            };
        }

        case ACRECORDS_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed', update: 'idle' }
            }
        }

        case ACRECORDS_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success', update: 'idle' }
            };
        }


        case ACRECORDS_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading' }
            };
        }

        case ACRECORDS_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed' }
            }
        }

        case ACRECORDS_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success' }
            };
        }


        case ACRECORDS_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading', create: 'idle', update: 'idle' }
            };
        }

        case ACRECORDS_FETCH_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, fetch: 'failed', update: 'idle' }
            }
        }

        case ACRECORDS_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'success', update: 'idle' },

            };
        }

        case AVAILABLE_CALENDAR_YEARS_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchYears: 'loading' },

            };
        }

        case AVAILABLE_CALENDAR_YEARS_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchYears: action.error },
                status: { ...state.status, fetchYears: 'failed' }
            };
        }

        case AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS: {
            return {
                ...state,
                availableYears: action.payload,
                error: {},
                status: { ...state.status, fetchYears: 'success' }
            }
        }

        case CLASSES_BASED_ON_YEAR_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchClasses: 'loading' }
            };
        }

        case CLASSES_BASED_ON_YEAR_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchClasses: action.error },
                status: { ...state.status, fetchClasses: 'failed' }
            };
        }

        case CLASSES_BASED_ON_YEAR_FETCH_SUCCESS: {
            return {
                ...state,
                availableClasses: action.payload,
                error: {},
                status: { ...state.status, fetchClasses: 'success' }
            }
        }

        case CLASSES_BASED_ON_SEMESTER_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchSemesters: 'loading' }
            };
        }

        case CLASSES_BASED_ON_SEMESTER_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchSemesters: action.error },
                status: { ...state.status, fetchSemesters: 'failed' }
            }
        }

        case CLASSES_BASED_ON_SEMESTER_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchSemesters: 'success' },
                availableSemesters: action.payload
            }
        }

        default:
            return state;
    }
}

export default reducer;