import {
    SUBJECT_CREATE,
    SUBJECT_CREATE_FAILED,
    SUBJECT_CREATE_SUCCESS,

    RESET_STATUS,

    SUBJECT_UPDATE,
    SUBJECT_UPDATE_FAILED,
    SUBJECT_UPDATE_SUCCESS,


    SUBJECTS_FETCH,
    SUBJECTS_FETCH_FAILED,
    SUBJECTS_FETCH_SUCCESS,

    SUBJECT_FETCH_TIMETABLE,
    SUBJECT_FETCH_TIMETABLE_FAILED,
    SUBJECT_FETCH_TIMETABLE_SUCCESS,
} from '../actions/subject';

import _ from 'lodash';

const initialStatus = {
    create: 'idle',
    fetch: 'idle',
    fetchByID: 'idle',
    update: 'idle'
};

const initialState = {
    list: [],
    data: {},
    subjects: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }
        case SUBJECT_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading', update: 'idle' }
            };
        }

        case SUBJECT_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success', update: 'idle' }
            };
        }

        case SUBJECT_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                data: {},
                status: { ...state.status, create: 'failed', update: 'idle' }
            };
        }

        case SUBJECTS_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading', create: 'idle', update: 'idle' }
            };
        }

        case SUBJECTS_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                list: action.payload,
                status: { ...state.status, fetch: 'success', create: 'idle', update: 'idle' }
            };
        }

        case SUBJECTS_FETCH_FAILED: {
            return {
                ...state,
                list: [],
                error: { fetch: action.error },
                status: { ...state.status, fetch: 'failed', create: 'idle', update: 'idle' }
            }
        }


        case SUBJECT_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case SUBJECT_UPDATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case SUBJECT_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success' }
            };
        }

        case SUBJECT_FETCH_TIMETABLE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case SUBJECT_FETCH_TIMETABLE_FAILED: {
            return {
                ...state,
                error: action.error,
                status: { ...state.status, fetch: 'failed' }
            }
        }

        case SUBJECT_FETCH_TIMETABLE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'success' },
                subjects: action.payload,

            }
        }

        default:
            return state;
    }
}

export default reducer;