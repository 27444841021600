import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { schema } from './reset-verification-form.validation';
import normalize from '../../../utils/normalizers';

// Assets
import ProductLogo from '../../../assets/img/loginFormIcon.png'
import LoginImg from '../../../assets/img/loginBg.png';

// Componennts
import Grid from "@material-ui/core/Grid";
import { Input, Password } from "../../common/form-unit";
import Button from '../../common/button';
import { makeStyles } from '@material-ui/core/styles';
import AlertBox from '../../common/alert-box';
import { useHistory } from 'react-router-dom';
import OtpInput from "react-otp-input";
import useToaster from 'hooks/use-toaster';

const VerificationForm = ({ status, resErr, resendEmail, onReset, verifyOtp }) => {
    const styles = useStyles();
    const history = useHistory();

    const initialError = {}, initialLoading = false;
    const initialInput = {
        emailAddress: ''
    };

    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })

    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [isLoading, setIsLoading] = useState(initialLoading);
    const [OTP, setOTP] = useState("");

    const [otpPage, setOtpPage] = useState(false)

    const [emailHashValue, setEmailHash] = useState('');
    useEffect(() => {
        if (status.resendEmail === 'loading') {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
        if (status.resendEmail === 'success') {
            setEmailHash(status.emailHash)
            setOtpPage(true);
            setTimeout(() => {
                onReset();
            }, 500)
        }
        if (status.otpStatus == 'success') {
            let statusObj = status.data;
            let msg = "OTP Verified Successfully..";
            setToastMsg(msg)
            onOpenToaster();
            setTimeout(() => {
                history.push(
                    {
                        pathname: '/password-resetting',
                        state: { token: statusObj?.passwordToken, emailHash: emailHashValue }
                    });
                setTimeout(() => {
                    onReset();
                }, 1000)
            }, 2000)
        }
    }, [status]);

    useEffect(() => {
        if (resErr?.length !== 0 || !_.isUndefined(resErr)) {
            setError({ ...error, 'response': resErr });
        }
    }, [resErr]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setInput({ ...input, [name]: value });
        setError(initialError);
    }

    const onSubmit = async () => {
        try {
            if (isLoading) { return; }

            const value = await schema.validateAsync(_.omitBy(_.omitBy({ ...input }, _.isNull), _.isUndefined));

            if (!_.isEmpty(value) && resendEmail) {
                let obj = {
                    emailHash: value.emailAddress,
                    emailType: "RESET_PASSWORD"
                }
                resendEmail(obj);
            }
        }
        catch (err) {
            const { key, value } = normalize.error.validation(err);

            setError({ ...error, [key]: value });

            if (document.querySelector(`input[name=${key}]`)) {
                document.querySelector(`input[name=${key}]`).focus();
            }
        }
    }

    const otpSubmit = async () => {
        try {
            if (isLoading) { return; }
            const value = await schema.validateAsync(_.omitBy(_.omitBy({ ...input }, _.isNull), _.isUndefined));
            if (!_.isEmpty(value) && verifyOtp) {
                let obj = {
                    emailHash: emailHashValue,
                    otp: OTP
                }
                verifyOtp(obj);
            }
        }
        catch (err) {
            const { key, value } = normalize.error.validation(err);

            setError({ ...error, [key]: value });

            if (document.querySelector(`input[name=${key}]`)) {
                document.querySelector(`input[name=${key}]`).focus();
            }
        }
    }


    const goToLogin = () => {
        history.push('/login');
    }



    return (
        <>
            <Toaster></Toaster>
            <div className={styles.container}>
                {/* <div className={styles.left__col}>
                    <img
                        className={styles.backgroundImage}
                        alt="LoginBg Image"
                        src={LoginImg}
                    />
                </div> */}
                <div className={styles.right__col}>
                    <Grid
                        className={styles.wrapper__inner}
                        container={true}
                        justify="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '1em', marginBottom: '2em', display: 'flex', justifyContent: 'center' }}>
                            <img alt="Product Logo" src={ProductLogo} />
                        </Grid>



                        <Grid
                            container={true}
                            item={true}
                            lg={12} md={12} sm={12} xs={12}
                        >
                            {!_.isUndefined(error?.response) && error?.response.length !== 0 &&
                                <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                                    <AlertBox
                                        message={error?.response}
                                        type="error"
                                    />
                                </Grid>
                            }


                            {otpPage == false ?
                                <>
                                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '.5em' }}>
                                        <Input
                                            label="Username"
                                            placeholder="Username"
                                            errorLabel="Email Address"
                                            name="emailAddress"
                                            value={input.emailAddress || ''}
                                            error={error?.emailAddress}
                                            onChange={onChange}
                                            required={true}
                                        />
                                    </Grid>

                                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                                        <Button
                                            onClick={onSubmit}
                                            label="Continue"
                                            isLoading={isLoading}
                                            type="primary"
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: '#424241'
                                            }}>
                                            <h2 style={{}}>Check Your Email</h2>
                                            <p>A 4-digit code has been sent to <br></br><b>{input.emailAddress}</b></p>

                                            <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                                                <OtpInput
                                                    containerStyle={{ display: 'flex', justifyContent: 'center' }}
                                                    inputStyle={{ width: '3em', height: '3em' }}
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    separator={<span>&nbsp;</span>} />
                                            </div>


                                        </div>
                                        <div style={{ textAlign: 'left', color: '#424241' }}>
                                            <p>- The OTP will expire in <span style={{ color: 'red' }}>2 days</span></p>
                                            <p>- Didn’t receive verification code?  </p>
                                            {/* <a style={{ textDecoration: 'underline', cursor: 'pointer' }}>Resend</a> */}
                                        </div>
                                    </Grid>
                                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '2em' }}>
                                        <Button
                                            onClick={otpSubmit}
                                            label="Verify"
                                            isLoading={isLoading}
                                            type="primary"
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '30px' }}>
                                <div onClick={goToLogin}>Back to Login? <span style={{ color: 'rgb(66, 66, 65)', textDecoration: 'underline', cursor: 'pointer' }}>Click Here.</span></div>
                            </Grid>

                        </Grid>

                    </Grid>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    left__col: {
        width: '120%',

        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    right__col: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        padding: '1em 2em',
        margin: 'auto',
        '@media screen and (max-width: 1024px)': {
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'center'
        }
    },
    wrapper__inner: {
        width: '80%',

        '@media screen and (max-width: 1024px)': {
            width: '100%'
        },

        '@media screen and (min-width: 1600px)': {
            width: '60%'
        }
    },
    backgroundImage: {
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundSize: 'cover',
    }
    // button: {
    //     background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
    //     border: 5,
    //     borderWidth: 15,
    //     borderColor: '#25335A',
    //     borderRadius: 3,
    //     //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //     color: 'black',
    //     height: 48,
    //     padding: '0 30px',
    //     width: "100%"
    // },
    // passwordStrengthBar: {
    //     marginTop: '2%'
    // },
    // passwordContainer: {
    //     width: '70%'
    // },
    // inputTitleContainer: {
    //     marginTop: '20%'
    // },
    // titleCotainer: {
    //     marginLeft: '10%'
    // },
    // container: {
    //     display: "flex",
    // },
    // img: {
    //     display: "flex",
    //     width: '50%',
    //     padding: 0,
    //     marginLeft: '-1%',
    //     marginTop: '-1%'
    // },
    // inputContainer: {
    //     marginTop: '5%',
    //     marginLeft: '10%',
    //     width: '100%',
    //     height: '100%'
    // },
    // title: {
    //     fontWeight: 'bold',
    //     fontSize: 48,
    //     fontFamily: 'Poppins',
    // },
    // input: {
    //     fontFamily: 'Poppins',
    // }
});

export default VerificationForm;