import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const Overview = () => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Breadcrumbs aria-label="breadcrumb"  className={clsx(classes.appBar)}>
        <Typography color="textPrimary" className={clsx(classes.breadcrumb)}>OverView</Typography>
      </Breadcrumbs>
    )
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
        boxShadow: 'none',
        marginTop: '-10px'
        // height: '80px'
      },
      breadcrumb:{
        fontWeight: 'bold',
        color: '#424241',
      },
}));

export default Overview;