import './card.css';
import React from 'react';
import clsx from 'clsx';

// Components
import { BsPeopleFill } from 'react-icons/bs';

const CardInfo = ({ type, title, body, count, style }) => {
    return (
        <div style={style} className={clsx('card-container', { [`card-${type}`]: type !== undefined && type?.length !== 0 && type })}>
            <div className="icon">
                <BsPeopleFill />
            </div>

            <div className="content">
                <span className="title">
                    {title}
                </span>

                <span className="body">
                    {body}
                </span>
            </div>

            <div className="statistic">
                <div>
                    <h2 className="ellipsis-text" title={count}>
                        {count}
                    </h2>
                </div>
            </div>
        </div>
    )
}

CardInfo.defaultProps = {
    type: 'info',
    title: 'Title',
    body: 'Body',
    count: null
}

export default CardInfo
