import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        idType: data.type,
        idNo: data.no,
        issueDate: data.issueDate,
        expiryDate: data.expiryDate,
        placeOfIssue: data.placeOfIssue
    }, _.isNull), _.isUndefined);
}

export default component;