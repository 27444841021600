import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import enc from 'helpers/enc';
import AlertBox from 'components/common/alert-box';
// Components
import useStyles from './style';

import Modal from 'components/common/modal';
import DepartmentAssign from 'components/master-list/departments/departments-assign';
import ProfileStatus from 'components/common/profile-status';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


import Button1 from '@material-ui/core/Button';
import Button from 'components/common/button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';


const GridBodyCard = ({ grids }) => {
    const cls = useStyles();

    return (
        <Grid container={true} justify="space-between">
            {!_.isEmpty(grids) && _.map(grids, (o, i) => {
                if (o.type === 'important') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': 'black' }} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    <span data-type={o.type} >
                                        {o.value}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    )
                }
                else if (o.type === 'information') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': '#756F86' }} data-type={o.type} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    <span data-type={o.type}>
                                        {o.value}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    )
                }
            })
            }
        </Grid>
    )
}

const TeacherInformation = ({ data, isLoading }) => {
    const cls = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={cls.card} style={{ backgroundColor: 'white' }}>
            <div className={cls.card__title}>
                <span>Teacher Information</span>
                <Button
                    label={t('action.edit')}
                    onClick={() => history.push(`/account/teachers/update/${enc.encode(data.profileID)}`)}
                />
            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && !_.isEmpty(data) &&
                    <GridBodyCard
                        grids={[
                            {
                                type: 'information',
                                title: 'Name',
                                value: data?.fullName
                            },
                            {
                                type: 'information',
                                title: 'Date Of Birth',
                                value: moment(data?.dob).format('DD/MM/YYYY')
                            },
                            {
                                type: 'information',
                                title: data?.idType === 'NRIC' ? 'National ID' : 'Passport No',
                                value: data?.idNo,
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                title: 'Religion',
                                value: _.upperFirst(_.toLower(data?.religion)),
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                title: 'Gender',
                                value: _.upperFirst(_.toLower(data?.gender)),
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                title: 'Prior School',
                                value: _.upperFirst(_.toLower(data?.priorSchool)) || 'Information not provided',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                title: 'Allergy',
                                value: data?.medicalCondition || 'Information not provided',
                                style: { marginTop: '1em' }
                            }
                        ]}
                    />
                }
            </div>
        </div>
    )
}

const EmergencyContact = ({ data, isLoading }) => {
    const cls = useStyles();

    return (
        <div className={cls.card} style={{ backgroundColor: '#EBC8C4' }}>
            <div className={cls.card__title}>
                <span data-type="information">Emergency Contacts</span>
            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && !_.isEmpty(data) &&
                    <GridBodyCard
                        grids={[
                            {
                                type: 'important',
                                value: data?.emergencyContactName,
                                title: 'Name'
                            },
                            {
                                type: 'important',
                                value: data?.emergencyContactRelationship,
                                title: 'Relationship with Student'
                            },
                            {
                                type: 'important',
                                value: `+${data?.emergencyContactFullNumber}`,
                                title: 'Mobile No.',
                                style: { marginTop: '1em' }
                            }
                        ]}
                    />
                }
            </div>
        </div>
    )
}

const Posts = ({ data, isLoading }) => {
    const cls = useStyles();
    const { t } = useTranslation();

    return (
        <div className={cls.card} style={{ backgroundColor: 'white' }}>
            <div className={cls.card__title}>
                <span data-type="information">{t('label.posts_assigned')}</span>
            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && _.isEmpty(data) &&
                    <span style={{
                        display: 'block',
                        marginTop: '.5em',
                        fontSize: '18px',
                        letterSpacing: .5,
                        textAlign: 'center'
                    }}>
                        {t('txt.no_post_assigned')}
                    </span>
                }

                {!isLoading && !_.isEmpty(data) &&
                    <div style={{ marginTop: '.5em' }}>
                        <div style={{ display: 'flex', borderBottom: '1px solid #DFE0EB', padding: '5px 0' }}>
                            <h3 style={{ display: 'block', flex: 1 }}>{t('label.posts')}</h3>
                            <h3 style={{ display: 'block', flex: 1 }}>{t('label.for')}</h3>
                        </div>

                        {_.map(data, (o, i) => (
                            <div style={{ display: 'flex', padding: '8px 0' }} key={i}>
                                <span style={{ display: 'block', flex: 1 }}>{o.post}</span>
                                <span style={{ display: 'block', flex: 1 }}>{o.for}</span>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

const Subjects = ({ data, isLoading }) => {
    const cls = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [nestedOpen, setNestedOpen] = useState(false);
    const [drawerIcon, setDrawerIcon] = useState(<DoubleArrowIcon />);
    const [menuPosition, setMenuPosition] = useState("30%")

    const handleDrawer = () => {
        if (open === true) {
          setDrawerIcon(<DoubleArrowIcon />);
          setOpen(false);
          setMenuPosition("30%");
          setNestedOpen(false);
        }
        else {
          setOpen(true);
          setDrawerIcon(<ChevronLeftIcon />);
          setMenuPosition("5%");
    
        }
      }

    return (
        <div className={cls.card} style={{ backgroundColor: 'white' }}>
            <div className={cls.card__title}>
                <span data-type="information">{t('label.subjects_assigned')}</span>
                <Button
                    label={t('action.edit')}
                    onClick={() => {
                        history.push({
                            pathname: '/masterlist',
                            state: { type: 'subjects' },
                        })
                    }}
                />

            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && _.isEmpty(data) &&
                    <span style={{
                        display: 'block',
                        marginTop: '.5em',
                        fontSize: '18px',
                        letterSpacing: .5,
                        textAlign: 'center'
                    }}>
                        {t('txt.no_subject_assigned')}
                    </span>
                }

                {!isLoading && !_.isEmpty(data) &&
                    <div style={{ marginTop: '.5em' }}>
                        <div style={{ display: 'flex', borderBottom: '1px solid #DFE0EB', padding: '5px 0' }}>
                            <h3 style={{ display: 'block', flex: 1 }}>{t('label.subjects')}</h3>
                            <h3 style={{ display: 'block', flex: 1 }}>{t('label.class')}</h3>
                            <h3 style={{ display: 'block', flex: 1 }}>{t('label.semester')}</h3>
                        </div>

                        {_.map(data, (o, i) => (
                            <div style={{ display: 'flex', padding: '8px 0' }} key={i}>
                                <span style={{ display: 'block', flex: 1 }}>{o.subject}</span>
                                <span style={{ display: 'block', flex: 1 }}>{o.class}</span>
                                <span style={{ display: 'block', flex: 1 }}>{o.sem}</span>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

const Departments = ({ data, isLoading, profileID, onFetchProfile, unAssignDept }) => {
    const cls = useStyles();
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);
    const [unAssignmodalOpen, setUnAssignModalOpen] = useState(false);
    const [selDept, setSelDept] = useState({})

    const onOpenModal = () => {
        setModalOpen(true);
    }

    const onCloseModal = () => {
        setModalOpen(false);
    }


    const onOpenUnAssignModal = (obj) => {
        setSelDept(obj)
        setUnAssignModalOpen(true);
    }

    const onCloseUnAssignModal = () => {
        setUnAssignModalOpen(false);
    }

    const onUnAssign = (id) => {
        setUnAssignModalOpen(false);
        let reqObj = {
            profiles: [
                profileID
            ],
            departmentID: id
        }
        unAssignDept(reqObj)
    }

    return (
        <Fragment>
            <div className={cls.card} style={{ backgroundColor: 'white' }}>
                <div className={cls.card__title}>
                    <span data-type="information">{t('label.departments')}</span>
                    <Button label={t('action.assign')} onClick={() => onOpenModal()} />
                </div>

                <div className={cls.card__body}>
                    {isLoading && <div>loading...</div>}

                    {!isLoading && _.isEmpty(data) &&
                        <span style={{
                            display: 'block',
                            marginTop: '.5em',
                            fontSize: '18px',
                            letterSpacing: .5,
                            textAlign: 'center'
                        }}>
                            {t('txt.no_department_assigned')}
                        </span>
                    }

                    {!isLoading && !_.isEmpty(data) &&
                        _.map(data, (o, i) => (
                            <div style={{ display: 'flex', padding: '8px 5px', borderBottom: '1px solid #ddd' }} key={i}>
                                <span style={{ display: 'block', flex: 1 }}>{o.departmentName}</span>
                                {/* <div style={{ cursor: 'pointer', color: '#B42C30' }} onClick={() => onUnAssign(o.departmentID)}  >UnAssign</div> */}
                                <div style={{ cursor: 'pointer', color: '#B42C30' }} onClick={() => onOpenUnAssignModal(o)}  >Unassign</div>

                            </div>
                        ))
                    }
                </div>
            </div>

            {modalOpen &&
                <Modal
                    title={t('title.assigned_department')}
                    showFooter={false}
                    open={modalOpen}
                    onHandleClose={onCloseModal}
                    // maxWidth="lg"
                    scroll="body"
                >
                    <DepartmentAssign
                        onFetchProfile={onFetchProfile}
                        onCloseModal={onCloseModal}
                        profileID={profileID}
                    />
                </Modal>
            }
            {unAssignmodalOpen &&
                <Modal
                    showFooter={false}
                    open={unAssignmodalOpen}
                    onHandleClose={onCloseUnAssignModal}
                    // maxWidth="lg"
                    scroll="body"
                >
                    <div style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
                        <h2 style={{ marginBottom: '15px' }}>Unassign Department</h2>
                        <img src={process.env.PUBLIC_URL + "/error.svg"} style={{ marginTop: '10px', marginBottom: '10px' }} />
                        <p style={{ fontSize: '18px', marginBottom: '20px', padding: '0px 15px' }}>TEACHER WILL BE <span style={{ color: '#ff0000' }}>UNASSIGNED</span> FROM THE  <span style={{ fontWeight: 'bold', color: '#424241', textTransform: 'uppercase' }}>{selDept?.departmentName}</span> DEPARTMENT</p>
                        <Button
                            label="Confirm Unassign"
                            style={{ backgroundColor: '#FF0000', color: 'white' }}
                            size="large"
                            onClick={() => onUnAssign(selDept?.departmentID)}
                        />
                    </div>



                </Modal>
            }
        </Fragment>
    )
}

const View = ({ data, status, resErr, onReset, onFetch, onCloseModalPopup, activeStatus, inActiveStatus, unAssignDept }) => {
    const cls = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [error, setError] = useState(false)
    const [errorStatus, setErrorStatus] = useState(false)

    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);


    useEffect(() => {
        if (status.fetch === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (status.statusUpdate == 'success') {
            setAnchorEl(null);
            onCloseModalPopup();
        }
        if (status.statusUpdate == 'failed') {
            setError(status.data)
            setErrorStatus(true)
            setAnchorEl(null);
            setTimeout(() => {
                setError('')
                setErrorStatus(false)
                onReset()
            }, 5000);
        }
    }, [status]);
    const ref = useRef(null);
    const {
        profile = {},
        departments = [],
        posts = [],
        subjects = []
    } = data;

    const updateStatus = (key) => {

        let obj = {
            "profileID": data.profile.profileID
        }
        if (key === 'active') {
            activeStatus(obj);
        } else if (key == 'inactive') {
            inActiveStatus(obj);
        }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container className={cls.container}>
            <ProfileStatus />

            <Grid container={true} spacing={2}>


                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} xl={2} lg={2} md={2} sm={2} xs={2}>
                        <IconButton component="span">
                            <Avatar
                                alt="Profile Picture"
                                src={data?.attachments?.profile_picture?.fileUrl}
                                style={{ width: '90px', height: '90px' }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item={true} xl={4} lg={4} md={4} sm={4} xs={4} style={{ margin: 'auto', marginLeft: '10px' }}>
                        <h2>{profile?.firstName + ' ' + profile?.lastName}</h2>
                    </Grid>
                    <Grid item={true} xl={4} lg={4} md={4} sm={4} xs={4} style={{ margin: 'auto', marginLeft: '10px' }}>
                        <div>
                            <Button1 aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                {profile?.profileStatus}
                            </Button1>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => updateStatus('active')}
                                >Active</MenuItem>
                                <MenuItem onClick={() => updateStatus('inactive')}>Inactive</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                </Grid>
                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2} style={{ margin: 'auto', marginLeft: '10px' }}>
                    <div style={{ marginTop: '.5em' }} ref={ref} tabIndex={-1}>
                        {errorStatus &&
                            <AlertBox icon={false} type="error" message={error} />
                        }
                    </div>
                </Grid>

                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} container={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TeacherInformation isLoading={loading} data={profile} />
                    </Grid>

                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Subjects isLoading={loading} data={subjects} />
                    </Grid>
                </Grid>

                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <EmergencyContact isLoading={loading} data={profile.emergencyContact} />
                    </Grid>

                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Posts isLoading={loading} data={posts} />
                    </Grid>

                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Departments
                            isLoading={loading}
                            data={departments}
                            profileID={profile.profileID}
                            onFetchProfile={onFetch}
                            unAssignDept={unAssignDept}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container={true} style={{ marginTop: '1em' }}>

            </Grid>
        </Container>
    )
}

export default View;