import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import Button from 'components/common/button';
import Typography from '@material-ui/core/Typography';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FlatList from 'flatlist-react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import { Select } from '../../common/form-unit';
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { TimetableTemplateContext } from '../../../context/TimetableContext'
import eduAPI from "../../../../src/api/api";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PlusIcon from '@material-ui/icons/AddCircle';
import Session from 'services/local/session';
import { useLocation } from "react-router-dom";
import SharedCommonMenu from 'components/common/menu';

const ITEM_HEIGHT = 48;
const TimeTable = ({ status, resErr, onCreate, template, tableLst }) => {

    const [timetableTemplates, setTimetableTemplates] = useContext(TimetableTemplateContext);
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    const [selectedRowObj, SetSelectedRow] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedIndex, setSelIndex] = useState();
    const [DATA, setData] = useState([]);

    const [input, setInput] = useState({
        template: ''
    })

    const [templatesInfo, setTemplates] = useState();

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const getTimeTableInfo = async (id, actionType, type) => {
        if (type === 'view') {
            try {
                await eduAPI.get("/timeTable/byKey/" + id, {
                    headers: {
                        Authorization: Session.BearerToken()
                    }
                }).then((data) => {
                    sessionStorage.setItem('tempObj', JSON.stringify(data.data.data));
                    history.push({
                        pathname: '/timetable-view',
                        state: { tempObj: data.data.data, actionType: actionType }
                    })
                });
            } catch (error) {
                console.error("fetch timtable error", error);
            }
        } else {
            try {
                await eduAPI.get("/timeTable/" + actionType + '/' + id, {
                    headers: {
                        Authorization: Session.BearerToken()
                    }
                }).then((data) => {
                    sessionStorage.setItem('tempObj', JSON.stringify(data.data.data));
                    history.push({
                        pathname: '/timetable-edit',
                        state: { tempObj: data.data.data, actionType: actionType, timeTableId: id }
                    })
                });
            } catch (error) {
                console.error("fetch timtable error", error);
            }
        }
    }

    const getDraftTimeTableInfo = async (id, actionType, type) => {

        try {
            await eduAPI.get("/timeTable/byDraftKey/" + id, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                sessionStorage.setItem('tempObj', JSON.stringify(data.data.data));
                history.push({
                    pathname: '/timetable-view',
                    state: { tempObj: data.data.data, actionType: actionType, timeTableId: id }
                })
            });
        } catch (error) {
            console.error("fetch timtable error", error);
        }

    }

    const handleOpenEdit = () => {
        let data = DATA[selectedIndex];
        SetSelectedRow(DATA[selectedIndex])
        handleCloseMenu()
        let actionType = data.timetable.status == 'PUBLISHED' ? data.draft == null ? 'byKey' : 'byDraftKey' : 'byDraftKey';
        getTimeTableInfo(data.timetable.timetableID, actionType, 'edit')
    };
    const handleOpenView = () => {
        let data = DATA[selectedIndex];
        SetSelectedRow(DATA[selectedIndex])
        handleCloseMenu()
        let actionType = 'View Published';
        getTimeTableInfo(data.timetable.timetableID, actionType, 'view')
    }

    const handleDraftView = () => {
        let data = DATA[selectedIndex];
        SetSelectedRow(DATA[selectedIndex])
        handleCloseMenu()
        let actionType = 'View Draft';
        getDraftTimeTableInfo(data.timetable.timetableID, actionType, 'draft')
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickButton = () => {
        history.push({
            pathname: '/masterlist',
            state: { type: 'timetable' }
        })
    };


    useEffect(() => {
        let templateOptions = []
        for (let i = 0; i < template.length; i++) {
            templateOptions.push({ value: { ...template[i] }, label: template[i].templateName });
        };
        setTemplates(templateOptions);
    }, [template])

    useEffect(() => {
        if (tableLst.length !== 0) {
            setData(tableLst)
            sessionStorage.removeItem('tempObj')
        }
    }, [tableLst])

    return (
        <div>

            <div className={classes.headerMenuContainer}>
                <div className={classes.headerMenuContainer} >
                    <div className={classes.createNewContainer}>
                        <Typography className={classes.createNewText}>Create New</Typography>
                    </div>
                    <div className={classes.createNewDropDownContainer}>
                        <Select
                            name="Templates"
                            required={true}
                            placeholder='Select Template'
                            value={templatesInfo || ''}
                            options={templatesInfo}
                            onChange={(e) => {
                                setTimetableTemplates(e.target.value);
                                history.push("/timetable-template");
                            }}
                            style={{ width: 295, height: 39, borderRadius: 6 }}
                        />

                    </div>
                </div>



                <div className={classes.inputButtonContainer}>
                    {/* <Button className={classes.inputButton} onClick={handleClickButton}>
                        <AddCircleIcon style={{ marginLeft: '-10%', marginRight: '10%', color: "black" }} />
                                        New Template
                        </Button> */}

                    {/* <Button 
                    variant="contained" 
                    className={classes.inputButton} 
                    onClick={handleClickButton}
                    >
                        <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} />
            New Template
        </Button> */}

                    <Button
                        // variant="contained"
                        className={classes.inputButton}
                        label={t('action.new_template')}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        type="primary"
                        size="large"
                        onClick={handleClickButton}>
                        {/* <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} /> */}
                        New Template
                    </Button>

                    {/* <Button
                        onClick={handleClickButton}
                        endIcon={<PlusIcon />}
                        label={t('action.new_template')}
                        type="primary"
                       
                    /> */}
                </div>
            </div>


            <Paper className={classes.listContainer}>

                <div className={classes.buttonContainer} style={{ marginBottom: '20px' }}>

                    <Button style={{ fontWeight: 'bold', color: "#25335a", textTransform: 'capitalize', fontSize: '18px' }}>
                        Timetables ({DATA.length})
                    </Button>
                    {/* <Button>
                        <img src={process.env.PUBLIC_URL + "/sort.svg"} alt='sort' className={classes.iconStyle} />
                        Sort
                    </Button>
                    <Button>
                        <img src={process.env.PUBLIC_URL + "/filter.svg"} alt='filter' className={classes.iconStyle} />
                        Filter
                    </Button> */}
                </div>


                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.titles}>Year</TableCell>
                                <TableCell align="left" className={classes.titles}>Semester</TableCell>
                                <TableCell align="left" className={classes.titles}>Class</TableCell>
                                <TableCell align="left" className={classes.titles}>Created By</TableCell>
                                <TableCell align="left" className={classes.titles}>Last Updated</TableCell>
                                <TableCell align="left" className={classes.titles}>Published</TableCell>
                                <TableCell align="left" className={classes.titles}>Published By</TableCell>
                                <TableCell align="left" className={classes.titles}>Status</TableCell>
                                <TableCell align="left" className={classes.titles}></TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            DATA.length ?
                                <TableBody>
                                    {DATA.map((data, index) => {
                                        const menuItems = []

                                        if (data.timetable?.status === 'PUBLISHED') {
                                            menuItems.push({
                                                content: 'View Published',
                                                onClick: () => { handleOpenView() }
                                            })
                                        }

                                        if (data.timetable?.status === 'PUBLISHED' &&data.draft !== null) {
                                            menuItems.push({
                                                content: 'View Draft',
                                                onClick:() => { handleDraftView() }
                                            })
                                        }
                                        if (data.timetable?.status === 'DRAFT') {
                                            menuItems.push({
                                                content: 'View Draft',
                                                onClick:() => { handleDraftView() }
                                            })
                                        }
                                        menuItems.push({
                                            content: 'Edit',
                                            onClick: () => {
                                                handleOpenEdit();
                                            }
                                        })
                                        return (
                                        <TableRow key={index}>
                                            <TableCell align="left" className={classes.dataText}>{data.classData?.year}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{"Semester " + data.timetable.semesterNo}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>{data.classData?.name}</TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                <div>{data.timetable.createdByName}</div>
                                                <div>{data.timetable.status == 'PUBLISHED' && data.draft !== null ? data.draft?.createdByName : ''}</div>
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                <div>{"On " + moment(data.timetable.updatedAt).format('DD.MM.YYYY')}</div>
                                                <div>{data.timetable.status == 'PUBLISHED' && data.draft !== null ? "On " + moment(data.draft?.updatedAt).format('DD.MM.YYYY') : ''}</div>
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                <div>{data.timetable.status}</div>
                                                <div>{data.timetable.status == 'PUBLISHED' && data.draft !== null ? data.draft?.status : ''}</div>
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>{data.timetable.publishedByName}</TableCell>
                                            <TableCell align="left" className={classes.dataText} style={{ "color": data.timetable.activeStatus == "Inactive" ? "#FF0000" : "black" }}>
                                                {data.timetable.activeStatus}
                                            </TableCell>
                                            {/* <TableCell align="center">
                                                {DATA[index].timetable.status == 'PUBLISHED' && <div>View Publish</div>}

                                                {DATA[index].timetable.status == 'PUBLISHED' && DATA[index].draft !== null ? <div>View Draft</div> : ''}

                                                {DATA[index].timetable.status == 'DRAFT' && <div>View Draft</div>}


                                                <div>Edit</div>
                                            </TableCell> */}
                                            <TableCell align="center">
                                                <SharedCommonMenu
                                                    selectedIndex={setSelIndex}
                                                    actionElement={(onOpenMenu) => (
                                                        <IconButton 
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true" 
                                                            onClick={(event) => { onOpenMenu(event, index); }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    )}
                                                    items={menuItems}
                                                    menuProps={{
                                                        PaperProps: {
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 3.5,
                                                                width: "20ch",
                                                            }
                                                        }
                                                    }}
                                                />
                                                {/* <div>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => { handleClick(event, index) }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id={index}
                                                        key={index}
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={openMenu}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 3.5,
                                                                width: "20ch",
                                                            }
                                                        }}
                                                    >

                                                        {DATA[selectedIndex]?.timetable?.status == 'PUBLISHED' && <MenuItem
                                                            key={index}
                                                            onClick={() => { handleOpenView() }}
                                                        >
                                                            {'View Published'}
                                                        </MenuItem>}

                                                        {DATA[selectedIndex]?.timetable?.status == 'PUBLISHED' && DATA[selectedIndex].draft !== null ? <MenuItem
                                                            key={index}
                                                            onClick={() => { handleDraftView() }}
                                                        >
                                                            {'View Draft'}
                                                        </MenuItem> : ''}


                                                        {DATA[selectedIndex]?.timetable?.status == 'DRAFT' && <MenuItem
                                                            key={index}
                                                            onClick={() => { handleDraftView() }}
                                                        >
                                                            {'View Draft'}
                                                        </MenuItem>}




                                                        <MenuItem
                                                            key={index}
                                                            onClick={() => {
                                                                handleOpenEdit();
                                                            }}
                                                        >
                                                            {'Edit'}
                                                        </MenuItem>
                                                    </Menu>
                                                </div> */}
                                            </TableCell>
                                        </TableRow>
                                    )
                                    })}
                                </TableBody>
                                :
                                <TableRow style={{ textAlign: 'center', fontSize: '20px', margin: '10px 0px' }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>

                                    <TableCell> Records Empty</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>}
                    </Table>
                </TableContainer>




            </Paper>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    createNewDropDownContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 295,
        paddingLeft: 15,

    },

    createNewDropDown: {
        width: 295,
    },
    createNewContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,

    },

    createNewText: {
        fontFamily: 'Poppins',
        fontSize: 20,
        fontWeight: 500,
    },

    headerMenuContainer: {
        display: 'flex',
        // flexDirection: 'row',
        width: '100%',
        marginTop: '-2%'
    },

    listContainer: {

        borderTopStyle: 'solid',
        borderTopWidth: '5px',
        borderTopColor: 'rgba(37, 7, 85, 0.89)',
        borderRadius: 10
    },
    iconStyle: {
        marginRight: '5%'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginRight: '1%',

    },

    titleContainer: {
        marginLeft: '5%'
    },
    dataContainer: {
        marginLeft: '7%'
    },

    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#9FA2B4',
        fontSize: 14,
        textAlign: 'center'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    input: {
        width: '65%',
        height: '50%',
        paddingBottom: '5%',
        paddintTop: '5%',
        fontSize: 12
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '1%',
        paddingLeft: '1%'

    },
    inputButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '2%',
        marginTop: '0.4%',
        paddingLeft: '1%',
        paddingTop: '0.3%'
    },
    inputButton: {
        display: 'flex',
        maxHeight: 50,
        width: 180,
        borderRadius: 7,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
    }


}));

export default TimeTable;