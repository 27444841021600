import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        profileCode: data.input || data.generated,
        primaryCode: data.primary
    }, _.isNull), _.isUndefined);
}

export default component;