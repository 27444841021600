/**
 * This is the component to EDIT the timetable
 */
import React, { useState, useEffect, useCallback, Fragment, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Button from 'components/common/button';
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { Resizable } from 're-resizable';
import moment from 'moment';
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

import { TimetableTemplateContext } from '../../../context/TimetableContext';
import useSession from 'hooks/use-session';
import { Select } from "../../common/form-unit";
import { useHistory } from "react-router-dom";
import eduAPI from "../../../api/api";
import useToaster from 'hooks/use-toaster';
import MultiSelect from "react-multi-select-component";


const getTime = (strtTme, stpTme) => {
  var timeStart =
    new Date('01/01/2007 ' + strtTme).getHours() * 60 +
    new Date('01/01/2007 ' + strtTme).getMinutes();
  var timeEnd =
    new Date('01/01/2007 ' + stpTme).getHours() * 60 +
    new Date('01/01/2007 ' + stpTme).getMinutes();

  var hourDiff = timeStart - timeEnd;
  return hourDiff;
}

const getName = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let name = '';
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      name = obj.subjectName == 'Empty' ? '' : obj.subjectName;
    }
  })
  return name;
}

const getColor = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let color = '';
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      color = obj.subjectName == 'Empty' ? 'white' : obj.subjectColor;
    }
  })
  return color;
}

const getTeacher = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let tchrName = '';
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      tchrName = obj.subjectName == 'Empty' ? '-' : obj.teacherName;
    }
  })
  return tchrName;
}

const getTecherId = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let id = [];
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      id = obj.teacherID.length == 0 ? [] : obj.teachers;
    }

  })
  return id;
}

const getTecherIdsOnly = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let id = [];
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      id = obj.teacherID.length == 0 ? [] : obj.teacherID;
    }

  })
  return id;
}

const getDragStatus = (key) => {
  let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
  let teachersList = sessionObject.teachers;
  let status = true;
  teachersList.map((obj) => {
    if (obj.subjectID == key) {
      status = obj.teacherID == null ? false : true;
    } else {
      if (obj.subject.toLowerCase() == 'block' || obj.subject.toLowerCase() == 'break' || obj.subject.toLowerCase() == 'lunch' || obj.subject.toLowerCase() == 'clean up') {

        status = false;
      }
    }
  })
  return status;
}





const generateDefaultRows = (day, timeStart) => {
  let slotObj = JSON.parse(sessionStorage.getItem('tempObj'));
  let slottts = slotObj.slotsData.find(data => data.day == day);

  let slots = [];
  let count = 0;

  let timetableTemplates = slotObj.templateData;
  const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
  const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;

  _.sortBy(slottts.timeSlots, "index").map((slot, i) => {
    let diff = getTime(slot.end, slot.start);
    let x = (totalDailyWidth * diff) / totalDailyMinute;



    slots.push({
      slot,
      startTime: slot.start,
      endTime: slot.end,
      // id: slot.subjectID == '97a55776-ad91-4ab1-ad6d-bc2c4896888b' ? uuid() : slottts.timeSlots[i].subjectID,
      id: uuid(),
      height: 52,
      isEmpty: slot.subjectID == '97a55776-ad91-4ab1-ad6d-bc2c4896888b' ? true : false,
      type: '',
      width: x !== 0 ? x : 120,
      subject: getName(slot.subjectID),
      subjectID: slot.subjectID,
      color: getColor(slot.subjectID)
    });


    // if ('97a55776-ad91-4ab1-ad6d-bc2c4896888b' === slot.subjectID) {
    //   slots.push({
    //     startTime: slot.start,
    //     endTime: slot.end,
    //     id: uuid(),
    //     type: "",
    //     width: 120,
    //     height: 52,
    //     isEmpty: true
    //   });
    // } else {
    //   slots.push({
    //     startTime: slot.start,
    //     endTime: slot.end,
    //     id: slot.subjectID ? slot.subjectID : uuid(),
    //     height: 52,
    //     isEmpty: false,
    //     type: '',
    //     width: x !== 0 ? x : 120,
    //     subject: getName(slot.subjectID),
    //     color: getColor(slot.subjectID)
    //   });
    // }
  });
  return slots;



  // for (let i = 0; i < slottts.timeSlots.length; i++) {

  //   let diff = getTime(slottts.timeSlots[i].end, slottts.timeSlots[i].start);
  //   let x = (totalDailyWidth * diff) / totalDailyMinute;

  //   if ('97a55776-ad91-4ab1-ad6d-bc2c4896888b' === slottts.timeSlots[i].subjectID) {
  //     slots.push({
  //       startTime: slottts.timeSlots[i].start,
  //       endTime: slottts.timeSlots[i].end,
  //       id: uuid(),
  //       type: "",
  //       width: 120,
  //       height: 52,
  //       isEmpty: true
  //     });
  //   } else {
  //     slots.push({
  //       startTime: slottts.timeSlots[i].start,
  //       endTime: slottts.timeSlots[i].end,
  //       id: slottts.timeSlots[i].subjectID ? slottts.timeSlots[i].subjectID : uuid(),
  //       height: 52,
  //       isEmpty: false,
  //       type: '',
  //       width: x !== 0 ? x : 120,
  //       subject: getName(slottts.timeSlots[i].subjectID),
  //       color: getColor(slottts.timeSlots[i].subjectID)
  //     });
  //   }

  // }

};



// TODO:
const generateInitialDayRowsFromTTTemplate = (startDay, noOfDays, DAYS_OF_WEEK, tempTime) => {
  let validDays = getValidDays(startDay, noOfDays, DAYS_OF_WEEK);
  let dayRows = [];
  for (let vD of validDays) {
    let dayStr = DAYS_OF_WEEK[vD];
    let day = {
      day: dayStr,
      slots: generateDefaultRows(dayStr, tempTime),
    };
    dayRows.push(day);
  }
  return dayRows;
};

const getValidDays = (startDay, numberOfDays, DAYS_OF_WEEK) => {
  let weekCount = DAYS_OF_WEEK.length;
  let validDays = [startDay];
  let count = 1;

  while (count !== numberOfDays) {
    count++;
    startDay++;

    // loop it around
    if (startDay === weekCount) {
      startDay = 0;
    };

    validDays.push(startDay);
  }
  return validDays;
};

const createNewDroppedSubjectSlot = (day, subjectInfo) => {
  return {
    day,
    ...(_.omit(subjectInfo, 'id')),
    // id: subjectInfo.id ? subjectInfo.id : uuid(),
    isEmpty: subjectInfo.id ? false : true,
    subjectID: subjectInfo.id,
  };
};

const TimetableTemplate = ({ status, tStatus, resErr, tError, onResetStatus, onFetchClassbyYear, availableYears, availableClasses, onUpdateTimeTable, onFetchClassbySemester, availableSemesters, onFetchSubjectbyLevel, subjects }) => {
  const [state, setState] = useState({ [uuid()]: [] });
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState();
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [days, setDays] = useState();
  const [subjectsInfo, setSubjects] = useState();
  const [disables, setDisables] = useState({
    class: false,
    semester: false,
  });
  const [doneButton, setDoneButton] = useState(false);
  ;
  const GridTooltip = withStyles((theme) => ({
    tooltip: {

      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  const [rows, setRows] = useState([
    { time: "07:00 - 08:00", id: uuid() },
    { time: "08:00 - 09:00", id: uuid() },
    { time: "09:00 - 10:00", id: uuid() },
    { time: "10:00 - 11:00", id: uuid() },
    { time: "11:00 - 12:00", id: uuid() },
    { time: "12:00 - 13:00", id: uuid() },
    { time: "13:00 - 14:00", id: uuid() },
    { time: "14:00 - 15:00", id: uuid() },
  ]);
  const [DAYS_OF_WEEK, setDAYS_OF_WEEK] = useState([
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ]);
  const [input, setInput] = useState({
    template: "",
    year: "",
    semester: "",
    class: "",
  });

  let initialYears = [
    {
      value: "2020",
      label: "2020",
    },
    {
      value: "2021",
      label: "2021",
    },
  ];
  let initialClasses = [
    {
      value: "class 1",
      label: "Class 1",
    },
    {
      value: "class 2",
      label: "Class 2",
    },
  ];
  let initialSems = [
    {
      value: "Sem 1",
      label: "Semester 1",
    },
    {
      value: "Sem 2",
      label: "semester 2",
    },
  ];

  const [toastMsg, setToastMsg] = useState("")
  const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
  const [timetableTemplates, setTimetableTemplates] = useContext(TimetableTemplateContext);
  const [MinuteWidth, setMinuteWidth] = useState();
  const minMinuteIncrement = 5;
  const session = useSession();
  const history = useHistory();
  const [yearsInfo, setYears] = useState(initialYears);
  const [templatesInfo, setTemplates] = useState([]);
  const [classesInfo, setClasses] = useState(initialClasses);
  const [semestersInfo, setSemesters] = useState(initialSems);
  const [times, setTimes] = useState();
  const minWidth = 20;
  const [clsData, setClsData] = useState({});
  const [tempData, setTempData] = useState({});
  const [timTabData, setTimTabData] = useState({});
  const [draftData, setDraftData] = useState({})
  const [action, setAction] = useState('')
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.actionType) {
      setAction(location?.state?.actionType)
    }
  }, [location])


  useEffect(() => {
    if (session.orgID) {
      const tempDays = [];
      const tempTime = [];
      const dragabbleSnap = [120];
      let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
      let timetableTemplates = sessionObject.templateData;

      // set the timetable context for update
      setTimetableTemplates(timetableTemplates)

      setClsData(sessionObject.classData);
      setTempData(sessionObject.timetableData);
      setTimTabData(sessionObject.templateData);
      setDraftData(sessionObject.draft == null ? null : sessionObject.draft)

      onFetchClassbyYear(session.orgID, sessionObject.classData.year);
      onFetchClassbySemester(sessionObject.classData.classID);
      onFetchSubjectbyLevel(sessionObject.classData.level, session.orgID);


      const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
      const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
      const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
      const condition = (e) => e === timetableTemplates.startDay;
      let time = timetableTemplates.startTime;
      const duration = timetableTemplates.durationOfPeriods;
      let i = DAYS_OF_WEEK.findIndex(condition);

      for (let h = 0; h < timetableTemplates.numberOfPeriods; h++) {
        tempTime.push({
          startTime: moment(time, 'HH:mm').format('HH:mm'),
          endTime: moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm'),
          id: uuid()
        });
        time = moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm');
      }

      for (let j = 0; j < timetableTemplates.numberOfDays; j++) {
        if (i < DAYS_OF_WEEK.length) {
          tempDays.push(DAYS_OF_WEEK[i]);
          i++;
        }
        else {
          i = 0;
          tempDays.push(DAYS_OF_WEEK[i]);
          i++;
        }
      }

      setDAYS_OF_WEEK(tempDays);
      setRows(tempTime);

      setDays(generateInitialDayRowsFromTTTemplate(0, timetableTemplates.numberOfDays, tempDays, tempTime))

      setMinuteWidth(minuteWidth);

      for (let i = 0; i < timetableTemplates.numberOfPeriods; i++) {
        dragabbleSnap.push(dragabbleSnap[i] + MinuteWidth);
      }
    }

  }, [sessionStorage.getItem('tempObj') && session.orgID]);


  useEffect(() => {

    let years = [];
    let classes = [];
    let semester = [];
    let tempSubject = [];

    if (subjects !== undefined) {
      for (let i = 0; i < subjects.length; i++) {
        tempSubject.push({
          id: subjects[i].subject.subjectID,
          color: subjects[i].subject.colour,
          subject: subjects[i].subject.name,
          teachers: [],
          dragDisabled: subjects[i]?.teachers !== null ? true : false,
          selTeacher: getTecherId(subjects[i].subject.subjectID),
          techerIds: getTecherIdsOnly(subjects[i].subject.subjectID)
        })
        if (subjects[i].teachers) {
          for (let j = 0; j < subjects[i].teachers.length; j++) {
            tempSubject[i].teachers.push({
              name: subjects[i].teachers[j].firstName + " " + subjects[i].teachers[j].lastName,
              teacherID: subjects[i].teachers[j].teacherID,
              isEmpty: false
            })
          }
        }
      }
      tempSubject.find((data) => {
        if (data.selTeacher) {
          data.dragDisabled = false
        }
      })
      setSubjects(tempSubject);
    }

    if (availableYears !== undefined) {
      for (let i = 0; i < availableYears.length; i++) {
        years.push({ value: availableYears[i], label: availableYears[i] });
      }
      setYears(years);
    }

    if (availableClasses !== undefined) {
      for (let i = 0; i < availableClasses.length; i++) {
        classes.push({ value: availableClasses[i].classID, label: availableClasses[i].className });
      }
      setClasses(classes);

    }

    if (availableSemesters !== undefined) {
      for (let i = 0; i < availableSemesters.length; i++) {
        semester.push({ value: availableSemesters[i], label: 'Semester' + availableSemesters[i].no })
      }
      setSemesters(semester);
    }
  }, [availableYears, availableClasses, availableSemesters, subjects])

  useEffect(() => {
    setState(
      { ...state });
  }, [days])

  const [tempId, setTempId] = useState('')
  const [clsName, setClsName] = useState("")

  useEffect(() => {
    if (tStatus.update == 'success') {
      setDoneButton(true);
      setToastMsg("Time Table Updated Succsessfully...")
      onOpenToaster();
      onResetStatus();
      setTimeout(() => {
        history.push(
          {
            pathname: '/timetable',
            state: { fetch: 'success' }
          }
        );
      }, 2000);
    }
  }, [tStatus])


  useEffect(() => {
    if (tError?.update?.message) {
      setToastMsg("Error " + tError?.update?.message)
      onOpenToaster();
    };
  }, [tError])


  //this is to replace
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  //this is to move
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  // this is to drag
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }
      const clonedDays = _.cloneDeep(days)
      let dayOfDropped = clonedDays.find((d) =>
        d.slots.find((s) => s.id === destination.droppableId)
      );
      if (dayOfDropped === undefined) {
        alert('error');
        return;
      } else {
        let newSlots = dayOfDropped.slots;
        newSlots = newSlots.map((n) => {
          if (n.id === destination.droppableId) {

            return {
              id: n.id,
              ...createNewDroppedSubjectSlot(
                dayOfDropped.day,
                subjectsInfo[source.index],
              ), width: n.width, height: n.height, startTime: n.startTime, endTime: n.endTime
            }
          }
          return n;
        });
        dayOfDropped.slots = newSlots;
        setDays(clonedDays)
      }
      switch (source.droppableId) {
        case destination.droppableId:
          setState({
            ...state,
            [destination.droppableId]: reorder(
              state[source.droppableId],
              source.index,
              destination.index
            ),
          });
          break;

        case "ITEMS":
          setState({
            ...state,
          });
          break;

        default:
          setState(
            ...state,
            move(
              state[source.droppableId],
              state[destination.droppableId],
              source,
              destination
            )
          );
          break;
      }
    },
    [state, subjectsInfo]
  );

  const onResize = (e, direction, ref, d, index, subindex) => {
    let daysCopy = days;
  }

  const onResizeStop = (e, direction, ref, d, index, subindex) => {
    let daysCopy = _.cloneDeep(days);
    const slotCopy = days[index].slots
    const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
    const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
    const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
    const minuteIncrement = Math.round(d.width / minuteWidth) * 5;
    const _count = count + 1;
    const totalPairWidth = days[index].slots[subindex].width + days[index].slots[subindex + 1].width;
    const totalPairMinutes = timetableTemplates?.durationOfPeriods * 2;
    const minuteConstrainX = Math.round((totalDailyMinute * 20) / totalDailyWidth);
    const minuteConstrain = Math.round(minuteConstrainX / 10) * 10;

    const pairConstrain = totalPairWidth - 20;
    const minutePairConstrain = totalPairMinutes - minuteConstrain;


    if (subindex + 1 < days[index].slots.length) {
      if (days[index].slots[subindex].width + d.width >= pairConstrain) {
        daysCopy[index].slots[subindex].width = pairConstrain;  // this is correct
        daysCopy[index].slots[subindex + 1].width = minWidth; // this is also correct
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex + 1].endTime, 'HH:mm').add(-5, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = daysCopy[index].slots[subindex].endTime;

      }
      else if (days[index].slots[subindex].width + d.width <= minWidth) {
        daysCopy[index].slots[subindex].width = minWidth;
        daysCopy[index].slots[subindex + 1].width = pairConstrain;
        const reducedMinuteIncrement = Math.round(minWidth / minuteWidth) * 5;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
      }
      else {
        daysCopy[index].slots[subindex].width = daysCopy[index].slots[subindex].width + d.width;
        daysCopy[index].slots[subindex + 1].width = daysCopy[index].slots[subindex + 1].width - d.width;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].endTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex + 1].startTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');

      }
    }
    setDays([
      ...daysCopy,
    ]);
  }


  const handleClick = (e, index) => {
    setAnchorEl(e.currentTarget);
    setMenuIndex(index);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  // const handleMenu = (e, index, teacherID) => {
  //   setAnchorEl(null);
  //   let tempSub = subjectsInfo;
  //   tempSub[index] = { ...tempSub[index], dragDisabled: false, isEmpty: false, selTeacher: teacherID };
  //   setSubjects(tempSub);
  // }

  const handleMenu = (e, index, teacherID) => {
    let techerIds = []
    e.map((obj) => {
      techerIds.push(obj.value)
    })

    setAnchorEl(null);
    let tempSub = subjectsInfo;
    tempSub[index] = { ...tempSub[index], dragDisabled: false, isEmpty: false, selTeacher: e, techerIds: techerIds };
    setSubjects(tempSub);
  }

  const onSave = () => {
    let timeTable = [];
    days.map((data) => {
      let timeSlots = [];
      data.slots.map((sObj, index) => {
        timeSlots.push({
          index: index,
          start: sObj.startTime,
          end: sObj.endTime,
          subjectID: sObj.subjectID
        });
      });
      timeTable.push({ day: data.day, timeSlots: timeSlots });
    });

    let teacherArray = [];

    subjectsInfo.map((data) => {
      if (data?.techerIds.length) {
        teacherArray.push({
          "subjectID": data.id,
          "teacherID": data.techerIds
        })
      }
    })

    let reqObj = {
      timetableID: location.state.timeTableId,
      teachers: teacherArray,
      timeTable: timeTable
    }
    onUpdateTimeTable(reqObj)
  }



  const getOpacity = (obj) => {
    console.log(obj)


    if (obj.selTeacher == "") {
      if (obj.teachers.length == 0) {
        // if (obj.subject.toLowerCase() == 'block' || obj.subject.toLowerCase() == 'break' || obj.subject.toLowerCase() == 'lunch' || obj.subject.toLowerCase() == 'clean up') {
        return 1
      } else {
        return 0.5
      }
    } else {
      return 1
    }
  }

  const getStatus = (obj) => {
    if (obj.teachers.length == 0) {
      // if (obj.subject.toLowerCase() == 'block' || obj.subject.toLowerCase() == 'break' || obj.subject.toLowerCase() == 'lunch' || obj.subject.toLowerCase() == 'clean up') {
      return 0
    } else {
      return 1
    }
  }
  
  const handleClearSubject = (i, j) => {
    const newDays = _.cloneDeep(days)
    const emptySubjectID = '97a55776-ad91-4ab1-ad6d-bc2c4896888b'
    try {
      newDays[i].slots[j] = {
        ...newDays[i].slots[j],
        subjectID: emptySubjectID,
        subject: getName(emptySubjectID),
        color: getColor(emptySubjectID),
        isEmpty: true,
      }
      setDays(newDays)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Toaster />
      <DragDropContext onDragEnd={onDragEnd} className={classes.listContainer}>
        <div className={classes.grdContainer}>
          <Grid container style={{

          }}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0px', fontSize: '20px', fontFamily: 'Poppins' }}>
              {clsData?.year} | Semester No {tempData?.semesterNo} | {clsData?.year}
            </Grid>
          </Grid>
          <Grid container style={{ flexWrap: 'nowrap' }}>
            <div className={classes.timetableFirstColumn}>
              <Typography>Day/Time</Typography>
            </div>
            {rows.map((row, index) => (
              <Grid item>
                <div className={classes.gridHeaderContainer}>
                  <Typography className={classes.grdLabel}>{row.startTime} - {row.endTime}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>

          {days?.map((day, i) => (
            <Grid container key={day.day} style={{ flexWrap: 'nowrap' }}>
              <div className={classes.timetableFirstColumn}>
                <Typography>{day.day}</Typography>
              </div>
              {day?.slots?.map((row, j) => {
                return (
                  <Droppable key={row.id} droppableId={row.id}>
                    {(provided, snapshot) => {
                      return (
                        <GridTooltip
                          title=
                          {
                            <div>
                              <Typography>Start Time: {row.startTime}</Typography>
                              <Typography>End Time: {row.endTime}</Typography>
                            </div>
                          }
                        >
                          <Resizable
                            size={{ height: row.height, width: row.width }}
                            grid={[MinuteWidth, MinuteWidth]}
                            snap={{ x: times }}
                            snapGap={MinuteWidth}
                            enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                            onResizeStop={(e, direction, ref, d) => { 
                              if (j === day?.slots?.length - 1) return;
                              onResizeStop(e, direction, ref, d, i, j) 
                            }}
                            onResize={(e, direction, ref, d) => { onResize(e, direction, ref, d, i, j); }}
                          >
                            <div
                              className={classes.gridContainer}
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                              style={{
                                width: row.width,
                                height: row.height,
                                backgroundColor: !row.isEmpty && row.color
                                  ? row.color
                                  : snapshot.isDraggingOver
                                    ? "grey"
                                    : "white",
                              }}
                            >
                              {
                                !row.isEmpty &&
                                <GridTooltip
                                  title='Remove subject'
                                  placement='right-end'
                                >
                                  <ClearIcon 
                                    className={classes.removeSubjectCross} 
                                    fontSize={row.width < 100 ? 'small' : 'medium'}
                                    color='error'
                                    onClick={() => handleClearSubject(i, j)}
                                  />
                                </GridTooltip>
                              }
                              {row.subject}
                            </div>
                          </Resizable>
                        </GridTooltip>
                      );
                    }}
                  </Droppable>
                );
              })}
            </Grid>
          ))}
        </div>

        <div>

          <div className={classes.dragNdropTxtContainer}>
            <Typography className={classes.dragNdropTxt}>
              Drag and Drop Subject
            </Typography>
          </div>

          <Droppable droppableId="ITEMS" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                className={classes.subjectContainer}
              >
                {subjectsInfo?.map((subject, index) => (
                  <Draggable
                    key={subject.id}
                    draggableId={subject.id}
                    index={index}
                    isDragDisabled={subject.dragDisabled}

                  >
                    {(provided, snapshot) => (
                      <Fragment>
                        <div
                          className={classes.subject}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >

                          {/* <Grid container >
                            <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4} style={{
                              opacity: getOpacity(subject)
                            }}>
                              {subject.subject}
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              {getStatus(subject) ?
                                <ExpandMoreIcon onClick={(e) => { handleClick(e, index) }} /> : ''}
                            </Grid>
                          </Grid> */}

                          <Grid container >
                            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} style={{
                              opacity: getOpacity(subject)
                            }} className={classes.overflowSub}>
                              {subject.subject}
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              {getStatus(subject) ?
                                <ExpandMoreIcon onClick={(e) => { handleClick(e, index) }} /> : ''}
                            </Grid>
                          </Grid>


                          <Button
                            style={{ backgroundColor: `${subject.color}` }}
                            className={classes.subjectButton}
                          ></Button>

                          <Menu
                            id='Subject-Menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            {/* {subjectsInfo[menuIndex]?.teachers?.map((teacher, i) => (
                              <MenuItem key={i} onClick={(e) => { handleMenu(e, menuIndex, teacher.teacherID) }}>{teacher.name}</MenuItem>
                            ))} */}


                            <div className={classes.customMenu}>

                              <MultiSelect
                                style={{ multiselectContainer: { height: '500px', width: '200px' } }}
                                value={subjectsInfo[menuIndex]?.selTeacher || []}
                                shouldToggleOnHover={true}
                                options={_.map(subjectsInfo[menuIndex]?.teachers, (teacher) => {
                                  return { label: teacher.name, value: teacher.teacherID }
                                })}
                                onChange={(e) => { handleMenu(e, menuIndex, 'id') }}

                                labelledBy={""}
                              />
                            </div>


                          </Menu>

                        </div>
                        {snapshot.isDragging && (
                          <div className={classes.subject}>
                            {subject.subject}
                            <Button
                              style={{ backgroundColor: `${subject.color}` }}
                              className={classes.subjectButton}
                            ></Button>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <br />
        <div className={classes.doneButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            type="submit"
            label="Update"
            onClick={onSave}
          >
            Update
          </Button>


        </div>
        <br />
      </DragDropContext>
    </>
  );
};

const useStyles = makeStyles(() => ({
  subjectContainer: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 800,
    marginLeft: "2%",
    // flexDirection: 'row',
  },

  customMenu: {
    width: '230px !important',
    height: '250px !important',
    padding: '5px 10px',
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 3px 3px 0px rgb(0 0 0 / 5%), 0px 3px 8px 0px rgb(0 0 0 / 5%) !important'
  },

  overflowSub: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  subjectButton: {
    // height: 10,
    width: "100%",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -10
  },
  subject: {
    paddingTop: 4,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginRight: "1%",
    marginTop: "1%",
    display: "flex",
    height: 40,
    width: 120,
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    // alignItems: 'center'
  },
  dragNdropTxtContainer: {
    marginLeft: "1%",
    marginTop: "1%",
  },
  dragNdropTxt: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
  },
  grdLabel: {
    textAlign: "center",
  },
  grdContainer: {
    marginLeft: 20,
    marginTop: 5,
    // overflowX: "scroll",
    // width: '200vw'
    // display: 'inline-block',
    // height: 1000,
    // width:  1000,
    // whiteSpace: 'nowrap'

  },
  grdtxt1: {
    marginTop: "15px",
    marginLeft: "0px",
    color: "#756F86",
    fontSize: "14px",
    fontWeight: "bold",
  },
  gridContainer: {
    // width: 120,
    // height: 52,
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&:hover': {
      '& $removeSubjectCross': {
        display: 'block',
      }
    }
  },
  removeSubjectCross: {
    display: 'none',
    position: 'absolute',
    top: 2,
    right: 4,
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // let it have an opacity so that red color subject still can see the Cross icon
  },

  gridHeaderContainer: {
    width: 120,
    height: 52,
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  subjectBox: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    padding: "5px 0px",
    margin: "10px",
    textAlign: "center",
    textTransform: "capitalize",
  },

  listContainer: {
    borderLeftStyle: "solid",
    borderLeftWidth: "10px",
    borderLeftColor: "rgba(61, 58, 214, 0.9)",
    borderRadius: 10,
  },
  iconStyle: {
    marginRight: "5%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: "1%",
  },

  titleContainer: {
    marginLeft: "5%",
  },
  dataContainer: {
    marginLeft: "7%",
  },

  titles: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#9FA2B4",
    fontSize: 14,
    textAlign: "center",
  },
  dataText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  input: {
    width: "65%",
    height: "50%",
    paddingBottom: "5%",
    paddintTop: "5%",
    fontSize: 12,
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "1%",
    paddingLeft: "1%",
  },
  inputButtonContainer: {
    alignItems: "center",
    display: "flex",
    paddingBottom: "2%",
    marginTop: "0.4%",
    paddingLeft: "1%",
    paddingTop: "0.3%",
  },
  inputButton: {
    height: "120%",
    width: "100%",
    borderRadius: 15,
    fontSize: 12,
    fontWeight: 400,
    // display: 'flex',
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
  },
  doneButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },

  doneButton: {
    height: 40,
    width: 175,
    color: "black",
  },

  timetableFirstColumn: {
    width: 120,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));

export default TimetableTemplate;




// let rowsList = []
// sessionObject.slotsData.map((data, index) => {
//   let slots = [];

//   _.sortBy(data.timeSlots, "index").map((slot, subIndex) => {

//     let diff = getTime(slot.end, slot.start);
//     let x = (totalDailyWidth * diff) / totalDailyMinute;

//     slots.push({
//       day: data.day,
//       startTime: slot.start,
//       endTime: slot.end,
//       height: 52,
//       id: slot.subjectID,
//       isEmpty: true,
//       type: '',
//       width: x !== 0 ? x : 120,
//       subject: getName(slot.subjectID),
//       color: getColor(slot.subjectID),
//       teacherName: getTeacher(slot.subjectID)
//     })
//   })
//   rowsList.push({
//     day: data.day,
//     slots: slots
//   })
// })