import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

// Components
import Form from '../departments-form';

import Loader from 'components/common/loader';
import Modal from 'components/common/modal';
import AlertBox from 'components/common/alert-box';

import { withStyles, makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ButtonComponent from 'components/common/button';
import PlusIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: '#031545',
        fontWeight: 600
    },
    body: {
        color: '#031545',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        border: '1.5px solid #DFE0EB'
    },
}))(TableRow);

const View = ({ status, resErr, departments }) => {
    const cls = useStyles();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({ open: false, content: null, title: null });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        if (status.fetch === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status.fetch]);

    const onCloseModal = () => {
        setModal({ ...modal, open: false });
    }

    const actionClick = ({ type }) => {
        let data = {};
        if (selectedIndex >= 0) {
            data = departments[selectedIndex];
        }

        switch (type) {
            case 'ADD':
                setModal({
                    ...modal,
                    open: true,
                    title: 'New Department',
                    content: <Form data={{}} closeModal={onCloseModal} />
                });

                break;

            case 'EDIT':
                setModal({
                    ...modal,
                    open: true,
                    title: 'Edit Department',
                    content: <Form data={data} closeModal={onCloseModal} />
                });

                break;

            default:
                break;
        }
    }

    const handleClick = (e, index) => {
        setAnchorEl(e.currentTarget);
        setSelectedIndex(index);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    if (loading) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px" />
            </div>
        )
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                {/* <Button
                    variant="contained"
                    className={classes.inputButton}
                    onClick={() => actionClick({ type: 'ADD' })}>
                    <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} />
                    Add Department
                </Button> */}

                <ButtonComponent
                    // variant="contained"
                    // className={classes.inputButton}
                    label="Add Department"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    type="primary"
                    size="large"
                    style={{width:'200px'}}
                    onClick={() => actionClick({ type: 'ADD' })}/>

            </div>

            {!_.isUndefined(resErr) && resErr?.length !== 0 &&
                <div style={{ marginTop: '1em' }}>
                    <AlertBox type="error" message={resErr} />
                </div>
            }

            <Grid container={true}>
                <Grid item={true} xl={12} lg={12} sm={12} xs={12}>
                    <TableContainer>
                        <Table className={cls.table}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Department</StyledTableCell>
                                    <StyledTableCell>Department Code</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Head Of Department</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {!_.isEmpty(departments) && _.map(departments, (o, i) => {
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell>{o.departmentName}</StyledTableCell>
                                            <StyledTableCell>{o.departmentCode}</StyledTableCell>
                                            <StyledTableCell>{o.departmentStatus}</StyledTableCell>
                                            <StyledTableCell>{o.headOfDepartmentName}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <IconButton size="small" onClick={(e) => { handleClick(e, i); }}>
                                                    <MoreIcon fontSize="small" />
                                                </IconButton>

                                                <Menu
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem onClick={() => { handleClose(); actionClick({ type: 'EDIT' }) }}>
                                                        Edit <CreateOutlinedIcon style={{ marginLeft: '.5em' }} />
                                                    </MenuItem>
                                                </Menu>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

{/* TODO: fix modal width */}
            {modal.open &&
                <Modal
                    title={modal.title}
                    open={modal.open}
                    onHandleClose={onCloseModal}
                    showFooter={null}
                >
                    {modal.content}
                </Modal>
            }
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    table: {
        border: '1.5px solid #DFE0EB',
        marginTop: '1em'
    },
    inputButton: {
        boxShadow: 'none',
        display: 'flex',
        maxHeight: 50,
        width: 178,
        height: 40,
        borderRadius: 5,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FDB714',
            opacity: 0.7
        }
    }
}));

export default View;
