import React from 'react';
import _ from 'lodash';

// Components
import MURadio from '@material-ui/core/Radio';
import MURadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputError from '../input-error';
import FormControl from '@material-ui/core/FormControl';
// import Label from '@material-ui/core/InputLabel';

const RadioGroup = ({ 
    value, 
    onChange, 
    name, 
    defaultValue,
    required,
    options, 
    error,
    labelPlacement,
    horizontal,
    label,
    id, 
    ...props 
}) => {
    // TODO put label
    return (
        <div> 
            <MURadioGroup
                value={value}
                onChange={onChange}
                name={name}
                defaultValue={defaultValue}
                {...props}
            >
                <div style={{ display: horizontal ? 'flex' : 'block' }}>
                    {!_.isEmpty(options) && _.map(options, (o, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                value={o.value}
                                label={`${o.label}`}
                                labelPlacement={labelPlacement}
                                control={(
                                    <MURadio id={id} required={o.required} {...o}/>
                                )}
                            />
                            // <Grid container={true} key={i} alignItems="center">
                            //     <Grid>
                            //         <MURadio
                            //             name={o?.name}
                            //             checked={value === o?.value}
                            //             onChange={onChange}
                            //             value={o?.value}
                            //             {...o}
                            //         />
                            //     </Grid>

                            //     <Grid>
                            //         <Label required={o?.required} error={o?.error}>
                            //             {o?.label}
                            //         </Label>
                            //     </Grid>
                            // </Grid>
                        )
                    })}
                </div>

                <InputError error={error} label={label}/>
            </MURadioGroup>
        </div>
    )
}

RadioGroup.defaultProps = {
    onChange: () => {},
    options: [],
    labelPlacement: 'end'
}

export default RadioGroup;
