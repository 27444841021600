import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        },
        marginBottom: '.8em',
        fontSize: '14px',
        color: '#756F86'   
    },
    rootInput: {
        '& :disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            cursor: 'not-allowed'
        },
        '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
            fontSize: '14px'
        },
        '& ::-moz-placeholder': { /* Firefox 19+ */
            fontSize: '14px'
        },
        '& :-ms-input-placeholder': { /* IE 10+ */
            fontSize: '14px'
        },
        '& :-moz-placeholder': { /* Firefox 18- */
            fontSize: '14px'
        }
    }
}));

export default useStyles