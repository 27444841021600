import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    toaster: {
        '&:root': {
            
        }
    }
}));

export default useStyles;