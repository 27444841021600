import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import enc from 'helpers/enc';
import useSession from 'hooks/use-session';

import ProfileAction from 'redux/actions/profile';

// Components
import View from './teacher-form-v2.view';

const mapStateToProps = (state) => {
    return {
        data: state.profile?.data,
        status: state.profile?.status, 
        error: state.profile?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onResetData$: () => {
            profileActions.resetData();
        },
        onResetStatus$: () => {
            profileActions.resetStatus();
        },
        onCreate$: (data, orgID) => {
            profileActions.createTeacher(data, orgID);
        },
        onUpdate$: (data, orgID) => {
            profileActions.updateTeacherProfile(data, orgID);
        },
        onFetchByID$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        }
    }
}

const Container = ({ 
    data, 
    status, 
    error, 
    onCreate$, 
    onUpdate$,
    onFetchByID$, 
    onResetData$,
    onResetStatus$ 
}) => {
    const session = useSession();
    const params = useParams();
    const teacherID = enc.decode(params?.teacherID);

    useEffect(() => {
        if (teacherID) { 
            onFetchByID$(teacherID); 
        }
        else {
            onResetData$();
            onResetStatus$();
        }
    }, [teacherID]);

    useEffect(() => {
        if (teacherID && status.update === 'success') {
            onFetchByID$(teacherID);
            onResetStatus$();
        }
    }, [status.update, teacherID]);

    const onCreate = (input) => {
        if (session.orgID) {
            onCreate$(input, session.orgID);
        }
    }

    const onUpdate = (input) => {
        if (session.orgID) {
            onUpdate$({ ...data.profile, ...input }, session.orgID);
        }
    }

    return (
        <View 
            data={data} 
            status={status} 
            resErr={error?.create || error?.update} 
            onCreate={onCreate}
            onUpdate={onUpdate}
            onResetStatus={onResetStatus$}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
