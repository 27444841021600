import _ from 'lodash';
import base from './base';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    let messageTemplateTypeString = null;
    if (data.type.length !== 0) {
        if (data.type.includes('_')) {
            messageTemplateTypeString = data.type.split('_').map((char) => {
                return _.upperFirst(_.toLower(char));
            }).join(' ');
        }
        else {
            messageTemplateTypeString = _.upperFirst(_.toLower(data.type));
        }
    }

    return _.omitBy(_.omitBy({
        ...base(data),
        messageTemplateID: data.messageTemplateID,
        messageTemplateType: data.type,
        messageTemplateTypeString,
        messageTemplateCategory: data.category,
        messageTemplateCategoryString: data?.category?.length !== 0 ? _.upperFirst(_.toLower(data.category)) : null,
        messageTemplateName: data.display_name,
        messageTemplateContent: data.template,
        warningCount: data.warningCount,
        warningCountString: data.warningCount ? String(data.warningCount) : null
    }, _.isNull), _.isUndefined);
}

export default normalizer;