import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Grid from "@material-ui/core/Grid";
import { RadioGroup, Input, DatePicker } from "../form-unit";

const IdentificationV2 = ({ input, error, onChange, onRadioChange, onDateChange, additionalInputProps }) => {
    const { t } = useTranslation();

    let label;
    if (input?.idType === 'NRIC') {
        label = t('label.idenfication_no');
    }
    else if (input?.idType === 'PASSPORT') {
        label = t('label.passport_no');
    }

    return (
        <Grid container={true}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <RadioGroup
                    label="Identification Type"
                    name="idType"
                    value={input?.idType || ''}
                    error={error?.idType}
                    onChange={onRadioChange}
                    horizontal={true}
                    labelPlacement="end"
                    options={[
                        { value: 'NRIC', label: 'National ID' },
                        { value: 'PASSPORT', label: 'Passport' }
                    ]}
                    {...additionalInputProps?.idType}
                />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Input
                    name="idNo"
                    label={label}
                    placeholder={t('placeholder.identification_no')}
                    value={input?.idNo || ''}
                    error={error?.idNo}
                    required={true}
                    onChange={onChange}
                    {...additionalInputProps?.idNo}
                />
            </Grid>

            {input?.idType === 'PASSPORT' &&
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container={true} spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <DatePicker
                                label="Issued Date"
                                name="issueDate"
                                placeholder={t('placeholder.issue_date')}
                                value={input?.issueDate|| null}
                                error={error?.issueDate}
                                required={true}
                                onChange={onDateChange}
                                {...additionalInputProps?.issueDate}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <DatePicker
                                label="Expiry Date"
                                name="expiryDate"
                                placeholder={t('placeholder.expiry_date')}
                                value={input?.expiryDate || null}
                                error={error?.expiryDate}
                                required={true}
                                onChange={onDateChange}
                                {...additionalInputProps?.expiryDate}
                            />
                        </Grid>
                    </Grid>

                    <Grid container={true}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                                label="Issued Location"
                                name="placeOfIssue"
                                required={true}
                                placeholder={t('placeholder.place_of_issue')}
                                value={input?.placeOfIssue || ''}
                                error={error?.placeOfIssue}
                                onChange={onChange}
                                {...additionalInputProps?.placeOfIssue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

IdentificationV2.defaultProps = {
    onChange: () => {},
    onCheckBoxChange: () => {},
    onDateChange: () => {}
}

export default IdentificationV2
