import subject from '../apis/subject';
export const RESET_STATUS = 'RESET_STATUS';
export const SUBJECTS_FETCH = 'SUBJECTS_FETCH';
export const SUBJECTS_FETCH_SUCCESS = 'SUBJECTS_FETCH_SUCCESS';
export const SUBJECTS_FETCH_FAILED = 'SUBJECTS_FETCH_FAILED';

export const SUBJECT_FETCH_TIMETABLE = 'SUBJECT_FETCH_TIMETABLE';
export const SUBJECT_FETCH_TIMETABLE_SUCCESS = 'SUBJECT_FETCH_TIMETABLE_SUCCESS';
export const SUBJECT_FETCH_TIMETABLE_FAILED = 'SUBJECT_FETCH_TIMETABLE_FAILED';

export const SUBJECT_CREATE = 'SUBJECT_CREATE';
export const SUBJECT_CREATE_SUCCESS = 'SUBJECT_CREATE_SUCCESS';
export const SUBJECT_CREATE_FAILED = 'SUBJECT_CREATE_FAILED';


export const SUBJECT_UPDATE = 'SUBJECT_UPDATE';
export const SUBJECT_UPDATE_SUCCESS = 'SUBJECT_UPDATE_SUCCESS';
export const SUBJECT_UPDATE_FAILED = 'SUBJECT_UPDATE_FAILED';


let dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createSubject = (data) => async (dispatch) => {
    try {
        dispatch({ type: SUBJECT_CREATE });
        const res = await subject.create(data);
        if (!res?.success) {
            return dispatch({
                type: SUBJECT_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SUBJECT_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SUBJECT_CREATE_FAILED,
            error: err
        });
    }
}


const updateSubject = (data) => async (dispatch) => {
    try {
        dispatch({ type: SUBJECT_UPDATE });
        const res = await subject.update(data);
        if (!res?.success) {
            return dispatch({
                type: SUBJECT_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SUBJECT_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SUBJECT_UPDATE_FAILED,
            error: err
        });
    }
}

const getSubjects = (filters) => async (dispatch) => {
    try {
        dispatch({ type: SUBJECTS_FETCH });

        // const { page, skip, limit, offset } = filters; // filter for pagination

        const res = await subject.get(); // TODO pass filter pagination
        if (!res.success) {
            return dispatch({
                type: SUBJECTS_FETCH_FAILED,
                error: res
            });
        }

        dispatch({
            type: SUBJECTS_FETCH_SUCCESS,
            error: {}
        });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SUBJECTS_FETCH_FAILED,
            error: {}
        });
    }
}

const getSubjectsforTimetable = (level, orgID) => async (dispatch) => {
    try {
        dispatch({ type: SUBJECT_FETCH_TIMETABLE });
        const res = await subject.getByLevel(level, orgID);
        if (!res?.success) {
            return dispatch({
                type: SUBJECT_FETCH_TIMETABLE_FAILED,
                error: res.message,
            });
        };
        dispatch({
            type: SUBJECT_FETCH_TIMETABLE_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: SUBJECT_FETCH_TIMETABLE_FAILED,
            error: err,
        });
    };
};

export default {
    createSubject,
    getSubjects,
    updateSubject,
    resetStatus,
    getSubjectsforTimetable,
}