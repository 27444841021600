import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

// hooks
import Session from 'services/local/session';

const useSession = () => {
    const sessionData = Session.getSession();
    const [state, setState] = useState({});

    useEffect(() => {
        if (!isEmpty(sessionData)) {
            setState(sessionData);
        }
    }, []);

    return { ...state };
}

export default useSession;