import React, { useEffect } from 'react';
import View from './subject.view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MasterSubjectAction from '../../../redux/actions/subject';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const mapStateToProps = (state) => {
    return {
        status: state.subject?.status,
        error: state.subject?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const MasterSubjectActions = bindActionCreators(MasterSubjectAction, dispatch);

    return {
        onReset$: () => {
            MasterSubjectActions.resetStatus();
        },
        getSubjects$: () => {
            MasterSubjectActions.getSubjects();
        },
        onCreate$: (data) => {
            MasterSubjectActions.createSubject(data);
        },
        onUpdate$: (data) => {
            MasterSubjectActions.updateSubject(data);
        },

    }
}

const Container = ({ status, error, onUpdate$, onReset$, onCreate$ }) => {
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canViewSubject = (uniquePermissions || []).includes('v_master_subject_web');

    const onCreate = (data) => {
        onCreate$(data);
    }

    const onUpdate = (data) => {
        onUpdate$(data)
    }

    const onReset = () => {
        onReset$();
    }

    return (
        canViewSubject ? <View status={status} resErr={error} onUpdate={onUpdate} onReset={onReset} onCreate={onCreate} /> : ''
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);;