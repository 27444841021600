import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import announcementAction from '../../../redux/actions/announcement';
import View from './announcement-view.view';


const mapStateToProps = (state) => {
    return {
        status: state.announcement?.status,
        error: state.announcement?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const AnnouncementActions = bindActionCreators(announcementAction, dispatch);

    return {
        getrecords$: () => {
            AnnouncementActions.getAnnouncements();
        }
    }
}

const Container = ({getrecords$ }) => {
    const getrecords = () => {
        getrecords$();
    }

    return (
        <View />
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Container);
