import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        textTransform: 'unset',
        boxShadow: 'none',
        transition: 'opacity 300ms ease',

        '&:hover': {
            boxShadow: 'none'
        }
    },
    bgPrimary: {
        backgroundColor: '#FDB714',
        color: 'black',
                
        '&:hover': {
            backgroundColor: '#FDB714',
            opacity: 0.7
        }
    },
    bgSecondary: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)'
    },
    outline: {
  
    },
    outlinePrimary: {
        backgroundColor: 'white',
        color: '#FDB714',
        border: '1px solid #FDB714',

        '&:hover': {
            border: '1px solid #FDB714',
        }
    }
}));

export default useStyles