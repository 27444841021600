import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useSession from 'hooks/use-session';

import departmentActions from 'redux/actions/department';

// Components
import View from './departments-form.view';

const mapStateToProps = (state) => {
    return {
        status: state.department?.status,
        error: state.department?.error
        // departments: state.department?.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    const DepartmentActions = bindActionCreators(departmentActions, dispatch);

    return {
        onReset$: () => {
            DepartmentActions.resetStatus();
        },
        onCreate$: (data) => {
            DepartmentActions.createDepartment(data);
        },
        onUpdate$: (data) => {
            DepartmentActions.updateDepartment(data);
        },
        onFetch$: (orgID) => {
            DepartmentActions.getDepartments(orgID);
        }
    }
}

const Container = ({ status, error, data, onReset$, onFetch$, onCreate$, onUpdate$, closeModal }) => {
    const session = useSession();

    useEffect(() => {
        if (status.create === 'success' || status.update === 'success') {
            onReset$();
            onFetch$(session.orgID);
        }
    }, [status]);

    const onCreate = (data) => {
        data = { ...data, orgID: session.orgID };
        onCreate$(data);
    }

    const onUpdate = (input) => {
        onUpdate$({ ...data, ...input });
    }

    return (
        <View 
            status={status} 
            resErr={error.create || error.update} 
            data={data}
            onReset={onReset$}
            onCreate={onCreate}
            onUpdate={onUpdate}
            closeModal={closeModal}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);