import { isEmpty, omitBy, isNull, isUndefined } from 'lodash';

const component = (data) => {
    if (isEmpty(data)) {
        return {};
    }

    let fullNumber;
    if (!isUndefined(data.emergencyContactCountryCode) && !isUndefined(data.emergencyContactNumber)) {
        fullNumber = `${data.emergencyContactCountryCode}${data.emergencyContactNumber}`;
    }

    return omitBy(omitBy({
        type: data.emergencyContactType,
        countryCode: data.emergencyContactCountryCode,
        number: data.emergencyContactNumber,
        fullNumber,
        name: data.emergencyContactName,
        relationship: data.emergencyContactRelationship
    }, isNull), isUndefined);
}

export default component