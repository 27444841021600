import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthAction from 'redux/actions/auth';

// Components
import ResetPasswordForm from './reset-password-form.view';
import { parseJWT } from 'helpers';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const authActions = bindActionCreators(AuthAction, dispatch);

    return {
        passwordResetting$: (payload) => {
            authActions.passwordResetting(payload);
        }
    }
}

const Container = ({ status, error, passwordResetting$ }) => {
    const history = useHistory();

    const passwordResetting = (data) => {
        passwordResetting$(data);
    }

    return (
        <ResetPasswordForm passwordResetting={passwordResetting} status={status} resErr={error.login} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
