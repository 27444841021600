import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';

// Component
import { AutoComplete } from '../../common/form-unit';

const SelectYear = ({ data, status, onChange, resErr, error, errorLabel, availableClasses }) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (status === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status]);

    useEffect(() => {
        if (data) {
            setSelectedValue(data);
        }
    }, [data]);

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue]);

    const onInputChange = (_, value) => {
        setInputValue(value);
    }

    const onSelectChange = (_, value) => {
        setSelectedValue(value);
    }

    const getOptionSelected = (option, value) => {
        return option.classID === value.classID;
    }

    const getOptionLabel = (option) => {
        return option.className + ' | ' + option.classLevel || '';
    }

    return (
        <Fragment>
            <AutoComplete
                placeholder="Search/Select Class"
                inputValue={inputValue}
                value={selectedValue}
                onChange={onSelectChange}
                onInputChange={onInputChange}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                options={!_.isEmpty(availableClasses) ? availableClasses : []}
                loading={loading}
                error={error}
                errorLabel={errorLabel}
            />
        </Fragment>
    )
}

SelectYear.defaultProps = {
    onChange: () => { }
}

export default SelectYear;
