import Joi from 'joi';

import {
    name,
    dob,
    gender,
    uuid,
    identificationType,
    nricNo,
    passportNo,
    issueDate,
    placeOfIssue,
    expiryDate,
    medicalCondition,
    race,
    religion,
    priorSchool,
    street,
    postcode,
    city_state,
    country,
    nationality,
    email,
    futureUse,
    contactNumber,
    countryCode,
    relationship,
    code
} from '../../../utils/validation';

const idNo = Joi.when('idType', {
    is: 'NRIC',
    then: nricNo.required(),
    otherwise: passportNo.required() 
});

const expiryD = Joi.when('idType', {
    is: 'PASSPORT',
    then: expiryDate.required(),
    otherwise: Joi.forbidden() 
});

const issueD = Joi.when('idType', {
    is: 'PASSPORT',
    then: issueDate.required(),
    otherwise: Joi.forbidden()
});

const placeIssue = Joi.when('idType', {
    is: 'PASSPORT',
    then: placeOfIssue.required(),
    otherwise: Joi.forbidden()
});

export const schema = Joi.object().keys({
    firstName: name.required(),
    lastName: name.required(),
    dob: dob.required(),
    gender: gender.required(),
    idType: identificationType.required(),
    idNo: idNo.required(),
    issueDate: issueD.required(),
    expiryDate: expiryD.required(),
    placeOfIssue: placeIssue.required(),
    street1: street.required(),
    street2: street.optional(),
    postcode: postcode.required(),
    city: city_state.required(),
    state: city_state.required(),
    country: country.required(),
    nationality: nationality.required(),
    religion: religion.required(),
    race: race.required(),
    priorSchool: Joi.optional(),
    medicalCondition: Joi.optional(),
    countryCode: countryCode.required(),
    contactNumber: contactNumber.required(),
    profileCode: Joi.when('useCurrentTeacherID', {
        is: true,
        then: code.required(),
        otherwise: Joi.forbidden()
    }),
    emailAddress: email.required(),
    emergencyContactName: name.required(),
    emergencyContactRelationship: relationship.required(),
    emergencyContactCountryCode: countryCode.required(),
    emergencyContactNumber: contactNumber.required(),
    profileImg: Joi.alternatives().try(Joi.string().optional(), Joi.object().optional()),
    attachments: Joi.array().items(
        Joi.object().required()
    ).optional(),
    removedDocumentIDs: Joi.array().items(uuid.required()).optional(),
    useCurrentTeacherID: Joi.bool().optional(),
})
.options({ stripUnknown: true });