import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useGetMessageTemplatesQuery } from 'services/api/message';
import useSession from 'hooks/use-session';
// Components
import Form from '../messages-form';

import Button from 'components/common/button';
import Loader from 'components/common/loader';
import Modal from 'components/common/modal';

import { withStyles, makeStyles } from '@material-ui/styles';
import PlusIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';

const StyledTableCell = withStyles(() => ({
    head: {
        color: '#031545',
        fontWeight: 600
    },
    body: {
        color: '#031545',
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        border: '1.5px solid #DFE0EB'
    },
  }))(TableRow);

const View = () => {
    const cls = useStyles();
    const session = useSession();
    const { t } = useTranslation();
    const { 
        data, 
        error, 
        isError, 
        isFetching, 
        isLoading, 
        isSuccess,
    } = useGetMessageTemplatesQuery(session?.orgID);
        
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({ open: false, content: null, title: null });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const onCloseModal = () => {
        setModal({ ...modal, open: false });
    }

    const actionClick = ({ type }) => {
        let messageData = {}; 
        if (selectedIndex >= 0) {
            messageData = data[selectedIndex];
        }

        switch(type) {
            case 'EDIT':
                setModal({
                    ...modal,
                    open: true,
                    title: t('title.edit_message'),
                    content: <Form data={messageData} closeFormModal={onCloseModal}/>
                });

                break;

            default:
                break;
        }
    }

    const handleClick = (e, index) => {
        setAnchorEl(e.currentTarget);
        setSelectedIndex(index);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    if (isLoading || isFetching) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px"/>
            </div>
        )
    }

    return (
        <Fragment>
            <Grid container={true}>
                <Grid item={true} xl={12} lg={12} sm={12} xs={12}>
                    <TableContainer>
                    <Table className={cls.table}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>{t('label.message_type')}</StyledTableCell>
                                <StyledTableCell>{t('label.message_for')}</StyledTableCell>
                                <StyledTableCell>{t('label.message')}</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {isSuccess && _.isEmpty(data) && (
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={4}>
                                        {t('txt.no_data')}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}

                            {isSuccess && !_.isEmpty(data) && _.map(data, (o, i) => {
                                return (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell width="200px">{o.messageTemplateCategoryString}</StyledTableCell>
                                        <StyledTableCell width="200px">{o.messageTemplateName}</StyledTableCell>
                                        <StyledTableCell>{o.messageTemplateContent}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <IconButton size="small" onClick={(e) => { handleClick(e, i); }}>
                                                <MoreIcon fontSize="small"/>
                                            </IconButton>

                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={() => { handleClose(); actionClick({ type: 'EDIT' }) }}>
                                                    {t('action.edit')} <CreateOutlinedIcon style={{ marginLeft: '.5em' }}/>
                                                </MenuItem>
                                            </Menu>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {modal.open &&
                <Modal 
                    title={modal.title} 
                    open={modal.open} 
                    onHandleClose={onCloseModal} 
                    showFooter={null}
                >
                    {modal.content}
                </Modal>
            }
        </Fragment>                                       
    )
}

const useStyles = makeStyles((theme) => ({
    table: {
        border: '1.5px solid #DFE0EB',
        marginTop: '1em'
    }
}));

export default View;
