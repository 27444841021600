import CryptoJS from 'crypto-js';

const Base64 = (s, a) => {
    if (!s) { return; }

    if (a === 'encode') {
        s = CryptoJS.enc.Utf8.parse(s);
        return (CryptoJS.enc.Base64.stringify(s)).replace('=', '');
    }
    else {
        s = CryptoJS.enc.Base64.parse(s); 
        return CryptoJS.enc.Latin1.stringify(s);
    }
}

const encode = (s) => {
    return Base64(s, 'encode');
}

const decode = (s) => {
    return Base64(s);
}

export default {
    encode,
    decode
}