import _ from 'lodash';
import base from './base';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    return _.omitBy(_.omitBy({
        ...base(data),
        departmentCode: data.code,
        departmentID: data.departmentID,
        departmentName: data.name,
        departmentStatus: data.status,
        headOfDepartmentID: data.headOfDepartmentID,
        headOfDepartmentName: data.headOfDepartmentName
    }, _.isNull), _.isUndefined);
}

export default normalizer;