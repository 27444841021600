/**
 * This is for CREATE timetable
 */
import React, { useState, useEffect, useCallback, Fragment, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from 'components/common/button';
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { Resizable } from 're-resizable';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { TimetableTemplateContext } from '../../../context/TimetableContext';
import useSession from 'hooks/use-session';
import { Select } from "../../common/form-unit";
import { useHistory } from "react-router-dom";
import eduAPI from "../../../api/api";
import useToaster from 'hooks/use-toaster';
import MultiSelect from "react-multi-select-component";
import _ from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';


const generateDefaultRows = (day, timeStart) => {

  let slots = [];
  let count = 0
  for (let i = 0; i < timeStart.length; i++) {
    slots.push({ day, startTime: timeStart[i].startTime, endTime: timeStart[i].endTime, id: uuid(), type: "", width: 120, height: 52, isEmpty: true });
  }
  return slots;
};



// TODO:
const generateInitialDayRowsFromTTTemplate = (startDay, noOfDays, DAYS_OF_WEEK, tempTime) => {
  let validDays = getValidDays(startDay, noOfDays, DAYS_OF_WEEK);
  let dayRows = [];
  for (let vD of validDays) {

    let dayStr = DAYS_OF_WEEK[vD];

    let day = {
      day: dayStr,
      slots: generateDefaultRows(dayStr, tempTime),
    };
    dayRows.push(day);

  }
  return dayRows;
};

const getValidDays = (startDay, numberOfDays, DAYS_OF_WEEK) => {
  let weekCount = DAYS_OF_WEEK.length;
  let validDays = [startDay];
  let count = 1;

  while (count !== numberOfDays) {
    count++;
    startDay++;

    // loop it around
    if (startDay === weekCount) {
      startDay = 0;
    };

    validDays.push(startDay);
  }
  return validDays;
};

const createNewDroppedSubjectSlot = (day, subjectInfo) => {
  console.debug('subjectInfo', subjectInfo);
  return { day, ...(_.omit(subjectInfo, 'id')),
    isEmpty: subjectInfo.id ? false : true,
    subjectID: subjectInfo.id,
  };
};

const TimetableTemplate = ({
  status,
  tStatus,
  resErr,
  tError,
  onResetStatus,
  onFetchClassbyYear,
  availableYears,
  availableClasses,
  onCreateTimeTable,
  onPublishTimeTable,
  onFetchClassbySemester,
  availableSemesters,
  onFetchSubjectbyLevel,
  subjects,
  reload
}) => {

  const [state, setState] = useState({ [uuid()]: [] });
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState();
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [days, setDays] = useState();
  const [subjectsInfo, setSubjects] = useState();
  const [disables, setDisables] = useState({
    class: true,
    semester: true,
  });
  const [doneButton, setDoneButton] = useState(false);
  ;
  const GridTooltip = withStyles((theme) => ({
    tooltip: {

      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  const [rows, setRows] = useState([
    { time: "07:00 - 08:00", id: uuid() },
    { time: "08:00 - 09:00", id: uuid() },
    { time: "09:00 - 10:00", id: uuid() },
    { time: "10:00 - 11:00", id: uuid() },
    { time: "11:00 - 12:00", id: uuid() },
    { time: "12:00 - 13:00", id: uuid() },
    { time: "13:00 - 14:00", id: uuid() },
    { time: "14:00 - 15:00", id: uuid() },
  ]);
  const [DAYS_OF_WEEK, setDAYS_OF_WEEK] = useState([
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ]);
  const [input, setInput] = useState({
    template: "",
    year: "",
    semester: "",
    class: "",
  });

  let initialYears = [
    {
      value: "2020",
      label: "2020",
    },
    {
      value: "2021",
      label: "2021",
    },
  ];
  let initialClasses = [
    {
      value: "class 1",
      label: "Class 1",
    },
    {
      value: "class 2",
      label: "Class 2",
    },
  ];
  let initialSems = [
    {
      value: "Sem 1",
      label: "Semester 1",
    },
    {
      value: "Sem 2",
      label: "semester 2",
    },
  ];

  const [toastMsg, setToastMsg] = useState("")
  const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
  const [timetableTemplates, setTimetableTemplates] = useContext(TimetableTemplateContext);
  const [MinuteWidth, setMinuteWidth] = useState();
  const minMinuteIncrement = 5;
  const session = useSession();
  const history = useHistory();
  const [yearsInfo, setYears] = useState(initialYears);
  const [templatesInfo, setTemplates] = useState([]);
  const [classesInfo, setClasses] = useState(initialClasses);
  const [semestersInfo, setSemesters] = useState(initialSems);
  const [times, setTimes] = useState();
  const [tempId, setTempId] = useState('')
  const [clsName, setClsName] = useState("")
  const minWidth = 20;

  useEffect(() => {
    if (session.orgID !== undefined) {
      reload()
    }
  }, [session.orgID]);


  useEffect(() => {
    const tempDays = [];
    const tempTime = [];
    //totalTime : todalWidth
    //40: x
    //totalTime(x) : totalWidth(40)
    // x : (todalWidth(40))/totalTime
    const dragabbleSnap = [120];
    if (timetableTemplates !== undefined) {
      const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
      const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
      const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
      const condition = (e) => e === timetableTemplates.startDay;
      let time = timetableTemplates.startTime;
      const duration = timetableTemplates.durationOfPeriods;
      let i = DAYS_OF_WEEK.findIndex(condition);
      for (let h = 0; h < timetableTemplates.numberOfPeriods; h++) {
        tempTime.push({ startTime: moment(time, 'HH:mm').format('HH:mm'), endTime: moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm'), id: uuid() });
        time = moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm');
      }
      for (let j = 0; j < timetableTemplates.numberOfDays; j++) {
        if (i < DAYS_OF_WEEK.length) {
          tempDays.push(DAYS_OF_WEEK[i]);
          i++;
        }
        else {
          i = 0;
          tempDays.push(DAYS_OF_WEEK[i]);
          i++;
        }
      }
      setDAYS_OF_WEEK(tempDays);
      setRows(tempTime);
      const _generatedDefaultDays = generateInitialDayRowsFromTTTemplate(0, timetableTemplates.numberOfDays, tempDays, tempTime)
      setDays(_generatedDefaultDays);
      setMinuteWidth(minuteWidth);

      for (let i = 0; i < timetableTemplates.numberOfPeriods; i++) {
        dragabbleSnap.push(dragabbleSnap[i] + MinuteWidth);
      }
    }

    else {
      history.push('/timetable');
    }
  }, [timetableTemplates]);



  useEffect(() => {

    let years = [];
    let classes = [];
    let semester = [];
    let tempSubject = [];

    if (subjects !== undefined) {
      for (let i = 0; i < subjects.length; i++) {
        tempSubject.push({
          id: subjects[i].subject.subjectID,
          color: subjects[i].subject.colour,
          subject: subjects[i].subject.name,
          teachers: [],
          isEmpty: false,
          dragDisabled: subjects[i]?.teachers !== null ? true : false,
          selTeacher: [],
          techerIds: []
        })
        if (subjects[i].teachers) {
          for (let j = 0; j < subjects[i].teachers.length; j++) {
            tempSubject[i].teachers.push({
              name: subjects[i].teachers[j].firstName + " " + subjects[i].teachers[j].lastName,
              teacherID: subjects[i].teachers[j].teacherID,
            })
          }
        }
      }
      setSubjects(tempSubject);
    }

    if (availableYears !== undefined) {
      for (let i = 0; i < availableYears.length; i++) {
        years.push({ value: availableYears[i], label: availableYears[i] });
      }
      setYears(years);
    }

    if (availableClasses !== undefined) {
      for (let i = 0; i < availableClasses.length; i++) {
        classes.push({ value: availableClasses[i].classID, label: availableClasses[i].className });
      }
      setClasses(classes);

    }

    if (availableSemesters !== undefined) {
      for (let i = 0; i < availableSemesters.length; i++) {
        semester.push({ value: availableSemesters[i], label: 'Semester' + availableSemesters[i].no })
      }
      setSemesters(semester);
    }
  }, [availableYears, availableClasses, availableSemesters, subjects])

  useEffect(() => {
    setState(
      { ...state });
  }, [days])


  useEffect(() => {
    if (tStatus.create == 'success') {
      setDoneButton(true);
      setTempId(tStatus?.data?.timetableData?.timetableID);
      setClsName(tStatus?.data?.classData?.name)
      setToastMsg("Time Table Saved Succsessfully...")
      onOpenToaster();
      onResetStatus();
      setTimeout(() => {
        history.push(
          {
            pathname: '/timetable',
            state: { fetch: 'success' }
          }
        );
      }, 2000);

    }
    if (tStatus.publish == 'success') {
      history.push(
        {
          pathname: '/timetable-publish',
          state: { templateName: clsName }
        }
      );
      onResetStatus()
    }
  }, [tStatus])


  useEffect(() => {
    if (tError?.create?.message) {
      setToastMsg("Error " + tError?.create?.message)
      onOpenToaster();
    };
  }, [tError])

  // ???????

  //this is to replace
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);


    return result;
  };
  //this is to clone
  const copy = (source, destination, droppableSource, droppableDestination) => {

    // const sourceClone = Array.from(source);
    // const destClone = Array.from(destination || []);
    // const item = sourceClone[droppableSource.index];
    // destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
    // return destClone;
  };
  //this is to move
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  // this is to drag
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const clonedDays = _.cloneDeep(days)
      let dayOfDropped = clonedDays.find((d) =>
        d.slots.find((s) => s.id === destination.droppableId)
      );
      if (dayOfDropped === undefined) {
        alert('error');
        return;
      } else {
        let newSlots = dayOfDropped.slots;
        newSlots = newSlots.map((n) => {
          if (n.id === destination.droppableId) {

            return {
              id: n.id,
              ...createNewDroppedSubjectSlot(
                dayOfDropped.day,
                subjectsInfo[source.index],
              ), width: n.width, height: n.height, startTime: n.startTime, endTime: n.endTime
            }
          }
          return n;
        });
        dayOfDropped.slots = newSlots;
        setDays(clonedDays)
      }
      switch (source.droppableId) {
        case destination.droppableId:
          // console.log(source.droppableId, destination.droppableId);
          setState({
            ...state,
            [destination.droppableId]: reorder(
              state[source.droppableId],
              source.index,
              destination.index
            ),
          });
          break;
        case "ITEMS":

          setState({
            ...state,

          });

          break;
        default:

          setState(
            ...state,
            move(
              state[source.droppableId],
              state[destination.droppableId],
              source,
              destination
            )
          );
          break;
      }
    },
    [state, subjectsInfo]
  );

  const onResize = (e, direction, ref, d, index, subindex) => {
    let daysCopy = days;
    // console.log('onResize------------------------', d.width);

    // if(d.width > 0){
    //   daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].endTime, 'HH:mm').add(5, 'minutes').format('HH:mm');
    //   daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex + 1].startTime, 'HH:mm').add(5, 'minutes').format('HH:mm');
    // }

    // setDays(daysCopy);


  }

  const onResizeStop = (e, direction, ref, d, index, subindex) => {
    let daysCopy = _.cloneDeep(days);
    // console.log(days);
    const slotCopy = days[index].slots
    const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
    const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
    const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
    const minuteIncrement = Math.round(d.width / minuteWidth) * 5;
    const _count = count + 1;
    const totalPairWidth = days[index].slots[subindex].width + days[index].slots[subindex + 1].width;
    const totalPairMinutes = timetableTemplates?.durationOfPeriods * 2;
    const minuteConstrainX = Math.round((totalDailyMinute * 20) / totalDailyWidth);
    const minuteConstrain = Math.round(minuteConstrainX / 10) * 10;

    const pairConstrain = totalPairWidth - 20;
    const minutePairConstrain = totalPairMinutes - minuteConstrain;
    // console.log('width---------', minuteIncrement);


    if (subindex + 1 < days[index].slots.length) {
      // console.log('Condition Successfully True');
      if (days[index].slots[subindex].width + d.width >= pairConstrain) {
        daysCopy[index].slots[subindex].width = pairConstrain;  // this is correct
        daysCopy[index].slots[subindex + 1].width = minWidth; // this is also correct
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex + 1].endTime, 'HH:mm').add(-5, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = daysCopy[index].slots[subindex].endTime;

      }
      else if (days[index].slots[subindex].width + d.width <= minWidth) {
        daysCopy[index].slots[subindex].width = minWidth;
        daysCopy[index].slots[subindex + 1].width = pairConstrain;
        const reducedMinuteIncrement = Math.round(minWidth / minuteWidth) * 5;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
      }
      else {
        daysCopy[index].slots[subindex].width = daysCopy[index].slots[subindex].width + d.width;
        daysCopy[index].slots[subindex + 1].width = daysCopy[index].slots[subindex + 1].width - d.width;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].endTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex + 1].startTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');

      }
    }
    else {
      // console.log('Condition Successfully False');
    }

    // console.log('total days object: ', days);


    setDays([
      ...daysCopy,
    ]);
    // doSomeSht();
  }

  // useEffect(() => {

  // }, [input])


  const DropDownFunctions = (type, e) => {
    switch (type) {
      case 'YEAR':
        setInput({ ...input, year: e.target.value });
        if (session.orgID !== undefined) {
          onFetchClassbyYear(session.orgID, e.target.value);
          setDisables({ ...disables, class: false });
        }
        break;

      case 'CLASS':
        setInput({ ...input, class: e.target.value });
        const classLevel = availableClasses.find(({ classID }) => classID === e.target.value);
        // console.log(classLevel);
        if (session.orgID !== undefined) {
          onFetchClassbySemester(e.target.value);
          onFetchSubjectbyLevel(classLevel.classLevel, session.orgID);
          setDisables({ ...disables, semester: false });
        }
        break;

      case 'SEMESTER':
        setInput({ ...input, semester: e.target.value });
        break;

      default:
        break;
    };
  };

  const handleClick = (e, index) => {
    setAnchorEl(e.currentTarget);
    // console.log(index);
    setMenuIndex(index);
  };
  const handleClose = (e) => {
    // console.log(e);
    setAnchorEl(null);
  };

  const handleMenu = (e, index, teacherID) => {
    let techerIds = []
    e.map((obj) => {
      techerIds.push(obj.value)
    })

    setAnchorEl(null);
    let tempSub = subjectsInfo;
    tempSub[index] = { ...tempSub[index], dragDisabled: false, isEmpty: false, selTeacher: e, techerIds: techerIds };
    setSubjects(tempSub);
  }




  const onSave = () => {
    let timeTable = [];
    days.map((data) => {
      let timeSlots = [];
      data.slots.map((sObj, index) => {
        timeSlots.push({
          index: index,
          start: sObj.startTime,
          end: sObj.endTime,
          subjectID: sObj.isEmpty == true ? '97a55776-ad91-4ab1-ad6d-bc2c4896888b' : sObj.subjectID,
        });
      });
      timeTable.push({ day: data.day, timeSlots: timeSlots });
    });

    let teacherArray = [];

    subjectsInfo.map((data) => {
      if (data?.techerIds.length) {
        teacherArray.push({
          "subjectID": data.id,
          "teacherID": data.techerIds
        })
      }
    })

    let reqObj = {
      templateID: timetableTemplates.templateID,
      classID: input.class,
      semesterNo: input.semester.no,
      createdBy: session.profile._key,
      teachers: teacherArray,
      timeTable: timeTable
    }
    onCreateTimeTable(reqObj)
  }

  const onPublish = () => {
    setDoneButton(false);
    let obj = {
      timetableID: tempId,
      publishedBy: session.profile._key
    }
    onPublishTimeTable(obj)  
  }
  //

  const getOpacity = (obj) => {
    if (obj.selTeacher == "") {
      if (obj.teachers.length == 0) {
        // if (obj.subject.toLowerCase() == 'block' || obj.subject.toLowerCase() == 'break' || obj.subject.toLowerCase() == 'lunch' || obj.subject.toLowerCase() == 'clean up') {
        return 1
      } else {
        return 0.5
      }
    } else {
      return 1
    }
  }

  const getStatus = (obj) => {
    if (obj.teachers.length == 0) {
      // if (obj.subject.toLowerCase() == 'block' || obj.subject.toLowerCase() == 'break' || obj.subject.toLowerCase() == 'lunch' || obj.subject.toLowerCase() == 'clean up') {
      return 0
    } else {
      return 1
    }
  }

  const handleClearSubject = (i, j) => {
    const newDays = _.cloneDeep(days)
    // const emptySubjectID = '97a55776-ad91-4ab1-ad6d-bc2c4896888b'
    try {
      newDays[i].slots[j] = {
        ...newDays[i].slots[j],
        subjectID: undefined,
        subject: undefined,
        color: undefined,
        type: '',
        isEmpty: true,
      }
      setDays(newDays)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Toaster />
      <Grid container
        style={{
          borderTop: "5px solid #5D518F",
          borderRadius: 5,
          paddingTop: '20px',
          paddingBottom: '20px'
        }}>
        <Grid item style={{ marginLeft: '20px', width: '100px' }}>
          <div className={classes.grdtxt1}>Assign to</div>
        </Grid>
        <Grid item style={{ padding: "0px 4px", width: '120px' }}>
          <Select
            placeholder="Year"
            name="Years"
            required={true}
            value={input.year || ""}
            options={yearsInfo}
            onChange={(e) => { DropDownFunctions('YEAR', e) }}
          />
        </Grid>

        <Grid item style={{ padding: "0px 4px", width: '180px' }}>
          <Select name="Classes"
            placeholder="Classes"
            disabled={disables.class}
            required={true}
            value={input.class || ""}
            options={classesInfo}
            onChange={(e) => { DropDownFunctions('CLASS', e) }}
          />
        </Grid>
        <Grid item style={{ padding: "0px 4px", width: '180px' }}>
          <Select name="Semesters"
            disabled={disables.semester}
            placeholder="Semesters"
            required={true}
            value={input.semester || ""}
            options={semestersInfo}
            onChange={(e) => { DropDownFunctions('SEMESTER', e) }}
          />
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd} className={classes.listContainer}>

        <div className={classes.grdContainer}>
          <Grid container style={{ flexWrap: 'nowrap' }}>
            <Grid item lg={1} md={1} sm={1} xs={1} >
              <p style={{ width: '100px', marginTop: '10px' }} className={classes.customFieldClr}>Day/Time</p>
            </Grid>
            {rows.map((row, index) => (
              <Grid item>
                <div className={classes.gridHeaderContainer}>
                  <Typography className={classes.grdLabel}>{row.startTime} - {row.endTime}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>

          {days?.map((day, i) => (
            <Grid container key={day.day} style={{ flexWrap: 'nowrap' }}>
              <Grid item lg={1} md={1} sm={1} xs={1} >
                <p style={{ width: '100px', marginTop: '10px' }} className={classes.customFieldClr}>{day.day}</p>
              </Grid>
              {day?.slots?.map((row, j) => {
                return (
                  <Droppable key={row.id} droppableId={row.id}>
                    {(provided, snapshot) => {
                      return (
                        <GridTooltip
                          title=
                          {
                            <div>
                              <Typography>Start Time: {row.startTime}</Typography>
                              <Typography>End Time: {row.endTime}</Typography>
                            </div>
                          }
                        >

                          <Resizable
                            size={{ height: row.height, width: row.width }}
                            grid={[MinuteWidth, MinuteWidth]}
                            snap={{ x: times }}
                            snapGap={MinuteWidth}
                            enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                            onResizeStop={(e, direction, ref, d) => { 
                              if (j === day?.slots?.length - 1) return;
                              onResizeStop(e, direction, ref, d, i, j) 
                            }}
                            onResize={(e, direction, ref, d) => { onResize(e, direction, ref, d, i, j); }}


                          >


                            <div
                              className={classes.gridContainer}
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                              style={{
                                width: row.width,
                                height: row.height,
                                backgroundColor: row.color
                                  ? row.color
                                  : snapshot.isDraggingOver
                                    ? "grey"
                                    : "white",
                              }}
                            >
                              {
                                !row.isEmpty &&
                                <GridTooltip
                                  title='Remove subject'
                                  placement='right-end'
                                >
                                  <ClearIcon 
                                    className={classes.removeSubjectCross} 
                                    fontSize={row.width < 100 ? 'small' : 'medium'}
                                    color='error'
                                    onClick={() => handleClearSubject(i, j)}
                                  />
                                </GridTooltip>
                              }
                              {row.type === "" ? row.type : row.subject}
                            </div>


                          </Resizable>
                        </GridTooltip>


                      );
                    }}
                  </Droppable>
                );
              })}
            </Grid>
          ))}
        </div>

        <div>

          <div className={classes.dragNdropTxtContainer}>
            <Typography className={classes.dragNdropTxt}>
              Drag and Drop Subject
            </Typography>
          </div>

          <Droppable droppableId="ITEMS" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                className={classes.subjectContainer}
              >
                {subjectsInfo?.map((subject, index) => (
                  <Draggable
                    key={subject.id}
                    draggableId={subject.id}
                    index={index}
                    isDragDisabled={subject.dragDisabled}

                  >
                    {(provided, snapshot) => (
                      //
                      <Fragment>
                        <div
                          className={classes.subject}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >

                          <Grid container >
                            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} style={{
                              opacity: getOpacity(subject)
                            }} className={classes.overflowSub}>
                              {subject.subject}
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              {getStatus(subject) ?
                                <ExpandMoreIcon onClick={(e) => { handleClick(e, index) }} /> : ''}
                            </Grid>
                          </Grid>


                          <Button
                            style={{ backgroundColor: `${subject.color}` }}
                            className={classes.subjectButton}
                          ></Button>

                          <Menu
                            id='Subject-Menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}

                          >

                            <div className={classes.customMenu}>

                              <MultiSelect
                                style={{ multiselectContainer: { height: '500px', width: '200px' } }}
                                value={subjectsInfo[menuIndex]?.selTeacher || []}
                                shouldToggleOnHover={true}
                                options={_.map(subjectsInfo[menuIndex]?.teachers, (teacher) => {
                                  return { label: teacher.name, value: teacher.teacherID }
                                })}
                                onChange={(e) => { handleMenu(e, menuIndex, 'id') }}

                                labelledBy={""}
                              />
                            </div>
                          </Menu>
                        </div>
                        {snapshot.isDragging && (
                          <div className={classes.subject}>
                            {subject.subject}
                            <Button
                              style={{ backgroundColor: `${subject.color}` }}
                              className={classes.subjectButton}
                            ></Button>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <br />
        <div className={classes.doneButtonContainer}>
          {!doneButton && (<Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            type="submit"
            label="Save"
            onClick={onSave}
          >
            Save
          </Button>)}

          {doneButton && (<Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            type="submmit"
            onClick={onPublish}

          >
            Publish
          </Button>)}

        </div>
        <br />
      </DragDropContext>
    </>
  );
};

const useStyles = makeStyles(() => ({

  customMenu: {
    width: '230px !important',
    height: '250px !important',
    padding: '5px 10px',
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 3px 3px 0px rgb(0 0 0 / 5%), 0px 3px 8px 0px rgb(0 0 0 / 5%) !important'
  },

  customFieldClr: {
    fontWeight: 'bold',
    color: '#424241'
  },
  overflowSub: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subjectContainer: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 800,
    marginLeft: "2%",
  },

  subjectButton: {
    width: "100%",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -10
  },
  subject: {
    paddingTop: 4,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginRight: "1%",
    marginTop: "1%",
    display: "flex",
    height: 40,
    width: 120,
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center"
  },
  dragNdropTxtContainer: {
    marginLeft: "1%",
    marginTop: "1%",
  },
  dragNdropTxt: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
  },
  grdLabel: {
    fontWeight: 'bold',
    color: '#424241',
    textAlign: "center",
  },
  grdContainer: {
    marginLeft: 20,
    marginTop: 5
  },
  grdtxt1: {
    marginTop: "15px",
    marginLeft: "0px",
    fontSize: "14px",
    color: '#424241',
    fontWeight: "bold",
  },
  gridContainer: {
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&:hover': {
      '& $removeSubjectCross': {
        display: 'block',
      }
    }
  },
  removeSubjectCross: {
    display: 'none',
    position: 'absolute',
    top: 2,
    right: 4,
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // let it have an opacity so that red color subject still can see the Cross icon
  },

  gridHeaderContainer: {
    width: 120,
    height: 52,
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  subjectBox: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    padding: "5px 0px",
    margin: "10px",
    textAlign: "center",
    textTransform: "capitalize",
  },

  listContainer: {
    borderLeftStyle: "solid",
    borderLeftWidth: "10px",
    borderLeftColor: "rgba(61, 58, 214, 0.9)",
    borderRadius: 10,
  },
  iconStyle: {
    marginRight: "5%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: "1%",
  },

  titleContainer: {
    marginLeft: "5%",
  },
  dataContainer: {
    marginLeft: "7%",
  },

  titles: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#9FA2B4",
    fontSize: 14,
    textAlign: "center",
  },
  dataText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  input: {
    width: "65%",
    height: "50%",
    paddingBottom: "5%",
    paddintTop: "5%",
    fontSize: 12,
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "1%",
    paddingLeft: "1%",
  },
  inputButtonContainer: {
    alignItems: "center",
    display: "flex",
    paddingBottom: "2%",
    marginTop: "0.4%",
    paddingLeft: "1%",
    paddingTop: "0.3%",
  },
  inputButton: {
    height: "120%",
    width: "100%",
    borderRadius: 15,
    fontSize: 12,
    fontWeight: 400,
    // display: 'flex',
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
  },
  doneButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },

  doneButton: {
    height: 40,
    width: 175,
    color: "black",
  },

  // "& .MuiPaper-elevation8": {
  //   boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  // }

}));

export default TimetableTemplate;
