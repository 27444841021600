import {
    RESET_STATUS,

    DEPARTMENTS_FETCH,
    DEPARTMENTS_FETCH_FAILED,
    DEPARTMENTS_FETCH_SUCCESS,

    DEPARTMENT_CREATE,
    DEPARTMENT_CREATE_FAILED,
    DEPARTMENT_CREATE_SUCCESS,

    DEPARTMENT_UPDATE,
    DEPARTMENT_UPDATE_FAILED,
    DEPARTMENT_UPDATE_SUCCESS,

    DEPARTMENT_ASSIGN,
    DEPARTMENT_ASSIGN_FAILED,
    DEPARTMENT_ASSIGN_SUCCESS,

    DEPARTMENT_UNASSIGN,
    DEPARTMENT_UNASSIGN_FAILED,
    DEPARTMENT_UNASSIGN_SUCCESS
} from '../actions/department';
import _ from 'lodash';

const initialStatus = {
    fetch: 'idle',
    create: 'idle',
    update: 'idle',
    assign: 'idle'
};

const initialState = {
    list: [],
    data: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }

        case DEPARTMENTS_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case DEPARTMENTS_FETCH_FAILED: {
            return {
                ...state,
                error: { fetch: action.error },
                status: { ...state.status, fetch: 'failed' }
            };
        }

        case DEPARTMENTS_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                list: action.payload,
                status: { ...state.status, fetch: 'success' }
            }
        }

        case DEPARTMENT_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            }
        }

        case DEPARTMENT_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case DEPARTMENT_CREATE_SUCCESS: {
            // const newList = [...state.list];
            // newList.push(action.payload);

            return {
                ...state,
                error: {},
                data: action.payload,
                // list: newList,
                status: { ...state.status, create: 'success' }
            }
        }

        case DEPARTMENT_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading' }
            }
        }

        case DEPARTMENT_UPDATE_SUCCESS: {
            // const list = [...state.list];
            // const foundIndex = _.findIndex(list, o => o.departmentID === action.payload?.departmentID);
            // console.log(action.payload);
            // if (foundIndex >= 0) {
            //     list.splice(foundIndex, 1, action.payload);
            // }

            return {
                ...state,
                // list,
                data: action.payload,
                error: { update: action.error },
                status: { ...state.status, update: 'success' }
            }
        }

        case DEPARTMENT_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed' }
            }
        }

        case DEPARTMENT_ASSIGN: {
            return {
                ...state,
                error: {},
                status: { ...state.status, assign: 'loading' }
            }
        }

        case DEPARTMENT_ASSIGN_SUCCESS: {
            return {
                ...state,
                error: {},
                list: [],
                status: { ...state.status, assign: 'success' }
            };
        }

        case DEPARTMENT_ASSIGN_FAILED: {
            return {
                ...state,
                error: { assign: action.error },
                status: { ...state.status, assign: 'failed' }
            };
        }

        case DEPARTMENT_UNASSIGN: {
            return {
                ...state,
                error: {},
                status: { ...state.status, unassign: 'loading' }
            }
        }

        case DEPARTMENT_UNASSIGN_SUCCESS: {
            return {
                ...state,
                error: {},
                list: [],
                status: { ...state.status, unassign: 'success' }
            };
        }

        case DEPARTMENT_UNASSIGN_FAILED: {
            return {
                ...state,
                error: { assign: action.error },
                status: { ...state.status, unassign: 'failed' }
            };
        }

        default:
            return state;
    }
}

export default reducer;