import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import countries from 'utils/countries.json';
import useToaster from 'hooks/use-toaster';

import normalize from 'utils/normalizers';
import { schema } from './teacher-form-v2.validation';

// Component
import useStyles from './style';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { Input, Select, DatePicker, TextArea, CheckBox, FileUpload } from 'components/common/form-unit';
import IdentificationV2 from 'components/common/identification-v2';
import AlertBox from 'components/common/alert-box';
import AddressFields from 'components/common/address';
import PhoneNumber from 'components/common/phone-number';
import Button from 'components/common/button';

const TeacherFormV2 = ({ data, status, resErr, onCreate, onUpdate, onResetStatus }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { onOpenToaster, Toaster } = useToaster({ message: t('toaster.updated_successfully') })

    const ref = useRef(null);
    const initialInput = {
        country: "MY",
        countryCode: "60",
        idType: "NRIC",
        gender: "",
        useCurrentTeacherID: false,
        city: "",
        contactName: "",
        contactNumber: "",
        contactRelationship: "",
        dob: "",
        firstName: "",
        lastName: "",
        idNo: "",
        medicalCondition: "",
        nationality: "MY",
        postcode: "",
        priorSchool: "",
        race: "",
        religion: "",
        state: "",
        street1: "",
        emailAddress: '',
        emergencyContactCountryCode: '60',
        emergencyContactName: '',
        emergencyContactNumber: '',
        emergencyContactRelationship: ''
    };
    const initialError = {}

    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [loading, setLoading] = useState(false);

    // useEffect(() => console.log(JSON.stringify(input, null, 2)), [input]);

    useEffect(() => {
        if (_.isEmpty(data)) {
            setInput(initialInput)
        }

        if (!_.isEmpty(data)) {
            const { profile, attachments } = data;

            setInput({
                ...input,
                ...profile,
                ...attachments,
                profileImg: !_.isEmpty(attachments.profile_picture) ? attachments.profile_picture?.fileUrl : null,
                attachedDocuments: !_.isEmpty(attachments.profile_attachments) ? attachments.profile_attachments : null,
                useCurrentTeacherID: profile.profileCode ? true : false
            });
        }
    }, [data]);

    useEffect(() => {
        if (!_.isEmpty(resErr)) {
            function focusError(errorKey, errMessage, focusName) {
                if (!errMessage) {
                    return null;
                };

                setError({ ...error, [errorKey]: { type: 'ERR_SERVER', message: errMessage } });

                if (document.querySelector(`*[name=${focusName ?? errorKey}]`)) {
                    document.querySelector(`*[name=${focusName ?? errorKey}]`).focus();
                }

                return;
            }

            if (resErr?.message.includes('This email already exist with pending status') || resErr?.message.includes('Email address already in use')) {
                focusError('emailAddress', resErr?.message);
            }
            else if (resErr?.message.includes('This phone number already exist with pending status') || resErr.message.includes('Phone number already in use')) {
                focusError('fullNumber', resErr?.message, 'contactNumber');
            }
            else if (resErr?.message.includes('This identification number already exist with pending status')) {
                focusError('idNo', resErr?.message);
            }
            else {
                ref.current.focus();
                setError({ ...error, response: resErr?.message });
            }
        }
    }, [resErr]);

    useEffect(() => {
        if (status.create === 'loading' || status.update === 'loading') {
            setLoading(true);
        }
        else if (status.create === 'success') {
            onResetStatus();
            history.push('/account/teachers/unassigned');
        }
        else if (status.update === 'success') {
            onResetStatus();
            onOpenToaster();
            setTimeout(() => {
                history.push('/account/teachers/unassigned');
            }, 2000);

        }
        else {
            setLoading(false);
        }
    }, [status]);

    
    const onChange = (e) => {
        let { name, value } = e.target;

        if (!['medicalCondition', 'priorSchool'].includes(name)) {
            if (!value || value.trim().length === 0) {
                value = undefined;
            }
        }

        setInput(prevInput => { return { ...prevInput, [name]: value } });
        setError(initialError);
    }

    const onCheckBoxChange = (e, targetValue = 'value') => {
        const { name, checked, value } = e.target;

        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: targetValue === 'value' ? value : checked
            }
        });

        setError(initialError);
    }

    const onRadioChange = (e) => {
        const { name, checked, value } = e.target;

        setInput(prevInput => { return { ...prevInput, [name]: value } });
        setError(initialError);
    }

    const onDateChange = (opt) => {
        const { date, name } = opt;

        setInput(prevInput => { return { ...prevInput, [name]: date } });
        setError(initialError);
    }

    const getBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => res(reader.result);
            reader.onerror = (error) => rej(error);
        });
    }

    const onChangeFile = async (e) => {
        const { name, files } = e.target;
        const file = files[0];
        // const base64 = await getBase64(file);

        setInput(prevInput => { return { ...prevInput, [name]: file } });
        setError(initialError);
    }

    /** @param files {Array<File>} */
    const onHandleAttachments = (files) => {
        setInput(prevInput => {
            return _.omitBy({
                ...prevInput,
                attachments: !_.isEmpty(files) ? files : null
            }, _.isNull);
        });
    }

    const removeFile = (index, fileID) => {
        let docIDs = !_.isEmpty(input.removedDocumentIDs) ? [...input.removedDocumentIDs] : [];
        if (fileID) {
            docIDs.push(fileID);
        }

        let attachedDocuments = !_.isEmpty(input.attachedDocuments) ? [...input.attachedDocuments] : [];
        attachedDocuments.splice(index, 1);

        setInput(prevInput => {
            return {
                ...prevInput,
                attachedDocuments,
                removedDocumentIDs: docIDs,
            };
        })
    }

    const onSubmit = async () => {

        try {
            if (loading) { return; }

            const value = await schema.validateAsync(_.omitBy(_.omitBy({
                ...input,
                dob: !_.isUndefined(input.dob) ? moment(input.dob).toISOString() : undefined,
                expiryDate: !_.isUndefined(input.expiryDate) ? moment(input.expiryDate).toISOString() : undefined,
                issueDate: !_.isUndefined(input.issueDate) ? moment(input.issueDate).toISOString() : undefined,
            }, _.isNull), _.isUndefined));

            if (!_.isEmpty(value) && _.isEmpty(data)) {
                onCreate(value);
            }

            if (!_.isEmpty(value) && !_.isEmpty(data)) {
                onUpdate(value);
            }
        }
        catch (err) {
            console.log(err);
            const { key, value } = normalize.error.validation(err);
            setError({ ...error, [key]: value });

            if (document.querySelector(`*[name=${key}]`)) {
                document.querySelector(`*[name=${key}]`).focus();
            }
        }
    }

    return (
        <>
            <Toaster />
            <div className={classes.container}>
                <h2 className={classes.title}>Teacher Information</h2>

                <div style={{ marginTop: '.5em' }} ref={ref} tabIndex={-1}>
                    {error?.response &&
                        <AlertBox icon={false} type="error" message={error?.response} />
                    }
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: '1em'
                }}>
                    {/* TODO do proper reusable component for uploader */}
                    {input.profileImg &&
                        <IconButton
                            onClick={() => {
                                removeFile(undefined, input?.profile_picture?.fileID);
                                setInput(prevInput => {
                                    return _.omitBy({
                                        ...prevInput,
                                        profileImg: null
                                    }, _.isNull)
                                })
                            }}
                            style={{
                                position: 'absolute',
                                top: -10,
                                right: '42%'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }

                    <input
                        name="profileImg"
                        accept="image/png, image/jpeg"
                        type="file"
                        id="profileImg"
                        onChange={onChangeFile}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="profileImg">
                        <IconButton component="span">
                            <Avatar
                                alt="Profile Picture"
                                src={input.profileImg || ''}
                                style={{ width: '90px', height: '90px' }}
                            />
                        </IconButton>
                    </label>
                    <span>Upload Picture</span>
                </div>

                {/* <Snackbar open={openToaster} autoHideDuration={5000}>
                    <MuiAlert elevation={6} variant="filled" severity="success">
                        Profile Created Successfully! 
                    </MuiAlert>
                </Snackbar> */}

                <Grid container spacing={2} style={{ marginTop: '2em' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="First Name"
                            name="firstName"
                            placeholder={t('placeholder.first_name')}
                            required={true}
                            value={input.firstName || ''}
                            error={error.firstName}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Last Name"
                            name="lastName"
                            required={true}
                            placeholder={t('placeholder.last_name')}
                            value={input.lastName}
                            error={error.lastName}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DatePicker
                            label="Date Of Birth"
                            name="dob"
                            placeholder={t('placeholder.dd_mm_yy')}
                            value={input.dob || null}
                            error={error.dob}
                            required={true}
                            onChange={onDateChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Select
                            label="Gender"
                            name="gender"
                            value={input.gender || ''}
                            onChange={onChange}
                            error={error.gender}
                            required={true}
                            options={[
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2} alignItems="flex-end">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <IdentificationV2
                            input={input}
                            error={error}
                            onRadioChange={onRadioChange}
                            onChange={onChange}
                            onDateChange={onDateChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Select
                            label="Nationality"
                            name="nationality"
                            value={input.nationality || ''}
                            error={error.nationality}
                            required={true}
                            onChange={onChange}
                            options={_.map(countries, (o) => {
                                return { label: o.name, value: o.iso }
                            })}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <AddressFields
                            input={input}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container={true}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Select
                                    label="Religion"
                                    name="religion"
                                    value={input.religion || ''}
                                    error={error.religion}
                                    required={true}
                                    onChange={onChange}
                                    options={[
                                        { label: 'Islam', value: 'ISLAM' },
                                        { label: 'Buddhist', value: 'BUDDHIST' },
                                        { label: 'Hindu', value: 'HINDU' },
                                        { label: 'Christian', value: 'CHRISTIAN' },
                                        { label: 'Sikh', value: 'SIKH' },
                                        { label: 'Other', value: 'OTHER' }
                                    ]}
                                />

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Select
                                        label="Race"
                                        name="race"
                                        value={input.race || ''}
                                        required={true}
                                        onChange={onChange}
                                        options={[
                                            { label: "Indian", value: "INDIAN" },
                                            { label: "Chinese", value: "CHINESE" },
                                            { label: "Malay", value: "MALAY" },
                                            { label: "Punjabi", value: "PUNJABI" },
                                            { label: "Other", value: "OTHER" },
                                        ]}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Input
                                        label="Prior School (if Applicable)"
                                        name="priorSchool"
                                        placeholder={t('placeholder.prior_school')}
                                        value={input.priorSchool || ''}
                                        error={error.priorSchool}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextArea
                            name="medicalCondition"
                            label="Medical Condition"
                            placeholder={t('placeholder.please_specify_here')}
                            value={input.medicalCondition || ''}
                            error={error.medicalCondition}
                            rows={5}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Teacher ID"
                            name="profileCode"
                            required={input.useCurrentTeacherID === true ? true : false}
                            value={input.profileCode || ''}
                            error={error.profileCode}
                            onChange={onChange}
                            disabled={input.useCurrentTeacherID === false || input.useCurrentTeacherID === undefined}
                        >
                            <CheckBox
                                styleLabel={{ width: 'fit-content' }}
                                onChange={(e) => onCheckBoxChange(e, 'checked')}
                                options={[
                                    {
                                        label: 'Use current teacher ID',
                                        name: 'useCurrentTeacherID',
                                        checked: input.useCurrentTeacherID
                                    }
                                ]}
                            />
                        </Input>
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Email"
                            name="emailAddress"
                            required={true}
                            placeholder={t('placeholder.email_address_here')}
                            value={input?.emailAddress || ""}
                            error={error?.emailAddress}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <PhoneNumber
                            input={input}
                            error={error.contactNumber || error.countryCode || error.fullNumber}
                            onChange={onChange}
                        // additionalInputProps={{
                        //     contactNumber: {
                        //         name: 'contactNumber',
                        //         value: input.contactNumber || '',
                        //         // error: error.teacherContactNumber
                        //     },
                        //     countryCode: {
                        //         name: 'countryCode',
                        //         value: input.countryCode || '',
                        //         // error: error.teacherCountryCode
                        //     }
                        // }}
                        />
                    </Grid>
                </Grid>

                <h2 className={classes.title} style={{ marginTop: '1em' }}>Emergency Contact</h2>

                <Grid container={true} spacing={2} style={{ marginTop: '1em' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Contact Name"
                            name="emergencyContactName"
                            required={true}
                            placeholder={t('placeholder.full_name_here')}
                            value={input.emergencyContactName || ''}
                            error={error.emergencyContactName}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Relationship with Teacher"
                            name="emergencyContactRelationship"
                            placeholder={t('placeholder.relationship')}
                            required={true}
                            value={input.emergencyContactRelationship || ''}
                            error={error.emergencyContactRelationship}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <PhoneNumber
                            input={input}
                            error={error.emergencyContactNumber || error.emergencyContactCountryCode}
                            onChange={onChange}
                            additionalInputProps={{
                                contactNumber: {
                                    name: 'emergencyContactNumber',
                                    value: input.emergencyContactNumber || '',
                                    placeholder: t('placeholder.emergency_contact_number')
                                    // error: error.teacherContactNumber
                                },
                                countryCode: {
                                    name: 'emergencyContactCountryCode',
                                    value: input.emergencyContactCountryCode || ''
                                    // error: error.teacherCountryCode
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: '1em' }}>
                    <FileUpload
                        multiple={true}
                        updateFilesCb={onHandleAttachments}
                    />

                    {!_.isEmpty(input.attachedDocuments) &&
                        <Grid item xl={6} lg={4} md={12} sm={12} xs={12}>
                            <h4 style={{ color: '#FDB714' }}>Attached Documents</h4>

                            {_.map(input.attachedDocuments, (o, i) => {
                                return (
                                    <List key={i} style={{
                                        border: '1px solid #ddd',
                                        margin: '5px',
                                        color: '#756F86',
                                        background: '#DBE2EA',
                                        fontSize: '20px'
                                    }}>
                                        <ListItem button>
                                            <ListItemText primary={o.fileName} style={{ color: "#6386af", fontSize: '18px' }} />
                                            <CloseIcon onClick={() => removeFile(i, o.fileID)} />
                                        </ListItem>
                                    </List>
                                )
                            })}
                        </Grid>
                    }
                </div>

                <Grid container={true} style={{ marginTop: '5em' }} justify="center">
                    <Grid item>
                        <Button
                            label={!_.isEmpty(data) ? t('action.update') : t('action.register_teacher')}
                            type="primary"
                            size="large"
                            isLoading={loading}
                            style={{ width: '180px' }}
                            onClick={onSubmit}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default TeacherFormV2