import Joi from 'joi';
import {
    name,
    dob,
    gender,
    identificationType,
    nricNo,
    passportNo,
    issueDate,
    placeOfIssue,
    expiryDate,
    medicalCondition,
    race,
    religion,
    priorSchool,
    street,
    postcode,
    city_state,
    country,
    nationality,
    dataUri,
    base64,
    email,
    futureUse,
    contactNumber,
    countryCode,
    relationship,
    code,
    uuid
} from '../../../utils/validation';

const idNo = Joi.when('idType', {
    is: 'NRIC',
    then: nricNo.required(),
    otherwise: passportNo.required() 
});

const expiryD = Joi.when('idType', {
    is: 'PASSPORT',
    then: expiryDate.required(),
    otherwise: Joi.forbidden() 
});

const issueD = Joi.when('idType', {
    is: 'PASSPORT',
    then: issueDate.required(),
    otherwise: Joi.forbidden()
});

const placeIssue = Joi.when('idType', {
    is: 'PASSPORT',
    then: placeOfIssue.required(),
    otherwise: Joi.forbidden()
});

const studentSchema = Joi.object().keys({
    profileID: uuid.optional(),
    firstName: name.required(),
    lastName: name.required(),
    dob: dob.required(),
    gender: gender.required(),
    idType: identificationType.required(),
    idNo: idNo.required(),
    issueDate: issueD.required(),
    expiryDate: expiryD.required(),
    placeOfIssue: placeIssue.required(),
    street1: street.required(),
    street2: street.optional(),
    postcode: postcode.required(),
    city: city_state.required(),
    state: city_state.required(),
    country: country.required(),
    nationality: nationality.required(),
    religion: religion.required(),
    race: race.required(),
    priorSchool: Joi.optional(),
    medicalCondition: Joi.optional(),
    emergencyContactName: name.required(),
    emergencyContactRelationship: relationship.required(),
    emergencyContactCountryCode: countryCode.required(),
    emergencyContactNumber: contactNumber.required(),
    profileCode: Joi.when('useCurrentStudID', {
        is: true,
        then: code.required(),
        otherwise: code.optional()
    }),
    profileImg: Joi.alternatives().try(Joi.string().optional(), Joi.object().optional()),
    attachments: Joi.array().items(
        Joi.object().required()
    ).optional(),
    removedDocumentIDs: Joi.array().items(uuid.required()).optional(),
    useCurrentStudID: Joi.bool().optional(),
    keepAddressSameAsStudent: Joi.bool().optional(),
})
.options({ stripUnknown: true });

const parentGuardianSchema = Joi.object().keys({
    profileID: uuid.optional(),
    firstName: name.required(),
    lastName: name.required(),
    nationality: nationality.required(),
    idType: identificationType.required(),
    idNo: idNo.required(),
    issueDate: issueD.required(),
    expiryDate: expiryD.required(),
    placeOfIssue: placeIssue.required(),
    emailAddress: email.required(),
    futureUse: futureUse.required(),
    street1: street.required(),
    street2: street.optional(),
    postcode: postcode.required(),
    city: city_state.required(),
    state: city_state.required(),
    country: country.required(),
    countryCode: countryCode.required(),
    contactNumber: contactNumber.required(),
    resultFromSearch: Joi.boolean().optional(),
    isEmergencyContact: Joi.boolean().optional()
})
.options({ stripUnknown: true });

export const schema = Joi.object().keys({
    student: studentSchema.required(),
    mother: parentGuardianSchema.optional(),
    father: parentGuardianSchema.optional(),
    guardianOne: parentGuardianSchema.optional(),
    guardianTwo: parentGuardianSchema.optional()
})
.or('mother', 'father', 'guardianOne', 'guardianTwo')
.options({ stripUnknown: true })
.messages({
    'object.missing': 'Must fill up one of the guardian/parent informations'
});