import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, map } from 'lodash';

// Services
import { useGetClassesAttendanceQuery } from 'services/api/attendance';

// Components
import AttendanceDetails from 'components/attendance/attendance-details';
import ReasonDetails from 'components/attendance/reason-details';

import { DatePicker } from 'components/common/form-unit';
import Button from 'components/common/button';
import Loader from 'components/common/loader';
import Modal from 'components/common/modal';

import { AiOutlineCheckCircle } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';
const AttendanceClass = ({ getData }) => {
    const { t } = useTranslation();

    // 06-04-2021 
    // date.format('DD-MM-YYYY')
    // const initialDate = moment('06-04-2021', 'DD-MM-YYYY');
    const initialDate = moment();
    const initialModal = { open: false, content: null, title: null };
    const [date, setDate] = useState(initialDate);
    const [modal, setModal] = useState(initialModal);

    const {
        data,
        error,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = useGetClassesAttendanceQuery({ date: date.format('DD-MM-YYYY') });

    useEffect(() => {
        if (data && getData) {
            getData(data);
        }
    }, [data]);

    const onActionClick = ({ type, data }) => {
        console.log(data)
        switch (type) {
            case 'VIEW_DETAILS':
                setModal({
                    ...modal,
                    open: true,
                    content: <AttendanceDetails attendanceID={data?.ID} />
                });

                break;

            case 'VIEW_TEXT_REASON':
                setModal({
                    ...modal,
                    open: true,
                    title: t('title.reason_submitted'),
                    content: <ReasonDetails type="TEXT" data={data} onCloseModal={onCloseModal}/>
                });

                break;

            case 'VIEW_IMAGE_REASON':
                setModal({
                    ...modal,
                    open: true,
                    title: t('title.image'),
                    content: <ReasonDetails type="IMAGE" data={data} onCloseModal={onCloseModal}/>
                });

                break;

            default:
                throw new Error(`CASE_ERROR: INVALID TYPE`);
        }
    }

    const onCloseModal = () => { 
        setModal({ ...modal, open: false });
        refetch()
    }

    if (isLoading || isFetching) {
        return (
            <div className="absolute-center">
                <Loader size="33px" />
            </div>
        )
    }



    return (
        <Fragment>
            <Grid container alignItems="center" style={{display:'flex'}}>
                <Grid item lg={2}>
                    <span>{t('txt.view_class_attendance_on')}</span>
                </Grid>

                <Grid item lg={2}>
                    <DatePicker
                        value={date}
                        onChange={({ date, value }) => setDate(date)}
                    />
                </Grid>

                <Grid item>
                    {/* search bar here */}
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('label.class')}</TableCell>
                            <TableCell>
                                <span style={{ color: '#40AC0D' }}>
                                    {t('label.present')}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span style={{ color: '#FF0000' }}>
                                    {t('label.absent')}
                                </span>
                            </TableCell>
                            <TableCell>{t('label.absentee_names')}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{t('label.submitted')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isSuccess && isEmpty(data.attendance) && (
                            <TableRow>
                                <TableCell align="center" colSpan={5}>
                                    {t('txt.no_data')}
                                </TableCell>
                            </TableRow>
                        )}

                        {isSuccess && !isEmpty(data.attendance) && map(data.attendance, (o, i) => {
                            const { class: classData = {}, presents = [], absents = [], attendance = {} } = o;

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <span className="text-link" onClick={() => onActionClick({ type: 'VIEW_DETAILS', data: attendance })}>
                                            {classData.name}
                                        </span>
                                    </TableCell>
                                    <TableCell>{presents.length}</TableCell>
                                    <TableCell>{absents.length}</TableCell>
                                    <TableCell>
                                        {isEmpty(absents) && <span>{'-'}</span>}

                                        {!isEmpty(absents) && map(absents, (initO, key) => {
                                            return (
                                                <span style={{ display: 'block', marginTop: key > 0 && '.5em' }} key={key}>
                                                    {initO.name}
                                                </span>
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {!isEmpty(absents) && map(absents, (initO, key) => {

                                            if (isEmpty(initO.reasons)) {
                                                return (
                                                    <span style={{ display: 'block', marginTop: key > 0 && '.5em' }} key={key}>
                                                        -
                                                    </span>
                                                )
                                            }

                                            return (
                                                <div key={key} style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                                                    {
                                                        initO.reasons.map((r, _rIndex) => {
                                                            const reasonType = r.content?.type;
                                                            const reasonText = r.content?.text;
                                                            const reasonStatus = r.status;

                                                            console.debug('r', r);
                
                                                            if (reasonStatus === 'PENDING' && reasonType === 'TEXT') {
                                                                const data = {
                                                                    absentReasonID: r.ID,
                                                                    updateByID: initO?.ID,
                                                                    reasonText
                                                                };
                
                                                                return (
                                                                    <div key={_rIndex}>
                                                                        <Button
                                                                            label={t('label.reason')}
                                                                            onClick={() => onActionClick({ type: 'VIEW_TEXT_REASON', data })}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            else if (reasonStatus === 'PENDING' && reasonType === 'FILE') {
                                                                const data = {
                                                                    absentReasonID: r.ID,
                                                                    updateByID: initO?.ID,
                                                                    reasonImage: r.content?.attendance_attachments?.[0]?.fileUrl
                                                                };
                
                                                                return (
                                                                    <div key={_rIndex}>
                                                                        <Button
                                                                            label={t('label.image')}
                                                                            onClick={() => onActionClick({ type: 'VIEW_IMAGE_REASON', data })}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            else if (reasonStatus === 'APPROVED') {
                                                                // return <span key={key}>Approve</span>
                                                                return <div key={_rIndex}>
                                                                    <AiOutlineCheckCircle fontSize="22px" color="#3C8636" />
                                                                </div>
                                                            }
                                                            else if (reasonStatus === 'REJECTED') {
                                                                return (<div key={_rIndex}>
                                                                    <ImCancelCircle fontSize="22px" color="red" />
                                                                </div>)
                                                            }
                                                            else {
                                                                return (
                                                                    <div key={_rIndex}>
    
                                                                    </div>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </div>
                                            )

                                            // else {
                                            //     return (
                                            //         <span style={{ display: 'block', marginTop: key > 0 && '.5em' }} key={key}>
                                            //             -
                                            //         </span>
                                            //     )
                                            // }
                                        })}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                title={modal.title}
                open={modal.open}
                onHandleClose={onCloseModal}
                showFooter={false}
            >
                {modal.content}
            </Modal>
        </Fragment>
    )
}

export default AttendanceClass;