import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
// import Button from 'components/common/button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import useSession from 'hooks/use-session';
// Components
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import { Input, Select, InputSearch } from '../../common/form-unit';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import MultiSelect from "react-multi-select-component";
import IconButton from "@material-ui/core/IconButton";
import UserDetails from '../user-details';
import Loader from 'components/common/loader';
import DataGrid from 'components/common/data-grid';
import Modal from 'components/common/modal';
import Grid from '@material-ui/core/Grid';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import eduAPI from "../../../api/api";
import Session from 'services/local/session';
import StudentDetails from '../../student/student-details';
import TeacherDetails from '../../teacher/teacher-details';
import { uniq } from 'lodash';

const ITEM_HEIGHT = 48;

const SystemAdminUsersList = ({ status, resErr, onReset, onCreate, onUpdate, onConfirmAssign }) => {
    const session = useSession();

    const history = useHistory();
    const cls = useStyles();
    const initialLoading = false;
    const initialModal = { open: false, content: null };
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(initialLoading);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [modal, setModal] = useState(initialModal);
    const initialInput = {
        group: "",
        status: "",
        searchText: ""
    };
    const [input, setInput] = useState(initialInput);

    const [modulesList, setModulesList] = useState([])
    //set Options
    const [selModulesList, setSelModulesList] = useState([])
    //options
    const [rolesList, setRolesList] = useState([
        {
            value: 1,
            label: "Role 1"
        },
        {
            value: 2,
            label: "Role 2"
        }
    ])
    //set Options
    const [selRolesList, setSelRolesList] = useState([])

    const [students, setStudents] = useState([])
    const [backupStudents, setBackUpStudents] = useState([])
    const [groupList, setGroupList] = useState([])

    const uniquePermissions = uniq(session.permissions);
    const canCreateNewUser = (uniquePermissions || []).includes('v_sys_user_create');
    const canAssignUser = (uniquePermissions || []).includes('v_sys_users_assign');
    // const canUnassignUser = (uniquePermissions || []).includes('v_sys_users_unassign');


    const fetchUsers = () => {
        try {
            eduAPI.get(`/profile/byOrg/${session.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                const usersList = data.data.data;
                const sortedList = usersList.sort((userA,userB)=>{return (userA.firstName).localeCompare(userB.firstName)});
                setStudents(sortedList);
                setBackUpStudents(sortedList);
                setClear(false)
            });
        } catch (error) {
            console.error("Fetch Roles Error", error);
        }
    }

    const fetchGroups = () => {
        let assignbleGroups = [];
        try {
            eduAPI.get(`/sys_ad/groups?orgID=${session.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                data.data.data = data.data.data.map((item) => {
                    item.value = item.groupID;
                    item.label = item.name;
                    if (item.assignable) {
                        assignbleGroups.push(item)
                    }
                    return item;
                })
                setGroupList(data.data.data);


                setModulesList(assignbleGroups)

                fetchUsers();
            });
        } catch (error) {
            console.error("Fetch Roles Error", error);
        }
    }

    const [fetchStatus, setFetchStatus] = useState(true)

    const SearchData = () => {
        // if (input.status && input.group && input.searchText) {
        //     let studentList = backupStudents.filter(item => {
        //         let group = item.groups.find(element => element == input.group);
        //         return (
        //             (item.status.toLowerCase() == input.status.toLowerCase()) &&
        //             (group !== undefined) &&
        //             (item.firstName.toLowerCase() + item.lastName.toLowerCase()).indexOf(input.searchText.toLowerCase()) > -1
        //         );
        //     })

        let studentList = backupStudents.filter(item => {
            return (
                ((input.status ? (item.status.toLowerCase() == input.status.toLowerCase()) : true) &&
                    (input.group ? (item.groups.find(element => element == input.group)) : true) &&
                    (input.searchText !== undefined ? (item.firstName.toLowerCase() + item.lastName.toLowerCase()).indexOf(input.searchText.toLowerCase()) > -1 : true))
            );
        })
        setClear(true)
        setStudents([])
        setStudents(studentList)
        // }
    }

    useEffect(() => {
        if (session.orgID && fetchStatus) {
            setFetchStatus(false)
            fetchGroups();
            reset();
        }
    }, [session])

    useEffect(() => {
        SearchData();
    }, [input.searchText]);

    useEffect(() => {
        SearchData();
    }, [input.status]);

    useEffect(() => {
        SearchData();
    }, [input.group])



    useEffect(() => {
        if (status.fetch === 'success' || status.create === 'success' || status.update === 'success') {
            fetchGroups();
            fetchUsers();
            closePopup();
        }
    }, [status])


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [selRows, setSelRows] = React.useState([]);
    const [clear, setClear] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };
    const handleOpenEdit = () => {

    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };


    const onSelectCheckBox = (ids = []) => {
        setSelectedIDs(ids);
    }


    const onCloseModal = () => {
        setModal({ ...modal, open: false });
        setSelectedIDs([])
        fetchUsers();
    }

    const onMenuClick = ({ type, data }) => {


        switch (type) {
            case 'EDIT':
                if (data.type == 'ADMIN') {
                    setModal({
                        ...modal,
                        open: true,
                        content: <UserDetails profileID={data.profileID} onCloseModal={onCloseModal} />
                    });
                    break;
                } else if (data.type == 'TEACHER') {
                    setModal({
                        ...modal,
                        open: true,
                        content: <TeacherDetails profileID={data.profileID} />
                    });
                    break;
                } else {
                    setModal({
                        ...modal,
                        open: true,
                        content: <StudentDetails profileID={data.profileID} />
                    });
                    break;
                }

            default:
                break;
        }
    }



    const getGroupName = (key) => {
        let val = '';
        groupList.find((item) => {
            if (item.groupID == key) {
                val = item.name;
            }
        })
        return val ? val : '-';
    }

    const getUserId = (obj) => {
        if (obj?.primary == true || obj?.primary == false) {
            return obj.primary == true ? obj.input == null ? '-' : obj.input : obj.generated;
        } else {
            return '-'
        }
    }


    const columns = useMemo(() => {
        const arr = [
            {
                field: "firstName",
                headerName: "Name",
                width: 180,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <div>
                            <ListItem>
                                <ListItemAvatar>
                                    {params.row.profile_picture ? <Avatar>
                                        <img src={params.row.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                                    </Avatar> : <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{params.value?.charAt(0)}</Avatar>}

                                </ListItemAvatar>
                                <ListItemText className={cls.name} primary={params.value} secondary="" onClick={() => onMenuClick({ type: 'EDIT', data: params.row })} />
                            </ListItem>
                        </div>
                    )
                },
            },
            {
                field: "codes",
                headerName: "User ID",
                width: 150,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <span>{getUserId(params.value)}</span>
                    )
                }
            },
            {
                field: "groups",
                headerName: "Groups",
                width: 400,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <div>
                            {params.value.map((val, index) => (
                                <span>{getGroupName(val)} {params.value.length - 1 == index ? '' : ','}  </span>
                            ))}
                        </div>
                    )
                }
            },
            {
                field: "emailAddress",
                headerName: "Email",
                width: 200,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <span>{params.value}</span>
                    )
                }
            },
            {
                field: "status",
                width: 100,
                headerName: "Status",
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <span className={cls.status}>{_.upperFirst(_.lowerCase(params.value))}</span>
                    )
                }
            },
            // {
            //     field: "",
            //     headerName: "",
            //     disableColumnMenu: true,
            //     renderCell: (params) => {
            //         return (
            //             <div>
            //                 <IconButton
            //                     aria-label="more"
            //                     aria-controls="long-menu"
            //                     aria-haspopup="true"
            //                 // onClick={(event) => { handleClick(event, index) }}
            //                 >
            //                     <MoreVertIcon />
            //                 </IconButton>
            //                 <Menu
            //                     // id={index}
            //                     // key={index}
            //                     anchorEl={anchorEl}
            //                     keepMounted
            //                     open={openMenu}
            //                     onClose={handleClose}
            //                     PaperProps={{
            //                         elevation: 1,
            //                         style: {
            //                             maxHeight: ITEM_HEIGHT * 3.5,
            //                             width: "20ch",
            //                         }
            //                     }}
            //                 >
            //                     <MenuItem
            //                         //   key={index}
            //                         onClick={() => {
            //                             handleOpenEdit();
            //                         }}
            //                     >
            //                         {'Edit'}
            //                     </MenuItem>
            //                 </Menu>
            //             </div>
            //         )
            //     }
            // }
        ];

        return arr;
    }, [students]);


    if (loading) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px" />
            </div>
        )
    }

    // setSelRows

    const openPopup = () => {

        let formatObj = [];
        for (let i = 0; i < selectedIDs.length; i++) {
            students.find((item) => {
                if (item.profileID == selectedIDs[i]) {
                    formatObj.push(item)
                }
            })
        }
        setTimeout(() => {
            setSelRows(formatObj)
            setOpenEdit(true);
        }, 1000);

    }


    const closePopup = () => {
        setOpenEdit(false);
    };

    const confirmAssign = () => {

        setSelectedIDs([])
        let g_ids = [];
        let p_ids = [];
        selModulesList.map((item) => {
            g_ids.push(item.value)
        })

        selRows.map((itm) => {
            p_ids.push(itm.profileID)
        })

        let obj = {
            "groupIDs": g_ids,
            "profileIDs": p_ids
        }

        onConfirmAssign(obj)

        setTimeout(() => {
            setSelModulesList([])
        }, 1000)

    }




    const reset = () => {
        setInput(initialInput);
        fetchUsers();
    }


    return (
        <Fragment>
            <Grid container style={{ marginBottom: '' }}>
                <Grid item lg={10} md={10} sm={10} xs={12}>
                </Grid>
                {canCreateNewUser && <Grid item lg={2} md={2} sm={2} xs={12}>
                    <div className={cls.inputButtonContainer}
                        style={{ textAlign: 'right !important' }}>
                        <Button
                            className={cls.inputButton}
                            style={{ marginLeft: '5px' }}
                            onClick={() => history.push('/systemadmin/users/create')}>
                            <AddCircleOutlineOutlinedIcon
                                style={{
                                    marginLeft: '-10%',
                                    marginRight: '10%',
                                    color: "black"
                                }} />
                            New User
                        </Button>
                    </div>
                </Grid>}
            </Grid>

            <Paper>
                <div className={cls.table__wrapper}>

                    <Grid container style={{ marginBottom: '', padding: '10px' }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div>
                                <Button style={{ fontWeight: 'bold', color: "#25335a", textTransform: 'capitalize', fontSize: '18px' }}>Users  {`(${students?.length})`}</Button>
                            </div>
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Select
                                label="Select Group"
                                name="group"
                                required={true}
                                value={input.group || ''}
                                options={[{ label: <em>All</em>, value: undefined }, ...groupList]}
                                onChange={(e) => {
                                    setInput({ ...input, group: e.target.value })
                                }}
                            />

                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={12}>
                            <Select
                                label="By Status"
                                name="status"
                                required={true}
                                value={input.status || ''}
                                options={[
                                    { label: <em>All</em>, value: undefined },
                                    { label: 'Active', value: 'ACTIVE' },
                                    { label: 'In Active', value: 'INACTIVE' }
                                ]}
                                onChange={(e) => {
                                    setInput({ ...input, status: e.target.value })
                                }}


                            />
                        </Grid>
                        <Grid item lg={3} md={1} sm={1} xs={12}></Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12} >Search</Grid>
                            <Grid container >
                                <Grid item lg={9} style={{ textAlign: 'right' }}>
                                    <InputSearch placeholder="Search by User Name"
                                        value={input.searchText || ''}
                                        onChange={(e) => {
                                            setInput({ ...input, searchText: e.target.value })
                                        }} />
                                </Grid>
                                <Grid item lg={2} style={{ textAlign: 'left' }}>
                                    <IconButton
                                        color="#FFC000"
                                    >
                                        <SearchOutlinedIcon
                                            onClick={SearchData}
                                            style={{
                                                color: "black",
                                                background: 'rgba(253, 183, 20, 1)',
                                                width: 55,
                                                padding: 7,
                                                height: 40,
                                                marginTop: -4,
                                                marginLeft: -14,
                                                borderRadius: 4
                                            }} />
                                    </IconButton>
                                </Grid>

                                <Grid item lg={1} md={1} sm={1} xs={12}>
                                    {clear ?
                                        <IconButton
                                            color="#FFC000"
                                        >
                                            <ClearIcon
                                                onClick={reset}
                                                style={{
                                                    color: "black",
                                                    background: 'linear-gradient(213.06deg, rgba(255, 192, 0, 0.8) 4.6%, rgba(255, 138, 0, 0.8) 81.54%)',
                                                    width: 55,
                                                    height: 40,
                                                    marginTop: -4,
                                                    marginLeft: -30,
                                                    borderRadius: 4
                                                }} />
                                        </IconButton>
                                        : ''}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    {canAssignUser && <Grid container style={{ marginBottom: '', padding: '10px' }}>
                        <Grid item={true} lg={2} md={2} sm={2} xs={12} style={{ margin: 'auto 0px' }}>
                            <div>Assign selected Users to</div>
                        </Grid>
                        <Grid item={true} lg={3} md={3} sm={3} xs={12}>
                            <MultiSelect
                                options={modulesList}
                                value={selModulesList}
                                onChange={setSelModulesList}
                                labelledBy={"Select Group"}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <div className={cls.inputButtonContainer} style={{ textAlign: 'right !important' }}>
                                <Button className={cls.inputButton2} onClick={openPopup}>
                                    Assign
                                </Button>
                            </div>
                        </Grid>
                    </Grid>}

                    <Grid container={true} style={{ marginTop: '1em' }}>
                        <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                            <DataGrid
                                rows={students}
                                getRowId={(row) => row.profileID}
                                columns={columns}
                                loading={loading}
                                onSelectionModelChange={({ selectionModel }) => onSelectCheckBox(selectionModel)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                //    className={cls.modal}
                showFooter={false}
                open={openEdit}
                onClose={handleCloseEdit}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openEdit}>
                    <div className={[cls.modalHeight].join(' ')} >
                        <h2 id="transition-modal-title" className={cls.popupTitle}>CONFIRM ASSIGN USER</h2>
                        <div style={{ padding: '0px 10px', textAlign: 'center', fontFamily: 'Poppins', }}>
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assignUsers.svg"} className={cls.iconStyle} />
                            </div>
                            <div>
                                The user(s) below will be enrolled to the selected group(s) : <span style={{ color: "#FDB714", fontWeight: 'bold' }}>
                                    {selModulesList.map((item) => (
                                        <span>{item.name} , </span>
                                    ))}
                                </span>
                            </div>
                            {selRows.map((row) => (
                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item={true} lg={6} md={6} sm={6} xs={6}>{row.firstName + ' ' + row.lastName}</Grid>
                                    <Grid item={true} lg={6} md={6} sm={6} xs={6}>{getUserId(row.codes)}</Grid>
                                </Grid>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                            <Button className={cls.buttonSubmit} onClick={confirmAssign}>Confirm Assign</Button>
                        </div>
                        <div style={{ textAlign: 'center', color: '#0172B1', textDecoration: 'underline', marginTop: '10px' }} onClick={closePopup}>CANCEL, return to User Listing Page</div>
                    </div>
                </Fade>
            </Modal>


            {modal.open &&
                <Modal
                    showFooter={false}
                    open={modal.open}
                    onHandleClose={onCloseModal}
                    fullScreen={true}
                    maxWidth="lg"
                    scroll="body"
                >
                    {modal.content}
                </Modal>
            }
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    popupTitle: {
        marginBottom: '10px',
        textAlign: 'center'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#25335A',
        fontSize: 18,
        textAlign: 'center'
    },
    asterik: {
        color: 'red'
    },
    breadcrum: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '5%',
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        color: 'purple'

    },
    container: {
        marginTop: '5%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {

    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '1%',
        marginTop: '1%',
        width: '100%'
    },
    button: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        width: "auto !important",
        //textTransform: 'capitalize !important'

    },
    buttonSubmit: {
        background: '#FDB714',
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 5,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 40,
        padding: '0 30px',
        width: 175,
        textTransform: 'none',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '40%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
    ,
    modalHeight: {
        overflow: 'auto !important',
        maxHeight: 'calc(100vh - 10px)'
    },
    inputButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '2%',
        paddingLeft: '1%',
        paddingTop: '0.3%'
    },
    inputButton: {
        boxShadow: 'none',
        display: 'flex',
        maxHeight: 50,
        width: 145,
        height: 40,
        borderRadius: 5,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FDB714',
            opacity: 0.7
        },
    },
    inputButton2: {
        borderRadius: 6,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        paddingLeft: '15px',
        paddingRight: '15px',
        textTransform: 'none',
    },
    title: {
        background: '-webkit-linear-gradient(#25335A, #141824)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontSize: 21,
        fontWeight: 300
    },
    card__default: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #2C9009',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__primary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #4e79cf',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__secondary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid orange',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    icon: {
        width: '100px',
        height: 'auto',
        flex: 1
    },
    'typography-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,

        '& span': {
            fontSize: '18px',
            letterSpacing: .5
        },

        '& h1': {
            fontSize: '30px',
            fontWeight: 500
        }
    },
    table__wrapper: {
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        padding: '20px 30px',
        marginTop: '1em',
        width: '100%',
        height: '100vh'
    },
    link: {
        textDecoration: 'none',
        fontSize: '19px',
        color: '#0b103b',

        '&:hover': {
            color: '#212b80'
        }
    },
    name: {
        color: 'rgba(1, 114, 177, 1)',
        cursor: 'pointer',
        textDecoration: 'underline',
        transition: 'all 200ms',

        '&:hover': {
            color: 'rgba(90, 114, 177, 1)'
        }
    },
    status: {
        color: 'rgba(1, 114, 177, 1)',
        cursor: 'pointer',
        transition: 'all 200ms'
    },
    title: {
        background: '-webkit-linear-gradient(#25335A, #141824)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontSize: 21,
        fontWeight: 300
    },
    card__default: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #2C9009',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__primary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #4e79cf',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__secondary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid orange',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    icon: {
        width: '100px',
        height: 'auto',
        flex: 1
    },
    'typography-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,

        '& span': {
            fontSize: '18px',
            letterSpacing: .5
        },

        '& h1': {
            fontSize: '30px',
            fontWeight: 500
        }
    },
    table__wrapper: {
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
        padding: '20px 30px',
        marginTop: '1em',
        width: '100%',
        // height: '100vh'
    },
    link: {
        textDecoration: 'none',
        fontSize: '19px',
        color: '#0b103b',

        '&:hover': {
            color: '#212b80'
        }
    }
}));

export default SystemAdminUsersList;
