import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Button from 'components/common/button';
import Typography from '@material-ui/core/Typography';
import FlatList from 'flatlist-react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import eduAPI from "../../../../api/api";
import { Input } from 'components/common/form-unit'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import IconButton from "@material-ui/core/IconButton";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useToaster from 'hooks/use-toaster';
import useSession from 'hooks/use-session';
import Session from 'services/local/session';
import SharedCommonMenu from 'components/common/menu';


const RolesList = ({ status, resErr }) => {
    const session = useSession();
    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }

    const { t } = useTranslation();
    const history = useHistory();
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [DATA, setData] = useState([]);
    const [roleSearchTerm, setRoleSearchTerm] = useState('');
    const [selectedIndex, setSelIndex] = useState();
    const openMenu = Boolean(anchorEl);
    const [selectedRowObj, SetSelectedRow] = useState();
    const [open, setOpen] = React.useState(false);
    const [fetchStatus, setFetchStatus] = useState(true)


    const filterSearch = (event, type) => {
        let searchVal = event.target.value.toLowerCase();
        setRoleSearchTerm(searchVal)
    }

    const fetchRoles = async () => {
        try {
            await eduAPI.get(`/sys_ad/roles?orgID=${session.orgID}`, headersData).then((data) => {
                setData(data.data.data)
            });
        } catch (error) {
            console.error("Fetch Roles Error", error);
        }
    }


    useEffect(() => {
        if (session.orgID && fetchStatus) {
            setFetchStatus(false)
            fetchRoles();
        }
    }, [session])


    const handleClickButton = () => {
        history.push(
            {
                pathname: '/systemadmin/newrole',
                state: { obj: DATA[selectedIndex] }
            });
    };


    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleOpenEdit = () => {
        handleCloseMenu();
        if (DATA[selectedIndex]?.fixed === true) {
            handleCloseMenu()
            let msg = "Fixed Role Cannot Editable..";
            setToastMsg(msg)
            onOpenToaster()
        } else {
            handleClickButton()
        }
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [openEdit, setOpenEdit] = React.useState(false);
    const ITEM_HEIGHT = 48;

    return (
        <>
            <Toaster />
            <div>
                <Grid container style={{ marginBottom: '' }}>
                    <Grid item lg={10} md={10} sm={10} xs={12}>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                        <div className={classes.inputButtonContainer}
                            style={{
                                // display: 'flex',
                                // textAlign: 'right !important',
                                // marginRight: '-10px !important',
                                marginTop: '-50px',
                                marginBottom: '10px'
                            }}>
                            <Button
                                // variant="contained"
                                // className={classes.inputButton}
                                label={t('action.new_role')}
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                type="primary"
                                size="large"
                                style={{ marginLeft: '35px' }}
                                onClick={handleClickButton}>

                                {/* <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} /> */}
                                New Role
                            </Button>
                        </div>
                    </Grid>
                </Grid>


                <Paper className={classes.listContainer}>
                    <Grid container style={{ marginBottom: '20px', padding: '10px' }}>
                        <Grid item lg={8} md={6} sm={12} xs={12}>
                            <div className={classes.buttonContainer} style={{ marginBottom: '20px', padding: '10px' }}>
                                <Button label="Roles" style={{ fontWeight: 'bold', color: "#25335a", textTransform: 'capitalize', fontSize: '18px', background: 'transparent' }}>
                                    Roles
                                </Button>
                                {/* <Button>
                                <img src={process.env.PUBLIC_URL + "/sort.svg"} alt='sort' className={classes.iconStyle} />
        Sort
    </Button>
                            <Button>
                                <img src={process.env.PUBLIC_URL + "/filter.svg"} alt='filter' className={classes.iconStyle} />
        Filter
    </Button> */}
                            </div>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12} style={{ textAlign: 'right' }}>
                            <Grid container >
                                <Grid item lg={9} style={{ textAlign: 'right' }}>
                                    <Input onKeyUp={(event) => filterSearch(event, 'receive')} placeholder="Search By Role Name/Code.." />
                                </Grid>
                                <Grid item lg={3} style={{ textAlign: 'left' }}>
                                    <IconButton
                                        color="#FFC000"
                                    >
                                        <SearchOutlinedIcon
                                            style={{
                                                color: "black",
                                                background: 'rgba(253, 183, 20, 1)',
                                                width: 55,
                                                padding: 7,
                                                height: 40,
                                                marginTop: -4,
                                                marginLeft: -14,
                                                borderRadius: 4
                                            }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <TableContainer className={classes.tableContainer} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.titles}>Role Code</TableCell>
                                    <TableCell align="left" className={classes.titles}>Role Name</TableCell>
                                    <TableCell align="left" className={classes.titles}>Description</TableCell>
                                    <TableCell align="left" className={classes.titles}>Permissons</TableCell>
                                    <TableCell align="left" className={classes.titles}>Assigned to</TableCell>
                                    <TableCell align="left" className={classes.titles}></TableCell>
                                </TableRow>
                            </TableHead>

                            {DATA.length == 0 ? <TableBody><TableRow><TableCell></TableCell><TableCell>Roles Empty</TableCell><TableCell></TableCell></TableRow></TableBody> :
                                <TableBody>
                                    {DATA.filter(item => {
                                        return (
                                            item.name.toLowerCase().indexOf(roleSearchTerm) > -1 || item.code.toLowerCase().indexOf(roleSearchTerm) > -1
                                        );
                                    }).map((row, index) => (

                                        <TableRow key={row.code}>

                                            <TableCell align="left" className={classes.dataText}>
                                                {row.code}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText} >
                                                {row.description}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.permissions.map((subRow) => (
                                                    <div>
                                                        {subRow.permissions.map((sub, index) => (
                                                            <div>{sub.name} {subRow.permissions.length - 1 !== index ? ',' : ''}</div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText}>
                                                {row.assignedTo.map((sub, index) => (
                                                    <div>{sub.name} {row.assignedTo.length - 1 !== index ? ',' : ''}</div>
                                                ))}
                                            </TableCell>
                                            <TableCell align="left" className={classes.dataText} >
                                                <SharedCommonMenu
                                                    selectedIndex={setSelIndex}
                                                    actionElement={(onOpenMenu) => (
                                                        <IconButton 
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true" 
                                                            onClick={(event) => { onOpenMenu(event, index); }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    )}
                                                    items={[
                                                        {
                                                            content: 'Edit',
                                                            onClick: () => {
                                                                handleOpenEdit();
                                                            }
                                                        }
                                                    ]}
                                                    menuProps={{
                                                        PaperProps: {
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 3.5,
                                                                width: "20ch",
                                                            }
                                                        }
                                                    }}
                                                />

                                                {/* <div>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => { handleClick(event, index) }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id={index}
                                                        key={index}
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={openMenu}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            elevation: 1,
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 3.5,
                                                                width: "20ch",
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem
                                                            key={index}
                                                            onClick={() => {
                                                                handleOpenEdit();
                                                            }}
                                                        >
                                                            {'Edit'}
                                                        </MenuItem>
                                                    </Menu>
                                                </div> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '5%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%'
    },

    createNewDropDownContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 295,
        paddingLeft: 15,

    },

    createNewDropDown: {
        width: 295,
    },
    createNewContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,

    },

    createNewText: {
        fontFamily: 'Poppins',
        fontSize: 20,
        fontWeight: 500,
    },

    headerMenuContainer: {
        display: 'flex',
        // flexDirection: 'row',
        width: '100%',
        marginTop: '-2%'
    },

    listContainer: {

        // borderLeftStyle: 'solid',
        // borderLeftWidth: '10px',
        // borderLeftColor: 'rgba(37, 7, 85, 0.89)',
        borderRadius: 10
    },
    iconStyle: {
        marginRight: '5%'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginRight: '1%',

    },

    titleContainer: {
        marginLeft: '5%'
    },
    dataContainer: {
        marginLeft: '7%'
    },

    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#9FA2B4',
        fontSize: 14,
        // textAlign: 'center'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        // textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    input: {
        width: '65%',
        height: '50%',
        paddingBottom: '5%',
        paddintTop: '5%',
        fontSize: 12
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '1%',
        paddingLeft: '1%'

    },
    inputButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '2%',
        marginTop: '0.4%',
        paddingLeft: '1%',
        paddingTop: '0.3%'
    },
    inputButton: {
        display: 'flex',
        width: 200,
        borderRadius: 7,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
    },


}));

export default RolesList;