import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from 'components/common/button';
import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CompactPicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import eduAPI from "../../../api/api";
import { Input, Select } from '../../common/form-unit';
import MultiSelect from "react-multi-select-component";
import useToaster from 'hooks/use-toaster';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const options = ["Edit"];

const ITEM_HEIGHT = 48;

const Subject = ({ status, resErr, onUpdate, onCreate, onReset }) => {


    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canEdit = (uniquePermissions || []).includes('v_master_subject_update');
    const canCreateSubject = (uniquePermissions || []).includes('v_master_subject_create');
    const canViewSubjectDetails = (uniquePermissions || []).includes('v_master_subject_details');


    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }

    let basicInputModel = {
        subject: '',
        level: '',
        teacher: '', status: 'Active',
        color: '',
        icon: "",
        orgID: sessionObj.orgID,

    };


    let imagesLocations = [
        "MSA_Business",
        "MSA_Economics",
        "MSA_Biology",
        "MSA_Physics",
        "MSA_Chemistry",
        "MSA_Science",
        "MSA_AddMaths",
        "MSA_Maths",
        "MSA_Accounts",
        "MSA_Sports",
        "MSA_ArtsCraft",
        "MSA_Kemahiran_Hidup",
        "MSA_Geography",
        "MSA_History",
        "MSA_Information_Technology",
        "MSA_Bahasa_Melayu",
        "MSA_English",
        "MSA_OtherLanguage"
    ]


    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [subInput, setSubInput] = useState(basicInputModel);
    const [open, setOpen] = React.useState(false);
    const [ViewOpen, setViewOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedRowObj, SetSelectedRow] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedIndex, setSelIndex] = useState();
    const [rows, setRows] = useState([]);
    const [teachersList, setTeachersList] = useState([]);
    const [teacherState, setTeacherState] = useState({ name: [] });
    const [fetchStatus, setFetchStatus] = useState(true)

    const handleOpen = () => {
        setSubInput(basicInputModel)
        setTeacherState({ name: [] })
        setOpen(true);
    };

    const handleClose = () => {
        setSubInput(basicInputModel)
        setTeacherState({ name: [] })
        setOpen(false);
        setAnchorEl(null);
    };

    const handleCloseEdit = () => {
        setSubInput(basicInputModel)
        setTeacherState({ name: [] })
        setOpenEdit(false);
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeColor = (color) => {
        setSubInput({ ...subInput, color: color.hex })
    };


    const handleOpenEdit = () => {
        let data = rows[selectedIndex];
        SetSelectedRow(rows[selectedIndex])
        let obj = {
            subject: data.name,
            level: data.level,
            status: data.status,
            color: data.colour,
            icon: data.icon,
        }

        populateTechersBySubId(data.subjectID)

        handleCloseMenu()
        setTimeout(() => {
            setOpenEdit(true);
            setSubInput(obj)
        }, 2000);
    };

    const [listing, setListing] = useState({});


    const getListingInfo = async (id) => {
        try {
            await eduAPI.get("/subject/details/byKey/" + id, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                setListing(data.data.data);
                setViewOpen(true)
            });
        } catch (error) {
            console.error("fetch subjects error", error);
        }
    }


    const handleOpenView = (obj, index) => {
        setSelIndex(index)
        getListingInfo(obj.subjectID)
    }
    const handleViewClose = () => {
        setViewOpen(false);
    };

    const onSubmit = () => {
        if (buttonDisabled) {
            let teachArry = [];
            teachSelected.find((item) => {
                teachArry.push(item.value)
            })
            let value = {};
            value.name = subInput.subject;
            value.status = subInput.status;
            value.colour = subInput.color;
            value.level = subInput.level;
            value.icon = subInput.icon;
            value.orgID = sessionObj.orgID;
            value.teachers = teachArry;
            onCreate(value);
        }
    }

    const onSubjectUpdate = () => {
        if (buttonDisabled) {
            let teachArry = [];
            teachSelected.find((item) => {
                teachArry.push(item.value)
            })
            let obj = {
                subjectID: selectedRowObj.subjectID,
                name: subInput.subject,
                status: subInput.status,
                colour: subInput.color,
                level: subInput.level,
                icon: subInput.icon,
                teachers: teachArry
            }
            onUpdate(obj)
        }
    }


    const fetchSubjects = async () => {
        try {
            await eduAPI.get(`/subject/byOrg/${sessionObj.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                setRows(data.data.data);
                onReset()
            });
        } catch (error) {
            console.error("fetch subjects error", error);
        }
    }

    const [disStatusLevel, setDisStatusLevel] = useState(false);

    const [rState, setRstate] = useState([])
    const populateTechersBySubId = async (id) => {
        let tchrObj = [];
        let rObj = [];
        let deptArray = [];

        try {
            await eduAPI.get("/subject/populateTeachers/" + id, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data.teachers.map((item) => {
                    if (item.teaching) {
                        for (let i = 0; i < teacherList.length; i++) {
                            if (item.ID == teacherList[i].value) {
                                deptArray.push(
                                    {
                                        label: teacherList[i].label,
                                        value: teacherList[i].value
                                    })
                            }
                        }
                        if (item.isInTimetable) {
                            rObj.push(item.ID)
                        }
                    }
                    if (item.isInTimetable) {
                        setDisStatusLevel(true)
                    }
                });
                setteachSelected(deptArray)
                setRstate(rObj)
            });
        } catch (error) {
            console.error("fetch techers error", error);
        }
    }

    const fetchTeachers = async () => {
        let tchrObj = [];
        try {
            await eduAPI.get(`/profile/type?profileType=TEACHER&orgID=${sessionObj.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                data.data.data = data.data.data.map((item) => {
                    item.label= item.firstName + ' ' + item.lastName
                    item.value= item.profileID
                    return item;
                });
                
                const sortedTeachers = data.data.data.sort((teacherA,teacherB)=>{return (teacherA.firstName).localeCompare(teacherB.firstName)})
                setteacherList(sortedTeachers);

            });
        } catch (error) {
            console.error("fetch Ac classes error", error);
        }
    }

    useEffect(() => {
        if (status.update == 'failed') {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
            onReset()
        }
        if (status.create == "failed") {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
            onReset()
        }
    }, [resErr])


    useEffect(() => {
        if (status.create == 'success' || status.update === 'success') {
            handleClose();
            fetchSubjects();
            setOpenEdit(false);
            setSubInput(basicInputModel)
            let msg = status.create == 'success' ? "Subject Created Successfully.." : "Subject Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster()
            onReset();
            setTimeout(() => {
                setToastMsg("")
                setteachSelected([])
            }, 2000);
        }

    }, [status]);

    useEffect(() => {
        if (sessionObj.orgID && fetchStatus) {
            setFetchStatus(false)
            fetchSubjects();
            fetchTeachers();
        }

    }, [sessionObj]);

    useEffect(() => {
        if (subInput.subject && subInput.status && subInput.color && subInput.icon && subInput.level && teacherList.length > 0) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }
    }, [subInput])

    const setIcon = (key) => {
        setSubInput({ ...subInput, icon: key })
    }

    const techerHandleChange = (event) => {
        setTeacherState({ name: event.target.value });
    };
    const [dummyList, setDummyList] = useState([{}, {}, {}]);
    const getTeacher = (key) => {
        let disKey = false;
        if (rState.length) {
            rState.map((obj) => {
                if (key == obj) {
                    disKey = true;
                }
            })
            return disKey;
        }
    }

    //options
    const [teacherList, setteacherList] = useState([])
    //slec
    const [teachSelected, setteachSelected] = useState([]);

    const gotoEditFromListing = () => {
        handleViewClose();
        handleOpenEdit();
    }

    return (

        <>
            <Toaster />
            <Paper className={classes.container}>
                <div className={classes.headerContainer}>
                    {canCreateSubject ?
                        <div className={classes.buttonContainer}>
                            {/* <Button variant="contained" className={classes.inputButton} onClick={handleOpen}>
                    <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} />
                            Add Subject
                        </Button> */}

                            <ButtonComponent
                                label="Add Subject"
                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                type="primary"
                                size="large"
                                style={{ width: '170px' }}
                                onClick={handleOpen} />

                        </div>
                        : ''}

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={ViewOpen}
                        onClose={handleViewClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={ViewOpen}>
                            <div className={[classes.paperView, classes.modalViewHeight].join(' ')} >
                                <Grid container item style={{ fontFamily: "Poppins" }}>
                                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '10px' }}></Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '10px', textAlign: 'right', cursor: 'pointer' }} onClick={handleViewClose}>
                                        X
                                    </Grid>
                                </Grid>
                                <div style={{ margin: "20px 0px" }}>
                                    {listing ?
                                        <Grid container item style={{ fontFamily: "Poppins" }}>
                                            <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '10px' }}>
                                                <Paper>
                                                    <Grid container item style={{ padding: '10px 15px', borderBottom: '4px solid #55073F' }}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <div style={{ color: "#25335A", fontSize: '16px', fontWeight: 'bold' }}>{listing.subjectData?.name}
                                                                <Button>
                                                                    <img src={process.env.PUBLIC_URL + "/subject-icons/" + listing.subjectData?.icon + ".svg"} alt={listing.subjectData?.icon} />
                                                                </Button>
                                                            </div>
                                                            <div>
                                                                <span style={{ paddingRight: '5px', color: '#25335A', fontSize: '14px' }}>{listing.subjectData?.code}</span>
                                                                <span style={{ paddingRight: '5px', color: '#25335A', fontSize: '14px', marginLeft: '10px', marginRight: '10px' }}>{listing.subjectData?.level}</span>
                                                                <span style={{ paddingRight: '5px', color: '#219653', fontSize: '14px' }}>{listing.subjectData?.status}</span>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12} style={{ textAlign: "right" }}>
                                                            <div style={{ marginTop: '10px' }} onClick={gotoEditFromListing}>
                                                                <span style={{ cursor: 'pointer', background: '#E1F0FF', color: '#369AFF', padding: '5px 10px', borderRadius: '4px' }}>Edit</span>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px', fontSize: '16px', marginBottom: '10px', color: "#25335A" }}>
                                                            {listing?.teacherData?.map((subRow, index) => (
                                                                <span>{subRow}{listing?.teacherData?.length - 1 == index ? '' : ','}</span>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12} ></Grid>
                                            {listing?.slots?.map((slotItem) => (
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{ padding: '10px' }}>
                                                    <Paper>
                                                        <Grid container item style={{ padding: '10px 15px' }}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <div style={{ color: "#25335A", fontSize: '16px', fontWeight: 'bold' }}>{slotItem.year} | {slotItem.calendarName} </div>
                                                                <div style={{ color: "#25335A", fontSize: '16px', fontWeight: 'bold' }}>Semester {slotItem.semester} </div>

                                                                <div>
                                                                    <TableContainer >
                                                                        <Table aria-label="simple table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left" style={{ color: "#25335A !important", textAlign: "center" }}>Class</TableCell>
                                                                                    <TableCell align="left" style={{ color: "#25335A !important", textAlign: "center" }}>Teacher</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {slotItem?.classAndTeacher?.map((slot, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="left" className={classes.dataText}>
                                                                                            <div>{slot.class}</div>
                                                                                        </TableCell>
                                                                                        <TableCell align="left" className={classes.dataText}>{slot.teacher}</TableCell>
                                                                                    </TableRow>
                                                                                ))}

                                                                            </TableBody>

                                                                        </Table>
                                                                    </TableContainer>


                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        : ''}
                                </div>
                            </div>
                        </Fade>
                    </Modal>



                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={open}>
                            <div className={[classes.paper, classes.modalHeight].join(' ')} >
                                <h2 id="transition-modal-title">New Subject</h2>

                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Subject: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={8}>
                                        <Input
                                            name="subject"
                                            required={true}
                                            value={subInput.subject}
                                            error={subInput.subject === ""}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, subject: e.target.value })
                                            }}
                                        >
                                        </Input>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Level: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="level"
                                            required={true}
                                            error={subInput.level === ""}
                                            value={subInput.level || ''}
                                            options={[
                                                { label: 'Primary', value: 'Primary' },
                                                { label: 'Secondary', value: 'Secondary' }
                                            ]}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, level: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Teachers: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* TODO: there's a bug where clicking the dropdown */}
                                            <MultiSelect
                                                options={teacherList}
                                                value={teachSelected}
                                                onChange={setteachSelected}
                                                labelledBy={"Select Teachers"}
                                            />



                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Status: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="status"
                                            required={true}
                                            error={subInput.status === ""}
                                            value={subInput.status}
                                            disabled
                                            options={[
                                                { label: 'Active', value: 'Active' },
                                                // { label: 'Inactive', value: 'Inactive' }
                                            ]}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, status: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Colour Code: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CompactPicker
                                            color={subInput.color}
                                            onChange={handleChangeColor}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Icons: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container item xs={12} >
                                            {imagesLocations.map((row) => (
                                                <Grid item xs={2} >
                                                    <Button onClick={() => setIcon(row)} >
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/subject-icons/" + row + ".svg"}
                                                            alt={row}
                                                            className={subInput.icon === row ? classes.iconStyle : ''}
                                                        />
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button className={classes.buttonSubmit} onClick={onSubmit}>Done</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    <div></div>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleCloseEdit}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openEdit}>
                            <div className={[classes.paper, classes.modalHeight].join(' ')} >
                                <h2 id="transition-modal-title">Edit Row</h2>

                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Subject: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={8}>
                                        <Input
                                            name="subject"
                                            required={true}
                                            error={subInput.subject === ""}
                                            value={subInput.subject}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, subject: e.target.value })
                                            }}
                                        >
                                        </Input>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Level: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="level"
                                            required={true}
                                            error={subInput.level === ""}
                                            value={subInput.level || ''}
                                            disabled={disStatusLevel}
                                            options={[
                                                { label: 'Primary', value: 'Primary' },
                                                { label: 'Secondary', value: 'Secondary' }
                                            ]}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, level: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid container item xs={12} spacing={2} style={{ marginTop: '0%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Teachers: <span className={classes.asterik}>*</span></Typography>

                                    </Grid>
                                    <Grid item xs={8}>
                                        <div>
                                            <MultiSelect
                                                options={teacherList}
                                                value={teachSelected}
                                                onChange={setteachSelected}
                                                labelledBy={"Select Teachers"}
                                            />

                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '0%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Status: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Select
                                            name="status"
                                            //     disabled={disStatusLevel}
                                            required={true}
                                            error={subInput.status === ""}
                                            value={subInput.status}
                                            options={[
                                                { label: 'Active', value: 'Active' },
                                                { label: 'Inactive', value: 'Inactive' }
                                            ]}
                                            onChange={(e) => {
                                                setSubInput({ ...subInput, status: e.target.value })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '0%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Colour Code: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <CompactPicker
                                            color={subInput.color}
                                            onChange={handleChangeColor}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={2} style={{ marginTop: '5%' }}>
                                    <Grid item xs={4}>
                                        <Typography>Icons: <span className={classes.asterik}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container item xs={12} >
                                            {imagesLocations.map((row) => (
                                                <Grid item xs={2} >
                                                    <Button onClick={() => setIcon(row)}>
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/subject-icons/" + row + ".svg"}
                                                            className={subInput.icon === row ? classes.iconStyle : ''}
                                                        />
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0%' }}>
                                    <Button className={classes.buttonSubmit} onClick={onSubjectUpdate} >Done</Button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>


                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.titles}>Subject</TableCell>
                                <TableCell align="left" className={classes.titles}>Sub Code</TableCell>
                                <TableCell align="left" className={classes.titles}>Status</TableCell>
                                <TableCell align="left" className={classes.titles}>Level</TableCell>
                                <TableCell align="left" className={classes.titles}>Teacher</TableCell>
                                <TableCell align="left" className={classes.titles}>Color</TableCell>
                                <TableCell align="left" className={classes.titles}>Icon</TableCell>
                                <TableCell align="left" className={classes.titles}></TableCell>
                            </TableRow>
                        </TableHead>
                        {rows.length ?
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={row.lastUpdated}>
                                        <TableCell align="left" className={classes.dataText}>
                                            {canViewSubjectDetails ?
                                                <div onClick={() => { handleOpenView(row, index); }}
                                                    style={{ color: "#0172B1", textDecoration: 'underline', cursor: 'pointer' }}>{row.name}</div>
                                                : <p>{row.name}</p>}
                                        </TableCell>
                                        <TableCell align="left" className={classes.dataText}>{row.code}</TableCell>
                                        <TableCell align="left" className={classes.dataText}>{row.status}</TableCell>
                                        <TableCell align="left" className={classes.dataText}>{row.level}</TableCell>
                                        <TableCell align="left" className={classes.dataText}>
                                            {row.teachers.map((subRow, index) => (
                                                <div className={classes.subteacher}>{subRow}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Button style={{
                                                height: 30,
                                                width: 100,
                                                backgroundColor: row.colour
                                            }}>
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button>
                                                <img src={process.env.PUBLIC_URL + "/subject-icons/" + row.icon + ".svg"} alt={row.icon} />
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => { handleClick(event, index) }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={openMenu}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        elevation: 1,
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: "20ch",
                                                        }
                                                    }}
                                                >
                                                    {canEdit && <MenuItem
                                                        key={index}
                                                        onClick={() => {
                                                            handleOpenEdit(index);
                                                        }}
                                                    >
                                                        {'Edit'}
                                                    </MenuItem>}
                                                </Menu>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            :

                            <div style={{ textAlign: 'center', fontSize: '20px', margin: '10px 0px' }}>
                                Records Empty
                            </div>

                        }
                    </Table>
                </TableContainer>
            </Paper >
        </>


    )
};

const useStyles = makeStyles((theme) => ({
    iconStyle: {
        marginRight: '2px',
        borderBottom: '1px solid red'
    },
    root: {
        '& .MuiOutlinedInput-input': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px'

        },
        '& .MuiInput-root': {
            width: '100% !important'
        }
    },
    label: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        },
        marginBottom: '.8em',
        fontSize: '14px',
        color: '#756F86'
    },
    subteacher: {
        margin: "5px 0px"
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#25335A',
        fontSize: 18,
        textAlign: 'center'
    },
    asterik: {
        color: 'red'
    },
    breadcrum: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '5%',
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        color: 'purple'

    },
    container: {
        marginTop: '-5%',

    },
    table: {
        minWidth: 650,

    },
    tableContainer: {

    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '1%',
        marginTop: '1%',
        width: '100%'
    },
    button: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        width: "auto !important",
        // textTransform: 'capitalize !important'

    },
    buttonSubmit: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        // boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 40,
        padding: '0 30px',
        width: "140px",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,
        textTransform: 'none',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '30%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 10px)'
    },
    paperView: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '60%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
    , modalViewHeight: {
        overflow: 'auto',
        maxHeight: 'calc(150vh - 1px)'
    },
    inputButton: {
        boxShadow: 'none',
        display: 'flex',
        maxHeight: 50,
        width: 140,
        height: 40,
        borderRadius: 5,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FDB714',
            opacity: 0.7
        }
    }
}));

export default Subject;