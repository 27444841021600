import {
    SYSADMNROLE_CREATE,
    SYSADMNROLE_CREATE_SUCCESS,
    SYSADMNROLE_CREATE_FAILED,

    RESET_STATUS,

    SYSADMNROLE_UPDATE,
    SYSADMNROLE_UPDATE_SUCCESS,
    SYSADMNROLE_UPDATE_FAILED,

    SYSADMNROLE_FETCH,
    SYSADMNROLE_FETCH_FAILED,
    SYSADMNROLE_FETCH_SUCCESS
} from '../../actions/system-admin/system-admin-role';

const initialStatus = {
    create: 'idle',
    fetch: 'idle'
};

const initialState = {
    list: [],
    data: [],
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {


        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }


        case SYSADMNROLE_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading' }
            };
        }

        case SYSADMNROLE_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed' }
            }
        }

        case SYSADMNROLE_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success' }
            };
        }

        case SYSADMNROLE_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case SYSADMNROLE_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case SYSADMNROLE_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success' }
            };
        }

        case SYSADMNROLE_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case SYSADMNROLE_FETCH_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, fetch: 'failed' }
            }
        }

        case SYSADMNROLE_FETCH_SUCCESS: {

            return {
                ...state,
                error: {},
                status: {
                    ...state.status, fetch: 'success', data: state.data
                }
            };
        }

        default:
            return state;
    }
}

export default reducer;