import React, { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Classes from '../components/master-list/classes';
import Subject from '../components/master-list/subjects';
import TimetableTemplate from '../components/master-list/timetable-template';
import Department from '../components/master-list/departments/departments-list';
import Message from 'components/master-list/messages/messages-list';

import { useLocation } from "react-router-dom";

// import Classes from '../components/master-list/classes/classes';

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: 'black',
    // border: '1px solid green',
    background: 'white',
    boxShadow: '0 0 1.5px 1.5px rgba(0, 0, 0, 0.1)',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,

    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    top: '0px',
    backgroundColor: 'transparent',
    height: '5px',

    '& > span': {
      width: '100%',
      backgroundColor: '#4DC591',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper>
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        </Paper>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `styled-tab-${index}`,
    'aria-controls': `styled-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({

}));

const MasterList = () => {
  const styles = useStyles();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location?.state?.type) {
      switch (location?.state?.type) {
        case 'classes':
          setValue(0)
          break;

        case 'subjects':
          setValue(1);
          break;

        case 'messages':
          setValue(2);
          break;

        case 'timetable':
          setValue(3);
          break;

        case 'departments':
          setValue(4);
          break;

        default:
          break;
      }
    }

  }, [location])


  let pageTitle;
  switch (value) {
    case 0:
      pageTitle = 'Classes';
      break;

    case 1:
      pageTitle = 'Subjects';
      break;

    case 2:
      pageTitle = 'Messages';
      break;

    case 3:
      pageTitle = 'Timetable Templates';
      break;

    case 4:
      pageTitle = 'Departments';
      break;

    default:
      break;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" style={{color:"#FDB714"}} />}
                    style={{ 
                        paddingLeft: '50px',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'white',
                        marginTop: -10,
                        marginBottom: 10,
                        marginLeft:-30,
                        marginRight:-30,
                     }}
                >
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            color: "black",
                            textDecoration: 'none'
                        }}
                    >
                        Academic Setup
                    </Link>
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            color: "rgba(124, 123, 123, 1)",
                        }}
                        href="/masterlist"
                    >
                        Master Lists
                    </Link>
                </Breadcrumbs>

      <div style={{ marginTop: '1em' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
          <StyledTab label="Classes" {...a11yProps(0)} />
          <StyledTab label="Subjects" {...a11yProps(1)} />
          <StyledTab label="Messages" {...a11yProps(2)} />
          <StyledTab label="Timetable" {...a11yProps(3)} />
          <StyledTab label="Departments" {...a11yProps(4)} />
        </StyledTabs>

        <TabPanel value={value} index={0}>
          <Classes />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Subject />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Message />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TimetableTemplate />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Department />
        </TabPanel>
      </div>
    </Fragment>
  )
};

export default MasterList;