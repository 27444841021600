import _ from 'lodash';
// import base from './base';

const normalizer = (data) => {
    if (_.isEmpty(data)) {
        return {};
    };

    return _.omitBy(_.omitBy({
        orgID: data.orgID,
        template: data.messageTemplateType,
        content: data.messageTemplateContent,
        category: data.messageTemplateCategory,
        warningCount: data.warningCount
    }, _.isNull), _.isUndefined);
}

export default normalizer;