import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import store from 'store';

import en from './translations/en.json';
import ms from './translations/my.json';

const options = {
    order: ['querystring', 'localStorage'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'i18n',
    caches: ['localStorage']
}

i18n
.use(detector)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    detection: options,
    resources : {
        en: { translation: en },
        ms: { translation: ms }
    },
    lng: 'en',
    fallbackLng: ['en', 'ms'],
    load: 'languageOnly',
    whitelist: ['en', 'ms'],
    keySeparator: false, 
    interpolation: {
        // react already safes from xss
        escapeValue: false,
        formatSeparator: ',',
        format: (value, format, lng) => {
            if (format === 'uppercase') return value.toUpperCase();
            return value;
        }
    },
    react: {
        useSuspense: false
    }
});

export default i18n;

export function changeLng(language) {
    i18n.changeLanguage(language, (err) => {
        if (err) {
            console.log('Language error: ', err);
        }
    });
}

export function getCurrentLng() {
    return i18n.language;
}
  