import React, { Fragment, useState } from 'react';

// Components
import Input from '../input';
// import IconButton from '@material-ui/core/IconButton';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Password = ({ ...props }) => {
    // const [showPassword, setShowPassword] = useState(false);

    // const onToggle = (e) => {
    //     e.preventDefault();
    //     setShowPassword(!showPassword);
    // }

    return (
        <Fragment>
            <Input
                type="password"
                {...props}
                // type={showPassword ? 'text': 'password'}
                // suffix={
                //     <InputAdornment position="end">
                //         <IconButton onClick={onToggle} disableRipple={true}>
                //             {showPassword ? <VisibilityOff /> : <Visibility />}
                //         </IconButton>
                //     </InputAdornment>
                // }
            />
        </Fragment>
    )
}

Password.defaultProps = {

}

export default Password
