import _ from 'lodash';

import systemAdminRole from '../../apis/system-admin/system-admin-role';

export const SYSADMNROLE_FETCH = 'SYSADMNROLE_FETCH';
export const SYSADMNROLE_FETCH_FAILED = 'SYSADMNROLE_FETCH_FAILED';
export const SYSADMNROLE_FETCH_SUCCESS = 'SYSADMNROLE_FETCH_SUCCESS';

export const SYSADMNROLE_CREATE = 'SYSADMNROLE_CREATE';
export const SYSADMNROLE_CREATE_FAILED = 'SYSADMNROLE_CREATE_FAILED';
export const SYSADMNROLE_CREATE_SUCCESS = 'SYSADMNROLE_CREATE_SUCCESS';

export const SYSADMNROLE_UPDATE = 'SYSADMNROLE_UPDATE';
export const SYSADMNROLE_UPDATE_FAILED = 'SYSADMNROLE_UPDATE_FAILED';
export const SYSADMNROLE_UPDATE_SUCCESS = 'SYSADMNROLE_UPDATE_SUCCESS';

export const RESET_STATUS = "RESET_STATUS";

const dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createRole = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_CREATE });
        const res = await systemAdminRole.create(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SYSADMNROLE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_CREATE_FAILED,
            error: err
        });
    }
}

const updateRole = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_UPDATE });
        const res = await systemAdminRole.update(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SYSADMNROLE_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_UPDATE_FAILED,
            error: err
        });
    }
}

const getRolesTableList = () => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_FETCH });
        const res = await systemAdminRole.getList();
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_FETCH_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_FETCH_FAILED,
            error: err
        });
    }
}


const getGroups = () => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_FETCH });
        const res = await systemAdminRole.getGroups();
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_FETCH_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_FETCH_FAILED,
            error: err
        });
    }
}

const getUsers = () => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_FETCH });
        const res = await systemAdminRole.getUsers();
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_FETCH_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_FETCH_FAILED,
            error: err
        });
    }
}

const createGroup = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_CREATE });
        const res = await systemAdminRole.createGroup(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SYSADMNROLE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_CREATE_FAILED,
            error: err
        });
    }
}

const updateGroup = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_UPDATE });
        const res = await systemAdminRole.updateGroup(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: SYSADMNROLE_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_UPDATE_FAILED,
            error: err
        });
    }
}


const confirmAssign = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_CREATE });
        const res = await systemAdminRole.confirmAssign(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_CREATE_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_CREATE_FAILED,
            error: err
        });
    }
}

const confirmUnAssign = (data) => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_CREATE });
        const res = await systemAdminRole.confirmUnAssign(data);
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_CREATE_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_CREATE_FAILED,
            error: err
        });
    }
}

const populatePermissions = () => async (dispatch) => {
    try {
        dispatch({ type: SYSADMNROLE_FETCH });
        const res = await systemAdminRole.populatePermissions();
        if (!res?.success) {
            return dispatch({
                type: SYSADMNROLE_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: SYSADMNROLE_FETCH_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: SYSADMNROLE_FETCH_FAILED,
            error: err
        });
    }
}

export default {
    createRole,
    updateRole,
    populatePermissions,
    getRolesTableList,
    getGroups,
    createGroup,
    getUsers,
    confirmAssign,
    confirmUnAssign,
    updateGroup,
    resetStatus
}