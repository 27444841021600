import identification from './identification';
import address from './address';
import emergencyContact from './emergency-contact';
import email from './email';
import phoneNumber from './phone-number';
import group from './group';
import code from './code';
import attachment from './file-attachment';

export default {
    identification,
    address,
    emergencyContact,
    email,
    phoneNumber,
    group,
    code,
    attachment
}
