import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthAction from 'redux/actions/auth';

// Components
import ResendEmailForm from './resend-email.form';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const authActions = bindActionCreators(AuthAction, dispatch);

    return {
        onResetStatus$: () => {
            authActions.resetStatus();
        },
        resendEmail$: (payload) => {
            authActions.resendEmail(payload);
        }
    }
}

const Container = ({ status, error, onResetStatus$, resendEmail$ }) => {
    const history = useHistory();

    useEffect(() => {

        if (status.login === 'success' && status?.data?.access?.includes('access_web')) {
            history.push('/account/students');
            setTimeout(() => {
                onResetStatus$()
            }, 1000);
        }
    }, [status.login]);

    const resendEmail = (data) => {
        resendEmail$(data);
    }

    return (
        <ResendEmailForm resendEmail={resendEmail} status={status} resErr={error.login} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
