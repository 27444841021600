class Storage {
    constructor() {
        try {
            localStorage.setItem('foo', 'The quick brown fox jumps over the lazy dog');
            localStorage.removeItem('foo');

            this.lsChecker = true;
        } 
        catch(e) {
            this.lsChecker = false;
        }
    }

    set(key, value) {
        if (this.lsChecker) {
            localStorage.setItem(key, value);
        }     
    }

    get(key) { 
        if (this.lsChecker) {
            const value = localStorage.getItem(key); 
            return value;
        }       
    }

    delete(key) {
        if (this.lsChecker) {
            localStorage.removeItem(key);
        }   
    }
}

const storage = new Storage();

export default storage;