import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        fileName: data.fileName,
        fileBase64: data.fileBase64
    }, _.isNull), _.isUndefined);
}

export default component;