import React from "react";
import countries from "../../../utils/countries.json";
import { map, isUndefined } from "lodash";
import { useTranslation } from 'react-i18next';

// Components
import Grid from "@material-ui/core/Grid";
import IdentificationV2 from "../identification-v2";
import AddressFields from "../address";
import PhoneNumber from "../phone-number";
import { Input, Select, CheckBox } from "../form-unit";

const GuardianParent = ({
    input,
    error,
    onChange,
    onDateChange,
    onCheckBoxChange,
    onRadioChange,
    type
}) => {
    const { t } = useTranslation();

    return (
        <Grid item container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Input
                    id={`${type}-firstName`}
                    label="First Name"
                    name="firstName"
                    required={true}
                    value={input?.firstName || ""}
                    error={error?.firstName}
                    onChange={onChange}
                    placeholder={t('placeholder.first_name')}
                />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Input
                    id={`${type}-lastName`}
                    label="Last Name"
                    name="lastName"
                    required={true}
                    value={input?.lastName || ""}
                    error={error?.lastName}
                    onChange={onChange}
                    placeholder={t('placeholder.last_name')}
                />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Select
                    id={`${type}-nationality`}
                    label="Nationality"
                    name="nationality"
                    value={input?.nationality || ""}
                    error={error?.nationality}
                    required={true}
                    onChange={onChange}
                    options={map(countries, (o) => {
                        return { label: o.name, value: o.iso };
                    })}
                />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Input
                    id={`${type}-emailAddress`}
                    label="Email"
                    name="emailAddress"
                    required={true}
                    value={input?.emailAddress || ""}
                    error={error?.emailAddress}
                    onChange={onChange}
                    placeholder={t('placeholder.email_address_here')}
                >
                    <CheckBox
                        onChange={onCheckBoxChange}
                        options={[
                            {
                                label: "Use this email for future communication",
                                name: "futureUse",
                                checked: input?.futureUse,
                            }
                        ]}
                    />
                </Input>
            </Grid>

            <Grid style={{ marginBottom: '2em' }} item lg={6} md={6} sm={12} xs={12}>
                <AddressFields 
                    input={input} 
                    error={error} 
                    onChange={onChange} 
                    additionalProps={{
                        street1: { id: `${type}-street1` },
                        street2: { id: `${type}-street2` },
                        postcode: { id: `${type}-postcode` },
                        city: { id: `${type}-city` },
                        state: { id: `${type}-state` },
                        country: { id: `${type}-country` },
                    }}
                />

                <CheckBox
                    onChange={onCheckBoxChange}
                    options={[
                        {
                            label: t('label.keep_address_as_student'),
                            name: 'keepAddressSameAsStudent',
                            checked: input?.keepAddressSameAsStudent
                        }
                    ]}
                />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <IdentificationV2
                        input={input}
                        error={error}
                        onChange={onChange}
                        onDateChange={onDateChange}
                        onCheckBoxChange={onCheckBoxChange}
                        onRadioChange={onRadioChange}
                        additionalInputProps={{
                            idType: { id: `${type}-idType` },
                            idNo: { id: `${type}-idNo` },
                            issueDate: { id: `${type}-issueDate` },
                            expiryDate: { id: `${type}-expiryDate` },
                            placeOfIssue: { id: `${type}-placeOfIssue` }
                        }}
                    />
                </Grid>
                
                <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 20 }}>
                    <PhoneNumber 
                        input={input} 
                        error={error} 
                        onChange={onChange} 
                        additionalInputProps={{
                            countryCode: { id: `${type}-countryCode` },
                            contactNumber: { 
                                id: `${type}-contactNumber`,
                                placeholder: t('placeholder.emergency_contact_number')
                            }
                        }}
                    />
                    <CheckBox 
                        onChange={onCheckBoxChange}
                        options={[
                            {
                                label: "Use this as emergency contact",
                                name: "isEmergencyContact",
                                checked: input?.isEmergencyContact ?? false,
                                disabled: isUndefined(input?.firstName) || isUndefined(input?.lastName) || isUndefined(input?.countryCode) || isUndefined(input?.contactNumber) 
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

GuardianParent.defaultProps = {
    onChange: () => {},
    onDateChange: () => {},
    onCheckBoxChange: () => {},
};

export default GuardianParent;
