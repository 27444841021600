import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px'
        }
    },
    label: {
        '& .MuiInputLabel-asterisk': {
            color: 'red'
        },
        marginBottom: '.8em',
        fontSize: '14px',
        color: '#756F86'          
    },
    placeholderTxt: {
        color: 'rgba(0, 0, 0, 0.3)',
        fontSize: '14px'
    },
    menuPaper: {
        marginTop: '3.3em'
    }
}));

export default useStyles
