import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';

// Component
import { AutoComplete } from '../../common/form-unit';

const SelectProfile = ({ profileID, status, resErr, error, errorLabel, profiles, onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profilesList,setProfilesList] = useState([]);

    useEffect(() => {
        if (status.search === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status.search]);

    useEffect(() => {
        if (profileID && !_.isEmpty(profiles)) {
            const foundProfile = _.find(profiles, o => o.profileID === profileID);
            setSelectedValue(foundProfile);
        }
        if(!_.isEmpty(profiles)){
            const sortedProfiles = profiles.sort((profileA,profileB)=>{return (profileA.firstName).localeCompare(profileB.firstName)});
            setProfilesList(sortedProfiles);
        }
    }, [profileID, profiles]);

    useEffect(() => { 
        onChange(selectedValue?.profileID); 
    }, [selectedValue]);

    const onInputChange = (_, value) => {
        setInputValue(value);
    }

    const onSelectChange = (_, value) => {
        setSelectedValue(value);
    }

    const getOptionSelected = (option, value) => {
        return option.profileID === value.profileID;
    }

    const getOptionLabel = (option) => {
        return `${option.firstName} ${option.lastName}` || '';
    }

    return (
        <Fragment>
            <AutoComplete
                placeholder="Search By Name"
                inputValue={inputValue}
                value={selectedValue}
                onChange={onSelectChange}
                onInputChange={onInputChange}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                options={profilesList}
                loading={loading}
                error={error}
                errorLabel={errorLabel}
            />
            {/* <Select
                value={value}
                onChange={onHandleChange}
                options={!_.isEmpty(profiles) && status.fetch === 'success' && _.map(profiles, o => {
                    return { 
                        label: o.firstName + ' ' + o.lastName, 
                        value: o.profileID 
                    }
                })}
                {...props}
            /> */}
        </Fragment>
    )
}

SelectProfile.defaultProps = {
    onChange: () => {}
}

export default SelectProfile;
