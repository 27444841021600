import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import enc from 'helpers/enc';
import useSession from 'hooks/use-session';

import ProfileAction from 'redux/actions/profile';

// Components
// import LoginForm from './login-form.view';
import View from './profile-form-v2.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status, 
        error: state.profile?.error,
        data: state.profile?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onResetData$: () => {
            profileActions.resetData();
        },
        onResetStatus$: () => {
            profileActions.resetStatus();
        },
        onCreate$: (data, orgID) => {
            profileActions.createProfile(data, orgID);
        },
        onCreateStudentExistingParent$: (data, orgID) => {
            profileActions.createStudentWithExistingParent(data, orgID);
        },
        onUpdate$: (data, orgID) => {
            profileActions.updateProfile(data, orgID);
        },
        onFetchByID$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        }
    }
}

const Container = ({ 
    data,
    status, 
    error, 
    onResetStatus$, 
    onResetData$, 
    onCreate$, 
    onUpdate$, 
    onFetchByID$, 
    onCreateStudentExistingParent$ 
}) => {
    const session = useSession();
    const params = useParams();
    const studentID = enc.decode(params?.studentID);

    useEffect(() => {
        if (!studentID) {
            onResetData$();
            onResetStatus$();
        }

        if (studentID) { 
            onFetchByID$(studentID);
        } 
    }, [studentID]);

    useEffect(() => {
        if (studentID && status.update === 'success') {
            onResetStatus$();
            onFetchByID$(studentID);
        }
    }, [status.update, studentID]);

    const onCreate = (input) => {
        if (session.orgID) {
            onCreate$(input, session.orgID);
        }
    }

    const onCreateStudentExistingParent = (input) => {
        if (session.orgID) {
            onCreateStudentExistingParent$(input, session.orgID);
        }
    }

    const onUpdate = (input) => {
        if (session.orgID) {
            onUpdate$(input, session.orgID);
        }
    }
    
    return (
        <View 
            data={data}
            status={status} 
            resErr={error?.create || error?.update} 
            onCreate={onCreate} 
            onCreateStudentExistingParent={onCreateStudentExistingParent}
            onReset={onResetStatus$}
            onUpdate={onUpdate}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
