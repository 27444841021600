

import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import enc from 'helpers/enc';
import useSession from 'hooks/use-session';

import ProfileAction from 'redux/actions/profile';

// Components
import View from './user-form-view';

const mapStateToProps = (state) => {
    return {
        data: state.profile?.data,
        status: state.profile?.status,
        error: state.profile?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onResetData$: () => {
            profileActions.resetData();
        },
        onResetStatus$: () => {
            profileActions.resetStatus();
        },
        onCreate$: (data, orgID) => {
            profileActions.createTeacher(data, orgID);
        },
        onUpdate$: (data, orgID) => {
            profileActions.updateTeacherProfile(data, orgID);
        },
        onFetchByID$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        }
    }
}

const Container = ({
    data,
    status,
    error,
    onCreate$,
    onUpdate$,
    onFetchByID$,
    onResetData$,
    onResetStatus$
}) => {
    const session = useSession();
    const params = useParams();
    const studentID = enc.decode(params?.studentID);

    useEffect(() => {
        if (studentID) {
            onFetchByID$(studentID);
        }
        else {
            onResetData$();
            onResetStatus$();
        }
    }, [studentID]);

    useEffect(() => {
        if (studentID && status.update === 'success') {
            onFetchByID$(studentID);
            onResetStatus$();
        }
    }, [status.update, studentID]);

    const onCreate = (input) => {
        if (session.orgID) {
            onCreate$(input, session.orgID);
        }
    }

    const onUpdate = (input) => {
        if (session.orgID) {
            onUpdate$({ ...data.profile, ...input }, session.orgID);
        }
    }

    return (
        <View
            data={data}
            status={status}
            resErr={error?.create || error?.update}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onResetStatus={onResetStatus$}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);



// import React, { useEffect } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import enc from 'helpers/enc';
// import useSession from 'hooks/use-session';

// import ProfileAction from 'redux/actions/profile';

// // Components
// // import LoginForm from './login-form.view';
// import View from './user-form-view';

// const mapStateToProps = (state) => {
//     return {
//         status: state.profile?.status,
//         error: state.profile?.error,
//         data: state.profile?.data
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     const profileActions = bindActionCreators(ProfileAction, dispatch);

//     return {
//         onResetData$: () => {
//             profileActions.resetData();
//         },
//         onResetStatus$: () => {
//             profileActions.resetStatus();
//         },
//         onCreate$: (data, orgID) => {
//             profileActions.createAdminProfile(data, orgID);
//         },
//         onCreateStudentExistingParent$: (data, orgID) => {
//             profileActions.createStudentWithExistingParent(data, orgID);
//         },
//         onUpdateAdminProfile$: (data, orgID) => {
//             profileActions.updateAdminProfile(data, orgID);
//         },
//         onFetchByID$: (profileID) => {
//             profileActions.getProfileDetails(profileID);
//         }
//     }
// }

// const Container = ({
//     data,
//     status,
//     error,
//     onResetStatus$,
//     onResetData$,
//     onCreate$,
//     onUpdateAdminProfile$,
//     onFetchByID$,
//     onCreateStudentExistingParent$
// }) => {
//     const session = useSession();
//     const params = useParams();
//     const studentID = enc.decode(params?.studentID);

//     useEffect(() => {
//         if (!studentID) {
//             onResetData$();
//             onResetStatus$();
//         }

//         if (studentID) {
//             onFetchByID$(studentID);
//         }
//     }, [studentID]);

//     useEffect(() => {
//         if (studentID && status.update === 'success') {
//             onResetStatus$();
//             onFetchByID$(studentID);
//         }
//     }, [status.update, studentID]);

//     const onCreate = (input) => {
//         if (session.orgID) {
//             onCreate$(input, session.orgID);
//         }
//     }

//     const onCreateStudentExistingParent = (input) => {
//         if (session.orgID) {
//             onCreateStudentExistingParent$(input, session.orgID);
//         }
//     }

//     const onUpdateAdminProfile = (input) => {
//         if (session.orgID) {
//             onUpdateAdminProfile$(input, session.orgID);
//         }
//     }

//     return (
//         <View
//             // data={{ ...data.profile, profileImg: data.profileImg }}
//             data={{ ...data.profile }}
//             profileImg={data.profileImg}
//             status={status}
//             resErr={error?.create || error?.update}
//             onCreate={onCreate}
//             onCreateStudentExistingParent={onCreateStudentExistingParent}
//             onReset={onResetStatus$}
//             onUpdate={onUpdateAdminProfile}
//         />
//     )
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Container);
