import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { schema } from './department-form.validation';
import normalize from '../../../../utils/normalizers';

// Components
import { Input, Select } from '../../../common/form-unit';
import Button from '../../../common/button';
import SelectProfile from '../../../common/select-profile';

import Grid from '@material-ui/core/Grid';

const DepartmentForm = ({ status, data, resErr, onCreate, onUpdate, onReset, closeModal }) => {
    const initialInput = { departmentStatus: 'Active' };
    const initialError = {};
    const initialLoading = false;

    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [loading, setLoading] = useState(initialLoading);

    useEffect(() => {
        const isLoading = status.create === 'loading' || status.update === 'loading';
        const isSuccess = status.create === 'success' || status.update === 'success';

        if (isLoading) {
            setLoading(true);
        }
        else if (isSuccess) {
            closeModal();
            onReset();
        }
        else {
            setLoading(false);
        }
    }, [status]);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setInput(data);
        }
    }, [data]);

    const onChange = (e) => {
        let { name, value } = e.target;

        if (!value || value.length === 0) {
            value = undefined;
        }

        setInput({ ...input, [name]: value });
        setError(initialError);
    }
    const classes = useStyles();
    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            if (loading) { return; }

            const value = await schema.validateAsync(input);
            if (!_.isEmpty(value) && onCreate && _.isEmpty(data)) {
                onCreate(value);
            }

            if (!_.isEmpty(value) && onUpdate && !_.isEmpty(data)) {
                onUpdate(value);
            }
        }
        catch(err) {
            const { key, value } = normalize.error.validation(err);
            setError({ ...error, [key]: value });

            if (document.querySelector(`*[name=${key}]`)) {
                document.querySelector(`*[name=${key}]`).focus();
            }
        }
    }

    return (
        <div>
            <Grid container={true} alignItems="center">
                <Grid item={true} lg={5}>
                    <h3>Department <span style={{ color: 'red' }}>*</span></h3>
                </Grid>
                <Grid item={true} lg={7}>
                    <Input
                        errorLabel="Department"
                        name="departmentName"
                        value={input?.departmentName || ''}
                        error={error?.departmentName}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>

            <Grid container={true} alignItems="center">
                <Grid item={true} lg={5}>
                    <h3>Status <span style={{ color: 'red' }}>*</span></h3>
                </Grid>
                <Grid item={true} lg={7}>
                    <Select
                        errorLabel="Status"
                        name="departmentStatus"
                        value={input.departmentStatus || ''}
                        error={error?.departmentStatus}
                        onChange={onChange}
                        disabled={_.isEmpty(data) ? true : false}
                        options={[
                            { label: 'Active', value: 'Active' },
                            { label: 'In Active', value: 'Inactive' }
                        ]}
                    />
                </Grid>
            </Grid>

            <Grid container={true} alignItems="center">
                <Grid item={true} lg={5}>
                    <h3>Head of Dept. <span style={{ color: 'red' }}>*</span></h3>
                </Grid>
                <Grid item={true} lg={7}>
                    <SelectProfile
                        profileType="TEACHER"
                        profileID={input.headOfDepartmentID}
                        error={error.headOfDepartmentID}
                        errorLabel="Head of Department"
                        onChange={(profileID) => setInput({ ...input, headOfDepartmentID: profileID })} 
                    />
                </Grid>
            </Grid>

            <div style={{ marginTop: '1em', padding: '1em 0', textAlign: 'center' }}>
                <Button
                className={classes.buttonSubmit}
                 
                    label="Done"
                    size="large"
                    isLoading={loading}
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    buttonSubmit: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        // boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 40,
        padding: '0 30px',
        width: "140px",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,
    },
}));

export default DepartmentForm
