import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';

// Component
import { AutoComplete } from '../../common/form-unit';

const SelectYear = ({ data, status, onChange, resErr, error, errorLabel, availableYears }) => {
    const currentYear = new Date().getFullYear().toString();

    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (status === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [status]);

    useEffect(() => { // This effect runs to make sure to choose current year as the default value
        if (!_.isEmpty(availableYears) && currentYear) {
            const foundCurrentYear = _.find(availableYears, o => o === currentYear);
            if (foundCurrentYear) {
                setSelectedValue(foundCurrentYear);
            }

            const sortedDescYears = availableYears.sort((a, b) => b - a);
            if (sortedDescYears[0] > new Date().getFullYear()) {
                setSelectedValue(_.toString(sortedDescYears[0]));
            }
        }
        // else {
        //     setSelectedValue(currentYear);
        // }
    }, [availableYears, currentYear]);

    useEffect(() => {
        if (data) {
            setSelectedValue(data);
        }
    }, [data]);

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue]);

    const onInputChange = (_, value) => {
        setInputValue(value);
    }

    const onSelectChange = (_, value) => {
        setSelectedValue(value);
    }

    return (
        <Fragment>
            <AutoComplete
                placeholder="Search/Select Year"
                inputValue={inputValue}
                value={selectedValue}
                onChange={onSelectChange}
                onInputChange={onInputChange}
                options={!_.isEmpty(availableYears) ? availableYears : [currentYear]}
                loading={loading}
                error={error}
                errorLabel={errorLabel}
            />
        </Fragment>
    )
}

SelectYear.defaultProps = {
    onChange: () => { }
}

export default SelectYear;
