import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import { classes } from '@material-ui/data-grid';

import academicStatus from '../json/academicStatus.json';
import results from '../json/results.json';

const StudentProfile = ({ history }) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = (e) => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    let data = academicStatus.map((o, i) => { return { ...o, id: i + 1 } });
    let data2 = results.map((o, i) => { return { ...o, id: i + 1 } });

    const rows = [
        {
           year: '2014',
           class: 'Yr 1',
           status: 'Completed',
           dateUpdated: '25.11.2014',
           updatedBy: 'Josephine Doe',
           notes: '-'
        },
        {
            year: '2015',
            class: 'Yr 2',
            status: 'Completed',
            dateUpdated: '25.11.2015',
            updatedBy: 'Josephine Doe',
            notes: '-'
         },
         {
            year: '2016',
            class: 'Yr 3',
            status: 'Failed',
            dateUpdated: '25.11.2016',
            updatedBy: 'Josephine Doe',
            notes: 'Failed Mathematics in Yr 3, will have to repeat a year.'
         },
         {
            year: '2017',
            class: 'Yr 3',
            status: 'Repeated',
            dateUpdated: '25.11.2017',
            updatedBy: 'Josephine Doe',
            notes: '-'
         },
         {
            year: '2018',
            class: 'Yr 4',
            status: 'Completed',
            dateUpdated: '25.11.2018',
            updatedBy: 'Josephine Doe',
            notes: '-'
         },
         {
            year: '2019',
            class: 'Yr 5',
            status: 'Completed',
            dateUpdated: '25.11.2019',
            updatedBy: 'Josephine Doe',
            notes: '-'
         },
         {
            year: '2020',
            class: 'Yr 6',
            status: 'Ongoing',
            dateUpdated: '',
            updatedBy: '',
            notes: '-'
         }
    ]

    const rows2 = [
        {
            subject: 'Maths',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'Biology',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'Physics',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'Chemistry',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'English',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'Economics',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        },
        {
            subject: 'Average',
            q1: '86',
            q2: '86',
            q3: '86',
            q4: '86'
        }
    ]



    return (

        <div className={classes.container}>
            <Helmet>Student Profile</Helmet>

            <div className={classes.header}>
                <Typography variant='h4' className={classes.title}>James Liam</Typography>
                <Typography variant='h5' className={classes.title}>Primary 4 Edison</Typography>
            </div>

    <Grid container spacing={2}>
    <Grid item lg={6}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography variant='h5' className={classes.title} gutterBottom>    
                        Attendance
                     </Typography>
                
                    <Typography className={classes.pos} color="textSecondary">
                    Description
                    </Typography>
                    <Typography variant="body2" component="p">
                    Description
                   
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>
            </Grid>
            <Grid item lg={6}>
            <Card className={classes.emergency} variant="outlined">
                <CardContent>

                <Typography variant='h5' style={{color: "#B42C30" }} gutterBottom>
                        Emergency Contact
                     </Typography>

                     <Grid container>
                     <Grid item lg={6}>
                         
                    <Typography variant="h6" component="h2">Name</Typography>
                    <Typography className={classes.pos} color="textSecondary">James William</Typography>

                    <Typography variant="h6" component="h2">Mobile No</Typography>
                    <Typography className={classes.pos} color="textSecondary">0175698569</Typography>

                    </Grid>

                    <Grid item lg={6}>
                    <Typography variant="h6" component="h2">Relationship with Student</Typography>
                    <Typography className={classes.pos} color="textSecondary">Uncle</Typography>

                    </Grid>
                </Grid>   
                    
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>
            </Grid>
            <Grid item lg={6}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography variant='h5' className={classes.title} gutterBottom>
                       Discipline
                     </Typography>
                
                    <Typography className={classes.pos} color="textSecondary">
                    Description
                    </Typography>
                    <Typography variant="body2" component="p">
                    Description
                   
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>

            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography variant='h5' className={classes.title} gutterBottom>
                       Results
                     </Typography>
                
                     <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">Q1</TableCell>
                            <TableCell align="left">Q2</TableCell>
                            <TableCell align="left">Q3</TableCell>
                            <TableCell align="left">Q4</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows2.map((row) => (
                            <TableRow key={row.name}>
                            <TableCell align="left">{row.subject}</TableCell>
                            <TableCell align="left">{row.q1}</TableCell>
                            <TableCell align="left">{row.q2}</TableCell>
                            <TableCell align="left">{row.q3}</TableCell>
                            <TableCell align="left">{row.q4}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>
            </Grid>
            <Grid item lg={6}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    
                    <Typography variant='h5' className={classes.title} gutterBottom>
                        Student Information
                        <Button><EditIcon/></Button>
                     </Typography>
                    
                <Grid container>

                   <Grid item lg={6}>
                   <Typography variant="h6" component="h2">Name</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                    James William
                    </Typography>
                    
                   <Typography variant="h6" component="h2">National ID</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   995887145878
                    </Typography>

                   <Typography variant="h6" component="h2">Religion</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   Christianity
                    </Typography>

                   <Typography variant="h6" component="h2">Home Address</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   2112 Jalan 2334, Bandar Lorem Ipsum, 445000, Kuala Lumpur, Malaysia 
                    </Typography>
                   
                   </Grid>

                   <Grid item lg={6}>
                   <Typography variant="h6" component="h2">Date Of Birth</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   02/02/20
                    </Typography>
                   
                   <Typography variant="h6" component="h2">Place of Birth</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   Kuala Lumpur, Malaysia
                    </Typography>
                   
                   <Typography variant="h6" component="h2">Gender</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   Male
                    </Typography>
                   
                   <Typography variant="h6" component="h2">Prior School</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   School of Lorem Ipsum 
                    </Typography>
                   
                   <Typography variant="h6" component="h2">Allergy</Typography>
                   <Typography className={classes.pos} color="textSecondary">
                   Lorem Ipsum
                    </Typography>
                   
                   </Grid>
                   </Grid>

                   <Typography variant='h5' className={classes.title} gutterBottom>
                        Parent Information
                     </Typography>

                     <Grid container>

                    <Grid item lg={6}>
                    <Typography variant="h6" component="h2">Father</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    Fedrick William
                    </Typography>
                    
                    <Typography variant="h6" component="h2">National ID</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    878998545412
                    </Typography>

                    <Typography variant="h6" component="h2">Mobile No.</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    0178965554
                    </Typography>

                    <Typography variant="h6" component="h2">Email</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    Loremipsum@lorem.com
                    </Typography>

                    </Grid>

                    <Grid item lg={6}>
                    <Typography variant="h6" component="h2">Mother</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    Susan O’neil
                    </Typography>

                    <Typography variant="h6" component="h2">National ID</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    878998545412
                    </Typography>

                    <Typography variant="h6" component="h2">Mobile No. </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    0178965554
                    </Typography>

                    <Typography variant="h6" component="h2">Email</Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    Loremipsum@lorem.com
                    </Typography>

                    </Grid>
                    </Grid>

                </CardContent>
            </Card>
            </Grid> 
            
            <Grid item lg={12}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                     <Typography variant='h5' className={classes.title} gutterBottom>
                       Academic Status
                     </Typography>
                    
                    <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">Year</TableCell>
                            <TableCell align="left">Class</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Date Updated</TableCell>
                            <TableCell align="left">Updated By</TableCell>
                            <TableCell align="left">Notes</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                            <TableCell align="left">{row.year}</TableCell>
                            <TableCell align="left">{row.class}</TableCell>
                            <TableCell align="left">{row.status}</TableCell>
                            <TableCell align="left">{row.dateUpdated}</TableCell>
                            <TableCell align="left">{row.updatedBy}</TableCell>
                            <TableCell align="left">{row.notes}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>
            </Grid>

            </Grid>

            < Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openModal}
                            onClose={handleCloseModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                        >
                            <Fade in={openModal}>
                                <div className={classes.paperModal}>
                                    <h2 id="transition-modal-title" style={{ textAlign: 'center', color: '#25335A' }}>CONFIRM  STUDENT</h2>
                                    <h2 style={{ textAlign: 'left', color: '#25335A' }}>
                                        The student(s) below will be transfered to the selected class:
                                        <a style={{ color: '#FFC000' }}> Yr 3 Newton</a>
                                    </h2>


                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Button className={classes.buttonSubmit} >Confirm </Button>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Link component="button"
                                            variant="body2"
                                            onClick={() => {
                                                console.info("I'm a button.");
                                            }}>DON'T , return to Student Page</Link>
                                    </div>


                                </div>
                            </Fade>
                        </Modal>


        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2%'
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        flexDirection: 'row',
        display: 'flex'
    },
    header: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        flexDirection: 'column',
        display: 'flex'
    },
    title: {
        color: 'dark blue grad'

    },
    container: {
        // marginTop: '2%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%',
        paddingBottom: '5%'
    },
    table: {
        minWidth: 650,
    },
    tableContainer: {
        height: '100vh',
        padding: '2em 0em'
    },
    button: {
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 60,
        padding: '0 30px',
        width: "20%",

    },
    buttonSubmit: {
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        color: 'black',
        height: 60,
        padding: '0 30px',
        width: "20%",
    },
    paper: {
        display: 'flex',
        height: 140,
        width: 100
    },
    control: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '30%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    headerTable: {
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        marginTop: '2em'
    },
    emergency:{
        marginTop: '2%',
        backgroundColor: '#EBC8C4'
    }

}));

export default StudentProfile
