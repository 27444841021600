import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

//import GlobalStyles from '../styles/globalStyles';
import { schema } from './login-form.validation';
import normalize from '../../../utils/normalizers';

// Assets
import ProductLogo from '../../../assets/img/loginFormIcon.png'
import LoginImg from '../../../assets/img/loginBg.png';

// Componennts
import Grid from "@material-ui/core/Grid";
import { Input, Password } from "../../common/form-unit";
import Button from '../../common/button';
import { makeStyles } from '@material-ui/core/styles';
import AlertBox from '../../common/alert-box';
// import TextField from '@material-ui/core/TextField';
// import PasswordStrengthBar from 'react-password-strength-bar';

const LoginForm = ({ status, resErr, onLogin }) => {
    const styles = useStyles();
    // const global = GlobalStyles();
    const history = useHistory();

    const initialError = {}, initialLoading = false;
    const initialInput = {
        // emailAddress: 'hudrosli@yahoo.com',
        // password: 'Password123!@'

        emailAddress: '',
        password: ''
    };

    // const [username, setUsername] = useState();
    // const [password, setPassword] = useState();
    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [isLoading, setIsLoading] = useState(initialLoading);

    useEffect(() => {
        if (status.login === 'loading') {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
        // if (status.login == 'success' && status?.data?.tokenData?.profile?.type !== 'ADMIN') {
        //     setError({ ...error, 'response': 'Only Admin Can Access The Application...' });
        // }

        if (status.login == 'success' && !status?.data?.access?.includes('access_web')) {
            setError({ ...error, 'response': 'Only Admin Can Access The Application...' });
        }


    }, [status.login, status?.data]);




    useEffect(() => {
        if (resErr?.length !== 0 || !_.isUndefined(resErr)) {
            setError({ ...error, 'response': resErr });
        }
    }, [resErr]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setInput({ ...input, [name]: value });
        setError(initialError);
    }

    const onSubmit = async () => {
        try {
            if (isLoading) { return; }

            const value = await schema.validateAsync(_.omitBy(_.omitBy({ ...input }, _.isNull), _.isUndefined));
            if (!_.isEmpty(value) && onLogin) {
                onLogin(value);
            }
        }
        catch (err) {
            const { key, value } = normalize.error.validation(err);

            setError({ ...error, [key]: value });

            if (document.querySelector(`input[name=${key}]`)) {
                document.querySelector(`input[name=${key}]`).focus();
            }
        }
    }


    const goToVerification = () => {
        history.push('/verification');
    }

    const goToPasswordReset = () => {
        history.push('/reset-verification');
    }

    return (
        <div className={styles.container}>
            {/* <div className={styles.left__col}>
                <img
                    className={styles.backgroundImage}
                    alt="LoginBg Image"
                    src={LoginImg}
                />
            </div> */}

            <div className={styles.right__col}>
                <Grid
                    className={styles.wrapper__inner}
                    container={true}
                    justify="center"
                    alignItems="center"
                    direction="row"
                >
                    <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '1em', display: 'flex', justifyContent: 'center' }}>
                        <img alt="Product Logo" src={ProductLogo} />
                    </Grid>

                    <Grid
                        item={true}
                        lg={12} md={12} sm={12} xs={12}
                        style={{ padding: '1.5em 0', display: 'flex', justifyContent: 'center' }}
                    >
                        <h2 style={{ color: '#424241' }}>Sign In</h2>
                    </Grid>

                    <Grid
                        container={true}
                        item={true}
                        lg={12} md={12} sm={12} xs={12}
                    >
                        {!_.isUndefined(error?.response) && error?.response.length !== 0 &&
                            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                                <AlertBox
                                    message={error?.response}
                                    type="error"
                                />
                            </Grid>
                        }

                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '.5em' }}>
                            <Input
                                placeholder="Email Address"
                                errorLabel="Email Address"
                                name="emailAddress"
                                value={input.emailAddress || ''}
                                error={error?.emailAddress}
                                onChange={onChange}
                                required={true}
                            />
                        </Grid>

                        <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                            <Password
                                placeholder="Password"
                                errorLabel="Password"
                                name="password"
                                value={input.password || ''}
                                error={error?.password}
                                onChange={onChange}
                                required={true}
                            />
                        </Grid>

                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <Button
                                onClick={onSubmit}
                                label="Login"
                                isLoading={isLoading}
                                type="primary"
                                style={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '30px' }}>
                            <div onClick={goToVerification}>If Your Account is not verified <span style={{ color: 'rgb(66, 66, 65)', textDecoration: 'underline', cursor: 'pointer' }}>Click Here.</span></div>
                        </Grid>
                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <div onClick={goToPasswordReset}>Forgotten your  <span style={{ color: 'rgb(66, 66, 65)', textDecoration: 'underline', cursor: 'pointer' }}>Password?</span></div>
                        </Grid>


                    </Grid>
                </Grid>
            </div>
        </div>
        // <div className={styles.container}>
        //     <Fragment>
        //         <img src={LoginImg} alt='Login' className={styles.img}/>
        //     </Fragment>

        //     <div className={styles.inputContainer}>
        //         <div className={styles.titleCotainer}>
        //             <p className={styles.title}>My School Asia</p>
        //         </div>

        //         <div className={styles.inputTitleContainer}>
        //             {/* <h4 className={styles.input}>Email</h4> */}
        //             <div className={styles.passwordContainer}>
        //                 <TextField
        //                     id="outlined-email-input"
        //                     label="Email"
        //                     type="email"
        //                     autoComplete= "current-email"
        //                     variant="outlined"
        //                     fullWidth={true}
        //                     onChange
        //                 />
        //             </div>
        //             {/* <h4 className={styles.input} style={{ marginTop: '1em' }}>Password</h4> */}
        //             <div className={styles.passwordContainer} style={{ marginTop: '.7em' }}>
        //                 <TextField
        //                     id="outlined-password-input"
        //                     label="Password"
        //                     type="password"
        //                     autoComplete="current-password"
        //                     variant="outlined"
        //                     fullWidth = {true}
        //                     onChange = {(e) => {setPassword(e.target.value)}}
        //                 />
        //                 <div style={{ display: 'flex', marginTop: '2em' }}>
        //                     <Button
        //                         onClick={onSubmit}
        //                         label="Login"
        //                         type="primary"
        //                         variant="contained"
        //                         isLoading={isLoading}
        //                         size="large"
        //                         style={{ flex: 1 }}
        //                     />
        //                 </div>
        //            </div>
        //         </div>
        //     </div>
        // </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    left__col: {
        width: '120%',

        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    right__col: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        padding: '2em 2em',
        margin: 'auto',
        '@media screen and (max-width: 1024px)': {
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'center'
        }
    },
    wrapper__inner: {
        width: '80%',

        '@media screen and (max-width: 1024px)': {
            width: '100%'
        },

        '@media screen and (min-width: 1600px)': {
            width: '60%'
        }
    },
    backgroundImage: {
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundSize: 'cover',
    }
    // button: {
    //     background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
    //     border: 5,
    //     borderWidth: 15,
    //     borderColor: '#25335A',
    //     borderRadius: 3,
    //     //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //     color: 'black',
    //     height: 48,
    //     padding: '0 30px',
    //     width: "100%"
    // },
    // passwordStrengthBar: {
    //     marginTop: '2%'
    // },
    // passwordContainer: {
    //     width: '70%'
    // },
    // inputTitleContainer: {
    //     marginTop: '20%'
    // },
    // titleCotainer: {
    //     marginLeft: '10%'
    // },
    // container: {
    //     display: "flex",
    // },
    // img: {
    //     display: "flex",
    //     width: '50%',
    //     padding: 0,
    //     marginLeft: '-1%',
    //     marginTop: '-1%'
    // },
    // inputContainer: {
    //     marginTop: '5%',
    //     marginLeft: '10%',
    //     width: '100%',
    //     height: '100%'
    // },
    // title: {
    //     fontWeight: 'bold',
    //     fontSize: 48,
    //     fontFamily: 'Poppins',
    // },
    // input: {
    //     fontFamily: 'Poppins',
    // }
});

export default LoginForm;