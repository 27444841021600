import React, { useState, Fragment } from 'react';
import AcademicSetup from 'components/system-admin/roles/academic-setup';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const NewRolesList = () => {
    return (
        <Fragment>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -10,
                    marginBottom: 10,
                    marginLeft: -30,
                    marginRight: -30,
                }}
            >
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }}
                >
                    System Admin
                </Link>
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/systemadmin/roles"
                >
                    Roles
                </Link>

                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/systemadmin/newrole"
                >
                    New Role and Permissions
                </Link>
            </Breadcrumbs>
            <div style={{ marginTop: '1em' }}>
                <AcademicSetup></AcademicSetup>
            </div>
        </Fragment >
    )
};

export default NewRolesList;