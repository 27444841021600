import _ from 'lodash';
import { parseJWT } from 'helpers';
import Storage from './storage';

class Session {
    constructor() { }

    createSession(data) {
        if (!data || _.isEmpty(data)) { return null; }

        const accessJwt = data?.accessToken;
        const refreshJwt = data?.refreshToken;

        const parsedAccessToken = accessJwt && parseJWT(accessJwt);
        const parsedRefreshToken = refreshJwt && parseJWT(refreshJwt);

        const accessToken = { expiryTime: parsedAccessToken?.exp, jwt: accessJwt };
        const refreshToken = { expiryTime: parsedRefreshToken?.exp, jwt: refreshJwt };

        const sessionData = _.omitBy({
            accessToken: !_.isEmpty(accessToken) ? accessToken : null,
            refreshToken: !_.isEmpty(refreshToken) ? refreshToken : null,
            profile: parsedAccessToken?.profile,
            orgID: parsedAccessToken?.orgID,
            groups: parsedAccessToken?.groups,
            appState: !_.isEmpty(parsedAccessToken?.appState) ? parsedAccessToken?.appState : null,
            permissions: !_.isEmpty(data.view) ? data.view : null
        }, _.isNil);

        Storage.set(process.env.REACT_APP_SESSION_KEY, JSON.stringify(sessionData));
    }

    // TODO: check expired jwt
    isExpired() {
        return true;
    }


    check() {
        const authSession = Storage.get(process.env.REACT_APP_SESSION_KEY);


        if (!authSession) { return false; }

        const data = JSON.parse(authSession);

        if (Date.now() >= data.accessToken.expiryTime * 1000) {
            Storage.delete(process.env.REACT_APP_SESSION_KEY);
            return false;
        }

        return (data.accessToken.expiryTime * 1000) - Date.now();

    }



    create = (data) => {
        Storage.set(process.env.REACT_APP_SESSION_KEY, JSON.stringify(data));
    }


    getOrg() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return { orgID: sessionData?.orgID }
    }

    getSession() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return Object.assign({}, sessionData);
    }

    getToken() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return _.pick(sessionData, ['accessToken', 'refreshToken']);
    }

    getGroups() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return _.pick(sessionData, ['groups']);
    }

    getProfile() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return _.pick(sessionData, ['profile']);
    }

    getAppState() {
        const sessionData = JSON.parse(Storage.get(process.env.REACT_APP_SESSION_KEY));
        if (!sessionData) {
            return null;
        }

        if (sessionData?.accessToken?.jwt === undefined) {
            return null;
        }

        return _.pick(sessionData, ['appState']);
    }

    removeSession() {
        Storage.delete(process.env.REACT_APP_SESSION_KEY);
    }

    BearerToken = () => {
        const { accessToken } = this.getToken();
        return `Bearer ${accessToken.jwt}`
    }

}

const session = new Session();

export default session;