import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLocation } from "react-router-dom";
import eduAPI from "../../../api/api";
import moment from 'moment';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from '@material-ui/icons/GetApp';
import Session from 'services/local/session';

/**  
 * Announcement View Component Creation  
 */
const AnnouncementView = () => {
    const classes = useStyles();
    const location = useLocation();
    const [announcement, setAnnouncement] = useState({})
    const [attachements, setAttachements] = useState([])

    useEffect(() => {
        getAnnouncementInfo()
    }, [location]);

    const getAnnouncementInfo = async () => {
        // location.state.id
        try {
            await eduAPI.get("/announcement/byKey/" + location.state.id, {
                headers: {
                    Authorization: Session.BearerToken()
                }
            }).then((data) => {
                setAnnouncement(data.data.data)
                if (data.data.data.announcement_attachments) {
                    setAttachements(data.data.data.announcement_attachments)
                }
            });
        } catch (error) {
            console.error("fetch annoucement error", error);
        }
    }

    const downloadFile = (obj) => {
        window.open(obj.fileUrl, "_blank");
    }

    return (
        <div className={classes.container}>

            <div style={{ marginBottom: '10px' }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" />}
                    style={{ marginLeft: 10, marginTop: 10 }}
                >
                    <Link
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            color: "black",
                        }}
                        href="/announcement"
                    >
                        Announcements
        </Link>
                    <Link
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            color: "black",
                        }}

                    >
                        {location.state.type ? location.state.type : 'View'}
                    </Link>
                </Breadcrumbs>
            </div>

            <Paper
                className={classes.paper2}
                style={{ borderRadius: 10, width: '100%', padding: '20px', marginTop: '10px' }}>

                <Grid container>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{announcement.subject}</div>
                        <div style={{ fontSize: '16px' }}>
                            from {announcement?.from?.name}
                        </div>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        {moment(announcement.createdAt).format('MMMM DD,YYYY | HH:mm a')}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: announcement.description
                            }}></div>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} style={{ marginTop: '10px' }}>
                        <>{attachements.length > 0 && <div className={classes.subHdr}>Attached Documents</div>}</>
                        {attachements.map((fileObj) =>
                            <>
                                <List style={{
                                    border: '1px solid #ddd', margin: '5px', color: '#756F86', background: '#DBE2EA', fontSize: '20px'
                                }}>
                                    <ListItem button onClick={() => downloadFile(fileObj)}>
                                        <ListItemText primary={fileObj.fileName} style={{ color: "#6386af", fontSize: '18px' }} />
                                        <GetAppIcon />
                                    </ListItem>
                                </List>
                            </>
                        )}

                    </Grid>
                </Grid>
            </Paper>
        </div >
    )
}


/**
 * Styles
 */

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2%'
    },
    subHdr: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#FDB714'
    },
    file: {
        border: '1px solid #ddd', margin: '5px', width: '30%', padding: '15px 10px', color: '#756F86', background: '#DBE2EA', fontSize: '20px'
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        flexDirection: 'row',
        display: 'flex'
    },
    header: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '10%',
        flexDirection: 'column',
        display: 'flex'
    },
    title: {
        color: 'dark blue grad'

    },
    container: {
        // marginTop: '2%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%',
        paddingBottom: '5%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {
        height: '100vh',
        padding: '2em 0em'
    },
    button: {
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 60,
        padding: '0 30px',
        width: "20%",

    },
    paper: {
        display: 'flex',
        height: 140,
        width: 100
    },
    paper2: {
        display: 'flex',
        width: 100
    },
    control: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

export default withRouter(AnnouncementView);