import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useSession from '../../../hooks/use-session';

import ProfileAction from '../../../redux/actions/profile';

// Components
import View from './select-profile.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status, 
        resErr: state.profile?.error,
        profiles: state.profile?.list,
    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetch$: (type, orgID) => {
            profileActions.getProfilesByType(type, orgID);
        }
    }
}

const Container = ({ 
    profileID, 
    profileType, 
    status, 
    resErr, 
    profiles, 
    onFetch$, 
    onChange, 
    onReset$, 
    error,
    errorLabel
}) => {
    const session = useSession();

    useEffect(() => {
        if (session.orgID) {
            onFetch$(profileType, session.orgID);
        }
    }, [session.orgID]);

    return (
        <View 
            profileID={profileID}
            status={status} 
            resErr={resErr} 
            profiles={profiles} 
            onChange={onChange} 
            error={error}
            errorLabel={errorLabel}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
