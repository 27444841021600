import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useToaster from 'hooks/use-toaster';
import _ from 'lodash';

import normalize from 'utils/normalizers'
import { schema } from './departments-assign.validation';

// Components
import Button from 'components/common/button';
import SelectDepartment from 'components/common/select-department';

const DepartmentAssign = ({ status, onAssign, onCloseModal, onResetStatus }) => {
    const { t } = useTranslation();
    const { onOpenToaster, Toaster } = useToaster({ message: t('toaster.assigned_successfully') });

    const [input, setInput] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (status.assign === 'loading') {
            setLoading(true);
        }
        else if (status.assign === 'success') {
            onCloseModal();
            onOpenToaster();
            
            onResetStatus();
        }
        else {
            setLoading(false);
        }
    }, [status.assign]);

    const onSubmit = async () => {
        try {
            const value = await schema.validateAsync(input);
            if (!_.isEmpty(value) && onAssign) {
                onAssign(value);
            }
        }
        catch(err) {
            console.log(err);

            const { key, value } = normalize.error.validation(err);
            setError({ ...error, [key]: value });

            if (document.querySelector(`*[name=${key}]`)) {
                document.querySelector(`*[name=${key}]`).focus();
            }
        }
    }

    return (
        <Fragment>
            <Toaster/>

            <SelectDepartment onSelect={(value) => {
                setInput({ ...input, departmentID: value });
                setError({});
            }}/>

            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
                <Button 
                    style={{ width: 130 }}
                    type="primary"
                    label={t('label.done')} 
                    isLoading={loading}
                    onClick={onSubmit}
                />
            </div>
        </Fragment>
    )
}

export default DepartmentAssign
