import React, { useRef, useState } from "react";
import Button from 'components/common/button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import AlertBox from 'components/common/alert-box';
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  errorCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [error, setError] = useState('');

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
    setError('');
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
      else {
        setError(`Cannot attach document size more than ${maxFileSizeInBytes} bytes`);
        break;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };


  return (
    <>
      <div>
        {error && error.length !== 0 &&
          <div style={{ padding: '10px 0' }}>
            <AlertBox type="error" message={error}/>
          </div>
        }

        <Button
          label="Attach Documents"
          type="primary"
          size="large"
          onClick={handleUploadBtnClick}
        />

        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </div>
      <div style={{ marginTop: '10px' }}>

        <div>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            // let isImageFile = file.type.split("/")[0] === "image";
            return (
              <Grid container key={index}>
                <Grid item lg={4} md={4} sm={4} xs={12} key={fileName}>
                  {index == 0 && <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#FDB714' }}>Selected Files</span>}
                  <List style={{
                    border: '1px solid #ddd', margin: '5px', color: '#756F86', background: '#DBE2EA', fontSize: '20px'
                  }}>
                    <ListItem button >
                      <ListItemText primary={file.name} style={{ color: "#6386af", fontSize: '18px' }} />
                      <ListItemText primary={convertBytesToKB(file.size) + 'kb'} />
                      <CloseIcon onClick={() => removeFile(fileName)} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FileUpload;
