import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthAction from 'redux/actions/auth';

// Components
import LoginForm from './login-form.view';
import { parseJWT } from 'helpers';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const authActions = bindActionCreators(AuthAction, dispatch);

    return {
        onResetStatus$: () => {
            authActions.resetStatus();
        },
        onLogin$: (payload) => {
            authActions.login(payload);
        }
    }
}

const Container = ({ status, error, onResetStatus$, onLogin$ }) => {
    const history = useHistory();

    useEffect(() => {

        if (status.login === 'success' && status?.data?.access?.includes('access_web')) {
            history.push('/account/students');
            setTimeout(() => {
                onResetStatus$()
            }, 1000);
        }


        // if (status.login === 'success' && status?.data?.tokenData?.profile?.type == 'ADMIN') {
        //     history.push('/account/students');
        //     setTimeout(() => {
        //         onResetStatus$()
        //     }, 1000);
        // }
    }, [status.login]);

    const onLogin = (data) => {
        onLogin$(data);
    }

    return (
        <LoginForm onLogin={onLogin} status={status} resErr={error.login} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
