import React from 'react';

import Announcement from 'components/announcement/announcement-overview/';

const AnnouncementOverview = () => {
    return (
        <Announcement/>
    )
};

export default AnnouncementOverview;