import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import countries from '../../../utils/countries.json';

// Components
import Grid from '@material-ui/core/Grid';
import { Input, Select } from '../form-unit';

const Address = ({ input, error, onChange, additionalProps }) => {
    const { t } = useTranslation();

    return (
        <Grid container={true}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Input
                    label="Street 1"
                    name="street1"
                    placeholder={t('placeholder.street_1')}
                    required={true}
                    value={input?.street1 || ''}
                    error={error?.street1}
                    onChange={onChange}
                    {...additionalProps?.street1}
                />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Input
                    label="Street 2"
                    name="street2"
                    placeholder={t('placeholder.street_2')}
                    value={input?.street2 || ''}
                    error={error?.street2}
                    onChange={onChange}
                    {...additionalProps?.street2}
                />
            </Grid>

            <Grid item container={true} spacing={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Input
                        label="Postcode"
                        name="postcode"
                        placeholder={t('placeholder.postcode')}
                        value={input?.postcode || ''}
                        error={error?.postcode}
                        required={true}
                        onChange={onChange}
                        {...additionalProps?.postcode}
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Input
                        label="City"
                        name="city"
                        placeholder={t('placeholder.city')}
                        value={input?.city || ''}
                        error={error?.city}
                        required={true}
                        onChange={onChange}
                        {...additionalProps?.city}
                    />
                </Grid>
            </Grid>

            <Grid item container={true} spacing={3}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Input
                        label="State"
                        name="state"
                        placeholder={t('placeholder.state')}
                        value={input?.state || ''}
                        error={error?.state}
                        required={true}
                        onChange={onChange}
                        {...additionalProps?.state}
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Select
                        label="Country"
                        name="country"
                        value={input?.country || ''}
                        error={error?.country}
                        required={true}
                        onChange={onChange}
                        options={_.map(countries, (o) => {
                            return { label: o.name, value: o.iso }
                        })}
                        {...additionalProps?.country}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Address;