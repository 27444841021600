import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// Services
import { useGetClassesAttendanceQuery } from 'services/api/attendance';

// Components
import { withStyles } from '@material-ui/core/styles';

import AttendanceClass from './attendance-class';
import AttendanceHighAbsentee from './attendance-high-absentee';

import Card from 'components/common/card';
import Button from 'components/common/button';

import PlusIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'black',
        // border: '1px solid green',
        background: 'white',
        boxShadow: '0 0 1.5px 1.5px rgba(0, 0, 0, 0.1)',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,

        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        top: '0px',
        backgroundColor: 'transparent',
        height: '5px',

        '& > span': {
            width: '100%',
            backgroundColor: '#4DC591',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const TabPanel = ({ children, value, index, ...props }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {value === index && (
                <Paper>
                    <Box p={3}>
                        {children}
                    </Box>
                </Paper>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `styled-tab-${index}`,
        'aria-controls': `styled-tabpanel-${index}`,
    };
}

const Index = ({ }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [tabKey, setTabKey] = useState(0);
    const [data, setData] = useState();

    const onTabChange = (_, key) => {
        setTabKey(key);
    }



    return (
        <div style={{ marginTop: '1em' }}>
            <Grid container spacing={2}>
                <Grid item>
                    {data &&
                        <Card
                            title={t('title.total_students')}
                            body={t('txt.present')}
                            count={data?.presentStudents}
                            type="success"
                        />
                    }
                </Grid>

                <Grid item>
                    {data &&
                        <Card
                            title={t('title.total_students')}
                            body={t('txt.absent')}
                            count={data?.absentStudents}
                            type="danger"
                        />
                    }
                </Grid>

                <Grid item style={{ marginLeft: 'auto' }}>
                    <Button
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        label={t('action.set_messages')}
                        onClick={() =>
                            history.push({
                                pathname: '/masterlist',
                                state: { type: 'messages' }
                            })

                        }
                        type="primary"

                    />
                </Grid>
            </Grid>

            <div style={{ marginTop: '2em' }}>
                <StyledTabs value={tabKey} onChange={onTabChange}>
                    <StyledTab label={t('label.classes')} {...a11yProps(0)} />
                    <StyledTab label={t('label.high_absentees')} {...a11yProps(1)} />
                </StyledTabs>
            </div>

            <TabPanel value={tabKey} index={0}>
                <AttendanceClass getData={(dataParam) => setData(dataParam)} />
            </TabPanel>
            <TabPanel value={tabKey} index={1}>
                <AttendanceHighAbsentee />
            </TabPanel>
        </div>
    )
}

export default Index
