import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import enc from 'helpers/enc';
import useToaster from 'hooks/use-toaster';
// Components
import useStyles from './style';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { Select, TextArea } from 'components/common/form-unit';

import Button from 'components/common/button';
import useSession from 'hooks/use-session';
import AlertBox from 'components/common/alert-box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button1 from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { Doughnut } from 'react-chartjs-2';

import Modal from 'components/common/modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { uniq } from 'lodash';


const GridBodyCard = ({ grids }) => {
    const cls = useStyles();

    return (
        <Grid container={true} justify="space-between">
            {!_.isEmpty(grids) && _.map(grids, (o, i) => {
                if (o.type === 'important') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': 'black' }} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    <span data-type={o.type} >
                                        {o.value}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    )
                }
                else if (o.type === 'information') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': '#756F86' }} data-type={o.type} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    <span data-type={o.type}>
                                        {o.value}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    )
                }
            })
            }
        </Grid>
    )
}

const ParentSection = ({ data, isLoading }) => {
    const cls = useStyles();

    const fatherData = _.find(data, o => o.profileType === 'FATHER');
    const motherData = _.find(data, o => o.profileType === 'MOTHER');

    return (
        <div className={cls.card} style={{ backgroundColor: 'white' }}>
            <div className={cls.card__title}>
                <span data-type="information">Parent Information</span>
            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && fatherData === undefined && motherData === undefined &&
                    <div>Parents Information Not Provided</div>
                }

                {!isLoading && (!_.isEmpty(fatherData) || !_.isEmpty(motherData)) &&
                    <GridBodyCard
                        grids={[
                            {
                                type: 'information',
                                value: fatherData ? fatherData?.fullName : '-',
                                title: 'Father'
                            },
                            {
                                type: 'information',
                                value: motherData ? motherData?.fullName : '-',
                                title: 'Mother'
                            },
                            {
                                type: 'information',
                                value: fatherData ? fatherData?.idNo : '-',
                                title: 'National ID',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                value: motherData ? motherData?.idNo : '-',
                                title: 'National ID',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                value: fatherData ? fatherData?.fullNumber : '-',
                                title: 'Mobile No.',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                value: motherData ? motherData?.fullNumber : '-',
                                title: 'Mobile No.',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                value: fatherData ? fatherData?.emailAddress : '-',
                                title: 'Email',
                                style: { marginTop: '1em' }
                            },
                            {
                                type: 'information',
                                value: motherData ? motherData?.emailAddress : '-',
                                title: 'Email',
                                style: { marginTop: '1em' }
                            },
                        ]}
                    />
                }
            </div>
        </div>
    )
}

const EmergencyContact = ({ data, isLoading }) => {
    const cls = useStyles();

    return (
        <div className={cls.card} style={{ backgroundColor: '#EBC8C4' }}>
            <div className={cls.card__title}>
                <span data-type="important">Emergency Contacts</span>
            </div>

            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && !_.isEmpty(data) &&
                    <GridBodyCard
                        grids={[
                            {
                                type: 'important',
                                value: data?.emergencyContactName,
                                title: 'Name'
                            },
                            {
                                type: 'important',
                                value: data?.emergencyContactRelationship,
                                title: 'Relationship with Student'
                            },
                            {
                                type: 'important',
                                value: `+${data?.emergencyContactFullNumber}`,
                                title: 'Mobile No.',
                                style: { marginTop: '1em' }
                            }
                        ]}
                    />
                }
            </div>
        </div>
    )
}

const AttandanceSection = ({ data, isLoading }) => {
    const cls = useStyles();


    let dataObj = {
        labels: ['Present', 'Absent', 'Absent with Reason'],
        datasets: [
            {
                label: '# of Votes',
                data: [data.presentCount ? data.presentCount : 0,
                data.absentCount ? data.absentCount : 0,
                data.absentWithReasonCount ? data.absentWithReasonCount : 0
                ],
                backgroundColor: ["#141824", "#DA0F0F", "#FF5400"],
                borderColor: ["#141824", "#DA0F0F", "#FF5400"],
                borderWidth: 1,
                legend: false
            },
        ]
    };

    return (
        <div className={cls.card} style={{ backgroundColor: 'white', width: '100%' }}>
            <div className={cls.card__title}>
                <span data-type="information">Attendance</span>
            </div>

            <div>
                <p style={{ color: "#141824", marginTop: '10px', fontSize: '18px' }}>Satisfactory</p>
                <div>
                    <Rating name="size-medium" defaultValue={data.overallAttendanceStars} readOnly />
                </div>
            </div>
            <div className={cls.card__body}>
                {isLoading && <div>loading...</div>}

                {!isLoading && data === undefined &&
                    <div>Attandance Information Not Provided</div>
                }

                {!isLoading && (!_.isEmpty(data)) &&
                    <Grid container={true}>
                        <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                            <div>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={{ background: '#141824' }}>{data.presentCount}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Present" secondary="" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={{ background: '#DA0F0F' }}>{data.absentCount}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Absent" secondary="" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={{ background: '#FF5400' }}>{data.absentWithReasonCount}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Absent with Reason" secondary="" />
                                </ListItem>
                            </div>
                        </Grid>
                        <Grid container={false} item={false} xl={4} lg={4} md={12} sm={12} xs={12} spacing={2}
                            style={{ position: 'relative' }}
                        >
                            <Doughnut
                                data={dataObj}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    }
                                }}
                            />
                            {data.overallAttendancePercentage ?
                                <span
                                    style={{
                                      position: 'absolute',
                                      top: 'calc(50% - 50px / 2)',
                                      left: 'calc(50% - 100px / 2)',
                                      height: 50,
                                      width: 100,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >{data.overallAttendancePercentage}%</span> : '0'}
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    )
}


const AcademicSection = ({ data, isLoading, profileInfo, onCloseModalPopup, onFetchProfile, onReset, attachmentsInfo, updateAcademicStatus, status, resErr }) => {

    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const cls = useStyles();
    const session = useSession();
    const [academicStatusPopup, setAcademicStatusPopup] = React.useState(false);
    const [selStatus, setSelStatus] = useState('');
    const [selObj, setSelObj] = useState({})

    const [note, setNotes] = useState('')

    let statusOptions = [
        { label: 'ONGOING', value: 'ONGOING' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'FAILED', value: 'FAILED' },
        { label: 'REPEATED', value: 'REPEATED' }
    ]
    const [hide, setHide] = useState(true)
    useEffect(() => {
        if (status.academicStatus == 'success') {
            setHide(false)
            setToastMsg("Academic Status Updated Successfully...")
            onOpenToaster();
            setTimeout(() => {
                onReset()
                setAcademicStatusPopup(false);
                onCloseModalPopup();
            }, 2000);
        }
    }, [status])

    const statusSelect = (ev, rowObj) => {
        window.scrollTo(0, 0)
        if (ev.target.value == "FAILED") {
            setSelStatus(ev.target.value)
            setSelObj(rowObj)
            academicStatusPopupOpen()
        } else {
            let obj = {
                statusID: rowObj.statusID,
                status: ev.target.value,
                notes: note ? note : '',
                updatedByID: session.profile._key
            }
            updateAcademicStatus(obj)
        }
    }

    const academicStatusPopupOpen = () => {
        setAcademicStatusPopup(true);
    }
    const handleCloseEdit2 = () => {
        setAcademicStatusPopup(false);
    };

    const closePopup = () => {
        setAcademicStatusPopup(false);
    };

    const confirmAcademicStatus = () => {
        let obj = {
            statusID: selObj.statusID,
            status: selStatus,
            notes: note ? note : '',
            updatedByID: session.profile._key
        }
        updateAcademicStatus(obj)
    }

    return (
        <>
            <Toaster />
            <div className={cls.card} style={{ backgroundColor: 'white' }}>
                <div className={cls.card__title}>
                    <span style={{ textDecoration: 'underline' }}>Academic Status</span>
                </div>

                <div className={cls.card__body}>
                    {hide ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Year</TableCell>
                                        <TableCell>Class</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Date Updated</TableCell>
                                        <TableCell>Updated By</TableCell>
                                        <TableCell>Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading && <TableCell colSpan="6" align="center">loading...</TableCell>}

                                    {_.isEmpty(data) && <TableCell colSpan="6" align="center">No Data</TableCell>}

                                    {!isLoading && !_.isEmpty(data) && _.map(data, (o, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{o.year || '-'}</TableCell>
                                            <TableCell>{o.class || '-'}</TableCell>
                                            <TableCell>
                                                {/* <span style={{ fontStyle: 'italic' }}>
                                            {_.upperFirst(_.toLower(o.status))}
                                        </span> */}
                                                <Select
                                                    name="gender"
                                                    value={o.status || ''}
                                                    onClick={(ev) => statusSelect(ev, o)}
                                                    options={statusOptions}
                                                />
                                            </TableCell>
                                            <TableCell>{moment(o.updatedAt).format('DD.MM.YYYY') || '-'}</TableCell>
                                            <TableCell>{o.updatedBy?.length !== 0 ? o.updatedBy : '-'}</TableCell>
                                            <TableCell>{o.notes || 'No information provided'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : ''}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={cls.modal}
                    showFooter={false}
                    open={academicStatusPopup}
                    onClose={closePopup}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={academicStatusPopup}>
                        <div className={[cls.modalHeight].join(' ')} >
                            <h2 id="transition-modal-title" style={{ textAlign: 'center' }}>Academic Status</h2>
                            <div style={{ padding: '0px 10px', fontFamily: 'Poppins' }}>

                                <TextArea
                                    label="Add Note"
                                    name="description"
                                    placeholder="Please specify here"
                                    required={true}
                                    value={note || ''}
                                    multiline={true}
                                    onChange={(e) => {
                                        setNotes(e.target.value)
                                    }}
                                    rows={5}
                                />

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                    <Button1 style={{
                                        background: '#FF0000',
                                        border: 5,
                                        borderWidth: 10,
                                        borderColor: '#25335A',
                                        textTransform: 'capitalize',
                                        borderRadius: 10,
                                        color: 'white',
                                        letterSpacing: '1px',
                                        height: 40
                                    }}
                                        onClick={confirmAcademicStatus}
                                    >
                                        Done
                                </Button1>
                                </div>
                                <div
                                    style={{ textAlign: 'center', color: '#0172B1', textDecoration: 'underline', marginTop: '10px', marginBottom: '10px' }}
                                    onClick={() => closePopup()}
                                > CANCEL, return to Student Profile
                            </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}


const View = ({ data, status, resErr, onReset, onFetch, onCloseModalPopup, activeStatus, inActiveStatus, updateAcademicStatus }) => {
    const history = useHistory();
    const cls = useStyles();
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canSeeAcadResult = (uniquePermissions || []).includes("v_acc_students_academic_status");
    const canToggleStatus = (uniquePermissions || []).includes("v_acc_students_toggle_status");
    const canUpdateDetails = (uniquePermissions || []).includes("v_acc_students_update");

    useEffect(() => {
        if (status.fetch === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }

        if (status.statusUpdate == 'success') {
            setAnchorEl(null);
            onCloseModalPopup();
        }
        if (status.statusUpdate == 'failed') {
            setError(status.data)
            setErrorStatus(true)
            setAnchorEl(null);
            setTimeout(() => {
                setError('')
                setErrorStatus(false)
                onReset()
            }, 5000);
        }
    }, [status]);

    const { profile = {}, guardians = [], status: academicStatus = [], attachments = [], attendance = [] } = data;

    const updateStatus = (key) => {

        let obj = {
            "profileID": data.profile.profileID
        }
        if (key === 'active') {
            activeStatus(obj);
        } else if (key == 'inactive') {
            inActiveStatus(obj);
        }

    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container className={cls.container}>
            <Grid container={true} spacing={2}>
                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} xl={2} lg={2} md={2} sm={2} xs={2}>
                        <IconButton component="span">
                            <Avatar
                                alt="Profile Picture"
                                src={data?.attachments?.profile_picture?.fileUrl}
                                style={{ width: '90px', height: '90px' }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item={true} xl={4} lg={4} md={4} sm={4} xs={4} style={{ margin: 'auto', marginLeft: '10px' }}>
                        <h2>{profile?.firstName + ' ' + profile?.lastName}</h2>
                    </Grid>
                    {canToggleStatus?<Grid item={true} xl={4} lg={4} md={4} sm={4} xs={4} style={{ margin: 'auto', marginLeft: '10px' }}>
                        <div>
                            <Button1 aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                {profile?.profileStatus}
                            </Button1>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => updateStatus('active')}
                                >Active</MenuItem>
                                <MenuItem onClick={() => updateStatus('inactive')}>Inactive</MenuItem>
                            </Menu>
                        </div>
                    </Grid>:''}
                </Grid>
                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2} style={{ margin: 'auto', marginLeft: '10px' }}>
                    <div style={{ marginTop: '.5em' }} ref={ref} tabIndex={-1}>
                        {errorStatus &&
                            <AlertBox icon={false} type="error" message={error} />
                        }
                    </div>
                </Grid>

                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} container xl={12} lg={12} md={12} sm={12} xs={12}
                        style={{width: '100%'}}
                    >
                        <AttandanceSection data={attendance} isLoading={loading} />
                    </Grid>
                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <EmergencyContact isLoading={loading} data={profile?.emergencyContact} />
                    </Grid>
                </Grid>
                <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className={cls.card} style={{ backgroundColor: 'white' }}>
                            <div className={cls.card__title}>
                                <span>Student Information</span>
                                {canUpdateDetails?
                                    <Button label="Edit" onClick={() => history.push(`/account/students/update/${enc.encode(profile.profileID)}`)} />
                                :''}
                            </div>

                            <div className={cls.card__body}>
                                {loading && <div>loading...</div>}

                                {!loading && !_.isEmpty(profile) &&
                                    <GridBodyCard
                                        grids={[
                                            {
                                                type: 'information',
                                                title: 'Name',
                                                value: profile?.fullName
                                            },
                                            {
                                                type: 'information',
                                                title: 'Date Of Birth',
                                                value: moment(profile?.dob).format('DD/MM/YYYY')
                                            },
                                            {
                                                type: 'information',
                                                title: profile?.idType === 'NRIC' ? 'National ID' : 'Passport No',
                                                value: profile?.idNo,
                                                style: { marginTop: '1em' }
                                            },
                                            {
                                                type: 'information',
                                                title: 'Religion',
                                                value: _.upperFirst(_.toLower(profile?.religion)),
                                                style: { marginTop: '1em' }
                                            },
                                            {
                                                type: 'information',
                                                title: 'Gender',
                                                value: _.upperFirst(_.toLower(profile?.gender)),
                                                style: { marginTop: '1em' }
                                            },
                                            {
                                                type: 'information',
                                                title: 'Prior School',
                                                value: _.upperFirst(_.toLower(profile?.priorSchool)) || 'Information not provided',
                                                style: { marginTop: '1em' }
                                            },
                                            {
                                                type: 'information',
                                                title: 'Allergy',
                                                value: _.upperFirst(_.toLower(profile?.medicalCondition)) || 'Information not provided',
                                                style: { marginTop: '1em' }
                                            }
                                        ]}
                                    />
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <ParentSection data={guardians} isLoading={loading} />
                    </Grid>
                </Grid>
            </Grid>

        {canSeeAcadResult?
            <Grid container={true} style={{ marginTop: '1em', marginBottom: '2em' }}>
                <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <AcademicSection
                        onReset={onReset}
                        status={status}
                        resErr={resErr}
                        data={academicStatus}
                        isLoading={loading}
                        profileInfo={profile}
                        attachmentsInfo={attachments}
                        onFetchProfile={onFetch}
                        onCloseModalPopup={onCloseModalPopup}
                        updateAcademicStatus={updateAcademicStatus} />
                </Grid>
            </Grid>
        :''}
        </Container>
    )
}

export default View;