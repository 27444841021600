import React, { createContext, useState } from "react";

export const TimetableTemplateContext = createContext({});

const TimetableContext = ({ children }) => {
  const [timetableTemplates, setTimetableTemplates] = useState()
  

  return (
    <TimetableTemplateContext.Provider value={[timetableTemplates, setTimetableTemplates]}>
      {children}
    </TimetableTemplateContext.Provider>
  );
};

export default TimetableContext;
