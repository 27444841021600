import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileAction from 'redux/actions/profile';
import SystemAdminRoleAction from '../../../redux/actions/system-admin/system-admin-role';

// Components
import View from './user-details.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        profile: state.profile?.data,

    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);
    const SystemAdminRoleActions = bindActionCreators(SystemAdminRoleAction, dispatch);

    return {
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetch$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        },
        onConfirmUnAssign$: (data) => {
            SystemAdminRoleActions.confirmUnAssign(data);
        },
        inActiveStatus$: (data) => {
            profileActions.updateStatusToInactive(data);
        },
        activeStatus$: (data) => {
            profileActions.updateStatusToActive(data);
        }
    }
}

// const Container = ({ status, error, profiles, onFetch$ }) => {
const Container = ({ profileID, status, error, profile, onFetch$, onReset$, activeStatus$, onConfirmUnAssign$, onCloseModal, inActiveStatus$ }) => {
    useEffect(() => {
        if (profileID) { onFetch$(profileID); }
    }, [profileID]);

    const onConfirmUnAssign = (data) => {
        onConfirmUnAssign$(data)
    }

    const inActiveStatus = (data) => {
        inActiveStatus$(data)
    }

    const activeStatus = (data) => {
        activeStatus$(data)
    }


    return (
        <View
            data={profile}
            onFetch={onFetch$}
            activeStatus={activeStatus}
            inActiveStatus={inActiveStatus}
            status={status}
            resErr={error}
            onReset={onReset$}
            onConfirmUnAssign={onConfirmUnAssign}
            onCloseModal={onCloseModal} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);