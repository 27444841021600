import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import useStyles from './style';
import Button from '../button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography'
import Grow from '@material-ui/core/Grow';

const Modal = ({ 
    title, 
    showFooter, 
    children, 
    fullScreen, 
    fullWidth,
    maxWidth,
    open, 
    onHandleClose,
    scroll,
    onOk,
    onCancel,
    ...props 
}) => { 
    // Were using dialog MUI here
    const cls = useStyles();
    const { t } = useTranslation();

    return (
        <Dialog 
            classes={{
                paperFullScreen: cls.paperFullScreen,
                paper: cls.paper
            }}
            open={open} 
            onClose={onHandleClose} 
            fullScreen={fullScreen} 
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            TransitionComponent={Grow}
            scroll={scroll}
            {...props}
        >
            <DialogTitle className={cls.modal__title} disableTypography={true}>
                {!title || title.length !== 0 &&
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{title}</Typography>
                }
            </DialogTitle>

            
            {onHandleClose &&
                <IconButton className={cls.closeBtn} onClick={onHandleClose}>
                    <CloseIcon />
                </IconButton>
            }    
            
            <DialogContent>
                {children}
            </DialogContent>

            {showFooter &&
                <DialogActions>
                    <Button label={t('action.cancel')} onClick={onCancel} />
                    <Button label={t('action.okay')} onClick={onOk} type="primary" />
                </DialogActions>
            }
        </Dialog>
    )
}

Modal.defaultProps = {
    title: '',
    fullScreen: false,
    showFooter: true,
    fullWidth: false,
    maxWidth: 'sm',
    open: false,
    onHandleClose: null,
    scroll: 'body',
    onCancel: () => {},
    onOk: () => {}
}

export default Modal
