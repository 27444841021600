import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        background: '-webkit-linear-gradient(#25335A, #141824)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fontSize: 21,
        fontWeight: 300
    },
    card__default: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #2C9009',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__primary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid #4e79cf',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    card__secondary: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '100%',
        height: '125px',
        borderLeft: '10px solid orange',
        borderRadius: 10,
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, .1)',
        padding: '10px 0'
    },
    icon: {
        width: '100px',
        height: 'auto',
        flex: 1
    },
    'typography-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,

        '& span': {
            fontSize: '18px',
            letterSpacing: .5
        },

        '& h1': {
            fontSize: '30px',
            fontWeight: 500
        }
    },
    table__wrapper: {
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        padding: '20px 30px',
        marginTop: '1em',
        width: '100%',
        height: '100vh'
    },
    link: {
        textDecoration: 'none',
        fontSize: '19px',
        color: '#0b103b',

        '&:hover': {
            color: '#212b80'
        }
    },
    name: {
        color: 'rgba(1, 114, 177, 1)',
        cursor: 'pointer',
        textDecoration: 'underline',
        transition: 'all 200ms',

        '&:hover': {
            color: 'rgba(90, 114, 177, 1)'
        }
    }
}));

export default useStyles;