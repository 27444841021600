import React from 'react';
import { useGetMessagePreviewQuery } from 'services/api/message';
import { useTranslation } from 'react-i18next';

// Assets
import BorderRoundedSVG from 'assets/img/border-rounded.svg'
import WarningIcon from 'assets/img/warning-icon.svg'

// Components
import Loader from 'components/common/loader';
import Button from 'components/common/button';

const MessagesPreview = ({ data, onCloseModal }) => {
    const { t } = useTranslation();
    const { 
        data: dataPreview, 
        isLoading, 
        isFetching, 
    } = useGetMessagePreviewQuery(data);

    if (isLoading || isFetching) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Loader size="35px"/>
            </div>
        )
    }

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center' 
        }}>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                background: 'linear-gradient(180deg, #FFFFFF 0%, #EEF1F9 100%)',
                alignItems: 'center',
                width: 300,
                minHeight: 350,
                padding: '1em',
                borderRadius: '15px'
            }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ position: 'relative' }}>
                        <img src={BorderRoundedSVG} />
                        <img src={WarningIcon} style={{ 
                            position: 'absolute', 
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}/>
                    </div>
                </div>

                <h1>{dataPreview?.messageTemplateTypeString}</h1>

                <span style={{ marginTop: '1em', textAlign: 'center' }}>
                    {dataPreview?.messageTemplateContent}
                </span>
            </div>

            <div style={{ marginTop: '2em' }}>
                <Button
                    label={t('action.done')}
                    type="primary"
                    onClick={onCloseModal}
                    style={{ width: 130 }}
                />
            </div>
        </div>
    )
}

export default MessagesPreview
