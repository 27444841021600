import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   container: {
       padding: '20px'
   },
   title: {
       color: '#25335A'
   }
}))

export default useStyles;