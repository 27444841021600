import React from 'react';

// Components
import TextField from '@material-ui/core/TextField';
import Label from '@material-ui/core/InputLabel';
import InputError from '../input-error';
import useStyles from './style';

const Input = ({ 
    onChange, 
    label, 
    name, 
    value, 
    error, 
    errorLabel,
    inputLabelProps, 
    children, 
    required,
    placeholder,
    disabled,
    prefix,
    suffix,
    InputProps, 
    type,
    alignment,
    ...props 
}) => {
    const classes = useStyles(); 
    
    return (
        <div style={{ padding: '8px 0px' }}>
            {label && 
                <Label required={required} error={error} className={classes.label} focused={error}>
                    {label}
                </Label>
            }

            <TextField
                classes={{
                    root: classes.rootInput
                }}
                type={type}
                onChange={onChange}
                name={name}
                value={value}
                error={error ? true : false}
                placeholder = {placeholder}
                InputLabelProps={inputLabelProps}
                variant="outlined"
                disabled={disabled}
                required={required}
                InputProps={{
                    startAdornment: prefix,
                    endAdornment: suffix,
                    ...InputProps
                }}
                {...props}
            />

            <InputError label={label || errorLabel} error={error}/>
            
            {children}
        </div>
    )
}

Input.defaultProps = {
    type: 'text',
    onChange: () => {},
    label: null,
    inputLabelProps: {
        shrink: true
    },
    required: false,
    fullWidth: true,
    size: 'small'
}

export default Input
