import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ProfileCreateContext } from "../context/ProfileContext";

const ProfileComplete = () => {
  const context = useContext(ProfileCreateContext);
  const classes = useStyles();
  //   const [email, setEmail] = useState("email@email.com");
  const emailStr = context.emails.join(", ");
  return (
    <div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="large" />}
        style={{ marginLeft: 10, marginTop: 10 }}
      >
        <Link
          style={{
            fontSize: 24,
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "black",
          }}
        >
          Account
        </Link>
        <Link
          style={{
            fontSize: 24,
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "black",
          }}
          href="/account/students"
        >
          Student
        </Link>
        <Link
          style={{
            fontSize: 24,
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "black",
          }}
          href="/create-student"
        >
          New Registration
        </Link>
        <Link
          style={{
            fontSize: 24,
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "black",
          }}
          href="/complete"
        >
          Registration Complete
        </Link>
      </Breadcrumbs>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <img
            src={process.env.PUBLIC_URL + "/Component4.svg"}
            alt=""
            className={classes.icon}
          />
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.fontStyles}>
            Registration form submitted successfully.
          </Typography>
          <Typography className={classes.fontStyles}>
            <span style={{ color: "#FFC000" }}>Mobile Application </span>account
            created.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.fontStyles}>
            An email with login details has been.
          </Typography>
          <Typography className={classes.fontStyles}>
            sent to <span style={{ color: "blue" }}>{emailStr}</span>.
          </Typography>
        </div>

        <div className={classes.doneButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            component={Link}
            to="/complete"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  fontStyles: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  icon: {
    marginTop: "10%",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    borderLeft: "10px solid #5D518F",
    borderRadius: 10,
  },
  doneButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
  },

  doneButton: {
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
    height: 52,
    width: 175,
    color: "black",
    borderRadius: 15,
  },
});

export default ProfileComplete;
