import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { omitBy, isNil } from 'lodash';
import useSession from 'hooks/use-session';
// Services
import { useReasonStatusMutation } from 'services/api/attendance';

// Components
import { TextArea } from 'components/common/form-unit';
import Button from 'components/common/button';

const ReasonDetails = ({ type, data, onCloseModal }) => {
    const { t } = useTranslation();
    const [onUpdate$, { isLoading: isUpdating }] = useReasonStatusMutation();

    const [state, setState] = useState({ input: '', error: '', isRejected: false });
    const session = useSession();
    /*
        absentReasonID
        updateByID
        status
        comments
    */

    const onApprove = async () => {
        const payload = omitBy({
            status: 'APPROVED',
            absentReasonID: data.absentReasonID,
            updateByID:  session.profile._key
        }, isNil);

        await onUpdate$(payload);
        onCloseModal();
    }

    const onReject = () => {
        setState({ ...state, isRejected: true });
    }

    const onSubmit = async () => {
        const payload = omitBy({
            status: 'APPROVED',
            absentReasonID: data.absentReasonID,
            updateByID: data.updateByID,
            comments: state.input.length !== 0 ? state.input : null
        }, isNil);

        await onUpdate$(payload);
        onCloseModal();
    }

    return (
        <Fragment>
            {type === 'TEXT' &&
                <TextArea
                    rows={10}
                    style={{ marginTop: '1em' }}
                    value={data.reasonText}
                    inputProps={{ readOnly: true }}
                />
            }

            {type === 'IMAGE' &&
                <img src={data.reasonImage} style={{ width: '100%' }} />
            }

            {!state.isRejected &&
                <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'center', padding: 10 }}>
                    <Button
                        style={{ marginRight: '.5em' }}
                        label={t('label.reject')}
                        onClick={onReject}
                    />

                    <Button
                        style={{ marginLeft: '.5em' }}
                        type="primary"
                        label={t('label.approve')}
                        onClick={onApprove}
                        isLoading={isUpdating}
                    />
                </div>
            }

            {state.isRejected &&
                <Fragment>
                    <Button style={{ marginTop: '1em' }} label={t('label.rejected')} disabled={true} />

                    <div style={{ marginTop: '1em' }}>
                        <TextArea
                            label={t('label.submit_reason')}
                            rows={10}
                            value={state.input}
                            onChange={(e) => setState({ ...state, input: e.target.value })}
                        />
                    </div>

                    <div style={{ display: 'grid', placeItems: 'center' }}>
                        <Button
                            label={t('action.submit')}
                            type="primary"
                            onClick={onSubmit}
                            isLoading={isUpdating}
                        />
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

export default ReasonDetails;