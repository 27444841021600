import { createApi, fetchBaseQuery } from '@rtk-incubator/rtk-query';
import _ from 'lodash';

import normalize from 'utils/normalizers';
import Session from 'services/local/session';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
    // prepareHeaders: (headers, { getState }) => { // TODO need to put later
    //     const session = Session.BearerToken()
    // }

    prepareHeaders: (headers, { getState }) => {
        if (Session.BearerToken()) {
            headers.set('Authorization', Session.BearerToken());
        }
        return headers;
    },
});

export const messageApi = createApi({
    reducerPath: 'messages',
    baseQuery,
    entityTypes: ['Messages'],
    endpoints: (build) => ({
        getMessageTemplates: build.query({
            query: (orgID) => {
                if (orgID) {
                    return {
                        url: `/message/templates`,
                        params: { orgID }
                    }
                }
            },
            transformResponse: (res) => {
                let messages = [];
                _.map(res.data, o => {
                    messages.push(normalize.client.message(o));
                });

                return messages;
            },
            provides: (results) => {
                return results.map(({ messageTemplateID: id }) => ({ type: 'Messages', id }));
            }
        }),
        getMessagePreview: build.query({
            query: (data) => {
                return {
                    url: '/message/preview',
                    method: 'POST',
                    body: normalize.server.message(data),
                }
            },
            transformResponse: (res) => {
                return normalize.client.message(res.data);
            },
            provides: (_, { messageTemplateID: id }) => [{ type: 'Messages', id }]
        }),
        createMessage: build.mutation({
            query: (data) => ({
                url: '/message',
                method: 'POST',
                body: data
            }),
        }),
        editMessageTemplate: build.mutation({
            query: (data) => {
                return {
                    url: '/message',
                    method: 'PUT',
                    body: normalize.server.message(data)
                }
            },
            invalidates: (_, { messageTemplateID: id }) => [{ type: 'Messages', id }]
        })
    })
});

export const {
    useGetMessageTemplatesQuery,
    useGetMessagePreviewQuery,
    useEditMessageTemplateMutation,
    useCreateMessageMutation
} = messageApi;