import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import ResendEmailForm from '../components/auth-form/resend-email';

const ResendEmailPage = () => {
    const styles = useStyles();

    const [password, setPassword] = useState('');
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Resent Email</title>
            </Helmet>
            <ResendEmailForm />


        </div>
    )
}

const useStyles = makeStyles({

    container: {
        height: window.height,
        width: window.width,
        display: "flex",
        flexDirection: 'row'
    }
});

export default ResendEmailPage;