import React from 'react';
import View from './classes.view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MasterClassAction from '../../../redux/actions/master-class';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const mapStateToProps = (state) => {
    return {
        status: state.masterClass?.status,
        error: state.masterClass?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const MasterClassActions = bindActionCreators(MasterClassAction, dispatch);

    return {
        onReset$: () => {
            MasterClassActions.resetStatus();
        },
        onCreate$: (data) => {
            MasterClassActions.createClass(data);
        },

        onUpdate$: (data) => {
            MasterClassActions.updateClass(data);
        },

        getClasses$: () => {
            MasterClassActions.getClasses();
        }
    }
}

const Container = ({ status, error, onCreate$, onReset$, onUpdate$, getClasses$ }) => {
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canViewClass  = (uniquePermissions || []).includes('v_master_class_web');

    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdate = (data) => {
        onUpdate$(data)
    }
    const getClasses = () => {
        getClasses$()
    }
    const onReset = () => {
        onReset$()
    }
    return (
        canViewClass?<View status={status} resErr={error} onCreate={onCreate} onReset={onReset} onUpdate={onUpdate} getClasses={getClasses} />:''
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);;