import React, {Fragment, useEffect, useState} from 'react';
// import DateFnsUtils from '@date-io/date-fns';
import {KeyboardTimePicker,  MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {isEmpty} from 'lodash';
import moment from 'moment';


const Newtimetable = () => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [numberofSubjects, setNumberOfSubjects] = useState(0);
    const [gridHeaders, setGridHeaders] = useState([]);
    const [periods, setPeriods] = useState([0]);
    const [startDay, setStartDay] = useState(''); 
    const [startTime, setStartTime] = useState();
    const [duration, setDuration] = useState();
    const [anchorEl, setAnchorEl] = useState(null); 
    const [nestedOpen, setNestedOpen] = useState(false);
    const [showForm, setShowForm] = useState(true);
    

    useEffect(() => {
        let subjectList = [];

        for(let i = 0; i < numberofSubjects; i++){
            subjectList.push({"assigned": "assign", "color": "black"});
            
        }
        
        setSubjects(subjectList);

    }, [numberofSubjects])

    const createSubjects = () => {
        // subjectList[i] = {"assigned": s, "color": "red",};
       
            //const s = [...subjects];

            subjects.splice(index, 1, {"assigned": "English", "color": "red"});

            setAnchorEl(null);
            setNestedOpen(!nestedOpen);
            //subjectList[i] = {"assigned": "English", "color": "red",};;
            setSubjects(subjects);
            
       
        
        
        // setSubjects(subjectList);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setNestedOpen(!nestedOpen);
        
    };

    const handleClick = (event, i) => {
        setAnchorEl(event.currentTarget);
        setIndex(i);
        
    };

    const gridHeaderGenerator = (e) => {
        let arr = [];
        
    }

    const gridGenerator = (e) => {
        let arr = [];
        for (let i = 0; i < e.target.value; i++ ){
           arr.push(i);
        };
        setPeriods(arr);
    }

    const grid = () => {
        return (
        
            <Grid item xs={12}>
                <Grid container justify="center" spacing={1}>
                    {periods.map((value) => (
                    <Grid key={value} item>
                        <Paper className={classes.paper} />
                    </Grid>
            ))}
                </Grid>
            </Grid>
            
      
        );
    }

    return(
        <div className = {classes.root}>
            <Breadcrumbs separator = {<NavigateNextIcon fontSize = "small"/>} aria-label = "breadcrumb">
            <Link color = "inherit" href= '/timetable' style= {{fontSize: 24}}>Timetable</Link>
            <Link color = "Inherit" href ='/create' style = {{fontSize: 24}}>Create New</Link>
        </Breadcrumbs>

        <div className = {classes.container}>
            <div className = {classes.headerContainer}> 
                <Typography variant = 'h5' className = {classes.title}>Create New Template</Typography>
                {showForm && (<form>
                    <div className = {classes.form}>
                        <div className = {classes.labelContainer}>
                            <Typography>Enter Number of Periods</Typography>
                        </div> 
                        
                        <TextField
                            id="outlined-number"
                            label="E.g 6"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            size = 'small'
                            onChange = {(event) => {gridGenerator(event)}}
                            
                            
                        />
                        
                        <div className = {classes.labelContainer}>
                            <Typography>Duration Of Periods</Typography>
                        </div>
                        
                        <TextField
                            id="outlined-number"
                            label="E.g 60 minutes"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            size = 'small'
                            onChange = {(event) => {setDuration(event.target.value)}}
                            
                        />

                        <div className = {classes.labelContainer}>
                            <Typography>Start Day</Typography>
                        </div>
                        
                        <TextField
                            id="outlined-number"
                            label="E.g Monday"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            size = 'small'
                            onChange = {(event) => {setStartDay(event.target.value);}}
                            
                        />
                        </div>

                        <div className = {classes.secondForm}>
                        <div className = {classes.labelContainer}>
                            <Typography>Start Time</Typography>
                        </div>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            id="outlined-number"
                            label="E.g 8 am"
                            variant="outlined"
                            size = 'small'
                            KeyboardButtonProps = {{
                                'aria-label' : 'change-time',
                            }}
                            onChange = {(event) => {setStartTime(event.target.value);}}
                            
                        />
                        </MuiPickersUtilsProvider> */}
                        <TextField
                            id="outlined-number"
                            label="E.g 60 Minutes"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            size = 'small'
                            onChange = {(event) => {setStartTime(event.target.value); }}
                        
                        />
                        
                        <div className = {classes.labelContainer}>
                            <Typography>Number of Subjects</Typography>
                        </div>
                         
                        <TextField
                            id="outlined-number"
                            label="E.g 6 subjects"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            size = 'small'
                            onChange = {(event) => {setNumberOfSubjects(event.target.value); }}
                            
                        />
                        
                        
                        </div> 
                </form>)}

                {!showForm && (
                    <Grid container className = {classes.grid} spacing = {2}>
                        {grid()}
                        {grid()}
                        {grid()}
                        {grid()}
                        {grid()}

                        {/* <h1>Test</h1> */}
                        
                    </Grid>
                    
                )}
                <div className = {classes.secondTitleContainer}>
                    <Typography variant = 'h5' className = {classes.title}>Create Subject List</Typography>
                    <div style = {{marginTop: '2%', display: 'flex', flexDirection: 'row', width: 800, flexWrap: 'wrap'}}>
                        {!isEmpty(subjects) && subjects.map((o, i) =>{
                            

                            // createSubjects(o, i)
                            return(
                            <div style = {{marginRight: '2%',  marginTop: '1%'}} key = {i}>
                                
                            <Button style = {{width: 100, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid black', borderRadius: 5, borderBottom: `10px solid ${o.color}`}} onClick = {(event) => {handleClick(event, i)}}>
                                <Typography>{o.assigned}</Typography>
                            
                            </Button>
                            <Menu
                             id= {i}
                             key = {i}
                             anchorEl={anchorEl}
                             keepMounted
                             open={Boolean(anchorEl)}
                             onClose={handleClose}
                            >
                                 <MenuItem key={i} onClick= {() => {setNestedOpen(!nestedOpen)}}>Subjects {nestedOpen ? <ExpandLess/> : <ExpandMore/>}</MenuItem>
                                 
                                 <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
                                     <MenuItem key={i}  onClick = {() => createSubjects()}>English</MenuItem>
                                 </Collapse>
                            

                         </Menu>
                         </div>
                            )
                        })}
                        
                    </div>
                    
                    
                </div>
                


            </div>
            <div className = {classes.buttonContainer}>
                <Button className = {classes.button} onClick = {() => {setShowForm(!showForm)}}>Create Template</Button>
            </div>
        </div>
        </div>
    )
};

const useStyles = makeStyles ((theme) => ({
    grid: {
        flexGrow: 1,
    },
    paper: {
        height: 50,
        width: 100,
        color: 'black',
      },
    root: {
        marginTop: '2%'
     },
    subjectButton: {

    },
    secondTitleContainer: {
        marginTop: '5%'
    },
    inputContainer: {
        marginLeft: '2%'
    },
    labelContainer: {
        marginRight: '2%',
        marginLeft: '2%'
    },
    secondForm: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2%',
        marginLeft: '5.3%',
        // marginLeft: '5%',
        width: 2000
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2%',
        // marginLeft: '5%',
        width: 2000
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        
    },
    container: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderLeft: '10px solid purple',
        height: '100%'
    },
    title: {
        color: 'purple'

    },

    button: {
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 50,
        padding: '0 30px',
        width: 200,
        

    },

    buttonContainer: {
        display: 'flex',
        marginLeft: '35%',
        marginTop: '10%'
    }
}))

export default Newtimetable;