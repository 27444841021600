import React, { createContext, useState } from "react";

export const ProfileCreateContext = createContext({});

const ProfileContext = ({ children }) => {
  const [state, setState] = useState({
    emails: [],
  });

  const setGlobalState = (key = null, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <ProfileCreateContext.Provider value={{ ...state, setGlobalState }}>
      {children}
    </ProfileCreateContext.Provider>
  );
};

export default ProfileContext;
