import React from 'react';

const ErrorPage = () => {
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        }}>
            <h1>404</h1>
            <h2>PAGE NOT FOUND</h2>
        </div>
    )
}

export default ErrorPage
