import announcement from '../apis/announcement';

export const RESET_STATUS = 'RESET_STATUS';

export const ANNOUNCEMENTS_FETCH = 'ANNOUNCEMENTS_FETCH';
export const ANNOUNCEMENTS_FETCH_SUCCESS = 'ANNOUNCEMENTS_FETCH_SUCCESS';
export const ANNOUNCEMENTS_FETCH_FAILED = 'ANNOUNCEMENTS_FETCH_FAILED';

export const ANNOUNCEMENT_CREATE = 'ANNOUNCEMENT_CREATE';
export const ANNOUNCEMENT_CREATE_SUCCESS = 'ANNOUNCEMENT_CREATE_SUCCESS';
export const ANNOUNCEMENT_CREATE_FAILED = 'ANNOUNCEMENT_CREATE_FAILED';


export const ANNOUNCEMENT_UPLOAD = 'ANNOUNCEMENT_UPLOAD';
export const ANNOUNCEMENT_UPLOAD_SUCCESS = 'ANNOUNCEMENT_UPLOAD_SUCCESS';
export const ANNOUNCEMENT_UPLOAD_FAILED = 'ANNOUNCEMENT_UPLOAD_FAILED';


export const ANNOUNCEMENT_UPDATE = 'ANNOUNCEMENT_UPDATE';
export const ANNOUNCEMENT_UPDATE_SUCCESS = 'ANNOUNCEMENT_UPDATE_SUCCESS';
export const ANNOUNCEMENT_UPDATE_FAILED = 'ANNOUNCEMENT_UPDATE_FAILED';

export const ANNOUNCEMENT_DELETE = 'ANNOUNCEMENT_DELETE';
export const ANNOUNCEMENT_DELETE_SUCCESS = 'ANNOUNCEMENT_DELETE_SUCCESS';
export const ANNOUNCEMENT_DELETE_FAILED = 'ANNOUNCEMENT_DELETE_FAILED';


let dev = process.env.NODE_ENV === 'development';


const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createAnnouncement = (data) => async (dispatch) => {
    try {
        dispatch({ type: ANNOUNCEMENT_CREATE });
        const res = await announcement.create(data);
        if (!res?.success) {
            return dispatch({
                type: ANNOUNCEMENT_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: ANNOUNCEMENT_CREATE_SUCCESS, response: res.data });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ANNOUNCEMENT_CREATE_FAILED,
            error: err
        });
    }
}

const uploadFiles = (data) => async (dispatch) => {
    try {
        dispatch({ type: ANNOUNCEMENT_UPLOAD });
        const res = await announcement.upload(data);
        if (!res?.success) {
            return dispatch({
                type: ANNOUNCEMENT_UPLOAD_FAILED,
                error: res
            });
        }
        return dispatch({ type: ANNOUNCEMENT_UPLOAD_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ANNOUNCEMENT_UPLOAD_FAILED,
            error: err
        });
    }
}

const updateAnnouncement = (data) => async (dispatch) => {
    try {
        dispatch({ type: ANNOUNCEMENT_UPDATE });
        const res = await announcement.update(data);
        if (!res?.success) {
            return dispatch({
                type: ANNOUNCEMENT_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: ANNOUNCEMENT_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ANNOUNCEMENT_UPDATE_FAILED,
            error: err
        });
    }
}


const deleteAnnouncement = (data) => async (dispatch) => {
    try {
        dispatch({ type: ANNOUNCEMENT_DELETE });
        const res = await announcement.deleteA(data);
        if (!res?.success) {
            return dispatch({
                type: ANNOUNCEMENT_DELETE_FAILED,
                error: {}
            });
        }
        return dispatch({ type: ANNOUNCEMENT_DELETE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ANNOUNCEMENT_DELETE_FAILED,
            error: err
        });
    }
}


export default {
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    resetStatus,
    uploadFiles
}