import React, { useState, Fragment, useEffect } from 'react';
import { isEmpty, map } from 'lodash';

import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';

const Menu = ({ items, actionElement, selectedIndex, menuProps }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [index, setIndex] = useState(-1);

    // index won't change inside the same component, this useEffect will only run once when it is clicked first time.
    // useEffect(() => {
    //     console.debug(index);
    //     if (selectedIndex) {
    //         selectedIndex(index);
    //     }
    // }, [index]);

    const onOpenMenu = (e, index) => {
        setIndex(index);
        setAnchorEl(e.currentTarget);
        if (selectedIndex) {
            // callback from parent to set the index of clicked row
            selectedIndex(index);
        }
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
    }

    const onClickItem = (onClick) => {
        onCloseMenu();
        onClick();
    }

    return (
        <Fragment>
            {actionElement && actionElement(onOpenMenu)}

            <MuiMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onCloseMenu}
                {...menuProps}
            >
                {!isEmpty(items) && map(items, (o, i) => {
                    return (
                        <MuiMenuItem key={i} onClick={() => onClickItem(o.onClick)}>
                            {o.content}
                        </MuiMenuItem>
                    )
                })}
            </MuiMenu>
        </Fragment>
    )
}

Menu.defaultProps = {
    actionElement: null,
    items: []
}

export default Menu;

// const Menu = ({ onMenuClick, items, keepMounted, ...props }) => {
//     const [anchorEl, setAnchorEl] = useState(null);

//     const handleClick = (e) => {
//         // onMenuClick(handleClick);
//         if (onMenuClick) {
//             onMenuClick();
//         }

//         setAnchorEl(e.currentTarget);
//     }

//     const handleClose = () => {
//         setAnchorEl(null);
//     }

//     return (
//         <MUMenu
//             anchorEl={anchorEl}
//             keepMounted={keepMounted}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//             onClick={handleClick}
//             {...props}
//         >
//             {!isEmpty(items) && map(items, (o, i) => {
//                 return (
//                     <MenuItem 
//                         key={i}
//                         onClick={() => { o.onClick(); handleClose(); }}
//                         {...o}
//                     >
//                         {o.item}
//                     </MenuItem>
//                 )
//             })}
//         </MUMenu>
//     )
// }

// Menu.defaultProps = {
//     keepMounted: true,
//     items: [],
//     onMenuClick: () => {}
// }

// export default Menu
