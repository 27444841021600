import React, { Fragment } from 'react';
import { upperCase, isObject, isEmpty } from 'lodash';
// import classnames from 'clsx';
// import useStyles from './style';

// Components
import Box from '@material-ui/core/Box';

const MessageBox = ({ message, ...props }) => {
    if (!message || message.length === 0) {
        return null;
    }

    return (
        <Box 
            bgcolor="error.main" 
            padding="6px 10px" 
            marginTop="3px" 
            color="error.contrastText" 
            {...props}
        >
            <span style={{ fontSize: '12px', letterSpacing: '.5px' }}>
                {upperCase(message)}
            </span>
        </Box>
    )
}

const InputError = ({ className, label, error, match, length, ...props }) => {
    return (
        <Fragment>
            {error === 'ERR_REQUIRED' &&
                <MessageBox
                    message={`${label} is required`} 
                    {...props}
                /> 
            }

            {(error === 'ERR_PATTERN' || error === 'ERR_LENGTH') &&
                <MessageBox
                    message={`Please enter a valid ${label}`}
                    {...props} 
                /> 
            }

            {error === 'ERR_MAX' && !length &&
                <MessageBox
                    message={`${label} is too short`} 
                    {...props}
                /> 
            }

            {error === 'ERR_MAX' && length &&
                <MessageBox
                    message={`${label} is too long (maximum is ${length} characters)`} 
                    {...props}
                /> 
            }

            {error === 'ERR_MIN' && !length &&
                <MessageBox
                    message={`${label} is too short`} 
                    {...props}
                /> 
            }

            {error === 'ERR_MIN' && length &&
                <MessageBox
                    message={`${label} must be of minimum ${length} characters`} 
                    {...props}
                /> 
            }

            {error === 'ERR_ONLY' && !match &&
                <MessageBox
                    message={`${label} does not match`} 
                    {...props}
                /> 
            }

            {error === 'ERR_ONLY' && match &&
                <MessageBox
                    message={`${label} and ${match} does not match`} 
                    {...props}
                /> 
            }

            {error === 'ERR_UNIQUE' &&
                <MessageBox
                    message={`Two ${label} cannot be the same`} 
                    {...props}
                /> 
            }

            {error === 'ERR_ONE_REQUIRED' &&
                <MessageBox
                    message={`At least one ${label} is required`} 
                    {...props}
                /> 
            }

            {isObject(error) && !isEmpty(error) && error?.type === 'ERR_SERVER' &&
                <MessageBox
                    message={error?.message} 
                    {...props}
                /> 
            }
        </Fragment>
    )
}

export default InputError
