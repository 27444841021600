import axios from 'axios';
import Session from 'services/local/session';

const getRecords = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar/byOrg/${Session.getOrg().orgID}`;

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}

const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
}


const getYears = async (orgID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar/populateYears`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getClasses = async (orgID, year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar/populateClasses`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID, year },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getSemesters = async (classID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/calendar/populateSemesters`;

    return await axios({
        method: 'GET',
        url: url,
        params: { classID },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}


export default {
    getRecords,
    getYears,
    getClasses,
    create,
    getSemesters,
    update
};