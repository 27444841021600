import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import useSession from 'hooks/use-session';
import DepartmentAction from 'redux/actions/department';

// Components
import View from './select-department.view';

const mapStateToProps = (state) => {
    return {
        status: state?.department?.status,
        resErr: state?.department?.error,
        departments: state?.department?.list
    }
}

const mapDispatchToProps = (dispatch) => {
    const departmentAction = bindActionCreators(DepartmentAction, dispatch);

    return {
        onResetStatus$: () => {
            departmentAction.resetStatus();
        },
        onFetch$: (orgID) => {
            departmentAction.getDepartments(orgID);
        }
    }
}


const Container = ({ departments, status, onFetch$, onSelect }) => {
    const session = useSession();

    useEffect(() => {
        if (session.orgID !== undefined) {
            onFetch$(session.orgID);
        }
    }, [session.orgID]);

    return (
        <View departments={departments} status={status} onSelect={onSelect}/>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);