import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import VerificationPageForm from '../components/auth-form/reset-verification';

const ReserVerificationPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Verification</title>
            </Helmet>

            <VerificationPageForm />
        </Fragment>
    )
}

export default ReserVerificationPage;