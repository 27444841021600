import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import announcementAction from '../../../redux/actions/announcement';
import View from './announcement.view';

const mapStateToProps = (state) => {
    return {
        status: state.announcement?.status,
        error: state.announcement?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const AnnouncementActions = bindActionCreators(announcementAction, dispatch);

    return {
        deleteAnnoucement$: (data) => {
            AnnouncementActions.deleteAnnouncement(data);
        },
        onReset$: () => {
            AnnouncementActions.resetStatus();
        },
    }
}

const Container = ({ status, error,  onReset$, deleteAnnoucement$ }) => {

    const deleteAnnoucement = (data) => {
        deleteAnnoucement$(data);
    }

    useEffect(() => {
        if (status.create === 'success' || status.update === 'success') {
            onReset$();
        }
    }, [status]);
    return (
        <View status={status} resErr={error} onReset={onReset$} deleteAnnoucement={deleteAnnoucement} />
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Container);