import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useSession from 'hooks/use-session';
import moment from 'moment';
import Joi from 'joi';
import normalization from 'utils/normalizers';

// Components
import useStyles from './style';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar';

import StudentDetails from '../student-details';

import Button from 'components/common/button';
import Loader from 'components/common/loader';
import DataGrid from 'components/common/data-grid';
import AlertBox from 'components/common/alert-box';
import { Select, InputSearch } from 'components/common/form-unit';
// import SelectClass from 'components/common/select-class';
import SelectAvailableClass from 'components/common/select-available-class';
import Modal from 'components/common/modal';
import SelectYear from 'components/common/select-year';
import Card from 'components/common/card';
import { makeStyles } from '@material-ui/core/styles';
import PlusIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LinkMaterial from "@material-ui/core/Link";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MateButton from '@material-ui/core/Button';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import { uniq } from 'lodash';
import { AutoComplete } from '../../common/form-unit';

const View = ({
    status,
    students,
    enrolledCount,
    unenrolledCount,
    totalCount,
    onTransfer,
    onEnroll,
    resErr,
    onReset,
    onFetch,
    avilableYears,
    secondaryCount,
    primaryCount,
    avilableClasses
}) => {

    const currentYear = new Date().getFullYear().toString();
    const session = useSession();
    const history = useHistory();
    const cls = useStyles();
    const classes = useCustomStyles();
    const { t } = useTranslation();

    const initialFilters = { selectedYear: moment().format('YYYY') };
    const initialInput = { actionType: 'TRANSFER', selectedYear: currentYear }
    const initialLoading = false;
    const initialModal = { open: false, content: null };

    const [filters, setFilters] = useState(initialFilters);
    const [input, setInput] = useState(initialInput);
    const [input2, setInput2] = useState(initialInput);
    const [error, setError] = useState({});
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(initialLoading);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [modal, setModal] = useState(initialModal);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const buttonLabel = input.actionType === 'TRANSFER' ? 'Transfer' : 'Enrol';
    const [backupStudents, setBackUpStudents] = useState([])
    const [studentList, setStudentList] = useState([]);
    const [clear, setClear] = useState(false)
    const [avlYears, setAvlYears] = useState([])
    const [genderValue,setGenderValue] = useState('');
    const sessionObj = useSession();
    const uniquePermissions = uniq(sessionObj.permissions);
    const canViewList = (uniquePermissions || []).includes("v_acc_students");
    const canViewStudentDetails = (uniquePermissions || []).includes("v_acc_students_details");
    const canCreateStudent = (uniquePermissions || []).includes("v_acc_students_create");
    const canEnrolStudents = (uniquePermissions || []).includes("v_acc_students_enrol" || "v_acc_students_transfer");

    const SearchData = () => {
        let studentList = backupStudents.filter(item => {
            return (
                (
                    (filters.gender !== undefined ? (item.gender.toLowerCase() == filters.gender.toLowerCase()) : true) &&
                    (filters.status !== undefined ? (item.status.toLowerCase() == filters.status.toLowerCase()) : true) &&
                    (input.selectedClass !== undefined ? (item.class?.ID?.indexOf(input.selectedClass) > -1) : true) &&
                    (filters.studentName !== undefined ? (item.name.toLowerCase().indexOf(filters.studentName.toLowerCase()) > -1) : true)
                )
            );
        })
        setClear(true)
        setStudentList([])
        setStudentList(studentList)
    }

    useEffect(() => {
        setAvlYears(avilableYears)
    }, [avilableYears])

    // useEffect(() => {
    //     setStudentList(students)
    //     setBackUpStudents(students); 
    // }, [students?.length > 0])
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (resErr?.enroll) {
            setError({ ...error, enroolErr: resErr?.enroll })
            setOpen(true);
        }
    }, [resErr])


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (session.orgID && input?.selectedYear) {
            setInput({ ...input, actionType: null })
            setInput2({ ...input2, selectedYear: null })
            setSelectedIDs([])
            onFetch(session.orgID, input?.selectedYear);
        }

        // Refetch after transfer success
        if (session.orgID && input?.selectedYear && status.transfer === 'success') {
            setInput({ ...input, actionType: null })
            setInput2({ ...input2, selectedYear: null })
            setSelectedIDs([])
            onFetch(session.orgID, input?.selectedYear);
            onReset();
        }
    }, [session.orgID, input?.selectedYear, status.transfer]);


    useEffect(() => {
        const isLoading = status.fetchEnrolled === 'loading' || status.transfer === 'loading' || status.enrolled === 'loading';

        if (isLoading) {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (status.fetchEnrolled === 'success') {
            const sortedStudents = students.sort((studA, studB)=>{return studA.name.localeCompare(studB.name)});
            setStudentList(sortedStudents);
            setBackUpStudents(sortedStudents);
        }
        if (status.enroll === 'success') {
            setMessage({ ...message, message: 'Student Enrol Successfully...' })
            setTimeout(() => {
                setMessage({ ...message, message: '' })
            }, 5000);
        }
    }, [status]);

    useEffect(() => {
        SearchData();
    }, [filters.gender])

    useEffect(() => {
        SearchData();
    }, [filters.status])

    useEffect(() => {
        SearchData();
    }, [filters.studentName])

    useEffect(() => {
        if (selectedIDs.length == 0) {
            SearchData();
        }
    }, [input.selectedClass])

    const onSelectCheckBox = (ids = []) => {
        setSelectedIDs(ids);
    }

    const actionClick = (e) => {
        e.preventDefault();

        let arr = [];
        _.map(selectedIDs, o => {
            const foundProfile = _.find(students, t => t.ID === o);

            arr.push(_.omitBy({
                studentID: o,
                sourceClassID: foundProfile?.class?.ID
            }, _.isNil));
        });

        const schemaSelectedClass = Joi.object().keys({
            selectedClass: Joi.string().guid({ version: 'uuidv4' }).required()
        });

        switch (input.actionType) {
            case 'TRANSFER':
                const result = schemaSelectedClass.validate({ selectedClass: input2?.selectedClass });
                if (!_.isUndefined(result.error)) {
                    const { key, value } = normalization.error.validation(result.error);
                    setError({ ...error, [key]: value });
                    break;
                }

                onTransfer(_.omitBy({
                    targetClassID: result.value?.selectedClass,
                    students: !_.isEmpty(arr) ? arr : null
                }, _.isNil));

                break;

            case 'ENROL':
                let stdArr = [];
                const enroleResult = schemaSelectedClass.validate({ selectedClass: input2?.selectedClass });
                if (!_.isUndefined(enroleResult.error)) {
                    const { key, value } = normalization.error.validation(enroleResult.error);
                    setError({ ...error, [key]: value });
                    break;
                }
                arr.find((d) => {
                    stdArr.push(d.studentID)
                })

                onEnroll(_.omitBy({
                    classID: enroleResult.value?.selectedClass,
                    studentIDs: !_.isEmpty(stdArr) ? stdArr : null
                }, _.isNil));

                break;

            default:
                break;
        }
    }

    const onCloseModal = () => {
        // setModal({ ...modal, open: false });
        // // window.location.reload(false);
        // onFetch(session.orgID, filters?.selectedYear);
        // onReset();
        setModal({ ...modal, open: false });
        setStudentList([])
        setBackUpStudents([]);
        setTimeout(() => {
            onFetch(session.orgID, filters?.selectedYear);
            setTimeout(() => {
                onReset();
            }, 1000);
        }, 100);

    }

    const onMenuClick = ({ type, data }) => {
        switch (type) {
            case 'EDIT':
                setModal({
                    ...modal,
                    open: true,
                    content: <StudentDetails profileID={data} onCloseModal={onCloseModal} />
                });

                break;

            default:
                break;
        }
    }

    const onChangeGender=(value)=>{
        setGenderValue(value);
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 300,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    // <span className={cls.name} onClick={() => onMenuClick({ type: 'EDIT', data: params.row.ID })}>
                    //     {params.row.name}
                    // </span>
                    <ListItem>
                        <ListItemAvatar>
                            {params.row.profile_picture ? <Avatar>
                                <img src={params.row.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                            </Avatar> : <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{params.value.charAt(0)}</Avatar>}

                        </ListItemAvatar>
                        {canViewStudentDetails?<ListItemText className={cls.name} primary={params.value} secondary="" onClick={() => onMenuClick({ type: 'EDIT', data: params.row.ID })} />:<p>{params.value}</p>}
                    </ListItem>
                )
            },
        },
        {
            field: "studentID",
            headerName: "Student ID",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{params.row.code || '-'}</span>
                )
            }
        },
        {
            field: "gender",
            headerName: "Gender",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{_.upperFirst(_.lowerCase(params.value))}</span>
                )
            }
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{_.upperFirst(_.lowerCase(params.value))}</span>
                )
            }
        },
        {
            field: "year",
            headerName: "Year",
            width: 150,
            disableColumnMenu: true
        },
        {
            field: "class",
            headerName: "Class",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{params.row?.class?.name}</span>
            }
        },
        //   {
        //     field: "attendance",
        //     headerName: "Attendance",
        //     width: 90,
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         return <span>{`-`}</span>
        //     }
        // },
        // {
        //     field: "results",
        //     headerName: "Results",
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         return <span>{`-`}</span>
        //     }
        // },
        {
            field: "academicStatus",
            headerName: "Academic Status",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{_.upperFirst(_.toLower(params.value)) ?? `-`}</span>
            }
        }
    ];

    if (loading) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px" />
            </div>
        )
    }




    const reset = () => {
        let obj = {
            gender: '',
            status: '',
            class: '',
            studentName: ''
        }
        setFilters(obj);
        setClear(false)
        setStudentList(backupStudents)
    }

    const gotoCalendar = () => {
        setOpen(false);
        history.push('/academic_calendar')
    }

    const getStudents = (event) => {
        setInput({ ...input, selectedYear: event.target.value })
    }

    const setYearForEnroll = (event) => {
        setInput2({ ...input2, selectedYear: event.target.value })
    }

    return (
        <Fragment>
            {/* <span className={cls.title}>{t('txt.students')}</span> */}

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -30,
                    marginBottom: 10,
                    marginLeft: -30,
                    marginRight: -30,
                }}
            >
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }}
                >
                    Account
                </LinkMaterial>
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/students"
                >
                    Students
                </LinkMaterial>

            </Breadcrumbs>

            <Grid container={true} spacing={2} alignItems="center" style={{ marginTop: '1em' }}>
                <Grid item={true}>
                    <Card
                        title={t('title.total_students')}
                        body={t('txt.registered')}
                        count={totalCount}
                        type="success"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_primary')}
                        body={t('txt.students')}
                        count={primaryCount}
                        type="info"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_secondary')}
                        body={t('txt.students')}
                        count={secondaryCount}
                        type="warning"
                    />
                </Grid>

            {canCreateStudent ?
                <Grid item={true} style={{ marginLeft: 'auto' }}>
                    <Button
                        label={t('action.new_student')}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        type="primary"
                        size="large"
                        onClick={() => history.push('/account/students/create')}
                    />
                </Grid>
            :''}
            </Grid>

            <AlertBox
                type="error"
                style={{ marginTop: '1em' }}
                icon={false}
                message={(
                    <Fragment>
                        <span>There are <b>{unenrolledCount}</b> unenrolled students.</span>
                        <Link
                            style={{ marginLeft: '3px', color: '#B42C30' }}
                            to="/account/students/unenrolled"
                        >
                            Enroll Now
                        </Link>
                    </Fragment>
                )}
            />

            <Grid container={true}>
                <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                    {message?.message ? <AlertBox
                        type="success"
                        style={{ marginTop: '1em', marginBottom: '1em' }}
                        icon={false}
                        message={message?.message}
                    /> : ''}
                </Grid>
            </Grid>


        {canViewList?
            <div className={cls.table__wrapper}>
                <span className={cls.title} style={{ fontSize: 18 }}>
                    Students {`(${students?.length})`}
                </span>


                <Grid container={true} spacing={1}>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Select
                            placeholder="By Year"
                            name="Year"
                            value={input.selectedYear || ''}
                            onChange={(value) => getStudents(value)}
                            options={avlYears.map((year) => {
                                return { value: year, label: year };
                            })}
                        />
                    </Grid>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Class</label> */}
                        <SelectAvailableClass
                            placeholder="Select Class"
                            year={input.selectedYear}
                            onChange={(value) => {setInput({ ...input, selectedClass: value?.classID })}}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Gender</label> */}
                        {/* <AutoComplete
                            placeholder="By Gender"
                            name="gender"
                            value={filters.gender || ''}
                            getOptionLabel={(opt)=>{return opt.label}}
                            inputValue={genderValue}
                            onInputChange={onChangeGender()}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            options={[
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        /> */}
                        <Select
                            placeholder="By Gender"
                            name="gender"
                            value={filters.gender || ''}
                            displayEmpty={false}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Status</label> */}
                        <Select
                            placeholder="By Status"
                            name="status"
                            value={filters.status || ''}
                            displayEmpty={false}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Active', value: 'ACTIVE' },
                                { label: 'Inactive', value: 'INACTIVE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        {/* <label>Search by Name</label> */}
                        <InputSearch

                            name="studentName"
                            value={filters.studentName || ''}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            placeholder="Search by Student Name or ID"
                        />
                    </Grid>


                    <Grid item lg={1} style={{ textAlign: 'left' }}>
                        <br></br>
                        <IconButton
                            color="#FFC000"
                        >
                            <SearchOutlinedIcon
                                onClick={SearchData}
                                style={{
                                    color: "black",
                                    background: 'rgba(253, 183, 20, 1)',
                                    padding: 7,
                                    width: 55,
                                    height: 40,
                                    borderRadius: 4,
                                    marginTop: -24,
                                    marginLeft: -21,
                                }} />
                        </IconButton>

                    </Grid>

                </Grid>



            {canEnrolStudents?
                <Grid
                    container={true}
                    alignItems="center"
                    spacing={1}
                    style={{
                        backgroundColor: 'rgba(216, 235, 243, 0.5)',
                        borderRadius: '3px',
                        padding: '0 10px',
                        opacity: !_.isEmpty(selectedIDs) ? 1 : 0.5,
                        pointerEvents: !_.isEmpty(selectedIDs) ? 'all' : 'none',
                        transition: 'all 200ms'
                    }}
                >
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Select
                            name="actionType"
                            value={input.actionType || ''}
                            onChange={({ target }) => {
                                setInput2({ ...input2, selectedYear: '', selectedClass: null })
                                setInput({ ...input, [target.name]: target.value });
                                setError({})
                            }}
                            options={[
                                { label: 'Enrol', value: 'ENROL' },
                                { label: 'Transfer', value: 'TRANSFER' },
                            ]}
                        />
                    </Grid>



                    <Grid item={true} lg={2} md={3} sm={12} xs={12} style={{ textAlign: 'center' }}>
                        <span style={{ color: 'rgba(37, 51, 90, 1)' }}>selected Students to</span>
                    </Grid>
                    {input.actionType == 'ENROL' &&
                        <>
                            <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                                {/* <SelectYear
                                    onChange={(value) => setInput2({ ...input2, selectedYear: value })}
                                /> */}

                                {/* {scheduleRows.filter(item => {
                                            return (
                                                item.subject.toLowerCase().indexOf(shdlSearchTerm) > -1 || item.description.toLowerCase().indexOf(shdlSearchTerm) > -1
                                            );
                                        }).map((row, index) =>  */}


                                <Select
                                    placeholder="By Year"
                                    name="Year"
                                    value={input2.selectedYear || ''}
                                    onChange={(value) => setYearForEnroll(value)}
                                    options={
                                        avlYears.filter(yr => { return yr > currentYear })
                                            .map((year) => {
                                                return { value: year, label: year };
                                            })
                                    }
                                />

                            </Grid>
                        </>
                    }
                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        <SelectAvailableClass
                            error={error.selectedClass}
                            errorLabel="Class"
                            year={input2.selectedYear}
                            onChange={(value) => { setInput2({ ...input2, selectedClass: value?.classID }); setError({}); }}
                        />
                    </Grid>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Button
                            disabled={!selectedIDs.length}
                            label={buttonLabel}
                            type="primary"
                            size="large"
                            style={{ width: '160px' }}
                            onClick={actionClick}
                        />
                    </Grid>
                </Grid>
            :''}

                <Grid container={true} style={{ marginTop: '1em' }}>
                    <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                        <DataGrid
                            rows={studentList}
                            getRowId={(row) => row.ID}
                            columns={columns}
                            loading={loading}
                            onSelectionModelChange={(param) => onSelectCheckBox(param.selectionModel)}
                        />
                    </Grid>
                </Grid>
            </div>
        :''}
            <Modal
                showFooter={false}
                open={modal.open}
                onHandleClose={onCloseModal}
                fullScreen={true}
                maxWidth="lg"
                scroll="body"
            >
                {modal.content}
            </Modal>

            <Modal
                className={classes.modal}
                open={open}
                showFooter={false}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
                        <h2 style={{ marginBottom: '15px' }}>Error</h2>
                        <img src={process.env.PUBLIC_URL + "/error.svg"} style={{ marginTop: '10px', marginBottom: '10px' }} />
                        <div style={{ textTransform: 'uppercase', fontSize: '16px', padding: '0px 20px' }}>
                            The student(s) can’t be Enrolled as Academic Years are <span style={{ color: '#FF0000', fontWeight: 'bold' }}>clashing.</span>
                        </div>
                        <p style={{ marginTop: '10px' }}>Note: To proceed with enrolment, fix clashes in Academic Calendar</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                            <MateButton style={{
                                background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
                                border: 5,
                                borderWidth: 10,
                                borderColor: '#25335A',
                                borderRadius: 10,
                                color: 'black',
                                height: 50,
                                padding: '10px 25px',
                                textTransform: 'initial'
                            }}
                                onClick={gotoCalendar}
                            >
                                Fix Error
                            </MateButton>
                        </div>
                        <div
                            onClick={handleClose}
                            style={{ cursor: 'pointer', textAlign: 'center', color: '#0172B1', textDecoration: 'underline', marginTop: '10px' }}
                        >CANCEL, return to Student Page</div>

                    </div>
                </Fade>
            </Modal>

        </Fragment>
    )
}

const useCustomStyles = makeStyles((theme) => ({
    modalHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 10px)'
    },
    popupTitle: {
        marginBottom: '10px'
    },
}));


export default View;
