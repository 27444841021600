import _ from 'lodash';

import acedemicCalendar from '../apis/acedemic-calendar';
import normalize from '../../utils/normalizers';

export const RESET_STATUS = 'RESET_STATUS';

export const ACRECORDS_FETCH = 'ACRECORDS_FETCH';
export const ACRECORDS_FETCH_FAILED = 'ACRECORDS_FETCH_FAILED';
export const ACRECORDS_FETCH_SUCCESS = 'ACRECORDS_FETCH_SUCCESS';

export const ACRECORDS_CREATE = 'ACRECORDS_CREATE';
export const ACRECORDS_CREATE_FAILED = 'ACRECORDS_CREATE_FAILED';
export const ACRECORDS_CREATE_SUCCESS = 'ACRECORDS_CREATE_SUCCESS';

export const ACRECORDS_UPDATE = 'ACRECORDS_UPDATE';
export const ACRECORDS_UPDATE_FAILED = 'ACRECORDS_UPDATE_FAILED';
export const ACRECORDS_UPDATE_SUCCESS = 'ACRECORDS_UPDATE_SUCCESS';

export const AVAILABLE_CALENDAR_YEARS_FETCH = 'AVAILABLE_CALENDAR_YEARS_FETCH';
export const AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS = 'AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS';
export const AVAILABLE_CALENDAR_YEARS_FETCH_FAILED = 'AVAILABLE_CALENDAR_YEARS_FETCH_FAILED';

export const CLASSES_BASED_ON_YEAR_FETCH = 'CLASSES_BASED_ON_YEAR_FETCH';
export const CLASSES_BASED_ON_YEAR_FETCH_SUCCESS = 'CLASSES_BASED_ON_YEAR_FETCH_SUCCESS';
export const CLASSES_BASED_ON_YEAR_FETCH_FAILED = 'CLASSES_BASED_ON_YEAR_FETCH_FAILED';

export const CLASSES_BASED_ON_SEMESTER_FETCH = 'CLASSES_BASED_ON_SEMESTER_FETCH';
export const CLASSES_BASED_ON_SEMESTER_FETCH_SUCCESS = 'CLASSES_BASED_ON_SEMESTER_FETCH_SUCCESS';
export const CLASSES_BASED_ON_SEMESTER_FETCH_FAILED = 'CLASSES_BASED_ON_SEMESTER_FETCH_FAILED';

const dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const createacCalendar = (data) => async (dispatch) => { // for acedemic calendar creation
    try {
        dispatch({ type: ACRECORDS_CREATE });
        const res = await acedemicCalendar.create(data);
        if (!res?.success) {
            return dispatch({
                type: ACRECORDS_CREATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: ACRECORDS_CREATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ACRECORDS_CREATE_FAILED,
            error: err
        });
    }
}


const updateacCalendar = (data) => async (dispatch) => { // for acedemic calendar creation
    try {
        dispatch({ type: ACRECORDS_UPDATE });
        const res = await acedemicCalendar.update(data);
        if (!res?.success) {
            return dispatch({
                type: ACRECORDS_UPDATE_FAILED,
                error: res
            });
        }
        return dispatch({ type: ACRECORDS_UPDATE_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ACRECORDS_UPDATE_FAILED,
            error: err
        });
    }
}



const getACrecords = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACRECORDS_FETCH });
        const res = await acedemicCalendar.getRecords(data);
        if (!res?.success) {
            return dispatch({
                type: ACRECORDS_FETCH_FAILED,
                error: {}
            });
        }
        return dispatch({ type: ACRECORDS_FETCH_SUCCESS });
    }
    catch (err) {
        if (dev) { console.log(err); }
        dispatch({
            type: ACRECORDS_FETCH_FAILED,
            error: err
        });
    }
}

const getAvailableYears = (orgID) => async (dispatch) => {
    try {
        dispatch({ type: AVAILABLE_CALENDAR_YEARS_FETCH });

        const res = await acedemicCalendar.getYears(orgID);
        if (!res?.success) {
            return dispatch({
                type: AVAILABLE_CALENDAR_YEARS_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({ type: AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: AVAILABLE_CALENDAR_YEARS_FETCH_SUCCESS,
            error: err
        });
    }
}

const getClassesBasedOnAvailableYear = (orgID, year) => async (dispatch) => {
    try {
        dispatch({ type: CLASSES_BASED_ON_YEAR_FETCH });

        const res = await acedemicCalendar.getClasses(orgID, year);
        if (!res?.success) {
            return dispatch({
                type: CLASSES_BASED_ON_YEAR_FETCH_FAILED,
                error: res.message
            });
        }

        dispatch({ type: CLASSES_BASED_ON_YEAR_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: CLASSES_BASED_ON_YEAR_FETCH_FAILED,
            error: err
        });
    }
}

const getClassesBasedOnAavailableSemester = (classID) => async (dispatch) => {
    try {
        dispatch({ type: CLASSES_BASED_ON_SEMESTER_FETCH });
        const res = await acedemicCalendar.getSemesters(classID);
        if (!res?.success) {
            return dispatch({
                type: CLASSES_BASED_ON_SEMESTER_FETCH_FAILED,
                error: res.message
            });
        }
        dispatch({ type: CLASSES_BASED_ON_SEMESTER_FETCH_SUCCESS, payload: res.data });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: CLASSES_BASED_ON_SEMESTER_FETCH_FAILED,
            error: err,
        });
    }
}

export default {
    createacCalendar,
    getACrecords,
    getAvailableYears,
    getClassesBasedOnAvailableYear,
    getClassesBasedOnAavailableSemester,
    updateacCalendar,
    resetStatus
}