import React, { useEffect } from 'react';
import View from './list.view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SystemAdminRoleAction from '../../../../redux/actions/system-admin/system-admin-role';

const mapStateToProps = (state) => {
    return {
        status: state.systemAdminRole?.status,
        error: state.systemAdminRole?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const SystemAdminRoleActions = bindActionCreators(SystemAdminRoleAction, dispatch);

    return {
        onCreate$: (data) => {
            SystemAdminRoleActions.createGroup(data);
        },

        onUpdate$: (data) => {
            SystemAdminRoleActions.updateGroup(data);
        },

        getGroups$: () => {
            SystemAdminRoleActions.getGroups();
        },
        onReset$: () => {
            SystemAdminRoleActions.resetStatus();
        },
    }
}

const Container = ({ status, error, onReset$, onCreate$, onUpdate$, getGroups$ }) => {
    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdate = (data) => {
        onUpdate$(data)
    }
    const getGroups = () => {
        getGroups$()
    }
    useEffect(() => {
        if (status.create === 'success' || status.update === 'success') {
            onReset$();
        }
    }, [status]);
    return (
        <View status={status} resErr={error} onCreate={onCreate} onReset={onReset$} onUpdate={onUpdate} getGroups={getGroups} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);;