import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { useGetAttendanceByIDQuery } from 'services/api/attendance';

// Components
import AbsentList from './absent-list';
import PresentList from './present-list';

import Loader from 'components/common/loader';

import Grid from '@material-ui/core/Grid';

const AttendanceDetails = ({ attendanceID }) => {
    const { t } = useTranslation();
    const {
        data,
        error,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch
    } = useGetAttendanceByIDQuery({ attendanceID }, { skip: !attendanceID });
 
    console.log(data)
 
    const classData = data?.class;
    const absentStudents = data?.absentStudents;
    const presentStudents = data?.presentStudents;

    if (isLoading || isFetching) {
        return (
            <div className="absolute-center">
                <Loader size="30px" />
            </div>
        )
    }

    return (
        <Fragment>
            <div className="center">
                <h1>{classData?.name}</h1>
            </div>

            <Grid container spacing={2} style={{ marginTop: '1em' }}>
                <Grid item sm={12} md={12} lg={5} xl={5}>
                    {isSuccess &&
                        <PresentList
                            data={presentStudents}
                            shouldRefetch={(condition) => {
                                if (condition) { refetch(); }
                            }}
                        />
                    }
                </Grid>

                <Grid item sm={12} md={12} lg={7} xl={7}>
                    {isSuccess &&
                        <AbsentList
                            data={absentStudents}
                            shouldRefetch={(condition) => {
                                if (condition) { refetch(); }
                            }}
                        />
                    }
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AttendanceDetails;