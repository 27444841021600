import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useSession from 'hooks/use-session';

import { useEditMessageTemplateMutation } from 'services/api/message';
import { schema } from './messages-form.validation';
import normalize from 'utils/normalizers';

// Components
import MessagePreview from '../messages-preview';
import { Input, TextArea } from 'components/common/form-unit';
import Button from 'components/common/button';
import Modal from 'components/common/modal';

import Grid from '@material-ui/core/Grid';

const MessagesForm = ({ data, closeFormModal }) => {
    const session = useSession();
    const { t } = useTranslation();
    const [onUpdate, { isLoading: isUpdating, isSuccess }] = useEditMessageTemplateMutation();

    const initialInput = {};
    const initialError = {};
    const initialModal = false;

    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [modal, setModal] = useState(initialModal);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setInput(data);
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess === true) {
            closeAllModal();
        }
    }, [isSuccess]);

    const openPreviewModal = () => {
        setModal(true);
    }

    const closePreviewModal = () => {
        setModal(false);
    }

    const closeAllModal = () => {
        closeFormModal(); // close the form modal
        closePreviewModal(); // close the preview modal
    }

    const onChange = (e) => {
        let { name, value } = e.target;

        if (!value || value?.length === 0) {
            value = undefined;
        }

        setInput({ ...input, [name]: value });
        setError(initialError);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            if (isUpdating) { return; }

            const value = await schema.validateAsync(input);

            if (!_.isEmpty(value) && !_.isUndefined(onUpdate) && !_.isEmpty(data)) {
                onUpdate({ ...data, ...value, orgID: session?.orgID });
            }
        }
        catch(err) {
            const { key, value } = normalize.error.validation(err);
            setError({ ...error, [key]: value });

            if (document.querySelector(`#message-form *[name=${key}]`)) {
                document.querySelector(`#message-form *[name=${key}]`).focus();
            }
        }
    }

    return (
        <Fragment>
            <div id="message-form">
                <Grid container={true} alignItems="center">
                    <Grid item={true} lg={5}>
                        <h3>Type</h3>
                    </Grid>
                    <Grid item={true} lg={7}>
                        <Input
                            errorLabel="Type"
                            name="messageTemplateCategoryString"
                            value={input?.messageTemplateCategoryString || ''}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} alignItems="center">
                    <Grid item={true} lg={5}>
                        <h3>Message For</h3>
                    </Grid>
                    <Grid item={true} lg={7}>
                        <Input
                            errorLabel="Message For"
                            name="messageTemplateName"
                            value={input?.messageTemplateName || ''}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} alignItems="center">
                    <Grid item={true} lg={5}>
                        <h3>Message <span style={{ color: 'red' }}>*</span></h3>
                    </Grid>
                    <Grid item={true} lg={7}>
                        <TextArea
                            name="messageTemplateContent"
                            errorLabel="Message"
                            value={input?.messageTemplateContent}
                            error={error?.messageTemplateContent}
                            onChange={onChange}
                            rows={5}
                        />
                        <div style={{fontSize:'11px'}}>
                        <a>*Use [name] to represent a student name in the message</a><br/>
                        <a>*Use [date] to represent a date in the message</a>
                        </div>
                    </Grid>
                </Grid>

                <div style={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1em', 
                    padding: '1em 0', 
                }}>
                    <Button
                        label={t('action.preview_message')}
                        size="large"
                        type="primary"
                        variant="outlined"
                        onClick={openPreviewModal}
                    />

                    <Button
                        type="primary"
                        label={t('action.edit')}
                        size="large"
                        isLoading={isUpdating}
                        style={{ marginLeft: '5px' }}
                        onClick={onSubmit}
                    />              
                </div>
            </div>

            {modal &&
                <Modal 
                    title={t('title.preview_message')} 
                    open={modal} 
                    showFooter={null}
                    scroll="body"
                    onHandleClose={closePreviewModal}
                >
                    <MessagePreview 
                        data={{ ...data, orgID: session?.orgID }}
                        onSubmit={onSubmit} 
                        onCloseModal={closePreviewModal}
                    />
                </Modal>
            }
        </Fragment>
    )
}

export default MessagesForm
