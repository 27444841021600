import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';

import TimeTableEditTemplate from '../components/timetable/edit';

const TimeTableEditTemplatePage = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {/* <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="large" />} style={{ marginLeft: 10, marginTop: 10 }}>
                <Link style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: 400, color: 'black' }} to="/timetable" >
                    Timetable
                    </Link>
                <Link style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: 600, color: 'black' }}>
                    Edit
                    </Link>
            </Breadcrumbs> */}

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -10,
                    marginBottom: 10,
                    marginLeft: -30,
                    marginRight: -30,
                }}
            >
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }} href="/timetable"
                >
                    Timetable
                </Link>
                <Link
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                        textDecoration: 'none'
                    }}
                >
                    Edit
                </Link>
            </Breadcrumbs>

            <Helmet>
                <title>Timetable Template Edit</title>
            </Helmet>

            <Paper className={classes.subContainer}
              style={{ borderTop: "5px solid #5D518F",
              borderRadius: 5,}}>
                <TimeTableEditTemplate />
            </Paper>

        </div>
    );
};


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: '0.5%',
    },

    subContainer: {
        marginTop: '5%',
        borderRadius: 10,
    }
}));


export default TimeTableEditTemplatePage;

