import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileActions from 'redux/actions/profile';

// Components

const mapDispatchToProps = (dispatch) => {
    const profileAction = bindActionCreators(ProfileActions, dispatch);

    return {
        onActivate$: () => {

        },
        onDeactivate$: () => {

        }
    }
}

const ProfileStatus = ({ data,  }) => {

    return (
        // <div>
        //     Profile Status
        // </div>
        <Fragment/>
    )
}

export default connect(null, mapDispatchToProps)(ProfileStatus);