import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ClassAction from '../../../redux/actions/master-class';

// Components
import View from './select-class.view';

const mapStateToProps = (state) => {
    return {
        status: state.masterClass?.status, 
        error: state.masterClass?.error,
        classes: state.masterClass?.data
    };
}

const mapDispatchToProps = (dispatch) => {
    const classActions = bindActionCreators(ClassAction, dispatch);

    return {
        onFetch$: () => {
            classActions.getClasses();
        }
    }
}

const Container = ({ status, error, classes, onFetch$, onChange, ...props }) => {
    useEffect(() => {
        onFetch$();
    }, []);

    return (
        <View 
            status={status} 
            resErr={error} 
            classes={classes} 
            onChange={onChange} 
            {...props}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
