import React from 'react';
import ReactDOM from 'react-dom';
// import 'fontsource-poppins';

// import { createStore, applyMiddleware, combineReducers } from 'redux';
// import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
// import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@rtk-incubator/rtk-query';

import App from './App';
import './i18n';

// reducers
import auth from './redux/reducers/auth';
import subject from './redux/reducers/subject';
import profile from './redux/reducers/profile';
import acedemicCalendar from './redux/reducers/acedemic-calendar';
import masterClass from './redux/reducers/master-class';
import timeTable from './redux/reducers/time-table';
import department from './redux/reducers/department';
import announcement from './redux/reducers/announcement';
import systemAdminRole from './redux/reducers/system-admin/system-admin-role';
// services
/* 
  Notes:
  - This services are for rtk-query implementation, developers can still use the old redux pattern,
    but i will try to do any remaining api with the new rtk-query structure

  The old way is:
  - Just import the reducer file like above, then pass it inside the reducer object below
*/
import { departmentApi } from 'services/api/department';
import { messageApi } from 'services/api/message';
import { attendanceApi } from 'services/api/attendance';

const reducer = {
  auth,
  subject,
  profile,
  acedemicCalendar,
  masterClass,
  timeTable,
  department,
  announcement,
  systemAdminRole
};

const store = configureStore({
  reducer: { // Using combine reducers under the hood
    ...reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false
    }).concat(
      departmentApi.middleware,
      messageApi.middleware,
      attendanceApi.middleware
    );
  },
  devTools: process.env.NODE_ENV === 'development'
});
setupListeners(store.dispatch);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// --------------------------
// OLD REDUX SETUP
// --------------------------

// const reducer = combineReducers({
//   auth,
//   subject,
//   profile,
//   acedemicCalendar,
//   masterClass,
//   timeTable,
//   department
// });

// function bindMiddleware(middleware) {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension');
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }

//   return applyMiddleware(...middleware);
// }

// function mainReducer(state, action) {
//   if (action.type === 'hydrate') {
//     return action.payload;
//   }
//   else {
//     return reducer(state, action);
//   }
// }

// const store = createStore(mainReducer, bindMiddleware([thunkMiddleware]));