import React, { useState,useMemo } from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useSession from 'hooks/use-session';
import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import Button from 'components/common/button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Input, CheckBox, RichTextEditor, TextArea, FileUpload } from 'components/common/form-unit'
import useToaster from 'hooks/use-toaster';
import MultiSelect from "react-multi-select-component";
import { useHistory } from 'react-router-dom';
import eduAPI from "../../../api/api";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from '@material-ui/icons/Close';
import Session from 'services/local/session';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
/**
 * Announcement Create Component
 */
const AnnouncementCreate = ({ status, resErr, onCreate, onUpdation, onReset, onUploadFile }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const [spacing, setSpacing] = React.useState(2);
    const [dob, setDob] = useState("");
    const [announceType, setAnnounceType] = useState("");
    const [announceId, setAnnounceId] = useState();
    let initialState = {
        allParents: false,
        allTeachers: false,
        selClass: false,
        selDept: false,
        selIndv: false,
        indvText: "",
        subject: "",
        scheduleDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
        untilDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
        description: ""
    }
    const [inputs, setInputs] = useState(initialState);
    const location = useLocation();
    const createType = '';
    const session = useSession();

    const [attachements, setAttachements] = useState([])


    const [classesList, setClassesList] = useState([])
    const [classSelected, setClassSelected] = useState([]);

    const [removeList, setRemoveList] = useState([])


    const [departmentList, setDepartmentList] = useState([])
    const [deptSelected, setDeptSelected] = useState([]);

    const [disableState, setdisableState] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(true)

    useEffect(() => {
        if (session.orgID && fetchStatus) {
            setFetchStatus(false)
            getClasses()
            getDepts()
        }

    }, [session])

    const validDateChange = (ev) => {
        setInputs({ ...inputs, untilDate: ev.target.value })
    }
    const scheduleDateChange = (ev) => {
        setInputs({ ...inputs, scheduleDate: ev.target.value })
    }

    const onSubmit = () => {

        let sendToArray = [];
        if (inputs.allTeachers) {
            sendToArray.push({ "type": "ALL_TEACHERS" })
        }
        if (inputs.allParents) {
            sendToArray.push({ "type": "ALL_PARENTS" })
        }
        if (inputs.selClass) {
            let classArray = [
                {
                    "type": "CLASSES",
                    "recipients": []
                }
            ];
            classSelected.map((ev) => {
                classArray[0].recipients.push(ev.value)
            })

            sendToArray.push(classArray[0])
        }
        if (inputs.selDept) {
            let deptArray = [
                {
                    "type": "DEPARTMENTS",
                    "recipients": []
                }
            ];
            deptSelected.map((ev) => {
                deptArray[0].recipients.push(ev.value)
            })
            sendToArray.push(deptArray[0])
        }

        if (inputs.selIndv) {
            let indvArray = [
                {
                    "type": "INDIVIDUALS",
                    "recipients": []
                }
            ];
            selProfilesList.map((ev) => {
                indvArray[0].recipients.push(ev.value)
            })
            sendToArray.push(indvArray[0])
        }

        let obj = {
            sendTo: sendToArray,
            "subject": inputs.subject,
            "validStart": announceType == 'compose' ? null : moment(inputs.scheduleDate).format(),
            "validUntil": moment(inputs.untilDate).format(),
            // "description": inputs.description,
            description: inputs.reasonContent,
            "senderID": session.profile._key,
            "orgID": session.orgID
        }

        setTimeout(() => {
            onCreate(obj)
            // console.log(obj);
        }, 1000);


    }


    const onUpdate = () => {
        let sendToArray = [];
        if (inputs.allTeachers) {
            sendToArray.push({ "type": "ALL_TEACHERS" })
        }
        if (inputs.allParents) {
            sendToArray.push({ "type": "ALL_PARENTS" })
        }
        if (inputs.selClass) {
            let classArray = [
                {
                    "type": "CLASSES",
                    "recipients": []
                }
            ];
            classSelected.map((ev) => {
                classArray[0].recipients.push(ev.value)
            })

            sendToArray.push(classArray[0])
        }
        if (inputs.selDept) {
            let deptArray = [
                {
                    "type": "DEPARTMENTS",
                    "recipients": []
                }
            ];
            deptSelected.map((ev) => {
                deptArray[0].recipients.push(ev.value)
            })
            sendToArray.push(deptArray[0])
        }

        let obj = {
            sendTo: sendToArray,
            subject: inputs.subject,
            validStart: moment(inputs.scheduleDate).format(),
            validUntil: moment(inputs.untilDate).format(),
            description: inputs.reasonContent ? inputs.reasonContent : annoucementInfo.description,
            announcementID: announceId,
            profileID: session.profile._key
        }

        onUpdation(obj)

    }



    const getClasses = () => {
        try {
            eduAPI.get(`/class/byOrg/${session.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                let formatData = data.data.data;
                formatData.map((sData) => {
                    sData.label = sData.classData.name;
                    sData.value = sData.classData.classID
                })
                setClassesList(formatData)

                if (editTimeCls) {
                    let clsArray = [];
                    editTimeClsData.find((key) => {
                        for (let i = 0; i < formatData.length; i++) {
                            if (key == formatData[i].classData.classID) {
                                clsArray.push(
                                    {
                                        label: formatData[i].classData.name,
                                        value: formatData[i].classData.classID
                                    })
                            }
                        }
                    })
                    setInputs({ ...inputs, 'selClass': true })
                    setClassSelected(clsArray)
                }
            });
        } catch (error) {
            console.error("fetch classes error", error);
        }
    }

    const getDepts = async () => {
        try {
            await eduAPI.get(`/department/byOrg?orgID=${session.orgID}`, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                let formatData = data.data.data;
                formatData.map((sData) => {
                    sData.label = sData.name;
                    sData.value = sData.departmentID
                })
                setDepartmentList(formatData)
                if (editTimeDept) {
                    let deptArray = [];
                    editTimeDeptData.find((key) => {
                        for (let i = 0; i < formatData.length; i++) {
                            if (key == formatData[i].departmentID) {
                                deptArray.push(
                                    {
                                        label: formatData[i].name,
                                        value: formatData[i].departmentID
                                    })
                            }
                        }
                    })
                    setInputs({ ...inputs, 'selDept': true })
                    setDeptSelected(deptArray)
                }
            });
        } catch (error) {
            console.error("fetch department error", error);
        }
    }

    const setDepartmentsEditTime = (obj) => {
        obj.sendTo.find((data) => {
            if (data.type == 'ALL_TEACHERS') {
                setInputs({ ...inputs, allTeachers: true })
            }
            if (data.type == 'ALL_PARENTS') {
                setInputs({ ...inputs, allParents: true })
            }
            if (data.type == 'CLASSES') {

                let clsArray = [];
                data.recipients.find((key) => {
                    for (let i = 0; i < classesList.length; i++) {
                        if (key == classesList[i].classData.classID) {
                            clsArray.push(
                                {
                                    label: classesList[i].classData.name,
                                    value: classesList[i].classData.classID
                                })
                        }
                    }
                })



                setTimeout(() => {
                    setInputs({ ...inputs, 'selClass': true })
                    setClassSelected(clsArray)
                }, 1000);
            }
            if (data.type == 'DEPARTMENTS') {

                let deptArray = [];
                data.recipients.find((key) => {
                    for (let i = 0; i < departmentList.length; i++) {
                        if (key == departmentList[i].departmentID) {
                            deptArray.push(
                                {
                                    label: departmentList[i].name,
                                    value: departmentList[i].departmentID
                                })
                        }
                    }
                })
                setTimeout(() => {
                    setInputs({ ...inputs, 'selDept': true })
                    setDeptSelected(deptArray)
                }, 1000);
            }
            if (data.type == 'INDIVIDUALS') {
                inputs.allTeachers = true
            }
        })
    }

    const [editTimeCls, setEditTimeCls] = useState(false)
    const [editTimeDept, setEditTimeDept] = useState(false)
    const [editTimeClsData, setEditTimeClsData] = useState([])
    const [editTimeDeptData, setEditTimeDeptData] = useState([])

    const [annoucementInfo, setAnnoucementInfo] = useState({})

    const setAnnouncementFields = (obj) => {
        setAnnoucementInfo(obj)
        let sendToArray = [];
        let setObj = {
            sendTo: sendToArray,
            subject: obj.subject,
            scheduleDate: moment(obj.validStart).format("YYYY-MM-DD[T]HH:mm:ss"),
            untilDate: moment(obj.validUntil).format("YYYY-MM-DD[T]HH:mm:ss"),
            description: obj.description,
            //  reasonContent: obj.description,
            reason: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(obj.description)
                )
            )
        }

        setState({
            ...state, reason: obj.description ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(obj.description)
                )
            ) : null
        })

        obj.sendTo.find((data) => {
            if (data.type == 'ALL_TEACHERS') {
                //setInputs({ ...inputs, allTeachers: true })
                setObj.allTeachers = true
            }
            if (data.type == 'ALL_PARENTS') {
                // setInputs({ ...inputs, allParents: true })
                setObj.allParents = true
            }
            if (data.type == 'CLASSES') {
                setEditTimeCls(true)
                setEditTimeClsData(data.recipients)
            }
            if (data.type == 'DEPARTMENTS') {
                setEditTimeDept(true)
                setEditTimeDeptData(data.recipients)
            }
            if (data.type == 'INDIVIDUALS') {
            }
        })

        setInputs(setObj)


        // setDepartmentsEditTime(obj)
    }

    const getAnnouncementInfo = async (id) => {
        try {
            await eduAPI.get("/announcement/populateAnnouncement/" + id, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                setAnnouncementFields(data.data.data)
                if (data.data.data.announcement_attachments) {
                    setAttachements(data.data.data.announcement_attachments)
                }
            });
        } catch (error) {
            console.error("fetch annoucement error", error);
        }
    }
    useEffect(() => {
        if (status.update == 'failed') {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
        }
        if (status.create == "failed") {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
        }
    }, [resErr])
    const [apiStatus, setApiStatus] = useState('')
    useEffect(() => {
        if (status.create == 'success') {
            if (status.response.announcementID) {
                if (newFilesInfo?.files?.length) {
                    setApiStatus(status.create == 'success' ? 'create' : 'update')
                    submitFiles(status.response.announcementID)
                    onReset();
                } else {
                    let msg = "Announcement Created Successfully..";
                    setToastMsg(msg)
                    onOpenToaster()
                    setInputs(initialState)
                    onReset();
                    setTimeout(() => {
                        history.push('/announcement');
                    }, 3000);
                }
            }
        }

        if (status.update === 'success') {
            if (location.state.announcementID) {
                if (newFilesInfo?.files?.length || removeList.length) {
                    setApiStatus(status.create == 'success' ? 'create' : 'update')
                    submitFiles(location.state.announcementID)
                    onReset();
                } else {
                    let msg = "Announcement Updated Successfully..";
                    setToastMsg(msg)
                    onOpenToaster()
                    setInputs(initialState)
                    onReset();
                    setTimeout(() => {
                        history.push('/announcement');
                    }, 3000);
                }
            }
        }

        if (status.upload === 'success') {
            let msg = apiStatus == 'create' ? "Announcement Created Successfully.." : "Announcement Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster()
            setInputs(initialState)
            onReset();
            setTimeout(() => {
                history.push('/announcement');
            }, 3000);
        }
    }, [status]);

    useEffect(() => {
        setAnnounceType(location.state.type)
        if (location.state.announcementID) {
            setAnnounceId(location.state.announcementID)
            getAnnouncementInfo(location.state.announcementID);
        }
    }, [location]);

    useEffect(() => {
        if (inputs.indvText) {
            if (inputs.indvText.length > 0) {
                getUserProfile()
            }
        }
    }, [inputs])

    useEffect(() => {
        if (inputs.allParents || inputs.allTeachers) {
            setdisableState(true)
            setInputs({ ...inputs, selClass: false })
            setInputs({ ...inputs, selDept: false })
            setInputs({ ...inputs, selIndv: false })
        } else {
            setdisableState(false)
        }
    }, [inputs.allParents, inputs.allTeachers])



    const [profilesList, setProfileList] = useState([])
    const [selProfilesList, setSelProfileList] = useState([])

    const getUserProfile = async () => {
        setProfileList([])
        let url = `/profile/existing/?searchStr=${inputs.indvText}&orgID=${session.orgID}&searchField=NAME`
        try {
            await eduAPI.get(url, {
                headers: {
                    Authorization: Session.BearerToken(),
                }
            }).then((data) => {
                let filData = data.data.data;
                filData.map((sData) => {
                    sData.label = sData.firstName + ' ' + sData.lastName;
                    sData.value = sData.profileID
                })
                setProfileList(filData)
            });
        } catch (error) {
            console.error("fetch techers error", error);
        }
    }
    const initialStateEditor = {
        reason: EditorState.createEmpty(),
        note: EditorState.createEmpty(),
        documents: [],
        labRequestDoc: {},
        imagingRequestDoc: {}
    }
    const [state, setState] = useState(initialStateEditor);
    const onRichTextChange = (editorState, name, contentName) => {
        let textValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let replacedText = textValue.replace(/<\/?[^>]+>/ig, "").trim();

        setInputs({ ...inputs, [contentName]: textValue, [name]: replacedText });
        //setError(initialError);

        setState({
            ...state,
            [name]: editorState
        });
    }
    const [newFilesInfo, setNewFilesInfo] = useState([]);

    const allOptions = ["Option One", "Option Two", "Option Three", "Option Four"];
    const [selectedOption, setSelectedOption] = useState(allOptions[0]);

    const [searchText, setSearchText] = useState("");
    const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const displayedOptions = useMemo(
        () => allOptions.filter((option) => containsText(option, searchText)),
        [searchText]
    );


    const updateUploadedFiles = (files) =>
        setNewFilesInfo({ ...newFilesInfo, files });


    const submitFiles = (key) => {

        let formData = new FormData();

        for (let i = 0; i < newFilesInfo?.files?.length; i++) {
            formData.append("announcement_attachments", newFilesInfo.files[i]);
        }


        for (let i = 0; i < removeList.length; i++) {
            formData.append("fileKeysToDelete[]", removeList[i]);
        }


        formData.append("type", "ANNOUNCEMENTS")
        formData.append("objectID", key)
        formData.append("orgID", session.orgID)
        formData.append("profileID", session.profile._key)

        setTimeout(() => {
            onUploadFile(formData)
        }, 500);

    };


    const removeFile = (index, id) => {
        attachements.splice(index, 1);
        setRemoveList([...removeList, id]);
    }

    const handleTextChange = (value) => {
        setInputs({ ...inputs, indvText: value })
    }

    const handleSetSelectProfileList = (value) => {
        setSelProfileList(value);
    }

    return (
        <>
            <Toaster></Toaster>
            <div className={classes.container}>

                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                    style={{
                        paddingLeft: '50px',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'white',
                        marginTop: -10,
                        marginLeft: -30,
                        marginRight: -30,
                    }}
                >
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            color: "black",
                            textDecoration: 'none'
                        }}
                    >
                        Announcement
                    </Link>
                    {announceType == 'schedule' ?
                        <Link
                            style={{
                                fontSize: 20,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                color: "rgba(124, 123, 123, 1)",
                            }}
                            href="/announcement_create"
                        >
                            Schedule New
                        </Link>
                        :
                        <Link
                            style={{
                                fontSize: 20,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                color: "rgba(124, 123, 123, 1)",
                            }}
                            href="/announcement_create"
                        >
                            Compose New
                        </Link>
                    }
                </Breadcrumbs>

                <Paper
                    className={classes.paper2}
                    style={{ borderRadius: 10, width: '100%', padding: '20px', marginTop: '10px' }}>
                    <Grid container>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '10px', fontWeight: 'bold' }}>To</div>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <div>
                                <CheckBox

                                    onChange={(e) => {
                                        setInputs({ ...inputs, allParents: inputs.allParents ? false : true })
                                    }
                                    }
                                    //   onChange={onParentsChange}
                                    options={[
                                        {
                                            label: "All Parents",
                                            name: "Parents",
                                            checked: inputs.allParents
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <CheckBox

                                    onChange={(e) => {
                                        setInputs({ ...inputs, allTeachers: inputs.allTeachers ? false : true })
                                    }
                                    }
                                    // onChange={onTeachersChange}
                                    options={[
                                        {
                                            label: "All Teachers",
                                            name: "Teachers",
                                            checked: inputs.allTeachers
                                        },
                                    ]}
                                />
                            </div>

                            <div>
                                <Grid container>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <div>
                                            <CheckBox

                                                onChange={(e) => {
                                                    setInputs({ ...inputs, selClass: inputs.selClass ? false : true })
                                                }
                                                }
                                                options={[
                                                    {
                                                        label: "Classes",
                                                        name: "classes",
                                                        disabled: disableState,
                                                        checked: inputs.selClass
                                                    },
                                                ]}
                                            />



                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <div>
                                            {inputs.selClass ? <MultiSelect
                                                options={classesList}
                                                value={classSelected}
                                                onChange={setClassSelected}
                                                labelledBy={"Select Class"}
                                            /> : ''}
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                            <div>
                                <Grid container>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <div>
                                            <CheckBox

                                                onChange={(e) => {
                                                    setInputs({ ...inputs, selDept: inputs.selDept ? false : true })
                                                }
                                                }
                                                options={[
                                                    {
                                                        label: "Departments",
                                                        name: "Departments",
                                                        disabled: disableState,
                                                        checked: inputs.selDept
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <div>
                                            {inputs.selDept ?

                                                <MultiSelect
                                                    options={departmentList}
                                                    value={deptSelected}
                                                    onChange={setDeptSelected}
                                                    labelledBy={"Select Departments"}
                                                />
                                                : ''}

                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                            <div>


                                <Grid container>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        <div>
                                            <CheckBox

                                                onChange={(e) => {
                                                    setInputs({ ...inputs, selIndv: inputs.selIndv ? false : true })
                                                }
                                                }
                                                options={[
                                                    {
                                                        label: "Individual",
                                                        name: "Individual",
                                                        disabled: disableState,
                                                        checked: inputs.selIndv
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <div>
                                            {inputs.selIndv &&
                                                // <Input
                                                //     id="1"
                                                //     required={true}
                                                //     name="Enter student or teacher name here"
                                                //     placeholder="Enter student or teacher name here"
                                                //     value={inputs.indvText}
                                                //     onChange={(e) => {
                                                //         setInputs({ ...inputs, indvText: e.target.value })
                                                //     }}
                                                // >
                                                // </Input>

                                                // Gurvin asked for this change for better look
                                                // https://v4.mui.com/components/autocomplete/#multiple-values
                                                <Autocomplete
                                                    multiple
                                                    id="1"
                                                    options={profilesList}
                                                    onChange={(object, value)=> handleSetSelectProfileList(value)}
                                                    getOptionLabel={(option) => option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            onChange={(e) => {
                                                                handleTextChange(e.target.value);
                                                            }}
                                                            required={true}
                                                            value={inputs.indvText}
                                                            variant="standard"
                                                            label="Enter student or teacher name here"
                                                            placeholder="Enter student or teacher name here"
                                                        />
                                                    )}
                                                />
                                            }
                                        </div>
                                        {/* {(inputs.selIndv && profilesList.length) ?

                                            <MultiSelect
                                                options={profilesList}
                                                value={selProfilesList}
                                                onChange={setSelProfileList}
                                                labelledBy={"Select Profiles"}
                                            />
                                        : ''}  */}
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>


                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '10px', fontWeight: 'bold' }}>Subject</div>
                        </Grid>

                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <div style={{ marginBottom: '10px' }}>
                                <Input
                                    value={inputs.subject}
                                    label=""
                                    name="SubjectName"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, subject: e.target.value })
                                    }}
                                ></Input>
                            </div>
                        </Grid>

                        {announceType == 'schedule' ?
                            <Grid item lg={6} md={6} sm={6} xs={6}>

                                <Grid container>
                                    <Grid item lg={2} md={2} sm={2} xs={2}></Grid>

                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '10px', fontWeight: 'bold' }}>Schedule</div>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={3} xs={3}>
                                        <TextField
                                            style={{ border: '1px solid #ddd', padding: '2px 5px', borderRadius: '4px' }}
                                            id="datetime-local"
                                            label=""
                                            type="datetime-local"
                                            value={inputs.scheduleDate}
                                            onChange={(event) => scheduleDateChange(event)}
                                            //   defaultValue="2017-05-24T10:30"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            : ''}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container>
                                <Grid item lg={2} md={2} sm={0} xs={0}></Grid>

                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                    <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '10px', fontWeight: 'bold' }}>Valid Until</div>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={6}>

                                    <TextField
                                        style={{ border: '1px solid #ddd', padding: '2px 5px', borderRadius: '4px' }}
                                        id="datetime-local"
                                        label=""
                                        type="datetime-local"
                                        value={inputs.untilDate}
                                        onChange={(event) => validDateChange(event)}
                                        // defaultValue="2017-05-24T10:30"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Rich Editor */}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div style={{ marginTop: '10px' }}>
                                <RichTextEditor
                                    editorState={state.reason}
                                    name="reason"
                                    required={true}
                                    value={inputs.reason}
                                    error={inputs.reason === ""}
                                    label="rich text editor"
                                    placeholder=" Please Enter Description...."
                                    onChange={(editorState) => onRichTextChange(editorState, 'reason', 'reasonContent')} />
                            </div>
                            <div style={{ marginTop: '100px' }}>
                                <FileUpload
                                    label=""
                                    multiple
                                    updateFilesCb={updateUploadedFiles}
                                />
                            </div>


                            <Grid item lg={4} md={4} sm={4} xs={12} style={{ marginTop: '10px' }}>
                                <>{attachements.length > 0 && <div className={classes.subHdr}>Attached Documents</div>}</>
                                {attachements.map((fileObj, index) =>
                                    <>
                                        <List style={{
                                            border: '1px solid #ddd', margin: '5px', color: '#756F86', background: '#DBE2EA', fontSize: '20px'
                                        }}>
                                            <ListItem button >
                                                <ListItemText primary={fileObj.fileName} style={{ color: "#6386af", fontSize: '18px' }} />
                                                <CloseIcon onClick={() => removeFile(index, fileObj.fileID)} />
                                            </ListItem>
                                        </List>
                                    </>
                                )}
                            </Grid>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                {announceId ?
                                    <Button
                                        label="Update"
                                        type="primary"
                                        size="large"
                                        // className={classes.button} 
                                        style={{ marginLeft: '0%', width: '160px' }}
                                        onClick={onUpdate}>
                                        Update
                                    </Button>
                                    :
                                    <Button
                                        label="Send"
                                        type="primary"
                                        size="large"
                                        // className={classes.button} 
                                        style={{ marginLeft: '0%', width: '160px' }}
                                        onClick={onSubmit}>
                                        Send
                                    </Button>
                                }
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
            </div >
        </>
    )
}


/**
 * Styles
 */

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2%'
    },
    subHdr: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#FDB714'
    },
    file: {
        border: '1px solid #ddd', margin: '5px', width: '30%', padding: '15px 10px', color: '#756F86', background: '#DBE2EA', fontSize: '20px'
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        flexDirection: 'row',
        display: 'flex'
    },
    header: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '10%',
        flexDirection: 'column',
        display: 'flex'
    },
    title: {
        color: 'dark blue grad'

    },
    container: {
        // marginTop: '2%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%',
        paddingBottom: '5%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {
        height: '100vh',
        padding: '2em 0em'
    },
    button: {
        background: '#FDB714',
        border: 5,
        borderWidth: 15,
        borderRadius: 5,
        boxShadow: '0 4px 10px 0px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 35,
        padding: '0 30px',
        width: "100",
        fontWeight: 500,
        fontFamily: 'Poppins',
        fontSize: 14,

    },
    paper: {
        display: 'flex',
        width: 100,
        height: 'auto'
    },
    paper2: {
        display: 'flex',
        width: 100,
        height: 'auto'
    },
    control: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

export default withRouter(AnnouncementCreate);