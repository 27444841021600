import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import Button from 'components/common/button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import IconButton from "@material-ui/core/IconButton";
import Carousel from 'react-material-ui-carousel'
import { Input } from 'components/common/form-unit'
import { DataGrid } from '@material-ui/data-grid';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import eduAPI from "../../../api/api";
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PlusIcon from '@material-ui/icons/AddCircle';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import useToaster from 'hooks/use-toaster';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


/**
 * Announcement Component Creation  
 */
const ITEM_HEIGHT = 48;
const Announcement = ({ status, resErr, onReset, deleteAnnoucement }) => {
    const history = useHistory();
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [receivedrows, setReceivedrows] = useState([])
    const [scheduleRows, setScheduleRows] = useState([])
    const [sentRows, setSentRows] = useState([])
    const openMenu = Boolean(anchorEl);
    const openMenu2 = Boolean(anchorEl2);
    const [selectedIndex, setSelIndex] = useState();
    const [openEdit, setOpenEdit] = React.useState(false);
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const sessionObj = useSession();

    useEffect(() => {
        if (status.delete == 'failed') {
            setToastMsg("Error: " + resErr.delete?.message)
            onReset();
            onOpenToaster();
        }
    }, [resErr])

    useEffect(() => {
        if (status.delete == 'success') {
            let msg = "Announcement Deleted Successfully..";
            setToastMsg(msg)
            onReset();
            onOpenToaster();
            fetchAnnoucements()
        }
    }, [status]);

    const [fetch, setFetch] = useState(true)
    useEffect(() => {
        if (sessionObj?.profile?._key && fetch) {
            setFetch(false)
            fetchAnnoucements();
        }
    }, [sessionObj])

    const fetchAnnoucements = async () => {
        if (sessionObj?.profile?._key) {
            let tchrObj = [];
            try {
                await eduAPI.get(`/announcement/viewAnnouncements/?orgID=${sessionObj.orgID}&profileID=${sessionObj?.profile?._key}`, {
                    headers: {
                        Authorization: Session.BearerToken(),
                    }
                }).then((data) => {
                    setReceivedrows(data.data.data.received)
                    setSentRows(data.data.data.received)
                    setScheduleRows(data.data.data.scheduled)
                });
            } catch (error) {
                console.error("fetch Annoucements error", error);
            }
        }
    }

    // Page redirect to announcement create page
    const composeClickButton = () => {
        history.push(
            {
                pathname: '/announcement_create',
                state: { type: 'compose' }
            });
    }

    // Page redirect to announcement create page
    const scheduleClickButton = () => {
        history.push(
            {
                pathname: '/announcement_create',
                state: { type: 'schedule' }
            });
    }


    // Page redirect to announcement view page
    const viewAnnouncementClick = (obj, type) => {
        history.push(
            {
                pathname: '/announcement_view',
                state: { id: obj.announcementID, type: type }
            });
    }

    // Datagrid rows Edit and Delete columns click events
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };

    const handleClick2 = (event, index) => {
        setAnchorEl2(event.currentTarget);
        setSelIndex(index)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleDelAnnouncement = () => {
        handleClose();
        let obj = {
            "announcementID": sentRows[selectedIndex].announcementID,
            "profileID": sessionObj.profile._key
        }
        deleteAnnoucement(obj)
    }

    const scheduleDelAnnouncement = () => {
        handleClose2();
        let obj = {
            "announcementID": scheduleRows[selectedIndex].announcementID,
            "profileID": sessionObj.profile._key
        }
        deleteAnnoucement(obj)
    }


    const scheduleEditAnnouncement = () => {
        handleClose2();
        history.push(
            {
                pathname: '/announcement_create',
                state: { type: 'schedule', announcementID: scheduleRows[selectedIndex].announcementID, }
            });
    }

    const [receveSearchTerm, setReceveSearchTerm] = useState("");
    const [sentSearchTerm, setSentSearchTeam] = useState("")
    const [shdlSearchTerm, setShdlSearchTeam] = useState("")


    const filterSearch = (event, type) => {
        let searchVal = event.target.value.toLowerCase();
        switch (type) {
            case 'receive': {
                setReceveSearchTerm(searchVal);
                break;
            }
            case 'sehedule': {
                setShdlSearchTeam(searchVal);
                break;
            }
            case 'sent': {
                setSentSearchTeam(searchVal);
                break;
            }

        }

    }

    const { t } = useTranslation();


    return (
        <>
            <Toaster></Toaster>
            <div className={classes.container}>

                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                    style={{
                        paddingLeft: '50px',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'white',
                        marginTop: -10,
                        marginLeft: -30,
                        marginRight: -30,
                    }}
                >
                    <Link
                        style={{
                            fontSize: 20,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            color: "black",
                            textDecoration: 'none'
                        }}
                    >
                        Announcement
                    </Link>
                </Breadcrumbs>

                <div className={classes.header}>
                    <Grid container justify="center" spacing={spacing} style={{ marginTop: '-80px' }}>
                        {/* Carousel Section Starts*/}
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Paper
                                className={classes.paper2}
                                style={{ borderLeft: '6px solid rgb(27 136 230)', borderRadius: 10, width: '100%', padding: '10px' }}>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {receivedrows.length ?
                                            <Carousel>
                                                {
                                                    receivedrows.map((item, i) =>
                                                        <Grid container>
                                                            <Grid item lg={9} md={9} sm={9} xs={9} style={{ color: '#424241', fontSize: '20px', margin: 'auto' }}>
                                                                {item.subject}
                                                            </Grid>

                                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                                <ListItem>
                                                                    <ListItemAvatar>
                                                                        {item.from.profile_picture ? <Avatar>
                                                                            <img src={item.from.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                                                                        </Avatar> : <Avatar style={{ background: '#FDB714' }}>{item.from.name.charAt(0)}</Avatar>}

                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={item.from.name} secondary="" />
                                                                </ListItem>
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.description}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.description
                                                                    }} ></div>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                            </Carousel>
                                            :
                                            <Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.caurosalEmpty}>
                                                    Announcements Data Empty
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Create Announcement buttons section*/}
                        <Grid item lg={3} md={3} sm={12} xs={12} style={{ margin: 'auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                                    startIcon={<AddCircleOutlineOutlinedIcon />}
                                    label={t('action.compose')}
                                    type="primary"
                                    onClick={composeClickButton}
                                />

                                <Button
                                    startIcon={<AccessTimeOutlinedIcon />}
                                    label={t('action.schedule')}
                                    type="primary"
                                    onClick={scheduleClickButton}
                                />

                            </div>
                        </Grid>
                    </Grid>
                </div>

                {/* Received Datatable
            * Passing rows and columns information to the <Datagrid> tag
            */}
                <Paper
                    style={{ borderRadius: 6, width: '100%', height: 'auto', padding: '20px', borderTop: '5px solid #4DC591' }}>
                    <div style={{ height: 'auto', width: '100%' }}>

                        <Grid container>
                            <Grid item lg={9} md={6} sm={12} xs={12}>
                                <div style={{ fontWeight: 'bold', fontSize: '20px', color: '#424241' }}>Received</div>
                            </Grid>

                            <Grid item lg={3} md={6} sm={12} xs={12} style={{ textAlign: 'right' }}>
                                <Grid container spacing={spacing} >
                                    <Grid item lg={10} style={{ textAlign: 'right' }}>
                                        <Input onKeyUp={(event) => filterSearch(event, 'receive')} placeholder="Search By Subject/Description.." />
                                    </Grid>
                                    <Grid item lg={2} style={{ textAlign: 'left' }}>
                                        <IconButton
                                            color="#FFC000"
                                        >
                                            <SearchOutlinedIcon
                                                style={{
                                                    color: "black",
                                                    background: 'rgba(253, 183, 20, 1)',
                                                    padding: 7,
                                                    width: 55,
                                                    height: 40,
                                                    marginTop: -4,
                                                    marginLeft: -30,
                                                    borderRadius: 4
                                                }} />
                                        </IconButton>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>

                        <TableContainer className={classes.tableContainer} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.titles}>From</TableCell>
                                        <TableCell align="left" className={classes.titles}>Subject</TableCell>
                                        <TableCell align="left" className={classes.titles}>Date</TableCell>
                                    </TableRow>
                                </TableHead>


                                {receivedrows.length == 0 ? <TableBody><TableRow><TableCell></TableCell><TableCell>Received Announcements Empty</TableCell><TableCell></TableCell></TableRow></TableBody> :
                                    <TableBody>
                                        {receivedrows.filter(item => {
                                            return (
                                                item.subject.toLowerCase().indexOf(receveSearchTerm) > -1 || item.description.toLowerCase().indexOf(receveSearchTerm) > -1
                                            );
                                        })
                                            .map((row, index) => (

                                                <TableRow key={row.announcementID}>
                                                    <TableCell align="left" className={classes.dataText}>
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                {row.from.profile_picture ? <Avatar>
                                                                    <img src={row.from.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                                                                </Avatar> : <Avatar style={{ background: '#FDB714' }}>{row.from.name.charAt(0)}</Avatar>}
                                                            </ListItemAvatar>
                                                            <ListItemText primary={row.from.name} secondary="" />
                                                        </ListItem>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.dataText} >
                                                        <div className={classes.description2} onClick={(event) => { viewAnnouncementClick(row, "Received") }}>
                                                            <span>{row.subject} : </span>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: row.description
                                                                }} ></span>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.dataText}>
                                                        {moment(row.createdAt).format('MMMM DD,YYYY | HH:mm a')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                }

                            </Table>
                        </TableContainer>

                    </div>
                </Paper>

                {/* Sent Datatable
            * Passing rows and columns information to the <Datagrid> tag
            */}

                <Paper
                    className={classes.paper2}
                    style={{ borderRadius: 6, width: '100%', height: 'auto', padding: '20px', marginTop: '10px', borderTop: '5px solid #F2994A' }}>
                    <div style={{ height: 'auto', width: '100%' }}>

                        <Grid container>
                            <Grid item lg={9} md={6} sm={12} xs={12}>
                                <div style={{ fontWeight: 'bold', fontSize: '20px', color: '#424241' }}>Sent</div>
                            </Grid>

                            <Grid item lg={3} md={6} sm={12} xs={12} style={{ textAlign: 'right' }}>
                                <Grid container spacing={spacing} >
                                    <Grid item lg={10} style={{ textAlign: 'right' }}>
                                        <Input onKeyUp={(event) => filterSearch(event, 'sent')} placeholder="Search By Subject/Description.." />
                                    </Grid>
                                    <Grid item lg={2} style={{ textAlign: 'left' }}>
                                        <IconButton
                                            color="#FFC000"
                                        >
                                            <SearchOutlinedIcon
                                                style={{
                                                    color: "black",
                                                    background: 'rgba(253, 183, 20, 1)',
                                                    padding: 7,
                                                    width: 55,
                                                    height: 40,
                                                    marginTop: -4,
                                                    marginLeft: -30,
                                                    borderRadius: 4
                                                }} />
                                        </IconButton>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>

                        <TableContainer className={classes.tableContainer} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.titles}>From</TableCell>
                                        <TableCell align="left" className={classes.titles}></TableCell>
                                        <TableCell align="left" className={classes.titles}>Subject</TableCell>
                                        <TableCell align="left" className={classes.titles}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                {sentRows.length == 0 ? <TableBody><TableRow><TableCell></TableCell><TableCell>Sent Announcements Empty</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow></TableBody> :
                                    <TableBody>
                                        {sentRows.filter(item => {
                                            return (
                                                item.subject.toLowerCase().indexOf(sentSearchTerm) > -1 || item.description.toLowerCase().indexOf(sentSearchTerm) > -1
                                            );
                                        }).map((row, index) => (
                                            <TableRow key={row.announcementID}>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <AvatarGroup max={4}>
                                                        {row.sendTo.map((val, index) => (
                                                            <Avatar alt={val.name.charAt(0)} src={val.profile_picture == false ? val.name : val.profile_picture}
                                                                style={{
                                                                    background: val.name.charAt(0) == "T" ? "#FDB714" : "#1C72CC",
                                                                }} />
                                                        ))}
                                                    </AvatarGroup>

                                                </TableCell>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <div className={classes.txtoverflow2} >
                                                        {row.sendTo.map((val, index) => (
                                                            <span>{val.name} {row.sendTo.length - 1 !== index ? ',' : ''}</span>
                                                        ))}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.dataText} >
                                                    <div className={classes.description2} onClick={(event) => { viewAnnouncementClick(row, "Sent") }}>
                                                        <span>{row.subject} : </span>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: row.description
                                                            }} ></span>

                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <div>  {moment(row.createdAt).format('MMMM DD,YYYY | HH:mm a')}

                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => { handleClick(event, index) }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={openMenu}
                                                            onClose={handleClose}
                                                            PaperProps={{
                                                                elevation: 1,
                                                                style: {
                                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                                    width: "20ch",
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    handleDelAnnouncement();
                                                                }}
                                                            >
                                                                {'Delete'}
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>


                    </div>
                </Paper>

                {/* Schedule Datatable
            * Passing rows and columns information to the <Datagrid> tag
            */}

                <Paper
                    className={classes.paper2}
                    style={{ borderRadius: 6, width: '100%', height: 'auto', padding: '20px', marginTop: '10px', borderTop: '5px solid #4DC591' }}>
                    <div style={{ height: 'auto', width: '100%' }}>

                        <Grid container>
                            <Grid item lg={9} md={6} sm={12} xs={12}>
                                <div style={{ fontWeight: 'bold', fontSize: '20px', color: '#424241' }}>Scheduled</div>
                            </Grid>

                            <Grid item lg={3} md={6} sm={12} xs={12} style={{ textAlign: 'right' }}>
                                <Grid container spacing={spacing} >
                                    <Grid item lg={10} style={{ textAlign: 'right' }}>
                                        <Input onKeyUp={(event) => filterSearch(event, 'sehedule')} placeholder="Search By Subject/Description.." />
                                    </Grid>
                                    <Grid item lg={2} style={{ textAlign: 'left' }}>
                                        <IconButton
                                            color="#FFC000"
                                        >
                                            <SearchOutlinedIcon
                                                style={{
                                                    color: "black",
                                                    background: 'rgba(253, 183, 20, 1)',
                                                    padding: 7,
                                                    width: 55,
                                                    height: 40,
                                                    marginTop: -4,
                                                    marginLeft: -30,
                                                    borderRadius: 4
                                                }} />
                                        </IconButton>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                        <TableContainer className={classes.tableContainer} >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.titles}>From</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="left" className={classes.titles}>Subject</TableCell>
                                        <TableCell align="left" className={classes.titles}>Scheduled for</TableCell>
                                    </TableRow>
                                </TableHead>
                                {scheduleRows.length == 0 ? <TableBody><TableRow><TableCell></TableCell><TableCell>Schedule Announcements Empty</TableCell><TableCell></TableCell><TableCell></TableCell></TableRow></TableBody> :
                                    <TableBody>
                                        {scheduleRows.filter(item => {
                                            return (
                                                item.subject.toLowerCase().indexOf(shdlSearchTerm) > -1 || item.description.toLowerCase().indexOf(shdlSearchTerm) > -1
                                            );
                                        }).map((row, index) => (
                                            <TableRow key={row.announcementID}>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <AvatarGroup max={4}>
                                                        {row.sendTo.map((val, index) => (
                                                            <Avatar alt={val.name.charAt(0)} src={val.profile_picture == false ? val.name : val.profile_picture}
                                                                style={{
                                                                    background: val.name.charAt(0) == "T" ? "#FDB714" : "#1C72CC",
                                                                }} />
                                                        ))}
                                                    </AvatarGroup>
                                                </TableCell>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <div className={classes.txtoverflow2} >
                                                        {row.sendTo.map((val, index) => (
                                                            <span>{val.name} {row.sendTo.length - 1 !== index ? ',' : ''}</span>
                                                        ))}
                                                    </div>
                                                </TableCell>

                                                <TableCell align="left" className={classes.dataText} >
                                                    <div className={classes.description2} onClick={(event) => { viewAnnouncementClick(row, "Schedule") }}>
                                                        <span>{row.subject} : </span>

                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: row.description
                                                            }} ></span>

                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={classes.dataText}>
                                                    <div>  {moment(row.createdAt).format('MMMM DD,YYYY | HH:mm a')}
                                                        <IconButton
                                                            style={{ paddingTop: '0px', paddingBottom: '0px' }}
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => { handleClick2(event, index) }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                            anchorEl={anchorEl2}
                                                            keepMounted
                                                            open={openMenu2}
                                                            onClose={handleClose2}
                                                            PaperProps={{
                                                                elevation: 1,
                                                                style: {
                                                                    width: "20ch",
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    scheduleEditAnnouncement();
                                                                }}
                                                            >
                                                                {'Edit'}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    scheduleDelAnnouncement();
                                                                }}
                                                            >
                                                                {'Delete'}
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }</Table>
                        </TableContainer>

                    </div>
                </Paper>
            </div >
        </>
    )
}

/**
 * Styles
 */

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2%'
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '3%',
        flexDirection: 'row',
        display: 'flex'
    },
    header: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '10%',
        flexDirection: 'column',
        display: 'flex'
    },
    title: {
        color: 'dark blue grad'

    },
    container: {
        // marginTop: '2%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%',
        paddingBottom: '5%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {
        // height: '100vh',
        // padding: '2em 0em'
    },
    button: {
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 60,
        padding: '0 30px',
        width: "20%",

    },
    paper: {
        display: 'flex',
        height: 140,
        width: 100
    },
    paper2: {
        display: 'flex',
        width: 100
    },
    control: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    txtoverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '450px'
    },
    txtoverflow2: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '200px'
    },
    caurosalEmpty: {
        textAlign: 'center',
        marginBottom: 40,
        marginTop: 40,
        fontSize: 20
    },
    description: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        //whiteSpace: 'nowrap',
        width: '100%',
        height: '100px'
    },
    description2: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        width: '450px',
        height: '40px'
        //whiteSpace: 'nowrap',
    }
}));

export default withRouter(Announcement);


{/* <DataGrid rows={receivedrows} columns={
                        [
                            { field: 'from', headerName: 'From', width: 330 },
                            {
                                field: 'subject',
                                headerName: 'Subject',
                                width: 330,
                                color: 'red',
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            <div onClick={viewAnnouncementClick}>{params.value}</div>
                                        </div>
                                    )
                                }
                            },
                            {
                                field: 'description',
                                headerName: 'Date',
                                width: 200
                            }
                        ]
                    } pageSize={5} /> */}
{/* <DataGrid rows={sentRows} columns={
                        [
                            { field: 'from', headerName: 'To', width: 330 },
                            {
                                field: 'subject',
                                headerName: 'Subject',
                                width: 330,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            <img src="/Ellipse40.png" className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                                            <div onClick={viewAnnouncementClick}>{params.value}</div>
                                        </div>
                                    )
                                }
                            },
                            {
                                field: 'date',
                                headerName: 'Date',
                                width: 200,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            <div>
                                                {params.value}
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>Delete</MenuItem>
                                            </Menu>
                                        </div>
                                    )
                                } 
                            }
                        ]
                    } pageSize={5} /> */}

{/* <DataGrid rows={scheduleRows} columns={
                        [
                            { field: 'from', headerName: 'To', width: 330 },
                            {
                                field: 'subject',
                                headerName: 'Subject',
                                width: 330,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            <div onClick={viewAnnouncementClick}>{params.value}</div>
                                        </div>
                                    )
                                }
                            },
                            {
                                field: 'date',
                                headerName: 'Scheduled for',
                                width: 200,
                                renderCell: (params) => {
                                    return (
                                        <div>
                                            <div>
                                                {params.value}
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>Edit</MenuItem>
                                                <MenuItem onClick={handleClose}>Delete</MenuItem>
                                            </Menu>
                                        </div>
                                    )
                                }
                            }
                        ]
                    } pageSize={5} /> */}