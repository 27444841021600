import axios from 'axios';
import Session from 'services/local/session';

// TODO all add authorization bearer in header

const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/subject`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
};

const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/subject`;

    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
};

const get = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/subject/byOrg/${Session.getOrg().orgID}`;

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
};

const getByID = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/subject`;

    return await axios({
        method: 'GET',
        url: url,
        params: {
            subjectID: id
        },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data
        })
};

const getByLevel = async (level, orgID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/subject/forTimetable`;
    return await axios({
        method: 'GET',
        url: url,
        params: { level, orgID },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            // console.log('error :', err);
            return err.response?.data;
        });
};


export default {
    create,
    update,
    get,
    getByID,
    getByLevel,
};