import React, { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import UserForm from "../../components/system-admin/user-form";

const SystemAdminUpdateUserPage = () => {
    const containerStyle = {
        borderLeft: "10px solid #5D518F",
        borderRadius: 10,
    };

    return (
        <Fragment>
            <Helmet>
                <title>Create User</title>
            </Helmet>

            <div style={containerStyle}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="large" />}
                    style={{ marginLeft: 10, marginTop: 10 }}
                >
                    <Link
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            color: "black",
                        }}
                    >
                        System Admin
          </Link>
                    <Link
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            color: "black",
                        }}
                        href="/systemadmin/users"
                    >
                        Users
          </Link>
                    <Link
                        style={{
                            fontSize: 24,
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            color: "black",
                        }}
                    >
                        Update User
          </Link>
                </Breadcrumbs>
                <UserForm />
            </div>
        </Fragment>
    );
};

export default SystemAdminUpdateUserPage;
