import React, { useEffect, useState, Fragment, isValidElement } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import enc from 'helpers/enc';
import Session from 'services/local/session';
import Button from '@material-ui/core/Button';
// Components
import useStyles from './style';
import AlertBox from 'components/common/alert-box';
import { Select } from 'components/common/form-unit';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Modal from 'components/common/modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import eduAPI from "../../../api/api";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useSession from 'hooks/use-session';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

const GridBodyCard = ({ grids }) => {
    const cls = useStyles();

    return (
        <Grid container={true} justify="space-between">
            {!_.isEmpty(grids) && _.map(grids, (o, i) => {
                if (o.type === 'important') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': 'black' }} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    {/* <span data-type={o.type} >
                                        {o.value}
                                    </span> */}

                                    {isValidElement(o.value) && o.value}
                                    {_.isString(o.value) &&
                                        <span data-type={o.type} >
                                            {o.value}
                                        </span>
                                    }
                                </div>
                            </div>
                        </Grid>
                    )
                }
                else if (o.type === 'information') {
                    return (
                        <Grid item={true} xl={6} lg={6} md={12} sm={12} xs={12} key={i} style={o.style}>
                            <div className={cls.list__container}>
                                <span style={{ '--title-color': '#756F86' }} data-type={o.type} className={cls.list__title}>{o.title}</span>
                                <div className={cls.list__content}>
                                    {/* <span data-type={o.type}>
                                        {o.value}
                                    </span> */}

                                    {isValidElement(o.value) && o.value}
                                    {_.isString(o.value) &&
                                        <span data-type={o.type} >
                                            {o.value}
                                        </span>
                                    }

                                </div>
                            </div>
                        </Grid>
                    )
                }
            })
            }
        </Grid>
    )
}

const View = ({ data, status, resErr, onFetch, onReset, onConfirmUnAssign, onCloseModal, activeStatus, inActiveStatus }) => {
    const history = useHistory();
    const cls = useStyles();
    const session = useSession();
    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }
    const [loading, setLoading] = React.useState(false);
    const [profileId, setProfileID] = useState('')

    React.useEffect(() => {
        if (status.statusUpdate === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (status.statusUpdate == 'success') {
            setAnchorEl(null);
            //   onFetch(profileId)
            onCloseModal();
        }
    }, [status]);


    const { profile = {}, guardians = [], status: academicStatus = [] } = data;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const cls = useStyles();
    //  const history = useHistory();
    const [fixErrorPopup, setFixErrorPopup] = React.useState(false);
    const [modal, setModal] = useState();
    const fixErrorPopupOpen = () => {
        setFixErrorPopup(true);
    }
    const closePopup = () => {
        setFixErrorPopup(false);
    };



    const fixError = () => {
        setFixErrorPopup(false);
    }

    const [unAssignPopup, setUnAssignPopup] = React.useState(false);
    const unAssignPopupOpen = () => {
        setUnAssignPopup(true);
    }
    const handleCloseEdit2 = () => {
        setUnAssignPopup(false);
    };

    const [groupList, setGroupList] = useState([])

    const getGroupName = (key) => {
        let val = '';
        groupList.find((item) => {
            if (item.groupID == key) {
                val = item.name;
            }
        })
        return val ? val : '-';
    }

    const getGroupType = (key) => {
        let type = '';
        groupList.find((item) => {
            if (item.groupID == key) {
                type = item.fixed ? item.fixed : false;
            }
        })
        return type ? 'true' : 'false';
    }

    const fetchGroups = () => {
        try {
            eduAPI.get(`/sys_ad/groups?orgID=${session.orgID}`, headersData).then((data) => {
                setGroupList(data.data.data);
            });
        } catch (error) {
            console.error("Fetch Roles Error", error);
        }
    }
    const [fetchStatus, setFetchStatus] = useState(true)

    useEffect(() => {
        if (groupList.length == 0 && session.orgID && fetchStatus) {
            fetchGroups();
            setFetchStatus(false)
        }
    }, [data, session])

    const [selGroupId, setSelGroupId] = useState("")
    const [selGroupName, setSelGroupName] = useState("")
    const [infoMessage, setInfoMessage] = useState(false)


    const unAssignGroup = (key) => {

        if (data?.profile?.groups.length > 1) {
            let groupName = getGroupName(key);

            setSelGroupName(groupName)
            setSelGroupId(key)

            setUnAssignPopup(true);
        } else {
            setInfoMessage("Can't UnAssign All Groups, User Must Have One Group")
            setTimeout(() => {
                setInfoMessage(false)
            }, 5000);
        }

    }

    const confirmUnassign = () => {
        setUnAssignPopup(false);
        onCloseModal();
        let obj = {
            groupIDs: [
                selGroupId
            ],
            profileIDs: [
                data.profile.profileID ? data.profile.profileID : data.profile.adminID
            ]
        }
        onConfirmUnAssign(obj)

    }

    const gotoSystemUsers = () => {
        setUnAssignPopup(false);
        setFixErrorPopup(false);
        history.push('/systemadmin/users')
    }


    const updateStatus = (key) => {

        let obj = {
            "profileID": data.profile.profileID
        }
        setProfileID(data.profile.profileID);

        if (key === 'active') {
            activeStatus(obj);
        } else if (key == 'inactive') {
            inActiveStatus(obj);
        }
    }


    return (
        <Container className={cls.container}>
            <div className={cls.card}>
                {loading && <div>loading...</div>}
                {!loading &&
                    <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                        <Grid item={true} xl={2} lg={2} md={2} sm={2} xs={2}>
                            <IconButton component="span">
                                <Avatar
                                    alt="Profile Picture"
                                    src={data?.attachments?.profile_picture?.fileUrl}
                                    style={{ width: '90px', height: '90px' }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item={true} xl={4} lg={4} md={4} sm={4} xs={4} style={{ margin: 'auto', marginLeft: '10px' }}>
                            <h2>{data?.profile?.firstName + ' ' + data?.profile?.lastName}</h2>
                            <p>{profile?.emailAddress}</p>
                        </Grid>
                        <Grid item={true} xl={2} lg={2} md={2} sm={2} xs={2} style={{ margin: 'auto' }}>
                            <div>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                    {data?.profile?.profileStatus}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        onClick={() => updateStatus('active')}
                                    >Active</MenuItem>
                                    <MenuItem onClick={() => updateStatus('inactive')}>Inactive</MenuItem>
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                }
                <Grid container={true} spacing={2}>
                    <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className={cls.card} style={{ backgroundColor: 'white' }}>
                                <div className={cls.card__title}>
                                    <span>Personal Information</span>
                                    <Button label="Edit" onClick={() => history.push(`/systemadmin/users/update/${enc.encode(profile.profileID)}`)}>Edit</Button>
                                </div>

                                <div className={cls.card__body}>
                                    {loading && <div>loading...</div>}

                                    {!loading && !_.isEmpty(profile) &&
                                        <GridBodyCard
                                            grids={[
                                                {
                                                    type: 'information',
                                                    title: 'Name',
                                                    value: profile?.fullName
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Date Of Birth',
                                                    value: moment(profile?.dob).format('DD/MM/YYYY')
                                                },
                                                {
                                                    type: 'information',
                                                    title: profile?.idType === 'NRIC' ? 'National ID' : 'Passport No',
                                                    value: profile?.idNo,
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Place Of Birth',
                                                    value: "-",
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Religion',
                                                    value: _.upperFirst(_.toLower(profile?.religion)),
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Gender',
                                                    value: _.upperFirst(_.toLower(profile?.gender)),
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Home Address',
                                                    value: <Fragment>
                                                        <span data-type="information">{profile.addresses[0].street1},</span>
                                                        <br />
                                                        <span data-type="information">{profile.addresses[0].street2},</span>
                                                        <br />
                                                        <span data-type="information">{profile.addresses[0].city}</span>
                                                        <br />
                                                        <span data-type="information">{profile.addresses[0].postcode}</span>
                                                        <br />
                                                        <span data-type="information">{profile.addresses[0].state}</span>
                                                        <br />
                                                        <span data-type="information">{profile.addresses[0].country}</span>
                                                    </Fragment>,
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Prior School',
                                                    value: _.upperFirst(_.toLower(profile?.priorSchool)),
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Allergy',
                                                    value: _.upperFirst(_.toLower(profile?.medicalCondition)) || 'Information not provided',
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Email',
                                                    value: profile?.emailAddress,
                                                    style: { marginTop: '1em' }
                                                },
                                                {
                                                    type: 'information',
                                                    title: 'Mobile No.',
                                                    value: "+" + profile?.phone?.fullNumber,
                                                    style: { marginTop: '1em' }
                                                }
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container={true} item={true} xl={6} lg={6} md={12} sm={12} xs={12} spacing={2}>
                        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className={cls.card} style={{ backgroundColor: '#EBC8C4' }}>
                                <div className={cls.card__title}>
                                    <span data-type="important">Emergency Contacts</span>
                                </div>

                                <div className={cls.card__body}>
                                    {loading && <div>loading...</div>}

                                    {!loading && !_.isEmpty(profile) &&
                                        <GridBodyCard
                                            grids={[
                                                {
                                                    type: 'important',
                                                    value: profile?.emergencyContact.emergencyContactName,
                                                    title: 'Name'
                                                },
                                                {
                                                    type: 'important',
                                                    value: profile?.emergencyContact?.emergencyContactRelationship,
                                                    title: 'Relationship with User'
                                                },
                                                {
                                                    type: 'important',
                                                    value: `+${profile?.emergencyContact?.emergencyContactFullNumber}`,
                                                    title: 'Mobile No.',
                                                    style: { marginTop: '1em' }
                                                }
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </Grid>

                        <Grid item={true} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className={cls.card} style={{ backgroundColor: 'white' }}>
                                <div className={cls.card__title}>
                                    <span style={{ textDecoration: 'underline' }}>Group Assigned</span>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {infoMessage ? <AlertBox icon={false} type="error" message={infoMessage} /> : ''}
                                </div>
                                <div className={cls.card__body}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><b>Groups</b></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* {isLoading && <TableCell colSpan="6" align="center">loading...</TableCell>} */}

                                                {_.isEmpty(data?.profile?.groups) && <TableCell colSpan="6" align="center">No Data</TableCell>}

                                                {!_.isEmpty(data?.profile?.groups) && _.map(data.profile.groups, (o, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{getGroupName(o)} </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>
                                                            {getGroupType(o) == 'true' ? '-' : <Button style={{ color: '#369AFF', borderRadius: '4px', cursor: 'pointer', background: '#E1F0FF', padding: '10px 50px' }} onClick={() => unAssignGroup(o)}>Unassign</Button>}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={cls.modal}
                                    showFooter={false}
                                    open={fixErrorPopup}
                                    onClose={closePopup}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500
                                    }}
                                >
                                    <Fade in={fixErrorPopup}>
                                        <div className={[cls.modalHeight].join(' ')} >
                                            <h2 id="transition-modal-title" style={{ textAlign: 'center' }}>Error</h2>
                                            <div style={{ padding: '0px 10px', textAlign: 'center', fontFamily: 'Poppins', }}>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + "/error.svg"} style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                </div>
                                                <div style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                    The USER can’t be UNASSIGNED FROM THIS GROUP AS USER IS CURRENTLY ASSIGN TO A CLASS IN THE MASTER LISTS.
                                                    <div style={{ textAlign: 'justify', fontSize: '12px' }}>
                                                        <div>Note: To proceed, unassign user from class in Master Lists.</div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                                        <Button style={{
                                                            background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
                                                            border: 5,
                                                            borderWidth: 10,
                                                            borderColor: '#25335A',
                                                            borderRadius: 10,
                                                            color: 'black',
                                                            height: 50
                                                        }}
                                                            onClick={fixError}>
                                                            Fix Error
                                                        </Button>
                                                    </div>
                                                    <div style={{ textAlign: 'center', color: '#0172B1', textDecoration: 'underline', marginTop: '10px' }}
                                                        onClick={() => gotoSystemUsers()}
                                                    >CANCEL, return to Student Page</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </Modal>

                                {/* Unassign Group */}

                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={cls.modal}
                                    showFooter={false}
                                    open={unAssignPopup}
                                    onClose={closePopup}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500
                                    }}
                                >
                                    <Fade in={unAssignPopup}>
                                        <div className={[cls.modalHeight].join(' ')} >
                                            <h2 id="transition-modal-title" style={{ textAlign: 'center' }}>Unassign Group</h2>
                                            <div style={{ padding: '0px 10px', textAlign: 'center', fontFamily: 'Poppins', }}>
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + "/unassign.svg"} style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                </div>
                                                <div style={{ fontSize: '16px', textAlign: 'justify' }}>
                                                    Are you sure you want to <span style={{ color: "red" }}>UNASSIGN</span> this user from the group : <span style={{ color: 'rgb(253, 183, 20)' }}>{selGroupName}</span>
                                                    <div style={{ textAlign: 'justify', fontSize: '12px' }}>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10%' }}>
                                                        <Button style={{
                                                            background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)',
                                                            border: 5,
                                                            borderWidth: 10,
                                                            borderColor: '#25335A',
                                                            borderRadius: 10,
                                                            color: 'black',
                                                            height: 50
                                                        }}
                                                            onClick={confirmUnassign}>
                                                            Confirm Unassign
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={{ textAlign: 'center', color: '#0172B1', textDecoration: 'underline', marginTop: '10px', marginBottom: '10px' }}
                                                        onClick={() => gotoSystemUsers()}
                                                    >CANCEL, return to User Profile</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                </Modal>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default View;