import { SHA256, parseJWT } from 'helpers';
import auth from '../apis/auth';
import Session from 'services/local/session';

import normalize from 'utils/normalizers';
export const RESET_STATUS = 'RESET_STATUS';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const USER_STATUS = 'USER_STATUS';
export const USER_STATUS_SUCCESS = 'USER_STATUS_SUCCESS';
export const USER_STATUS_FAILED = 'USER_STATUS_FAILED';

export const OTP_VERIFY = 'OTP_VERIFY';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAILED = 'OTP_VERIFY_FAILED';

export const PASWORD_SET_STATUS = 'PASWORD_SET_STATUS';
export const PASWORD_SET_STATUS_SUCCESS = 'PASWORD_SET_STATUS_SUCCESS';
export const PASWORD_SET_STATUS_FAILED = 'PASWORD_SET_STATUS_FAILED';



export const RESEND = 'RESEND';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_FAILED = 'RESEND_FAILED';

export const TOKEN_RENEW = 'TOKEN_RENEW';
export const TOKEN_RENEW_SUCCESS = 'TOKEN_RENEW_SUCCESS';
export const TOKEN_RENEW_FAILED = 'TOKEN_RENEW_FAILED';

let dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const login = (payload) => async (dispatch) => {
    dispatch({ type: LOGIN });

    try {
        const loginData = normalize.server.auth({
            emailHash: SHA256(payload.emailAddress),
            passwordHash: SHA256(payload.password)
        });

        const res = await auth.login(loginData);
        if (!res.success) {
            return dispatch({
                type: LOGIN_FAILED,
                error: normalize.error.response(res)
            });
        }

        if (res?.data) {
            Session.removeSession();
            Session.createSession(res?.data);
        }

        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: LOGIN_FAILED, error: {} });
    }
}

const refreshToken = () => async (dispatch) => {
    dispatch({ type: TOKEN_RENEW });
    try {
        const response = await auth.renewToken();
        if (response.status === 'error') {
            return dispatch({
                type: TOKEN_RENEW_FAILED,
                error: response
            });
        }

        const token = response.data;

        if (token) {
            Session.removeSession();
            Session.createSession(token);
        }
        else {
            return dispatch({
                type: TOKEN_RENEW_FAILED,
                error: { key: 'resError', value: 'TOKEN_RENEW_FAILED' }
            });
        }

        return dispatch({
            type: TOKEN_RENEW_SUCCESS,
            payload: token,
            app_state: response.data.app_state,
        });
    }
    catch (err) {
        return dispatch({
            type: TOKEN_RENEW_FAILED,
            error: { key: 'resError', value: 'TOKEN_RENEW_FAILED' }
        });
    }
}

const logout = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT });

        const res = await auth.logout();
        if (!res.success) {
            return dispatch({
                type: LOGOUT_FAILED,
                message: res.message,
                data: res.data
            });
        }

        Session.removeSession();

        dispatch({ type: LOGOUT_SUCCESS });
    }
    catch (err) {
        dispatch({
            type: LOGOUT_FAILED,
            error: err
        })
    }
}

const passwordSetting = (payload) => async (dispatch) => {
    dispatch({ type: PASWORD_SET_STATUS });

    try {
        let firstSetPassword = normalize.server.auth({
            passwordHash: SHA256(payload.password)
        });

        let obj = {
            emailHash: payload.email,
            pwdHash: firstSetPassword.passwordHash,
            token: payload.token
        }

        const res = await auth.passwordSetting(obj);
        if (!res.success) {
            return dispatch({
                type: PASWORD_SET_STATUS_FAILED,
                error: normalize.error.response(res)
            });
        }

        dispatch({ type: PASWORD_SET_STATUS_SUCCESS });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: PASWORD_SET_STATUS_FAILED, error: {} });
    }
}

const passwordResetting = (payload) => async (dispatch) => {
    dispatch({ type: PASWORD_SET_STATUS });

    try {
        let firstSetPassword = normalize.server.auth({
            passwordHash: SHA256(payload.password)
        });

        let obj = {
            emailHash: payload.email,
            pwdHash: firstSetPassword.passwordHash,
            token: payload.token
        }

        const res = await auth.passwordReSetting(obj);
        if (!res.success) {
            return dispatch({
                type: PASWORD_SET_STATUS_FAILED,
                error: normalize.error.response(res)
            });
        }
        dispatch({ type: PASWORD_SET_STATUS_SUCCESS });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: PASWORD_SET_STATUS_FAILED, error: {} });
    }
}

const checkStatus = (payload) => async (dispatch) => {
    dispatch({ type: USER_STATUS });

    try {
        const loginData = normalize.server.auth({
            emailHash: SHA256(payload.emailAddress)
        });


        const res = await auth.checkStatus(loginData);
        if (!res.success) {
            return dispatch({
                type: USER_STATUS_FAILED,
                error: normalize.error.response(res)
            });
        }

        dispatch({ type: USER_STATUS_SUCCESS, data: res?.data, emailHash: loginData.emailHash });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: USER_STATUS_FAILED, error: {} });
    }
}

const verifyOtp = (payload) => async (dispatch) => {
    dispatch({ type: OTP_VERIFY });
    try {

        const res = await auth.verifyOtp(payload);
        if (!res.success) {
            return dispatch({
                type: OTP_VERIFY_FAILED,
                error: normalize.error.response(res)
            });
        }

        dispatch({ type: OTP_VERIFY_SUCCESS, data: res?.data });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: OTP_VERIFY_FAILED, error: {} });
    }
}

const resendEmail = (payload) => async (dispatch) => {
    dispatch({ type: RESEND });

    try {

        const resendData = {
            emailHash: SHA256(payload.emailHash),
            emailType: payload.emailType
        }
        const res = await auth.resendEmail(resendData);
        if (!res.success) {
            return dispatch({
                type: RESEND_FAILED,
                error: normalize.error.response(res)
            });
        }
        dispatch({ type: RESEND_SUCCESS, data: res?.data, emailHash: resendData.emailHash });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: RESEND_FAILED, error: {} });
    }
}

export default {
    login,
    logout,
    checkStatus,
    resetStatus,
    passwordSetting,
    verifyOtp,
    resendEmail,
    passwordResetting,
    refreshToken
}