import React from 'react';
import View from './timtetable-template.view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TableAction from '../../../redux/actions/time-table';
import useSession from 'hooks/use-session';

const mapStateToProps = (state) => {
    return {
        status: state.timeTable?.status,
        error: state.timeTable?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const TableActions = bindActionCreators(TableAction, dispatch);

    return {
        onCreate$: (data) => {
            TableActions.createTemplate(data);
        },
        onUpdate$: (data) => {
            TableActions.updateTemplate(data);
        }
    }
}

const Container = ({ status, error, onCreate$, onUpdate$ }) => {
    const session = useSession();
    const canViewTimetable = (session.permissions || []).includes('v_master_ttTemplate_web');

    const onCreate = (data) => {
        onCreate$(data);
    }

    const onUpdate = (data) => {
        onUpdate$(data);
    }
    return (
        canViewTimetable?<View status={status} resErr={error} onCreate={onCreate} onUpdate={onUpdate} />:''
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);;