import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, map } from 'lodash';

import useSession from 'hooks/use-session';

// Services
import { useGetHighAbsenteesQuery, attendanceApi } from 'services/api/attendance';
import { useCreateMessageMutation } from 'services/api/message';

// Components
import AttendanceDetails from 'components/attendance/attendance-details';

import { DatePicker } from 'components/common/form-unit';
import Button from 'components/common/button';
import Loader from 'components/common/loader';
import Modal from 'components/common/modal';

import { AiOutlineCheckCircle } from 'react-icons/ai'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const AttendanceHighAbsentee = ({ ...props }) => {
    const { t } = useTranslation();
    const session = useSession();
    const styles = useStyles();
    const {
        data,
        error,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch
    } = useGetHighAbsenteesQuery();

    const [onCreate$, { isLoading: isCreating, isSuccess: isCreatingSuccess }] = useCreateMessageMutation();



    // 06-04-2021 
    // date.format('DD-MM-YYYY')
    const initialDate = moment('06-04-2021', 'DD-MM-YYYY');
    const initialModal = { open: false, content: null, title: null };

    const [date, setDate] = useState(initialDate);
    const [modal, setModal] = useState(initialModal);

    useEffect(() => {
        if (isCreatingSuccess) {
            refetch();
        }
    }, [isCreatingSuccess]);

    const onActionClick = ({ type, data }) => {
        switch (type) {
            case 'VIEW_DETAILS':
                // setModal({ 
                //     ...modal, 
                //     open: true, 
                //     content: <AttendanceDetails attendanceID={data.ID} /> 
                // });

                break;

            default:
                throw new Error(`CASE_ERROR: INVALID TYPE`);
        }
    }

    const onCloseModal = () => { setModal({ ...modal, open: false }); }

    const onSendWarning = (param) => {
        const payload = {
            studentID: param.student?.ID,
            orgID: session?.orgID ? session?.orgID : null,
            category: 'ATTENDANCE',
            template: 'WARNING',
            data: {
                name: param.student?.name
            }
        };

        onCreate$(payload);
    }

    if (isLoading || isFetching) {
        return (
            <div className="absolute-center">
                <Loader size="33px" />
            </div>
        )
    }


    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('label.class')}</TableCell>
                            <TableCell>{t('label.absentees')}</TableCell>
                            <TableCell>
                                <div style={{ color: '#FF0000', textAlign: 'center' }}>
                                    {t('label.absent')}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{ color: '#FF0000', textAlign: 'center' }}>
                                    {t('label.warning')}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{ color: '#FF0000', textAlign: 'center' }}>
                                    {t('label.action')}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isSuccess && isEmpty(data) && (
                            <TableRow>
                                <TableCell align="center" colSpan={5}>
                                    {t('txt.no_data')}
                                </TableCell>
                            </TableRow>
                        )}

                        {isSuccess && !isEmpty(data) && map(data, (o, i) => (
                            <React.Fragment key={i}>
                                {!isEmpty(o.absent_students) && map(o.absent_students, (initO, absentStudentIndex) => {
                                    return (<TableRow key={absentStudentIndex} className={absentStudentIndex !== o.absent_students.length - 1 ? styles.borderBottomNone : ''}>
                                        <TableCell>
                                            <b>{absentStudentIndex === 0 && o.class}</b>
                                        </TableCell>
                                        <TableCell>
                                            <span key={absentStudentIndex} style={{ display: 'block', marginTop: absentStudentIndex > 0 && '.3em' }}>
                                                {initO.student?.name}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span key={absentStudentIndex} style={{ textAlign: 'center', display: 'block', marginTop: absentStudentIndex > 0 && '.3em' }}>
                                                {initO.absent_count}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span key={absentStudentIndex} style={{ textAlign: 'center', display: 'block', marginTop: absentStudentIndex > 0 && '.3em' }}>
                                                {initO.warning_count}
                                            </span>
                                        </TableCell>
                                        <TableCell>

                                            {initO.warning_count === 3 && (
                                                <div key={absentStudentIndex} style={{ textAlign: 'center', display: 'block', marginTop: absentStudentIndex > 0 && '.3em' }}>
                                                    {t('txt.action_needed')}
                                                </div>
                                            )
                                            }
                                            {!initO.new_absent_after_last_warning && (
                                                <div style={{ textAlign: 'center' }}><AiOutlineCheckCircle key={absentStudentIndex} style={{ marginTop: absentStudentIndex > 0 && '.3em' }} fontSize="22px" color="#3C8636" /></div>
                                            )}

                                            {initO.new_absent_after_last_warning && (
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button
                                                        key={absentStudentIndex}
                                                        label={t('action.send_warning')}
                                                        style={{ border: '1px solid red', marginTop: absentStudentIndex > 0 && '.3em', background: 'white', color: 'red' }}
                                                        onClick={() => onSendWarning(initO)}
                                                        isLoading={isCreating}
                                                    /></div>
                                            )}


                                        </TableCell>
                                    </TableRow>
                                    )
                                })
                                }
                            </React.Fragment>

                        ))}

                        {/* {isSuccess && !isEmpty(data) && map(data, (o, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <span onClick={() => onActionClick({ type: 'VIEW_DETAILS', data: o })}>
                                            {o.class}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {!isEmpty(o.absent_students) && map(o.absent_students, (initO, key) => {
                                            return (
                                                <span key={key} style={{ display: 'block', marginTop: key > 0 && '.3em' }}>
                                                    {initO.student?.name}
                                                </span>
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {!isEmpty(o.absent_students) && map(o.absent_students, (initO, key) => {
                                            return (
                                                <span key={key} style={{ display: 'block', marginTop: key > 0 && '.3em' }}>
                                                    {initO.absent_count}
                                                </span>
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {!isEmpty(o.absent_students) && map(o.absent_students, (initO, key) => {
                                            return (
                                                <span key={key} style={{ display: 'block', marginTop: key > 0 && '.3em' }}>
                                                    {initO.warning_count}
                                                </span>
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {!isEmpty(o.absent_students) && map(o.absent_students, (initO, key) => {
                                            if (initO.warning_count === 3) {
                                                return (
                                                    <div key={key} style={{ textAlign: 'center', display: 'block', marginTop: key > 0 && '.3em' }}>
                                                        {t('txt.action_needed')}
                                                    </div>
                                                )
                                            }

                                            if (initO.new_absent_after_last_warning) {
                                                return (
                                                    <div style={{ textAlign: "center" }}>
                                                        <Button
                                                            key={key}
                                                            label={t('action.send_warning')}
                                                            style={{ border: '1px solid red', marginTop: key > 0 && '.3em', background: 'white', color: 'red' }}
                                                            onClick={() => onSendWarning(initO)}
                                                            isLoading={isCreating}
                                                        /></div>
                                                )
                                            }

                                            if (!initO.new_absent_after_last_warning) {
                                                return <div style={{ textAlign: "center" }}><AiOutlineCheckCircle key={key} style={{ marginTop: key > 0 && '.3em' }} fontSize="22px" color="#3C8636" /></div>
                                            }
                                        })}
                                    </TableCell>
                                </TableRow>
                            )
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={modal.open}
                onHandleClose={onCloseModal}
                showFooter={false}
            >
                {modal.content}
            </Modal>
        </Fragment>
    )
}


const useStyles = makeStyles({
    borderBottomNone: {
        '& td': {
            borderBottom: 'none',
        }
    }
});

export default AttendanceHighAbsentee;