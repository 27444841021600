import {
    RESET_STATUS,

    RESET_DATA,

    PROFILE_CREATE,
    PROFILE_CREATE_SUCCESS,
    PROFILE_CREATE_FAILED,

    PROFILES_FETCH,
    PROFILES_FETCH_FAILED,
    PROFILES_FETCH_SUCCESS,

    STUDENTS_ENROLL,
    STUDENTS_ENROLL_FAILED,
    STUDENTS_ENROLL_SUCCESS,

    PROFILE_SEARCH,
    PROFILE_SEARCH_FAILED,
    PROFILE_SEARCH_SUCCESS,

    PROFILE_FETCH,
    PROFILE_FETCH_FAILED,
    PROFILE_FETCH_SUCCESS,

    STUDENTS_UNENROLLED_FETCH,
    STUDENTS_UNENROLLED_FETCH_FAILED,
    STUDENTS_UNENROLLED_FETCH_SUCCESS,

    STUDENTS_ENROLLED_FETCH,
    STUDENTS_ENROLLED_FETCH_FAILED,
    STUDENTS_ENROLLED_FETCH_SUCCESS,

    STUDENTS_TRANSFER,
    STUDENTS_TRANSFER_FAILED,
    STUDENTS_TRANSFER_SUCCESS,

    TEACHERS_UNASSIGNED_FETCH,
    TEACHERS_UNASSIGNED_FETCH_FAILED,
    TEACHERS_UNASSIGNED_FETCH_SUCCESS,

    TEACHERS_ASSIGNED_FETCH,
    TEACHERS_ASSIGNED_FETCH_SUCCESS,
    TEACHERS_ASSIGNED_FETCH_FAILED,

    PROFILE_UPDATE,
    PROFILE_UPDATE_FAILED,
    PROFILE_UPDATE_SUCCESS,

    ENROLLED_STUDENT_FILTER,
    ENROLLED_STUDENT_FILTER_FAILED,
    ENROLLED_STUDENT_FILTER_SUCCESS,


    STATUS_UPDATE,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAILED,

    ACADEMICSTATUS_UPDATE,
    ACADEMICSTATUS_UPDATE_SUCCESS,
    ACADEMICSTATUS_UPDATE_FAILED

} from '../actions/profile';

const initialStatus = {
    create: 'idle',
    update: 'idle',
    fetch: 'idle',
    fetchUnenrolled: 'idle',
    fetchEnrolled: 'idle',
    fetchAssigned: 'idle',
    fetchUnassigned: 'idle',
    enroll: 'idle',
    search: 'idle',
    transfer: 'idle',
    filter: 'idle',
    statusUpdate: 'idle',
    academicStatus: 'idle'
};

const initialState = {
    searchResult: [],
    filteredResult: [],
    assigned: [],
    unassigned: [],
    enrolled: [],
    unenrolled: [],
    list: [],
    data: {},
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            }
        }

        case RESET_DATA: {
            return {
                ...state,
                error: {},
                searchResult: [],
                filteredResult: [],
                assigned: [],
                unassigned: [],
                enrolled: [],
                unenrolled: [],
                list: [],
                data: {},
            }
        }

        case PROFILE_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading' }
            };
        }

        case PROFILE_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed' }
            }
        }

        case PROFILE_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success' }
            };
        }

        case PROFILES_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case PROFILES_FETCH_FAILED: {
            return {
                ...state,
                error: { fetch: action.error },
                status: { ...state.status, fetch: 'failed' }
            }
        }

        case PROFILES_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                list: action.payload,
                status: { ...state.status, fetch: 'success' }
            };
        }

        case STUDENTS_ENROLL: {
            return {
                ...state,
                error: {},
                status: { ...state.status, enroll: 'loading' }
            };
        }

        case STUDENTS_ENROLL_FAILED: {
            return {
                ...state,
                error: { enroll: action.error },
                status: { ...state.status, enroll: 'failed' }
            };
        }

        case STUDENTS_ENROLL_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, enroll: 'success' }
            };
        }

        case PROFILE_SEARCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, search: 'loading' }
            };
        }

        case PROFILE_SEARCH_FAILED: {
            return {
                ...state,
                error: { search: action.error },
                status: { ...state.status, search: 'failed' }
            };
        }

        case PROFILE_SEARCH_SUCCESS: {
            return {
                ...state,
                searchResult: action.payload,
                error: {},
                status: { ...state.status, search: 'success' }
            };
        }

        case PROFILE_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading' }
            };
        }

        case PROFILE_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                data: action.payload,
                status: { ...state.status, fetch: 'success' }
            };
        }

        case PROFILE_FETCH_FAILED: {
            return {
                ...state,
                error: { fetch: action.error },
                status: { ...state.status, fetch: 'failed' }
            };
        }

        case STUDENTS_UNENROLLED_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchUnenrolled: 'loading' }
            };
        }

        case STUDENTS_UNENROLLED_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                unenrolled: action.payload,
                status: { ...state.status, fetchUnenrolled: 'success' }
            };
        }

        case STUDENTS_UNENROLLED_FETCH_FAILED: {
            return {
                ...state,
                error: { unenrolled: action.error },
                status: { ...state.status, fetchUnenrolled: 'failed' }
            };
        }

        case STUDENTS_ENROLLED_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchEnrolled: 'loading' }
            };
        }

        case STUDENTS_ENROLLED_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                enrolled: action.payload,
                status: { ...state.status, fetchEnrolled: 'success' }
            };
        }

        case STUDENTS_ENROLLED_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchEnrolled: action.error },
                status: { ...state.status, fetchEnrolled: 'failed' }
            };
        }

        case STUDENTS_TRANSFER: {
            return {
                ...state,
                error: {},
                status: { ...state.status, transfer: 'loading' }
            };
        }

        case STUDENTS_TRANSFER_FAILED: {
            return {
                ...state,
                error: { transfer: action.error },
                status: { ...state.status, transfer: 'failed' }
            };
        }

        case STUDENTS_TRANSFER_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, transfer: 'success' }
            };
        }

        case TEACHERS_UNASSIGNED_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchUnassigned: 'loading' }
            };
        }

        case TEACHERS_UNASSIGNED_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                unassigned: action.payload,
                status: { ...state.status, fetchUnassigned: 'success' }
            };
        }

        case TEACHERS_UNASSIGNED_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchUnassigned: action.error },
                status: { ...state.status, fetchUnassigned: 'failed' }
            };
        }

        case TEACHERS_ASSIGNED_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetchAssigned: 'loading' }
            };
        }

        case TEACHERS_ASSIGNED_FETCH_SUCCESS: {
            return {
                ...state,
                error: {},
                assigned: action.payload,
                status: { ...state.status, fetchAssigned: 'success' }
            };
        }

        case TEACHERS_ASSIGNED_FETCH_FAILED: {
            return {
                ...state,
                error: { fetchAssigned: action.error },
                status: { ...state.status, fetchAssigned: 'failed' }
            };
        }

        case PROFILE_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading' }
            };
        }

        case PROFILE_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                data: action.payload,
                status: { ...state.status, update: 'success' }
            };
        }

        case PROFILE_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed' }
            };
        }

        case ENROLLED_STUDENT_FILTER: {
            return {
                ...state,
                error: {},
                status: { ...state.status, filter: 'loading' }
            };
        }

        case ENROLLED_STUDENT_FILTER_SUCCESS: {
            const enrolledStudents = state.enrolled;

            return {
                ...state,
                filteredResult: [],
                status: { ...state.status, filter: 'success' }
            }
        }

        case ENROLLED_STUDENT_FILTER_FAILED: {
            return {
                ...state,
                error: { filter: action.error },
                status: { ...state.status, filter: 'failed' }
            }
        }



        case STATUS_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, statusUpdate: 'loading' }
            };
        }

        case STATUS_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                data: action.payload,
                status: { ...state.status, statusUpdate: 'success' }
            };
        }

        case STATUS_UPDATE_FAILED: {
            return {
                ...state,
                error: { statusUpdate: action.payload },
                status: { ...state.status, statusUpdate: 'failed', data: action.payload }
            };
        }


        case ACADEMICSTATUS_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, academicStatus: 'loading' }
            };
        }

        case ACADEMICSTATUS_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                data: action.payload,
                status: { ...state.status, academicStatus: 'success' }
            };
        }

        case ACADEMICSTATUS_UPDATE_FAILED: {
            return {
                ...state,
                error: { statusUpdate: action.payload },
                status: { ...state.status, academicStatus: 'failed', data: action.payload }
            };
        }


        default:
            return state;
    }
}

export default reducer;