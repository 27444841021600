import React, { useState, Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useToaster from 'hooks/use-toaster';
import Button from '@material-ui/core/Button';
import CustomTable from 'components/system-admin/roles/common-table';
import { useLocation } from "react-router-dom";
import TableBody from '@material-ui/core/TableBody';
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Input } from "../../../common/form-unit";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import eduAPI from "../../../../api/api";
import { useHistory } from 'react-router-dom';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import _ from 'lodash';

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'black',
        // border: '1px solid green',
        background: 'white',
        boxShadow: '0 0 1.5px 1.5px rgba(0, 0, 0, 0.1)',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,

        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);
const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        top: '0px',
        backgroundColor: 'transparent',
        height: '5px',

        '& > span': {
            width: '100%',
            backgroundColor: '#4DC591',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Paper>
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                </Paper>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `styled-tab-${index}`,
        'aria-controls': `styled-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    modalHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 10px)'
    },
    inputButton: {
        height: 40,
        width: "140px",
        borderRadius: 5,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        textTransform: 'capitalize',
        // display: 'flex',
        background: '#FDB714',
    },

    topHeader: {
        color: '#424241',
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: '18px',
        marginBottom: '10px',
        marginLeft: ' 5px',
        fontFamily: 'Poppins'
    },
    hdr1: {
        marginTop: '15px',
        fontWeight: 'bold',
        fontSize: '16px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '40%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        fontFamily: 'Poppins',
        borderRadius: '10px'
    },
    popSubHer: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginTop: '10px'
    },
    popupTitle: {
        marginBottom: '20px',
        marginTop: '20px',
        fontSize: '18px',
        textAlign: 'center'
    },
}));

const View = ({ status, resErr, onCreate, onReset, onUpdate }) => {
    const session = useSession();
    const [toastMsg, setToastMsg] = useState("")
    const history = useHistory();
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const styles = useStyles();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [open, setOpen] = React.useState(false);
    let initial = {
        roleName: '',
        description: ''
    }
    const location = useLocation();
    const [inputs, setInputs] = useState(initial)
    const [list, setList] = useState([])

    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }

    const [listTypes, setListTypes] = useState([]);

    const [getListTypes, setGetListTypes] = useState([]);

    const fetchList = () => {
        try {
            eduAPI.get("/sys_ad/populatePermissions", headersData).then((data) => {
                data.data.data[0].permissions.map((item, index) => {
                    item.module = item.module == "Account" ? "Accounts" : item.module === "Announcement" ? "Announcements" : item.module;
                    item.permission = location?.state?.obj ? getStatus(item) : false;
                    return item;
                })
                setList(data.data.data[0].permissions);
                setGetListTypes(data.data.data[0].accessTypes);
                data.data.data[0].accessTypes.find((data) => {
                    if (data.module == 'Academic Setup') {
                        setListTypes(data.types)
                    }
                })
            });
        } catch (error) {
            console.error("Fetch populate Permissions Error", error);
        }
    }
    const [selRole, setSelRole] = useState();
    useEffect(() => {
        if (location?.state?.obj) {
            let obj = {
                roleName: location?.state?.obj.name,
                description: location?.state?.obj.description
            }
            setInputs(obj);
            setSelRole(location?.state?.obj);
            fetchList()
        } else {
            fetchList()
        }
    }, [])

    const selModPermission = {
        academinSetup: [],
        accounts: [],
        attendance: [],
        announcements: [],
        timetable: [],
        homework: []
    };


    const [selModulePermissons, setSelModulePermissons] = useState(selModPermission)

    const handleOpen = () => {

        const tabKeys = ["Academic Setup", "Accounts", "Attendance", "Announcements", "Timetable", "Homework"];


        selModPermission.academinSetup = list.filter(i => tabKeys[0].includes(i.module) && i.permission);
        selModPermission.accounts = list.filter(i => tabKeys[1].includes(i.module) && i.permission);
        selModPermission.attendance = list.filter(i => tabKeys[2].includes(i.module) && i.permission);
        selModPermission.announcements = list.filter(i => tabKeys[3].includes(i.module) && i.permission);
        selModPermission.timetable = list.filter(i => tabKeys[4].includes(i.module) && i.permission);
        selModPermission.homework = list.filter(i => tabKeys[5].includes(i.module) && i.permission);


        setSelModulePermissons(selModPermission)


        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = async (event, newValue) => {
        setListTypes([])
        let pageName = '';
        switch (value) {
            case 0:
                pageName = 'Academic Setup';
                break;
            case 1:
                pageName = 'Account';
                break;
            case 2:
                pageName = 'Attendance';
                break;
            case 3:
                pageName = 'Announcement';
                break;
            case 4:
                pageName = 'Timetable';
                break;
            case 5:
                pageName = 'Homework';
                break;
            default:
                break;
        }

        getListTypes.find((data) => {
            if (data.module == pageName) {
                setListTypes(data.types)
            }
        })

        setValue(newValue);
    };

    const handleTableChange = (index) => {
        let backUp = _.cloneDeep(list);
        let status = !backUp[index].permission;
        backUp[index].permission = status;
        setList(backUp)
    };

    const createSubmit = () => {
        if (location?.state?.obj) {
            let ids = [];
            list.find((item) => {
                if (item.permission) {
                    ids.push(item.id)
                }
            })
            let obj = {
                "roleID": selRole.roleID,
                "name": inputs.roleName,
                "description": inputs.description,
                "permissionIDs": ids
            }
            onUpdate(obj)
        } else {
            let ids = [];
            list.find((item) => {
                if (item.permission) {
                    ids.push(item.id)
                }
            })
            let obj = {
                "orgID": session.orgID,
                "name": inputs.roleName,
                "description": inputs.description,
                "permissionIDs": ids
            }
            onCreate(obj)
        }

    }


    useEffect(() => {
        if (resErr?.create?.success == false) {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
            onReset()
        }
        if (resErr?.update?.success == false) {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
            onReset()
        }
    }, [resErr])

    useEffect(() => {
        if (status.create === 'success') {
            fetchList()
            setOpen(false);
            setInputs(initial)
            let msg = "Role Created Successfully..";
            setToastMsg(msg)
            onOpenToaster();
            onReset()
            setTimeout(() => {
                history.push('/systemadmin/roles');
            }, 3000);
        }
        if (status.update === 'success') {
            fetchList()
            setOpen(false);
            setInputs(initial)
            let msg = "Role Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster();
            onReset()
            setTimeout(() => {
                history.push('/systemadmin/roles');
            }, 3000);
        }
    }, [status])


    let pageTitle;
    switch (value) {
        case 0:
            pageTitle = 'Academic Setup';
            break;

        case 1:
            pageTitle = 'Accounts';
            break;

        case 2:
            pageTitle = 'Attendance';
            break;

        case 3:
            pageTitle = 'Announcements';
            break;

        case 4:
            pageTitle = 'Timetable';
            break;
        case 5:
            pageTitle = 'Homework';
            break;

        default:
            break;
    }


    const getStatus = (obj) => {
        let status = false;

        location.state.obj.permissions.find((item) => {
            let itemModule = item.module == "Account" ? "Accounts" : item.module === "Announcement" ? "Announcements" : obj.module;
            if (itemModule === obj.module) {
                item.permissions.find((data) => {
                    if (data.permissionID == obj.id) {
                        status = true;
                    }
                })
            }
        });
        return status;
    }

    const getActualName = (name) => {
        let sectionName = 'Web Portal';
        switch (name) {
            case 'access_web':
                sectionName = 'Web Portal';
                break;

            case 'access_app_teacher':
                sectionName = 'Teacher App';
                break;

            case 'access_app_parent':
                sectionName = 'Parent App';
                break;

            default:
                break
        }

        return sectionName;
    }

    return (
        <>
            <Toaster />
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={open}>
                        <div className={[classes.paper, classes.modalHeight].join(' ')} >
                            <h2 className={classes.popupTitle}> CONFIRM ROLE AND PERMISSIONS</h2>
                            <div>
                                <div className={classes.popSubHer}>Role Name : <span style={{ color: "#FDB714" }}>{inputs.roleName}</span></div>
                                <div className={classes.popSubHer}>Description : <span style={{ fontWeight: 'normal' }}>{inputs.description}</span></div>
                            </div>
                            <div className={classes.hdr1}>Permissions:</div>

                            <TableContainer className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Module</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permissions</TableCell>
                                        </TableRow>

                                        {selModulePermissons.academinSetup.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Academic Setup</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.academinSetup.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}


                                        {selModulePermissons.accounts.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Accounts</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.accounts.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}


                                        {selModulePermissons.attendance.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Attendance</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.attendance.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}

                                        {selModulePermissons.announcements.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Announcements</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.announcements.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}

                                        {selModulePermissons.homework.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Homework</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.homework.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}


                                        {selModulePermissons.timetable.length !== 0 ?

                                            <TableRow>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px", borderRight: '1px solid #ddd' }}>Timetable</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>
                                                    {selModulePermissons.timetable.map((i) => (
                                                        <div>{i.name}</div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>

                                            : ''}

                                    </TableHead>
                                </Table>
                            </TableContainer>


                            <Grid item lg={12} md={12} sm={12} style={{ textAlign: 'center', marginTop: '25px' }}>
                                <Button className={classes.inputButton} onClick={createSubmit}>
                                    Confirm
                                </Button>
                            </Grid>

                        </div>
                    </Fade>
                </Modal>



                <div style={{ marginTop: '1em' }}>

                    <Grid container={true} style={{ marginBottom: '2em' }}>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <Input
                                name="roleName"
                                value={inputs.roleName}
                                label="Role Name"
                                placeholder="Enter here"
                                onChange={(e) => {
                                    setInputs({ ...inputs, roleName: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={12}></Grid>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <Input
                                value={inputs.description}
                                name="description"
                                placeholder="Enter here"
                                label="Description"
                                onChange={(e) => {
                                    setInputs({ ...inputs, description: e.target.value })
                                }}
                            />
                        </Grid>
                    </Grid>




                    <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                        <StyledTab label="Academic Setup" {...a11yProps(0)} />
                        <StyledTab label="Accounts" {...a11yProps(1)} />
                        <StyledTab label="Attendance" {...a11yProps(2)} />
                        <StyledTab label="Announcements" {...a11yProps(3)} />
                        <StyledTab label="Timetable" {...a11yProps(4)} />
                        <StyledTab label="Homework" {...a11yProps(5)} />
                    </StyledTabs>



                    <TabPanel value={value} index={value}>
                        {listTypes.map((subType) => (
                            <>
                                <div className={classes.topHeader}>{getActualName(subType)}</div>
                                <TableContainer component={Paper} className={classes.tableContainer} >
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow style={{ background: "#ddd" }}>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                                <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                            </TableRow>

                                            {list.map((row, index) => (
                                                <>
                                                    {row.module === pageTitle && row.type === subType ?
                                                        <CustomTable rowItem={row} index={index} handleChange={handleTableChange} /> :
                                                        ''}
                                                </>
                                            ))}
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </>
                        ))}
                    </TabPanel>





                    {/* 
                    <TabPanel value={value} index={0}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} />
                                                    : ''}
                                            </>
                                        ))}

                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "Web" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Mobile App</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "App" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "Web" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} tabType="col2" />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Mobile App</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>User</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "App" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} tabType="-" />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "Web" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} tabType="col2" />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Mobile App</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>User</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle && row.type === "App" ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} tabType="-" />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Grid item lg={12} md={12} sm={12}>
                            <div className={classes.topHeader}>Web Portal</div>
                            <TableContainer component={Paper} className={classes.tableContainer} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#ddd" }}>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Sub Tabs</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Action</TableCell>
                                            <TableCell style={{ color: "#424241", fontFamily: 'Poppins', fontSize: "16px" }}>Permission</TableCell>
                                        </TableRow>
                                        {list.map((row, index) => (
                                            <>
                                                {row.module === pageTitle ?
                                                    <CustomTable rowItem={row} index={index} handleChange={handleTableChange} />
                                                    : ''}
                                            </>
                                        ))}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
 */}

                    <Grid item lg={12} md={12} sm={12} style={{ textAlign: 'center', marginTop: '25px' }}>
                        {location?.state?.obj ?
                            <Button className={classes.inputButton} onClick={handleOpen}>
                                Update
                            </Button>
                            : <Button className={classes.inputButton} onClick={handleOpen}>
                                Create
                            </Button>}
                    </Grid>
                </div>
            </Fragment >
        </>

    )
};

export default View;
