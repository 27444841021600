import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';
//import GlobalStyles from '../styles/globalStyles';
import LoginImg from '../assets/img/image2.png';

import PaaswordSettingForm from '../components/auth-form/password-setting';

const FirstTimeLogin = () => {
    const styles = useStyles();
    // const global = GlobalStyles();

    const [password, setPassword] = useState('');
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <PaaswordSettingForm />
            {/* <div className = {styles.inputContainer}>
                <div className = {styles.titleCotainer}>
                    <p className = {styles.title}>My School Asia</p>
                </div>
                    
                <div className = {styles.inputTitleContainer}>
                    <h4 className = {styles.input}>Set New Password</h4>
                    <div className = {styles.passwordContainer}>
                        <TextField
                            id="outlined-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            fullWidth = {true}
                            onChange = {(e) => {setPassword(e.target.value)}}
                        />
                    <div className = {styles.passwordStrengthBar}>
                        <PasswordStrengthBar password={password} />
                    </div>
                    <div style = {{marginTop: '10%'}}>
                        <Button className = {styles.button} component = {Link} to = "/login">Set Password</Button>
                    </div>                    
                   </div>
                </div>
            </div> */}

        </div>
    )
}

const useStyles = makeStyles({
    button: {
        background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 3,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 48,
        padding: '0 30px',
        width: "100%"

    },
    passwordStrengthBar: {
        marginTop: '2%'
    },
    passwordContainer: {
        width: '70%'
    },
    inputTitleContainer: {
        marginTop: '20%'
    },
    titleCotainer: {
        marginLeft: '10%'
    },
    container: {
        height: window.height,
        width: window.width,
        display: "flex",
        flexDirection: 'row'
    },
    img: {
        display: "flex",
        width: '50%',
        padding: 0,
        marginLeft: '-1%',
        marginTop: '-1%'
    },

    inputContainer: {
        marginTop: '5%',
        marginLeft: '10%',
        width: '100%',
        height: '100%'

    },

    title: {
        fontWeight: 'bold',
        fontSize: 48,
        fontFamily: 'Poppins',
    },

    input: {
        fontFamily: 'Poppins',
    }

});

export default FirstTimeLogin;