import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        '& .MuiFormControlLabel-label': {
            fontSize: '14px'   
        }
    }
}));

export default useStyles