import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProfileAction from 'redux/actions/profile';

// Components
import View from './student-details.view';

const mapStateToProps = (state) => {
    return {
        status: state.profile?.status,
        error: state.profile?.error,
        profile: state.profile?.data
    };
}

const mapDispatchToProps = (dispatch) => {
    const profileActions = bindActionCreators(ProfileAction, dispatch);

    return {
        onReset$: () => {
            profileActions.resetStatus();
        },
        onFetch$: (profileID) => {
            profileActions.getProfileDetails(profileID);
        },
        inActiveStatus$: (data) => {
            profileActions.updateStatusToInactive(data);
        },
        activeStatus$: (data) => {
            profileActions.updateStatusToActive(data);
        },
        updateAcademicStatus$: (data) => {
            profileActions.updateAcademicStatus(data);
        }
    }
}

// const Container = ({ status, error, profiles, onFetch$ }) => {
const Container = ({ profileID, status, error, profile, updateAcademicStatus$, onFetch$, onReset$, activeStatus$, onCloseModal, inActiveStatus$ }) => {
    useEffect(() => {
        if (profileID) { onFetch$(profileID); }
    }, [profileID]);

    const inActiveStatus = (data) => {
        inActiveStatus$(data)
    }

    const activeStatus = (data) => {
        activeStatus$(data)
    }
    const updateAcademicStatus = (data) => {
        updateAcademicStatus$(data)
    }

    return (
        <View
            activeStatus={activeStatus}
            inActiveStatus={inActiveStatus}
            updateAcademicStatus={updateAcademicStatus}
            data={profile}
            status={status}
            resErr={error}
            onCloseModalPopup={onCloseModal}
            onReset={onReset$}
            onFetch={onFetch$} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);