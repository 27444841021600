import React from 'react';

const Messages = () => {
    return (
        <div>
            <h1>Messages</h1>
        </div>
    )
}

export default Messages;