import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import VerificationForm from '../components/auth-form/verification';

const VerificationPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Verification</title>
            </Helmet>

            <VerificationForm/>
        </Fragment>
    )
}

export default VerificationPage;