import axios from 'axios';
import Session from 'services/local/session';

const get = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile`;

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getType = async (profileType, orgID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/type`;

    return await axios({
        method: 'GET',
        url: url,
        params: { profileType, orgID },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        });
}

const getByID = async (profileID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/${profileID}`;

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        });
}

const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile`;

    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const enroll = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/enrol`;

    return await axios({
        method: 'PUT',
        url: url,
        data: data,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getUnenrolled = async (orgID, year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/students/unenrolled`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID, year },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getEnrolled = async (orgID, year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/students/enrolled`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID, year },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const searchParent = async ({ searchStr, orgID, searchField }) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/existing`;

    return await axios({
        method: 'GET',
        url: url,
        params: {
            searchStr,
            orgID,
            searchField
        },
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const transferStudent = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/transfer`;

    return await axios({
        method: 'PUT',
        url: url,
        data,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getProfileDetails = async (profileID) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/details/${profileID}`;

    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getUnassigned = async (orgID, year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable/teacher/unassigned`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID, year },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const getAssigned = async (orgID, year) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/timeTable/teacher/assigned`;

    return await axios({
        method: 'GET',
        url: url,
        params: { orgID, year },
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const update = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile`;

    return await axios({
        method: 'PUT',
        url: url,
        data,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const activeStatus = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/activate`;

    return await axios({
        method: 'POST',
        url: url,
        data,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const inActiveStatus = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/profile/deactivate`;

    return await axios({
        method: 'POST',
        url: url,
        data,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}

const academicStatus = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/extra_status`;

    return await axios({
        method: 'PUT',
        url: url,
        data,
        headers: {
            Authorization: Session.BearerToken()
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            return err.response?.data
        })
}


export default {
    get,
    getUnenrolled,
    getEnrolled,
    getType,
    getByID,
    create,
    enroll,
    searchParent,
    transferStudent,
    getProfileDetails,
    getUnassigned,
    getAssigned,
    update,
    activeStatus,
    inActiveStatus,
    academicStatus
};