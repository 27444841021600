import axios from 'axios';
import Session from 'services/local/session';

const getList = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/class/byOrg/${Session.orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}

const getGroups = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/groups?orgID=${Session.orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}

const getUsers = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/profile?orgID=${Session.orgID}`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}




const create = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/role`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}

const update = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/role`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}


const createGroup = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/group`;
    return await axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}

const updateGroup = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/group`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}

const confirmAssign = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/group/assign`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}
const confirmUnAssign = async (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/group/unassign`;
    return await axios({
        method: 'PUT',
        url: url,
        data: payload,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}



const populatePermissions = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sys_ad/populatePermissions`;
    return await axios({
        method: 'GET',
        url: url,
        headers: {
            Authorization: Session.BearerToken(),
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.log('error :', err);
            return err.response?.data
        })
}



export default {
    getList,
    create,
    update,
    populatePermissions,
    getGroups,
    createGroup,
    confirmAssign,
    confirmUnAssign,
    updateGroup
};