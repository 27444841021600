import React, { Fragment } from 'react';

// Components
import { DataGrid as MUDataGrid, DataGridProps } from '@material-ui/data-grid';
import useStyles from './style';

/**
 * @component 
 * @param {DataGridProps} props
 */
const DataGrid = (props) => {
    const cls = useStyles();

    return (
        <Fragment>
            <MUDataGrid
                className={cls.table}
                autoHeight={true}
                checkboxSelection={true}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                rows={props.rows}
                columns={props.columns}
                loading={props.loading}
                {...props}
            />
        </Fragment>
    )
}

DataGrid.defaultProps = {
    autoHeight: true,
    checkboxSelection: true,
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    rows: [],
    columns: [],
    loading: false
}

export default DataGrid
