import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './richtext-editor.module.scss';

import React from 'react';
import classnames from 'classnames';

// Components
// import { Form, Input as AntdInput } from 'antd';
import { Editor } from "react-draft-wysiwyg";
import Select from "../select";
// import { InputError } from '~/components/common/form-unit';
import TextField from '@material-ui/core/TextField';
const RichTextEditor = ({ required, label, error, onChange, editorState, ...props }) => {
    //Available Options - ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
    const toolbarOptions = {
        options: ['history', 'fontFamily', 'fontSize', 'inline', 'list', 'textAlign'],
        inline: {
            options: ['bold', 'italic', 'underline']
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Calibri'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        list: {
            inDropdown: false,
            options: ['unordered', 'ordered', 'indent', 'outdent']
        },
    };

    return (
        <div
            label={label &&
                <span className={classnames({ required })}>
                    {label}
                </span>
            }
            labelAlign="left"
            labelCol={{ xs: 24 }}
            wrapperCol={{ xs: 24 }}
        >
            <div className="rich-text-container" style={{ border: '1px solid #ddd', borderTop: 'none', minHeight: '200px' }}>
                <Editor
                    {...props}
                    editorState={editorState}
                    toolbar={toolbarOptions}
                    toolbarClassName="rich-text-toolbar"
                    wrapperClassName="demo-wrapper"
                    editorClassName="rich-text-editor"
                    onEditorStateChange={onChange}

                />

                {/* <InputError error={error} label={label} /> */}
            </div>
        </div>
    )
}

RichTextEditor.defaultProps = {
    onChange: () => { }
}

export default RichTextEditor;