import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import useSession from '../../../hooks/use-session';
import CalendarAction from '../../../redux/actions/acedemic-calendar';

// Components
import View from './select-available-class.view';

const mapStateToProps = (state) => {
    return {
        status: state.acedemicCalendar?.status, 
        resErr: state.acedemicCalendar?.error,
        availableClasses: state.acedemicCalendar?.availableClasses,
    };
}

const mapDispatchToProps = (dispatch) => {
    const calendarActions = bindActionCreators(CalendarAction, dispatch);

    return {
        onFetch$: (orgID, year) => {
            calendarActions.getClassesBasedOnAvailableYear(orgID, year);
        }
    }
}

const Container = ({ 
    data, 
    year, 
    error, 
    errorLabel, 
    onChange, 
    status, 
    resErr, 
    availableClasses, 
    onFetch$ 
}) => {
    const session = useSession();

    useEffect(() => {
        if (session.orgID && year) {
            onFetch$(session.orgID, year);
        }
    }, [session.orgID, year]);

    return (
        <View 
            data={data}
            error={error}
            errorLabel={errorLabel}
            status={status.fetchClasses}
            resErr={resErr}
            availableClasses={availableClasses}
            onChange={onChange}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);