import {
    TIMETABLE_CREATE,
    TIMETABLE_CREATE_SUCCESS,
    TIMETABLE_CREATE_FAILED,

    RESET_STATUS,

    TIMETABLE_UPDATE,
    TIMETABLE_UPDATE_SUCCESS,
    TIMETABLE_UPDATE_FAILED,

    TIMETABLE_FETCH,
    TIMETABLE_FETCH_FAILED,
    TIMETABLE_FETCH_SUCCESS,

    TIMETABLE_PUBLISH,
    TIMETABLE_PUBLISH_FAILED,
    TIMETABLE_PUBLISH_SUCCESS,


    TIMETABLE_TIMETABLE_FETCH,
    TIMETABLE_TIMETABLE_FETCH_FAILED,
    TIMETABLE_TIMETABLE_FETCH_SUCCESS


} from '../actions/time-table';

const initialStatus = {
    create: 'idle',
    fetch: 'idle',
    update: 'idle',
    publish: 'idle'
};

const initialState = {
    list: [],
    tList: [],
    data: [],
    error: {},
    status: initialStatus
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case RESET_STATUS: {
            return {
                ...state,
                error: {},
                status: initialStatus
            };
        }

        case TIMETABLE_CREATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'loading', update: 'idle' }
            };
        }

        case TIMETABLE_CREATE_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, create: 'failed', update: 'idle' }
            }
        }

        case TIMETABLE_CREATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, create: 'success', data: action.payload, update: 'idle' }
            };
        }


        case TIMETABLE_UPDATE: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'loading', create: 'idle' }
            };
        }

        case TIMETABLE_UPDATE_FAILED: {
            return {
                ...state,
                error: { update: action.error },
                status: { ...state.status, update: 'failed', create: 'idle' }
            }
        }

        case TIMETABLE_UPDATE_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, update: 'success', create: 'idle' }
            };
        }

        case TIMETABLE_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading', create: 'idle', update: 'idle' }
            };
        }

        case TIMETABLE_FETCH_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, fetch: 'failed', create: 'idle', update: 'idle' }
            }
        }

        case TIMETABLE_FETCH_SUCCESS: {

            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'success', data: state.data, create: 'idle', update: 'idle' },
                list: action.payload,

            }
        }


        case TIMETABLE_TIMETABLE_FETCH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'loading', create: 'idle', update: 'idle' }
            };
        }

        case TIMETABLE_TIMETABLE_FETCH_FAILED: {
            return {
                ...state,
                error: { create: action.error },
                status: { ...state.status, fetch: 'failed', create: 'idle', update: 'idle' }
            }
        }

        case TIMETABLE_TIMETABLE_FETCH_SUCCESS: {

            return {
                ...state,
                error: {},
                status: { ...state.status, fetch: 'success', data: state.data, create: 'idle', update: 'idle' },
                tList: action.payload,

            }
        }

        case TIMETABLE_PUBLISH: {
            return {
                ...state,
                error: {},
                status: { ...state.status, publish: 'loading' }

            }
        }

        case TIMETABLE_PUBLISH_FAILED: {
            return {
                ...state,
                error: { publish: action.error },
                status: { ...state.status, publish: 'failed' }
            }
        }

        case TIMETABLE_PUBLISH_SUCCESS: {
            return {
                ...state,
                error: {},
                status: { ...state.status, publish: 'success' }
            }
        }

        default:
            return state;
    }
}

export default reducer;