import _ from 'lodash';

const component = (data) => {
    if (_.isEmpty(data)) {
        return {};
    }

    return _.omitBy(_.omitBy({
        input: data.inputCode,
        primary: data.primary
    }, _.isNull), _.isUndefined);
}

export default component;