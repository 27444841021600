import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

// Components
import useGlobalStyles from '../styles/globalStyles';
import AssignedList from '../components/teacher/assigned-list';

const StudentPage = () => {
    const style = useGlobalStyles();

    return (
        <Fragment>
            <Helmet>
                <title>Assigned Teachers</title>
            </Helmet>

            <div className={style.mainContainer}>
                <AssignedList/>
            </div>
        </Fragment>
    )
}

export default StudentPage
