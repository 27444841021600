import React from 'react';
// import _ from 'lodash';

// Components
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './style';

const AlertBox = ({ 
    title, 
    message, 
    type, 
    children, 
    variant, 
    action, 
    onClose,
    iconMapping,
    closeText,
    ...props 
}) => {
    const cls = useStyles();

    return (
        <Alert 
            // className={styles.alert}
            classes={{
                root: cls.root,
                standardSuccess: cls.success,
                standardError: cls.error,
                standardInfo: cls.info
            }}
            severity={type} 
            variant={variant}
            action={action}
            onClose={onClose}
            iconMapping={iconMapping}
            closeText={closeText}
            {...props}
        >
            {(title || title.length !== 0) && 
                <AlertTitle>{title}</AlertTitle>
            }

            {message}

            {children}
        </Alert>
    )
}

export const AlertWithTransition = ({ ...props }) => {
    const [open, setOpen] = React.useState(true);

    return (
        <Collapse in={open} timeout={100}>
            <AlertBox 
                onClose={() => { props.onClose(); setOpen(false); }} 
                {...props}
            />
        </Collapse>
    )
}

AlertBox.defaultProps = {
    onClose: null,
    type: 'info',
    variant: 'standard',
    title: ''
}

export default AlertBox;