import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SystemAdminRoleAction from '../../../../redux/actions/system-admin/system-admin-role';

// Components
import View from './academic-setup-view';

const mapStateToProps = (state) => {
    return {
        status: state.systemAdminRole?.status,
        error: state.systemAdminRole?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const SystemAdminRoleActions = bindActionCreators(SystemAdminRoleAction, dispatch);

    return {
        onReset$: () => {
            SystemAdminRoleActions.resetStatus();
        },
        onCreate$: (data) => {
            SystemAdminRoleActions.createRole(data);
        },
        onUpdate$: (data) => {
            SystemAdminRoleActions.updateRole(data);
        },
    }
}

const Container = ({ status, error, onReset$, onCreate$, onUpdate$ }) => {

    const onCreate = (data) => {
        onCreate$(data);
    }
    const onUpdate = (data) => {
        onUpdate$(data);
    }
    const onReset = (data) => {
        onReset$(data);
    }
    return (
        <View
            status={status}
            resErr={error}
            onCreate={onCreate}
            onReset={onReset}
            onUpdate={onUpdate}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
