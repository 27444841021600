import React from 'react';

const Fees = () => {
    return (
        <div>
            <h1>Fees</h1>
        </div>
    )
};

export default Fees;

