import React from 'react';

import AnnouncementCreatePage from 'components/announcement/announcement-create';

const AnnouncementCreate = () => {
    return (
        <AnnouncementCreatePage />
    );
}

export default AnnouncementCreate;