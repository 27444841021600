import React, { Fragment } from 'react';
import classes from 'clsx';

// Components
import useStyles from './style';

import Button from 'components/common/button';
import AlertBox from 'components/common/alert-box';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Toaster = ({ open, onHandleClose, position, message, type, ...props }) => {
    const cls = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        onHandleClose();
    };

    if (!message || message.length === 0) {
        return <Fragment/>
    }

    return (
        <Fragment>
            <Snackbar
                // className={cls[type]}
                anchorOrigin={position}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={message}
                action={
                    <Fragment>
                        <IconButton 
                            size="small" 
                            aria-label="close" 
                            color="inherit" 
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                }
                {...props}
            >
            </Snackbar>
        </Fragment>
    )
}

Toaster.defaultProps = {
    type: 'info',
    open: false,
    position: { vertical: 'top', horizontal: 'right' },
    handleClose: () => {}
}

export default Toaster;