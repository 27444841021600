import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useSession from 'hooks/use-session';

import departmentActions from 'redux/actions/department';
// import { useGetDepartmentsQuery } from 'services/department';

// Components
import View from './departments-list.view';

const mapStateToProps = (state) => {
    return {
        status: state.department?.status,
        departments: state.department?.list,
        error: state.department?.error
    }
}

const mapDispatchToProps = (dispatch) => {
    const DepartmentActions = bindActionCreators(departmentActions, dispatch);

    return {
        onReset$: () => {
            DepartmentActions.resetStatus();
        },
        onFetch$: (orgID) => {
            DepartmentActions.getDepartments(orgID)
        }
    }
}

const Container = ({ status, error, departments, onReset$, onFetch$ }) => {
    const session = useSession();
    // const orgID = session?.orgID ?? null;

    // const { data } = useGetDepartmentsQuery(orgID);
    // console.log(data);

    useEffect(() => {
        if (session.orgID) {
            onFetch$(session.orgID);
        }
    }, [session.orgID]);

    return (
        <View 
            status={status} 
            resErr={error.fetch} 
            departments={departments}
            onReset={onReset$}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);