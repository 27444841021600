import { isEmpty, isNull, isUndefined, map, omitBy, find, toLower, upperFirst } from 'lodash';
import moment from 'moment';

import base from './base';
import component from './component';

const normalizer = (data) => {
    if (isEmpty(data)) {
        return {};
    };

    const primaryAddress = find(data?.addresses, (o) => { return o.primary === true });
    const addressComponent = component.address(primaryAddress);

    const emailComponent = component.email(data?.email);
    const identificationComponent = component.identification(data?.identification);
    const phoneComponent = component.phoneNumber(data?.phone);
    const contactComponent = component.emergencyContact(data?.emergencyContact);
    const codeComponent = component.code(data?.codes);

    let groups = [];
    if (!isEmpty(data.groups)) {
        map(data.groups, o => {
            groups.push(component.group(o));
        });
    }

    let addresses = [];
    if (!isEmpty(data.addresses)) {
        map(data.addresses, o => {
            addresses.push(component.address(o));
        });
    }

    return omitBy(omitBy({
        ...base(data),
        ...addressComponent,
        ...emailComponent,
        ...identificationComponent,
        ...phoneComponent,
        ...contactComponent,
        ...codeComponent,
        emailAddress: isEmpty(data.email) && !isUndefined(data.emailAddress) ? data.emailAddress : emailComponent.emailAddress,
        profileStatus: data.status,
        profileID: data.profileID || data.studentID || data.guardianID || data.teacherID || data.adminID,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: `${data.firstName} ${data.lastName}`,
        nationality: data.nationality,
        race: data.race,
        raceString: data.race && upperFirst(toLower(data.race)),
        religion: data.religion,
        dob: data.dob,
        dobString: data.dob && moment(data.dob).format('DD.MM.YYYY'),
        gender: data.gender,
        genderString: data.gender && upperFirst(toLower(data.gender)),
        profileType: data.type,
        profileTypeString: data.type && toLower(data.type),
        priorSchool: data.priorSchool,
        medicalCondition: data.medicalCondition,
        groups: !isEmpty(data.groups) ? data.groups : null,
        addresses: !isEmpty(addresses) ? addresses : null,
        codes: !isEmpty(codeComponent) ? codeComponent : null,
        emergencyContact: !isEmpty(contactComponent) ? contactComponent : null,
        phone: !isEmpty(phoneComponent) ? phoneComponent : null,
        email: !isEmpty(emailComponent) ? emailComponent : null,
        identification: !isEmpty(identificationComponent) ? identificationComponent : null
    }, isNull), isUndefined);
}

export default normalizer;