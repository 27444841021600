import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useSession from '../../../hooks/use-session';
import moment from 'moment';

// Components
import useStyles from './style';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar';

import Button from '../../common/button';
import Loader from '../../common/loader';
import DataGrid from '../../common/data-grid';
import AlertBox from '../../common/alert-box';
import { Select, InputSearch } from '../../common/form-unit';
import SelectClass from '../../common/select-class';
import SelectAvailableClass from '../../common/select-available-class';
import Modal from '../../common/modal';
import SelectYear from '../../common/select-year';
import Card from 'components/common/card';

import StudentDetails from '../student-details';

import PlusIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// import Skeleton from '@material-ui/lab/Skeleton';
import LinkMaterial from "@material-ui/core/Link";

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";

const View = ({ status, students, resErr, enrolledCount, avilableYears, unenrolledCount, totalCount, onEnroll, onFetch, onReset }) => {
    const session = useSession();
    const history = useHistory();
    const cls = useStyles();
    const { t } = useTranslation();

    const initialFilters = { selectedYear: moment().format('YYYY') };
    const initialInput = { actionType: 'ENROL', selectedYear: moment().format('YYYY') };
    const initialLoading = false;
    const initialModal = { open: false, content: null };

    const [input, setInput] = useState(initialInput);
    const [filters, setFilters] = useState(initialFilters);
    const [loading, setLoading] = useState(initialLoading);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [modal, setModal] = useState(initialModal);
    const [filteredList, setFilteredList] = useState([]);

    const [backupStudents, setBackUpStudents] = useState([])
    const [studentList, setStudentList] = useState([]);
    const [clear, setClear] = useState(false)
    const [avlYears, setAvlYears] = useState([])

    const SearchData = () => {
        let studentList = backupStudents.filter(item => {
            return (
                (
                    (filters.gender !== undefined ? (item.gender.toLowerCase() == filters.gender.toLowerCase()) : true) &&
                    (filters.status !== undefined ? (item.status.toLowerCase() == filters.status.toLowerCase()) : true) &&
                    (input.selectedClass !== undefined ? (item.class?.ID?.indexOf(input.selectedClass) > -1) : true) &&
                    (filters.studentName !== undefined ? (item.name.toLowerCase().indexOf(filters.studentName.toLowerCase()) > -1) : true)
                )
            );
        })
        setClear(true)
        setStudentList([])
        setStudentList(studentList)
    }

    useEffect(() => {
        setAvlYears(avilableYears)
    }, [avilableYears])

    // useEffect(() => {
    //     console.log('students....')
    //     setStudentList(students)
    //     setBackUpStudents(students);
    // }, [students?.length > 0])

    useEffect(() => {
        if (session.orgID && filters.selectedYear) {
            setSelectedIDs([])
            onFetch(session.orgID, filters.selectedYear)
        }

        // Refetch after transfer success
        if (session.orgID && filters?.selectedYear && status.enroll === 'success') {
            setSelectedIDs([])
            onFetch(session.orgID, filters?.selectedYear);
            onReset();
        }
    }, [session.orgID, filters.selectedYear, status.enroll]);

    useEffect(() => {
        if (status.fetchUnenrolled === 'loading' || status.fetchEnrolled === 'loading' || status.enroll === 'loading') {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (status.fetchUnenrolled === 'success') {
            const sortedStudents = students.sort((studA, studB)=>{return studA.name.localeCompare(studB.name)});
            setStudentList(sortedStudents)
            setBackUpStudents(sortedStudents);
        }
    }, [status]);

    useEffect(() => {
        SearchData();
    }, [filters.gender])

    useEffect(() => {
        SearchData();
    }, [filters.status])

    useEffect(() => {
        SearchData();
    }, [filters.studentName])


    useEffect(() => {
        if (selectedIDs.length == 0) {
            SearchData();
        }
    }, [input.selectedClass])

    const onSelectCheckBox = (ids = []) => {
        setSelectedIDs(ids);
    }

    const onCloseModal = () => {
        setModal({ ...modal, open: false });
        setStudentList([])
        setBackUpStudents([]);
        setTimeout(() => {
            onFetch(session.orgID, filters?.selectedYear);
            setTimeout(() => {
                onReset();
            }, 1000);
        }, 100);

    }

    const onMenuClick = ({ type, data }) => {
        switch (type) {
            case 'EDIT':
                setModal({
                    ...modal,
                    open: true,
                    content: <StudentDetails profileID={data} onCloseModal={onCloseModal} />
                });

                break;

            default:
                break;
        }
    }

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 200,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    // <span className={cls.name} onClick={() => onMenuClick({ type: 'EDIT', data: params.row.ID })}>
                    //     {params.row.name}
                    // </span>
                    <ListItem>
                        <ListItemAvatar>
                            {params.row.profile_picture ? <Avatar>
                                <img src={params.row.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                            </Avatar> : <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{params.value.charAt(0)}</Avatar>}

                        </ListItemAvatar>
                        <ListItemText className={cls.name} primary={params.value} secondary="" onClick={() => onMenuClick({ type: 'EDIT', data: params.row.ID })} />
                    </ListItem>
                )
            },
        },
        {
            field: "studentID",
            headerName: "Student ID",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{params.row.code || '-'}</span>
                )
            }
        },
        {
            field: "gender",
            headerName: "Gender",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span>{_.upperFirst(_.lowerCase(params.value))}</span>
                )
            }
        },
        {
            field: "status",
            headerName: "Status",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => {
                return (
                    <span>{_.upperFirst(_.lowerCase(params.value))}</span>
                )
            }
        },
        // {
        //     field: "attendance",
        //     headerName: "Attendance",
        //     width: 150,
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         return <span>{`-`}</span>
        //     }
        // },
        // {
        //     field: "results",
        //     headerName: "Results",
        //     width: 150,
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         return <span>{`-`}</span>
        //     }
        // },
        {
            field: "year",
            headerName: "Year",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{`-`}</span>
            }
        },
        {
            field: "class",
            headerName: "Class",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{`-`}</span>
            }
        },
        {
            field: "academicStatus",
            headerName: "Academic Status",
            width: 150,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <span>{`-`}</span>
            }
        }
    ];

    const enrollAction = () => {
        const data = { studentIDs: selectedIDs, classID: input.selectedClass };
        onEnroll(data);
    }

    if (loading) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Loader size="33px" />
            </div>
        )
    }

    const reset = () => {
        let obj = {
            gender: '',
            status: '',
            class: '',
            studentName: ''
        }
        setFilters(obj);
        setClear(false)
        setStudentList(backupStudents)
    }

    const getStudents = (event) => {
        setFilters({ ...filters, selectedYear: event.target.value })
        //onChange={(target) => setFilters({ ...filters, selectedYear: target.value || moment().format('YYYY') })}
    }

    return (
        <Fragment>
            {/* <span className={cls.title}>Students</span> */}

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="large" style={{ color: "#FDB714" }} />}
                style={{
                    paddingLeft: '50px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: 'white',
                    marginTop: -30,
                    marginBottom: 10,
                    marginLeft: -30,
                }}
            >
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "black",
                        textDecoration: 'none'
                    }}
                >
                    Account
                </LinkMaterial>
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/students"
                >
                    Students
                </LinkMaterial>
                <LinkMaterial
                    style={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "rgba(124, 123, 123, 1)",
                    }}
                    href="/account/students/unenrolled"
                >
                    Unenrolled
                </LinkMaterial>

            </Breadcrumbs>

            {/* <Grid container={true} spacing={2} alignItems="center" style={{ marginTop: '1em' }}>
                <Grid item={true}>
                    <Card
                        title={t('title.total_students')}
                        body={t('txt.registered')}
                        count={totalCount}
                        type="success"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_primary')}
                        body={t('txt.students')}
                        count={0}
                        type="info"
                    />
                </Grid>

                <Grid item={true}>
                    <Card
                        title={t('title.total_secondary')}
                        body={t('txt.students')}
                        count={0}
                        type="warning"
                    />
                </Grid>

                <Grid item={true} style={{ marginLeft: 'auto' }}>
                    <Button
                        label={t('action.new_student')}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        type="primary"
                        size="large"
                        onClick={() => history.push('/account/students/create')}
                    />
                </Grid>
            </Grid> */}

            <AlertBox
                type="success"
                style={{ marginTop: '1em' }}
                icon={false}
                message={(
                    <Fragment>
                        <span>There are <b>{enrolledCount}</b> enrolled students.</span>
                        <Link style={{ marginLeft: '3px', color: '#5C7356' }} to="/account/students">
                            View now
                        </Link>
                    </Fragment>
                )}
            />

            {/* <Breadcrumbs
                separator={<NavigateNextIcon style={{ color: '#0880AE' }} size="small" />}
                style={{ marginTop: '1em' }}
            >
                <span className={cls.link}>
                    Account
                </span>
                <NavLink className={cls.link} exact={true} activeStyle={{ fontWeight: 600 }} to="/account/students">
                    Students
                </NavLink>
                <NavLink className={cls.link} exact={true} activeStyle={{ fontWeight: 600 }} to="/account/students/unenrolled">
                    Unenrolled
                </NavLink>
            </Breadcrumbs> */}

            <div className={cls.table__wrapper}>
                <span className={cls.title} style={{ fontSize: 18 }}>
                    Students {`(${students?.length})`}
                </span>


                {/* Hud New code (for search implementation)*/}
                <Grid container={true} spacing={1}>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Year</label> */}
                        {/* <SelectYear
                            onChange={(value) => setFilters({ ...filters, selectedYear: value || moment().format('YYYY') })}
                        /> */}
                        <Select
                            placeholder="By Year"
                            name="Year"
                            value={filters.selectedYear || ''}
                            onChange={(value) => getStudents(value)}

                            options={avlYears.map((year) => {
                                return { value: year, label: year };
                            })}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={2} sm={12} xs={12}>
                        {/* <label>Class</label> */}
                        <SelectAvailableClass
                            placeholder="Select Class"
                            year={filters.selectedYear}
                            onChange={(value) => setFilters({ ...filters, selectedClass: value?.classID })}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Gender</label> */}
                        <Select
                            placeholder="By Gender"
                            name="gender"
                            value={filters.gender || ''}
                            displayEmpty={false}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Status</label> */}
                        <Select
                            placeholder="By Status"
                            name="status"
                            displayEmpty={false}
                            value={filters.status || ''}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            options={[
                                { label: <em>All</em>, value: undefined },
                                { label: 'Active', value: 'ACTIVE' },
                                { label: 'Inactive', value: 'INACTIVE' }
                            ]}
                        />
                    </Grid>

                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        {/* <label>Search by Name</label> */}
                        <InputSearch
                            name="studentName"
                            value={filters.studentName || ''}
                            onChange={({ target }) => setFilters({ ...filters, [target.name]: target.value })}
                            placeholder="Search by Student Name or ID"
                        />
                    </Grid>


                    <Grid item lg={1} style={{ textAlign: 'left' }}>
                        <br></br>
                        <IconButton
                            color="#FFC000"
                        >
                            <SearchOutlinedIcon
                                onClick={SearchData}
                                style={{
                                    color: "black",
                                    background: 'rgba(253, 183, 20, 1)',
                                    padding: 7,
                                    width: 55,
                                    height: 40,
                                    borderRadius: 4,
                                    marginTop: -24,
                                    marginLeft: -21,
                                }} />
                        </IconButton>

                    </Grid>
                    {/* <Grid item lg={1} style={{ textAlign: 'left' }}>
                        <br></br> 
                        {clear ?
                            <IconButton
                                color="#FFC000"
                            >
                                <ClearIcon
                                    onClick={reset}
                                    style={{
                                        color: "black",
                                        background: 'linear-gradient(213.06deg, rgba(255, 192, 0, 0.8) 4.6%, rgba(255, 138, 0, 0.8) 81.54%)',
                                        width: 55,
                                        height: 40,
                                        borderRadius: 4
                                    }} />
                            </IconButton>
                            : ''}
                    </Grid> */}
                </Grid>

                <Grid
                    container={true}
                    // justify="flex-end"
                    alignItems="center"
                    spacing={1}
                    style={{
                        backgroundColor: 'rgba(216, 235, 243, 0.5)',
                        borderRadius: '3px',
                        padding: '0 10px',
                        opacity: !_.isEmpty(selectedIDs) ? 1 : 0.5,
                        pointerEvents: !_.isEmpty(selectedIDs) ? 'all' : 'none',
                        transition: 'all 200ms'
                    }}
                >
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Select
                            name="actionType"
                            value={input.actionType}
                            onChange={({ target }) => setInput({ ...input, [target.name]: target.value })}
                            options={[
                                { label: 'Enrol', value: 'ENROL' }
                            ]}
                        />
                    </Grid>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12} style={{ textAlign: 'center' }}>
                        <span style={{ color: 'rgba(37, 51, 90, 1)' }}>selected Students to</span>
                    </Grid>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <SelectYear
                            onChange={(value) => {
                                setInput({ ...input, selectedClass: null })
                                setInput({ ...input, selectedYear: value })
                            }}
                        />

                        {/* onChange={(value) => { setInput2({ ...input2, selectedClass: value?.classID }); setError({}); }} */}
                    </Grid>
                    <Grid item={true} lg={3} md={3} sm={12} xs={12}>
                        <SelectAvailableClass
                            year={input.selectedYear}
                            onChange={(value) => setInput({ ...input, selectedClass: value?.classID })}
                        />
                    </Grid>
                    <Grid item={true} lg={2} md={3} sm={12} xs={12}>
                        <Button
                            label="Enrol"
                            type="primary"
                            size="large"
                            style={{ width: '160px' }}
                            onClick={enrollAction}
                            isLoading={loading}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} style={{ marginTop: '1em' }}>
                    <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                        <DataGrid
                            // rows={!_.isEmpty(filteredList) ? filteredList : students}
                            rows={studentList}
                            getRowId={(row) => row.ID}
                            columns={columns}
                            onSelectionModelChange={({ selectionModel }) => onSelectCheckBox(selectionModel)}
                        />
                    </Grid>
                </Grid>
            </div>

            {modal.content &&
                <Modal
                    showFooter={false}
                    open={modal.open}
                    onHandleClose={onCloseModal}
                    fullScreen={true}
                    maxWidth="lg"
                    scroll="body"
                >
                    {modal.content}
                </Modal>
            }
        </Fragment>
    )
}

export default View;