import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';

import TimeTableList from '../components/timetable/list';

const Timetable = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Breadcrumbs 
               aria-label="breadcrumb"
               separator={<NavigateNextIcon fontSize="large" style={{color:"#FDB714"}} />}
               style={{ 
                   paddingLeft: '50px',
                   paddingTop: 10,
                   paddingBottom: 10,
                   backgroundColor: 'white',
                   marginTop: -10,
                   marginBottom: 10,
                   marginLeft:-30,
                   marginRight:-30,
                }}
           >
                <Link 
               style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: 600,
                color: "black",
                textDecoration: 'none'
               }}
                >
                    Timetable
                    </Link>
            </Breadcrumbs>

            <Helmet>
                <title>Timetable</title>
            </Helmet>

            <div className={classes.subContainer}>
                <TimeTableList />
            </div>

        </div>
    );
};


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: '0.5%',
    },

    subContainer: {
        marginTop: '5%',
        borderRadius: 10
    }
}));


export default Timetable;

