import { isEmpty, isUndefined, omitBy, isNull, map, findIndex, cloneDeep } from 'lodash';
import component from './component';

const normalizer = (data) => {
    if (isEmpty(data)) {
        return {};
    };

    let identificationData;
    if (!isEmpty(data.identification) && !isUndefined(data.identification)) {
        identificationData = { ...component.identification(data.identification), ...component.identification(data) };
    }
    else {
        identificationData = { ...component.identification(data) };
    }

    let emergencyContactData;
    if (!isEmpty(data.emergencyContact) && !isUndefined(data.emergencyContact)) {
        emergencyContactData = { ...component.emergencyContact(data.emergencyContact), ...component.emergencyContact(data) };
    }
    else {
        emergencyContactData = { ...component.emergencyContact(data) };
    }

    let codeData;
    if (!isEmpty(data.code) && !isUndefined(data.code)) {
        codeData = { ...component.code(data.code), ...component.code(data) };
    }
    else {
        codeData = { ...component.code(data) }
    }

    let emailData;
    if (!isEmpty(data.email) && !isUndefined(data.email)) {
        emailData = { ...component.email(data.email), ...component.email(data) };
    }
    else if (isEmpty(data.email) && !isUndefined(data.emailAddress)) { // for teachers case, the profile object a bit different
        emailData = null;
    }
    else {
        emailData = { ...component.email(data) };
    }

    let phoneData;
    if (!isEmpty(data.phone) && !isUndefined(data.phone)) {
        phoneData = { ...component.phoneNumber(data.phone), ...component.phoneNumber(data) };
    }
    else {
        phoneData = { ...component.phoneNumber(data) };
    }

    let addresses = [];
    if (!isEmpty(data.addresses) && !isUndefined(data.addresses)) {
        let newAddresses = cloneDeep(data.addresses)
        const foundIndex = findIndex(newAddresses, a => a.primaryAddress)
        if (foundIndex >= 0) {
            newAddresses[foundIndex] = {
                addressType: 'HOME',
                primaryAddress: true,
                ...component.address(data)
            }
        }
        map(newAddresses, o => { addresses.push({
            ...(o.type && {type: o.addressType}),
            ...(o.primaryAddress && {primary: o.primaryAddress}),
            ...component.address(o),
        }) });
    }
    else {
        addresses.push(component.address(data));
    }

    let groups = []
    if (!isEmpty(data.groups) && !isUndefined(data.groups)) {
        map(data.groups, o => { groups.push(o); });
    }
    // else {
    //     groups.push(data);
    // }

    return omitBy(omitBy({
        profileID: data.profileID,
        emailAddress: !isUndefined(data.emailAddress) && isEmpty(data.email) ? data.emailAddress : null,
        status: data.profileStatus,
        type: data.profileType,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        gender: data.gender,
        nationality: data.nationality,
        religion: data.religion,
        race: data.race,
        medicalCondition: data.medicalCondition,
        priorSchool: data.priorSchool,
        groups: !isEmpty(groups) ? groups : null,
        code: !isEmpty(codeData) ? codeData : null,
        email: !isEmpty(emailData) ? emailData : null,
        phone: !isEmpty(phoneData) ? phoneData : null,
        emergencyContact: !isEmpty(emergencyContactData) ? emergencyContactData : null,
        addresses: !isEmpty(addresses) ? addresses : null,
        identification: !isEmpty(identificationData) ? identificationData : null,
    }, isNull), isUndefined);
}

export default normalizer;