import { first, omitBy, isNull, isUndefined, isEmpty } from 'lodash';

let dev = process.env.APP_ENV === 'dev';

const validation = (err) => {
    if (dev) { console.error(err) }
    
    if (isEmpty(err)) return false;

    const { context, message, type } = first(err?.details || []) || {};
    const { label, key } = context || {};

    let value;

    if (type?.includes('empty') || type?.includes('required')) {
        value = 'ERR_REQUIRED';
    }
    else if (type?.includes('base') || type?.includes('pattern') || type?.includes('alternatives.match')) {
        value = 'ERR_PATTERN';
    }
    else if (type?.includes('min')) {
        value = 'ERR_MIN';
    }
    else if (type?.includes('max')) {
        value = 'ERR_MAX';
    }
    else if (type?.includes('length')) {
        value = 'ERR_LENGTH';
    }
    else if (type?.includes('only')) {
        value = 'ERR_ONLY';
    }
    else if (type?.includes('array.unique')) {
        value = 'ERR_UNIQUE';
    }
    else if (type?.includes('array.includesRequiredUnknowns')) {
        value = 'ERR_ONE_REQUIRED';
    }

    return key ? omitBy(omitBy({ key, value, name: label, message }, isNull), isUndefined) : false;
}

export default validation;