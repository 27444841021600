import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import View from './role-list.view';


const mapStateToProps = (state) => {
    return {
        status: state.acedemicCalendar?.status,
        error: state.acedemicCalendar?.error
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

    }
}

const Container = ({ status, error, onCreate$ }) => {

    return (
        <View status={status} resErr={error} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);