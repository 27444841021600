import React, { useEffect } from 'react';

import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
const TimetablePublish = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();


    const backToListing = () => {
        history.push(
            {
                pathname: '/timetable',
                state: { fetch: 'success' }
            }
        );
    }

    return (
        <div className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="large" />} style={{ marginLeft: 10, marginTop: 10 }}>
                <Link style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: 'bold', color: '#141824' }} to="/timetable" >
                    Timetable
                    </Link>
                <Link style={{ fontSize: 24, fontFamily: 'Poppins', color: '#141824' }}>
                    View
                    </Link>
                <Link style={{ fontSize: 24, fontFamily: 'Poppins', color: '#141824' }}>
                    Publish
                    </Link>
            </Breadcrumbs>

            <Helmet>
                <title>Timetable Template</title>
            </Helmet>

            <Paper className={classes.subContainer}>
                <div className={classes.listContainer}>
                    <div>
                        <CheckIcon fontSize="large" className={classes.customicon} />
                    </div>
                    <div style={{ color: '#141824', fontSize: '18px' }}>
                        Timetable for<span className={classes.itemname}> {location?.state?.templateName} </span> <br />Published successfully
                    </div>
                    <div className={classes.doneButtonContainer}>
                        <Button
                            onClick={backToListing}
                            variant="contained"
                            color="primary"
                            className={classes.doneButton}
                            type="submit"
                        >
                            Done
          </Button>
                    </div>
                </div>
            </Paper>

        </div>
    );
};


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: '0.5%',
    },

    customicon: {
        margin: '20px 0px',
        color: '#424241',
        borderRadius: '50px',
        fontSize: '100px',
        background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)'
    },
    itemname: {
        color: '#FFC000'
    }
    ,
    subContainer: {
        marginTop: '5%',
        borderRadius: 10
    },
    listContainer: {
        fontFamily: 'Poppins',
        borderLeftStyle: 'solid',
        borderLeftWidth: '10px',
        borderLeftColor: 'rgba(61, 58, 214, 0.9)',
        borderRadius: 10,
        minHeight: '300px',
        textAlign: 'center',
        margin: 'auto'
    },
    doneButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%",
    },

    doneButton: {
        background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
        height: 40,
        width: 175,
        color: "black",
        borderRadius: 15,
        marginBottom: '20px'
    },

}));


export default TimetablePublish;

