import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Alert from '@material-ui/lab/Alert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import image1 from '../images/total.png'
import image2 from '../images/secondary.png'
import image3 from '../images/primary.png';

import StudentInfo from './studentProfile';

import { DataGrid } from '@material-ui/data-grid';

import profileData from '../json/profiles2.json';
import studentProfile from './studentProfile';

const options = ["Edit Account", "Message Parent"];

const ITEM_HEIGHT = 48;

const StudentEnrol = ({ history }) => {
    const classes = useStyles();

    const handleClickButton = () => {
        history.push('/create-student');
    }

    const handleClickView = () => {
        history.push('/account/students');
    }

    let data = profileData.map((o, i) => { return { ...o, id: i + 1 } });

    const [spacing, setSpacing] = React.useState(2);

    const initialInput = { nationality: 'MY' };
    const initialError = {};
    const initialState = 'ENROL';

    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [buttonState, setButtonState] = useState(initialState);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [age, setAge] = React.useState('');
    const [disableField, setDisableField] = useState(true);
    const [scroll, setScroll] = React.useState('paper');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenInfo = (scrollType) => {
        setOpenInfo(true);
        setScroll(scrollType);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false);
    };

    const handleOpenEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleOpenModal = (e) => {
        e.preventDefault()
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onSubmit = async () => {
        try {
            //console.log(input);
        }
        catch (err) {
           // console.log(err);
        }
    }

    const onChange = (e) => {
        let { name, value } = e.target;

        setInput(prevInput => {
            return {
                [name]: value
            }
        }
        );

        setError(initialError);
    }

    const onHandleCheckbox = ({ rowIds }) => {
        const foundIndex = data.findIndex(o => {
            const isSelected = rowIds.some(initO => {
                return initO === o.id
            });

            return isSelected ? 1 : -1;
        });

        if (foundIndex >= 0) {
            setDisableField(false);
        }

        if (rowIds.length === 0) {
            setDisableField(true);
        }
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div className={classes.container}>
            <Helmet>
                <title>Students</title>
            </Helmet>

            <div className={classes.headerContainer}>
                <Typography variant='h5' className={classes.title}>Students</Typography>

            </div>

            <div className={classes.header}>
                <Grid container justify="center" spacing={spacing} style={{ marginTop: '-80px' }}>
                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <Paper
                            className={classes.paper}
                            style={{ borderLeft: '10px solid #2C9009', borderRadius: 10, width: '90%' }}>
                            <Grid container>
                                <Grid item lg={6}><img src={image1} width="150" height="100" style={{ marginTop: '15px' }} />
                                </Grid>
                                <Grid item lg={6} style={{ borderLeft: '1px solid #2C9009', height: '70%', marginTop: '20px', textAlign: 'center' }}>
                                    <Typography variant='h6'>Total</Typography>
                                    <Typography variant='h4'>523</Typography>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <Paper
                            className={classes.paper}
                            style={{ borderLeft: '10px solid #3D3AD6', borderRadius: 10, width: '90%' }}>
                            <Grid container>
                                <Grid item lg={6}><img src={image2} width="150" height="100" style={{ marginTop: '15px' }} />
                                </Grid>
                                <Grid item lg={6} style={{ borderLeft: '1px solid #3D3AD6', height: '70%', marginTop: '20px', textAlign: 'center' }}>
                                    <Typography variant='h6'>Primary</Typography>
                                    <Typography variant='h4'>300</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <Paper
                            className={classes.paper}
                            style={{ borderLeft: '10px solid #FF8A00', borderRadius: 10, width: '90%' }}>
                            <Grid container>
                                <Grid item lg={6}><img src={image3} width="150" height="100" style={{ marginTop: '15px' }} />
                                </Grid>
                                <Grid item lg={6} style={{ borderLeft: '1px solid #FF8A00', height: '70%', marginTop: '20px', textAlign: 'center' }}>
                                    <Typography variant='h6'>Secondary</Typography>
                                    <Typography variant='h4'>223</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Button className={classes.button} onClick={handleClickButton} style={{ marginLeft: '0%' }}>
                        <AddCircleIcon style={{ marginLeft: '-10%', marginRight: '10%' }} /> New Students</Button>

                </Grid>


            </div>

            <div className={classes.root}>

                <Alert
                    style={{ marginTop: '-50px' }}
                    icon={false}
                    variant="filled" s
                    everity="success">
                    There are 2345 enrolled students. <Link color="inherit" style={{cursor: 'pointer'}} onClick={handleClickView}>View now </Link>
                </Alert>
            </div>

            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ marginTop: '20px', marginBottom: '15px' }}>
                <Link color="inherit" href='' style={{ fontSize: 24 }}>Account</Link>
                <Link color="Inherit" href='' style={{ fontSize: 24 }}>Student</Link>
                <Link color="Inherit" href='' style={{ fontSize: 24 }}>Unenrolled Students</Link>
            </Breadcrumbs>



            <TableContainer component={Paper} className={classes.tableContainer} >
                {/* <div style={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)', padding: 'auto', margin: 'auto' }}> */}
                <div >
                    <Typography variant='h5' className={classes.title} style={{ padding: '30px' }}>Unenrolled Students (2)</Typography>
                </div>

                <Grid container spacing={spacing}>
                    <Grid item lg={2} md={2} sm={1} xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="year">Year</InputLabel>
                            <Select
                                labelId="year"
                                id="year"
                                value={input.year}
                                onChange={handleChange}
                                label="Year"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='2021'>2021</MenuItem>
                                <MenuItem value='2020'>2020</MenuItem>
                                <MenuItem value='2019'>2019</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={2} xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="class">Class</InputLabel>
                            <Select
                                labelId="class"
                                id="class"
                                value={input.class}
                                onChange={handleChange}
                                label="Class"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='YR1'>Yr 1 Edison</MenuItem>
                                <MenuItem value='YR2'>Yr 2 Edison</MenuItem>
                                <MenuItem value='YR3'>Yr 3 Edison</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="gender">By Gender</InputLabel>
                            <Select
                                labelId="gender"
                                id="gender"
                                value={input.gender}
                                onChange={handleChange}
                                label="By Gender"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='MALE'>Male</MenuItem>
                                <MenuItem value='FEMALE'>Female</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="status">By Status</InputLabel>
                            <Select
                                labelId="status"
                                id="status"
                                value={input.status}
                                onChange={handleChange}
                                label="Status"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='ACTIVE'>Active</MenuItem>
                                <MenuItem value='INACTIVE'>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg container alignItems="center" style={{ marginTop: '-3px' }}>
                        <Grid item lg={10}>
                            <TextField
                                className={classes.formControl}
                                id="outlined-search-input"
                                label="Search"
                                type="search"
                                autoComplete="current-search"
                                variant="outlined"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <IconButton
                                color="#FFC000"
                            >
                                <SearchOutlinedIcon style={{ color: "black", background: 'linear-gradient(213.06deg, rgba(255, 192, 0, 0.8) 4.6%, rgba(255, 138, 0, 0.8) 81.54%)', width: 67, height: 55 }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container spacing={spacing} alignItems="center" style={{ backgroundColor: "#D8EBF3", opacity: disableField ? 0.5 : 1 }}>
                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">Enrol</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={buttonState}
                                onChange={(e) => {
                                    const setButton = e.target.value;
                                    setButtonState(setButton);
                                }}
                                label="Status"
                                disabled={disableField}
                            >
                                <MenuItem value='TRANSFER'>Transfer</MenuItem>
                                <MenuItem value='ENROL'>Enrol</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item className={classes.formControl}>
                        <label>selected Students to</label>
                    </Grid>

                    <Grid item lg={true} md={2} sm={1} xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="year">Year</InputLabel>
                            <Select
                                labelId="year"
                                id="year"
                                value={input.year}
                                onChange={handleChange}
                                label="Year"
                                disabled={disableField}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='2021'>2021</MenuItem>
                                <MenuItem value='2020'>2020</MenuItem>
                                <MenuItem value='2019'>2019</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">Class</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="class"
                                value={input.class}
                                onChange={handleChange}
                                label="Class"
                                disabled={disableField}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='YR1'>Yr 1 Edison</MenuItem>
                                <MenuItem value='YR2'>Yr 2 Edison</MenuItem>
                                <MenuItem value='YR3'>Yr 3 Edison</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={6}>
                        <Button className={classes.button} onClick={handleOpenModal} style={{ width: "60%", }}>{buttonState}</Button>
                        < Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openModal}
                            onClose={handleCloseModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                        >
                            <Fade in={openModal}>
                                <div className={classes.paperModal}>
                                    <h2 id="transition-modal-title" style={{ textAlign: 'center', color: '#25335A' }}>CONFIRM {buttonState} STUDENT</h2>
                                    <h2 style={{ textAlign: 'left', color: '#25335A' }}>
                                        The student(s) below will be transfered to the selected class:
                                        <a style={{ color: '#FFC000' }}> Yr 3 Newton</a>
                                    </h2>

                                    <Grid container item xs={22} spacing={2}>
                                        <Grid item xs={4} >
                                            <Typography>Sam M Gabriel</Typography>
                                            <Typography>Joshua H Adam</Typography>
                                            <Typography>Brian B Brown</Typography>
                                            <Typography>Alysha M Samuel</Typography>
                                            <Typography>Sam M Gabriel</Typography>
                                            <Typography>Joshua H Adam</Typography>
                                            <Typography>Brian B Brown</Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>700201548</Typography>
                                            <Typography>700201632</Typography>
                                            <Typography>700201789</Typography>
                                            <Typography>700201548</Typography>
                                            <Typography>700201548</Typography>
                                            <Typography>700201632</Typography>
                                            <Typography>700201789</Typography>
                                        </Grid>
                                    </Grid>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Button className={classes.buttonSubmit} onClick={onSubmit}>Confirm {buttonState}</Button>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Link component="button"
                                            variant="body2"
                                            onClick={() => {
                                                console.info("I'm a button.");
                                            }}>DON'T {buttonState}, return to Student Page</Link>
                                    </div>


                                </div>
                            </Fade>
                        </Modal>
                    </Grid>

                </Grid>

                <DataGrid
                    className={classes.headerTable}
                    rows={data}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            width: 300,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                                return (
                                    <Link onClick={handleOpenInfo}>{params.value}</Link>
                                )
                            }
                        },
                        { field: 'studentID', headerName: 'Student ID', width: 150, disableColumnMenu: true },
                        { field: 'gender', headerName: 'Gender', disableColumnMenu: true },
                        { field: 'attendance', headerName: 'Attendance', width: 150, disableColumnMenu: true },
                        { field: 'results', headerName: 'Results', disableColumnMenu: true },
                        { field: 'year', headerName: 'Year', disableColumnMenu: true },
                        { field: 'class', headerName: 'Class', width: 150, disableColumnMenu: true },
                        { field: 'status', headerName: 'Status', disableColumnMenu: true },
                        {
                            field: 'academicStatus',
                            headerName: 'Academic Status',
                            width: 180,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                                return (
                                    <Chip
                                        color="primary"
                                        label={params.value}
                                        style={{
                                            backgroundColor: params.value === 'Ongoing' ? '#DEF2D6' : '#EBC8C4',
                                            color: params.value === 'Ongoing' ? '#57B43F' : 'red',
                                            borderRadius: '3px'
                                        }}
                                    />
                                )
                            }
                        },
                        {
                            field: '',
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                                return (
                                    <div>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={openMenu}
                                            onClose={handleCloseMenu}
                                            PaperProps={{
                                                elevation: 1,
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: "20ch",
                                                }
                                            }}
                                        >
                                            {options.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    onClick={() => {
                                                        handleOpenEdit()
                                                        handleCloseMenu()
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                )
                            }
                        }
                    ]}
                    checkboxSelection={true}
                    autoHeight={true}
                    // onRowSelected={(a) => console.log(a)}
                    onSelectionChange={onHandleCheckbox}
                />
            </TableContainer>

            <Dialog
                open={openInfo}
                onClose={handleCloseInfo}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                fullScreen={true}
            >
                <DialogContent className={classes.paperModalInfo}>
                    <div>
                        <StudentInfo />
                    </div>
                </DialogContent>
            </Dialog>

        </div >
    )
}


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
  },
  headerContainer: {
    marginBottom: "5%",
    marginLeft: "1%",
    marginTop: "3%",
    flexDirection: "row",
    display: "flex",
  },
  header: {
    marginBottom: "5%",
    marginLeft: "1%",
    marginTop: "3%",
    flexDirection: "column",
    display: "flex",
  },
  title: {
    color: "dark blue grad",
  },
  container: {
    // marginTop: '2%',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    height: "100%",
    paddingBottom: "5%",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    overflowX: "visible !important",
    height: "100vh",
    padding: "2em 0em",
  },
  button: {
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
    border: 5,
    borderWidth: 15,
    borderColor: "#25335A",
    borderRadius: 15,
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: "black",
    height: 60,
    padding: "0 30px",
    width: "20%",
  },
  buttonSubmit: {
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
    border: 5,
    borderWidth: 15,
    borderColor: "#25335A",
    borderRadius: 15,
    color: "black",
    height: 60,
    padding: "0 30px",
    width: "20%",
  },
  paper: {
    display: "flex",
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "30%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperModalInfo: {
    backgroundColor: "#EBECF5",
    border: "none",
    // height: '100',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  headerTable: {
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    marginTop: "2em",
  },
}));

export default withRouter(StudentEnrol);