import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { omitBy, reduce, first, upperCase, isNull, isUndefined, map, find, keys, isEmpty, lowerCase } from 'lodash';
import countries from 'utils/countries.json';
import { useTranslation } from 'react-i18next';
import useToaster from 'hooks/use-toaster';

import normalize from 'utils/normalizers';
import { schema } from './profile-form-v2.validation';

// Components
import useStyles from './style';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { Input, Select, DatePicker, TextArea, CheckBox, FileUpload } from 'components/common/form-unit';
import AlertBox from 'components/common/alert-box';
import IdentificationV2 from 'components/common/identification-v2';
import AddressFields from 'components/common/address';
import ParentGuardianForm from 'components/common/guardian-parent';
import PhoneNumber from 'components/common/phone-number';
import Button from 'components/common/button';
import SearchParent from 'components/common/search-parent';

const ProfileForm = ({ data, status, resErr, onReset, onCreate, onCreateStudentExistingParent, onUpdate }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { Toaster, onOpenToaster } = useToaster({ message: t('toaster.updated_successfully') });

    const ref = useRef(null);
    const imageRef = useRef(null);

    const initialState = {};
    const initialShowField = { mother: false, father: false, guardianOne: false, guardianTwo: false };
    const initialInput = {
        student: {
            country: "MY",
            idType: "NRIC",
            nationality: "MY",
            useCurrentStudID: false,
            emergencyContactCountryCode: "60",
            emergencyContactName: "",
            emergencyContactNumber: "",
            emergencyContactRelationship: "",
            dob: "",
            firstName: "",
            gender: "",
            idNo: "",
            lastName: "",
            medicalCondition: "",
            postcode: "",
            priorSchool: "",
            race: "",
            city: '',
            religion: "",
            state: "",
            street1: "",
            street2: "",
        },
        mother: {
            // "firstName": "Brood",
            // "lastName": "Mother",
            // "emailAddress": "brood@dev.to",
            // "futureUse": true,
            // "countryCode": "60",
            // "contactNumber": "1128021945"
        },
        father: {
            // "firstName": "Muse",
            // "lastName": "Bellamy",
            // "emailAddress": "bellamy@gmail.com",
            // "futureUse": true,
            // "countryCode": "60",
            // "contactNumber": "1128021434"

            city: "",
            countryCode: "60",
            contactNumber: "",
            // contactNumber: "139241198",
            country: "MY",
            emailAddress: "",
            // emailAddress: "jacksonSamantha@dev.com",
            firstName: "",
            futureUse: true,
            idNo: "",
            // idNo: "800425031492",
            idType: "NRIC",
            lastName: "",
            nationality: "MY",
            postcode: "",
            state: "",
            street1: ""
        },
        guardianOne: {

        },
        guardianTwo: {}
    };

    const initialError = {
        student: {},
        mother: {},
        father: {},
        guardianOne: {},
        guardianTwo: {}
    };

    const [state, setState] = useState(initialState);
    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [showField, setShowField] = useState(initialShowField);
    const [loading, setLoading] = useState(false);

    useEffect(() => { // This effect runs to populate data for update
        if (isEmpty(data)) {
            setInput(initialInput);
        }

        if (!isEmpty(data)) {
            const { profile: studentData = {}, guardians = [], attachments } = data;

            const guardiansObject = reduce(guardians, (obj, o) => {
                obj[o.profileTypeString] = o;
                return obj;
            }, {});

            // Trigger checkbox each of the guardians data that exist
            setShowField({
                father: !isEmpty(guardiansObject.father) ? true : false,
                mother: !isEmpty(guardiansObject.mother) ? true : false,
                guardianOne: !isEmpty(guardiansObject.guardianOne) ? true : false,
                guardianTwo: !isEmpty(guardiansObject.guardianTwo) ? true : false,
            });

            setInput({
                ...input,
                ...guardiansObject,
                [studentData.profileTypeString]: omitBy(omitBy({
                    ...input[studentData.profileTypeString],
                    ...studentData,
                    ...attachments,
                    profileImg: !isEmpty(attachments.profile_picture) ? attachments.profile_picture?.fileUrl : null,
                    attachedDocuments: !isEmpty(attachments.profile_attachments) ? attachments.profile_attachments : null,
                    useCurrentStudID: studentData.profileCode ? true : false
                }, isNull), isUndefined)
            });
        }
    }, [data]);


    useEffect(() => {
        if (!isEmpty(resErr)) {
            if (resErr?.message.includes('NRIC/PASSPORT NO. of this student exist already')) {
                setError({
                    ...error,
                    student: {
                        ...error.student,
                        idNo: { type: 'ERR_SERVER', message: resErr?.message }
                    }
                });

                if (document.querySelector('*[id=student-idNo]')) {
                    document.querySelector('*[id=student-idNo]').focus();
                }

                return;
            }
            else if (resErr?.message.includes('This email already exist with pending status') || resErr?.message.includes('Email address already in use')) {
                let foundKey;
                for (let key in input) {
                    const emailAddress = input[key]?.emailAddress;
                    const responseEmail = resErr?.data?.email ?? resErr?.data;

                    if (!isUndefined(emailAddress) && emailAddress === responseEmail) {
                        foundKey = key;
                        break;
                    }
                }

                setError({
                    ...error,
                    [foundKey]: {
                        ...error[foundKey],
                        emailAddress: { type: 'ERR_SERVER', message: resErr?.message }
                    }
                });

                if (document.querySelector(`*[id=${foundKey}-idNo]`)) {
                    document.querySelector(`*[id=${foundKey}-idNo]`).focus();
                }

                return;
            }
            // else if (resErr?.message.includes('This phone number already exist with pending status') || resErr?.message.includes('Phone number already in use')) {
            else if (resErr?.message.includes('This phone number already exist with pending status')) {
                let foundKey;
                for (let key in input) {
                    const fullNumber = `${input[key]?.countryCode}${input[key]?.contactNumber}`;

                    // 139241190
                    // console.log(fullNumber); 

                    // TODO: Ask BE to return same error response for update
                    // !BUG: BE need to check for update whether ic exist oso or not

                    if (!isUndefined(fullNumber) && fullNumber === resErr?.data?.phoneNumber) {
                        foundKey = key;
                        break;
                    }
                }

                setError({
                    ...error,
                    [foundKey]: {
                        ...error[foundKey],
                        fullNumber: { type: 'ERR_SERVER', message: resErr?.message }
                    }
                });

                if (document.querySelector(`*[id=${foundKey}-contactNumber]`)) {
                    document.querySelector(`*[id=${foundKey}-contactNumber]`).focus();
                }

                return;
            }
            else if (resErr?.message.includes('This identification number already exist with pending status')) {
                let foundKey;
                for (let key in input) {
                    const idNo = input[key]?.idNo;

                    if (!isUndefined(idNo) && idNo === resErr?.data?.idNo) {
                        foundKey = key;
                        break;
                    }
                }

                setError({
                    ...error,
                    [foundKey]: {
                        ...error[foundKey],
                        idNo: { type: 'ERR_SERVER', message: resErr?.message }
                    }
                });

                if (document.querySelector(`*[id=${foundKey}-idNo]`)) {
                    document.querySelector(`*[id=${foundKey}-idNo]`).focus();
                }

                return;
            }
            else {
                ref.current.focus();
                setError({ ...error, response: upperCase(resErr?.message) });
            }
        }
    }, [resErr]);

    useEffect(() => {
        // trigger checkbox parent selection if error occurs in one of em
        if (!isEmpty(error.father)) {
            setShowField({ ...showField, father: true });
        }
        else if (!isEmpty(error.mother)) {
            setShowField({ ...showField, mother: true });
        }
        else if (!isEmpty(error.guardianOne)) {
            setShowField({ ...showField, guardianOne: true });
        }
        else if (!isEmpty(error.guardianTwo)) {
            setShowField({ ...showField, guardianTwo: true });
        }
    }, [error]);

    useEffect(() => {
        if (status.create === 'loading' || status.update === 'loading') {
            setLoading(true);
        }
        else if (status.create === 'success') {
            onReset();
            history.push('/account/students/unenrolled');
        }
        else if (status.update === 'success') {
            onOpenToaster();
            setTimeout(() => {
                history.push('/account/students/unenrolled');
            }, 2000);
        }
        else {
            setLoading(false);
        }
    }, [status.create, status.update]);

    useEffect(() => { // ! Bugs
        const { father, mother, guardianOne, guardianTwo } = input;

        if (father.isEmergencyContact === true) {
            setInput(prevInput => {
                return {
                    ...prevInput,
                    student: {
                        ...prevInput.student,
                        emergencyContactName: `${father.firstName} ${father.lastName}`,
                        emergencyContactRelationship: 'Father',
                        emergencyContactCountryCode: father.countryCode,
                        emergencyContactNumber: father.contactNumber
                    },
                    mother: !isEmpty(prevInput.mother) ? { ...prevInput.mother, isEmergencyContact: false } : {},
                    guardianOne: !isEmpty(prevInput.guardianOne) ? { ...prevInput.guardianOne, isEmergencyContact: false } : {},
                    guardianTwo: !isEmpty(prevInput.guardianTwo) ? { ...prevInput.guardianTwo, isEmergencyContact: false } : {}
                }
            })
        }

        if (mother.isEmergencyContact === true) {
            setInput(prevInput => {
                return {
                    ...prevInput,
                    student: {
                        ...prevInput.student,
                        emergencyContactName: `${mother.firstName} ${mother.lastName}`,
                        emergencyContactRelationship: 'Mother',
                        emergencyContactCountryCode: mother.countryCode,
                        emergencyContactNumber: mother.contactNumber
                    },
                    father: !isEmpty(prevInput.father) ? { ...prevInput.father, isEmergencyContact: false } : {},
                    guardianOne: !isEmpty(prevInput.guardianOne) ? { ...prevInput.guardianOne, isEmergencyContact: false } : {},
                    guardianTwo: !isEmpty(prevInput.guardianTwo) ? { ...prevInput.guardianTwo, isEmergencyContact: false } : {}
                }
            });
        }

        if (guardianOne.isEmergencyContact === true) {
            setInput(prevInput => {
                return {
                    ...prevInput,
                    student: {
                        ...prevInput.student,
                        emergencyContactName: `${guardianOne.firstName} ${guardianOne.lastName}`,
                        // emergencyContactRelationship: '', // Up to user to decide relationship for guardian
                        emergencyContactCountryCode: guardianOne.countryCode,
                        emergencyContactNumber: guardianOne.contactNumber
                    },
                    father: !isEmpty(prevInput.father) ? { ...prevInput.father, isEmergencyContact: false } : {},
                    mother: !isEmpty(prevInput.mother) ? { ...prevInput.mother, isEmergencyContact: false } : {},
                    guardianTwo: !isEmpty(prevInput.guardianTwo) ? { ...prevInput.guardianTwo, isEmergencyContact: false } : {},
                }
            })
        }

        if (guardianTwo.isEmergencyContact === true) {
            setInput(prevInput => {
                return {
                    ...prevInput,
                    student: {
                        ...prevInput.student,
                        emergencyContactName: `${guardianTwo.firstName} ${guardianTwo.lastName}`,
                        // emergencyContactRelationship: '', // Up to user to decide relationship for guardian
                        emergencyContactCountryCode: guardianTwo.countryCode,
                        emergencyContactNumber: guardianTwo.contactNumber
                    },
                    father: !isEmpty(prevInput.father) ? { ...prevInput.father, isEmergencyContact: false } : {},
                    guardianOne: !isEmpty(prevInput.guardianOne) ? { ...prevInput.guardianOne, isEmergencyContact: false } : {},
                    mother: !isEmpty(prevInput.mother) ? { ...prevInput.mother, isEmergencyContact: false } : {},
                }
            })
        }
    },
        [
            input.father?.isEmergencyContact,
            input.guardianOne?.isEmergencyContact,
            input.mother?.isEmergencyContact,
            input.guardianTwo?.isEmergencyContact
        ]);

    useEffect(() => {
        const onHandleKeepTheSameAddressAsStudent = (profileType, value) => {
            setInput(prevInput => {
                const { student } = prevInput;

                return {
                    ...prevInput,
                    [profileType]: omitBy({
                        ...prevInput[profileType],
                        street1: value ? student.street1 : undefined,
                        street2: value ? student.street2 : undefined,
                        postcode: value ? student.postcode : undefined,
                        city: value ? student.city : undefined,
                        state: value ? student.state : undefined,
                        country: value ? student.country : undefined
                    }, isUndefined)
                }
            })
        }

        if (input.father?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('father', input.father?.keepAddressSameAsStudent);
        }
        else if (!input.father?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('father', input.father?.keepAddressSameAsStudent);
        }

        if (input.mother?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('mother', input.mother?.keepAddressSameAsStudent);
        }
        else if (!input.mother?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('mother', input.mother?.keepAddressSameAsStudent);
        }

        if (input.guardianOne?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('guardianOne', input.guardianOne?.keepAddressSameAsStuden);
        }
        else if (!input.guardianOne?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('guardianOne', input.guardianOne?.keepAddressSameAsStudent);
        }

        if (input.guardianTwo?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('guardianTwo', input.guardianTwo?.keepAddressSameAsStudent);
        }
        else if (!input.guardianTwo?.keepAddressSameAsStudent) {
            onHandleKeepTheSameAddressAsStudent('guardianTwo', input.guardianTwo?.keepAddressSameAsStudent);
        }
    },
        [
            input.father?.keepAddressSameAsStudent,
            input.mother?.keepAddressSameAsStudent,
            input.guardianOne?.keepAddressSameAsStudent,
            input.guardianTwo?.keepAddressSameAsStudent
        ]);

    /** 
     * @param {ChangeEvent<HTMLInputElement>} e event object 
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     * */
    const onChange = (e, profileType) => {
        let { name, value } = e.target;

        if (!['medicalCondition', 'priorSchool'].includes(name)) {
            if (!value || value.trim().length === 0) {
                value = undefined;
            }
        }

        setInput(prevInput => {
            return {
                ...prevInput,
                [profileType]: {
                    ...prevInput[profileType],
                    [name]: value
                }
            }
        });

        setError(initialError);
    }

    /** 
     * @param {ChangeEvent<HTMLInputElement>} e event object 
     */
    const onShowFields = (e) => {
        const { name, checked } = e.target;

        setShowField({ ...showField, [name]: checked });
    }

    /** 
     * @param {ChangeEvent<HTMLInputElement>} e event object 
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     * @param {('checked'|'value')} targetValue 'checked' or 'value' to be targeted
     * */
    const onCheckBoxChange = (e, profileType, targetValue = 'value') => {
        const { name, checked, value } = e.target;

        setInput(prevInput => {
            return {
                ...prevInput,
                [profileType]: {
                    ...prevInput[profileType],
                    [name]: targetValue === 'value' ? value : checked
                }
            }
        });

        setError(initialError);
    }

    /** 
     * @param {ChangeEvent<HTMLInputElement>} e event object 
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     * */
    const onRadioChange = (e, profileType) => {
        const { name, checked, value } = e.target;

        setInput(prevInput => {
            return {
                ...prevInput,
                [profileType]: {
                    ...prevInput[profileType],
                    [name]: value
                }
            }
        });

        setError(initialError);
    }

    /**
     * @param {Object} opt
     * @param {Moment} opt.date moment object
     * @param {string} opt.value format of DD/MM/YYYY
     * @param {string} opt.name 
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     */
    const onDateChange = (opt, profileType) => {
        const { date, name } = opt;

        setInput(prevInput => {
            return {
                ...prevInput,
                [profileType]: {
                    ...prevInput[profileType],
                    [name]: date
                }
            }
        });

        setError(initialError);
    }

    /** @param file {File} */
    const getBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => res(reader.result);
            reader.onerror = (error) => rej(error);
        });
    }

    /** 
     * @param {ChangeEvent<HTMLInputElement>} e event input  
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     */
    const onChangeFile = async (e, profileType) => {
        const { name, files } = e.target;
        const file = files[0];
        // const base64 = await getBase64(file);

        setInput(prevInput => {
            return {
                ...prevInput,
                [profileType]: {
                    ...prevInput[profileType],
                    [name]: file
                    // [name]: base64
                }
            };
        });
    }

    /** 
     * @param {('student'|'mother'|'father'|'guardianOne'|'guardianTwo')} profileType can be one of these values 'student' 'mother' 'father' 'guardianOne' 'guardianTwo' 
     */
    const onClearFields = (profileType) => () => {
        setInput(prevInput => {
            return { ...prevInput, [profileType]: {} }
        });
    }

    /** @param files {Array<File>} */
    const onHandleAttachments = (files) => {
        setInput(prevInput => {
            return {
                ...prevInput,
                student: omitBy({
                    ...prevInput.student,
                    attachments: !isEmpty(files) ? files : null
                }, isNull)
            };
        });
    }

    const removeFile = (index, fileID) => {
        let docIDs = !isEmpty(input.student.removedDocumentIDs) ? [...input.student.removedDocumentIDs] : [];
        if (fileID) {
            docIDs.push(fileID);
        }

        let attachedDocuments = !isEmpty(input.student.attachedDocuments) ? [...input.student.attachedDocuments] : [];
        attachedDocuments.splice(index, 1);

        setInput(prevInput => {
            return {
                ...prevInput,
                student: {
                    ...prevInput.student,
                    attachedDocuments,
                    removedDocumentIDs: docIDs,
                }
            };
        })
    }

    // TODO
    // const displaySourceImage = async (image) => {
    //     if (!image) { return; }

    //     if (!isEmpty(data)) {
    //         // pass the s3 link
    //         return;
    //     }

    //     if (isEmpty(data)) {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             imageRef.current = e.target.result;
    //         }

    //         reader.readAsDataURL(image);
    //     }
    // }

    const onSubmit = async () => {
        try {
            if (loading) { return; }

            // Cleanup
            let sanityInput = {};
            for (const profileType in input) {
                const profileData = input[profileType];

                const obj = omitBy(omitBy({
                    ...profileData,
                    dob: !isUndefined(profileData.dob) ? moment(profileData.dob).toISOString() : undefined,
                    expiryDate: !isUndefined(profileData.expiryDate) ? moment(profileData.expiryDate).toISOString() : undefined,
                    issueDate: !isUndefined(profileData.issueDate) ? moment(profileData.issueDate).toISOString() : undefined,
                }, isNull), isUndefined);

                sanityInput = { ...sanityInput, [profileType]: obj };
            }

            sanityInput = omitBy(sanityInput, isEmpty);

            const value = await schema.validateAsync(sanityInput);
            const isToCreateExist = keys(value).some(o => {
                if (o !== 'student') {
                    return !isEmpty(value[o]) && value[o].resultFromSearch;
                }
            });

            // Normal Create
            if (isEmpty(data) && !isEmpty(value) && onCreate && !isToCreateExist) {
                onCreate(value);
                return;
            }

            // Create student with existing parent
            if (isEmpty(data) && !isEmpty(value) && onCreateStudentExistingParent && isToCreateExist) {
                onCreateStudentExistingParent(value);
                return;
            }

            // Normal Update
            if (!isEmpty(data) && !isEmpty(value) && onUpdate) {
                const sanityFormData = reduce(value, (obj, o, key) => {
                    if (key !== 'student') {
                        const foundGuardian = find(data.guardians, initO => initO.profileTypeString === key) || {};
                        // if (!o.resultFromSearch) {
                        obj[key] = { ...foundGuardian, ...o };
                        // }

                        // if (o.resultFromSearch) {
                        //     obj[key] = o;
                        // }
                    }
                    else if (key === 'student') {
                        obj[key] = { ...data.profile, ...o };
                    }

                    return obj;
                }, {});

                onUpdate(omitBy(sanityFormData, isEmpty));
            }
        }
        catch (err) {
            // console.log(err);

            const profileType = String(err).split(' ')[1].replace(/"/g, '').split('.')[0];
            const { key, value } = normalize.error.validation(err);

            if (first(err.details).type === 'object.missing') {
                ref.current.focus();
                setError({ ...error, response: first(err.details).message });
                return;
            }

            setError({ ...error, [profileType]: { ...error[profileType], [key]: value } });

            if (document.querySelector(`*[id=${profileType}-${key}]`)) {
                document.querySelector(`*[id=${profileType}-${key}]`).focus();
            }
        }
    }

    return (
        <React.Fragment>
            <Toaster />

            <div className={classes.container}>
                <h2 className={classes.title}>Student Information</h2>

                <div style={{ marginTop: '.5em' }} ref={ref} tabIndex={-1}>
                    {error?.response && error?.response.length !== 0 &&
                        <AlertBox icon={false} type="error" message={error?.response} />
                    }
                </div>

                <div style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: '1em'
                }}>
                    {/* TODO do proper reusable component for uploader */}
                    {input.student.profileImg &&
                        <IconButton
                            onClick={() => {
                                removeFile(undefined, input.student?.profile_picture?.fileID);
                                setInput(prevInput => {
                                    return {
                                        ...prevInput,
                                        student: omitBy({
                                            ...prevInput.student,
                                            profileImg: null
                                        }, isNull)
                                    }
                                })
                            }}
                            style={{
                                position: 'absolute',
                                top: -10,
                                right: '42%'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }

                    <input
                        name="profileImg"
                        accept="image/png, image/jpeg"
                        type="file"
                        id="student-profileImg"
                        onChange={(e) => onChangeFile(e, 'student')}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="student-profileImg">
                        <IconButton component="span">
                            <Avatar
                                alt="Profile Picture"
                                src={input.student?.profileImg || ''}
                                style={{ width: '90px', height: '90px' }}
                            />
                        </IconButton>
                    </label>
                    <span>Upload Picture</span>
                </div>

                <Grid container spacing={2} style={{ marginTop: '2em' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="First Name"
                            id="student-firstName"
                            name="firstName"
                            placeholder={t('placeholder.first_name')}
                            required={true}
                            value={input.student?.firstName}
                            error={error.student?.firstName}
                            onChange={(e) => onChange(e, 'student')}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Last Name"
                            id="student-lastName"
                            name="lastName"
                            placeholder={t('placeholder.last_name')}
                            required={true}
                            value={input.student?.lastName}
                            error={error.student?.lastName}
                            onChange={(e) => onChange(e, 'student')}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <DatePicker
                            label="Date Of Birth"
                            name="dob"
                            id="student-dob"
                            placeholder={t('placeholder.dd_mm_yy')}
                            value={input.student?.dob || null}
                            error={error.student?.dob}
                            required={true}
                            onChange={(opt) => onDateChange(opt, 'student')}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Select
                            label="Gender"
                            name="gender"
                            id="student-gender"
                            value={input.student?.gender || ''}
                            required={true}
                            onChange={(e) => onChange(e, 'student')}
                            error={error.student?.gender}
                            options={[
                                { label: 'Male', value: 'MALE' },
                                { label: 'Female', value: 'FEMALE' }
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2} alignItems="flex-end">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <IdentificationV2
                            input={input.student}
                            error={error.student}
                            onRadioChange={(e) => onRadioChange(e, 'student')}
                            onChange={(e) => onChange(e, 'student')}
                            onDateChange={(e) => onDateChange(e, 'student')}
                            additionalInputProps={{
                                idType: { id: 'student-idType' },
                                idNo: { id: 'student-idNo' },
                                issueDate: { id: 'student-issueDate' },
                                expiryDate: { id: 'student-expiryDate' },
                                placeOfIssue: { id: 'student-placeOfIssue' }
                            }}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Select
                            label="Nationality"
                            name="nationality"
                            id="student-nationality"
                            value={input.student?.nationality || ''}
                            error={error.student?.nationality}
                            required={true}
                            onChange={(e) => onChange(e, 'student')}
                            options={map(countries, (o) => {
                                return { label: o.name, value: o.iso }
                            })}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <AddressFields
                            input={input.student}
                            error={error.student}
                            onChange={(e) => onChange(e, 'student')}
                            additionalProps={{
                                street1: { id: 'student-street1' },
                                street2: { id: 'student-street2' },
                                postcode: { id: 'student-postcode' },
                                city: { id: 'student-city' },
                                state: { id: 'student-state' },
                                country: { id: 'student-country' },
                            }}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container={true}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Select
                                    label="Religion"
                                    name="religion"
                                    id="student-religion"
                                    value={input.student?.religion || ''}
                                    error={error.student?.religion}
                                    required={true}
                                    onChange={(e) => onChange(e, 'student')}
                                    options={[
                                        { label: 'Islam', value: 'ISLAM' },
                                        { label: 'Buddhist', value: 'BUDDHIST' },
                                        { label: 'Hindu', value: 'HINDU' },
                                        { label: 'Christian', value: 'CHRISTIAN' },
                                        { label: 'Sikh', value: 'SIKH' },
                                        { label: 'Other', value: 'OTHER' }
                                    ]}
                                />

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Select
                                        label="Race"
                                        name="race"
                                        id="student-race"
                                        value={input.student?.race || ''}
                                        required={true}
                                        onChange={(e) => onChange(e, 'student')}
                                        options={[
                                            { label: "Indian", value: "INDIAN" },
                                            { label: "Chinese", value: "CHINESE" },
                                            { label: "Malay", value: "MALAY" },
                                            { label: "Punjabi", value: "PUNJABI" },
                                            { label: "Other", value: "OTHER" },
                                        ]}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Input
                                        label="Prior School (if Applicable)"
                                        name="priorSchool"
                                        placeholder={t('placeholder.prior_school')}
                                        id="student-priorSchool"
                                        value={input.student?.priorSchool || ''}
                                        error={error.student?.priorSchool}
                                        onChange={(e) => onChange(e, 'student')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextArea
                            name="medicalCondition"
                            label="Allergy or Medical Conditions"
                            id="student-medicalCondition"
                            placeholder={t('placeholder.please_specify_here')}
                            value={input.student?.medicalCondition || ''}
                            error={error.student?.medicalCondition}
                            rows={5}
                            onChange={(e) => onChange(e, 'student')}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Student ID"
                            name="profileCode"
                            id="student-profileCode"
                            required={input.student?.useCurrentStudID === true ? true : false}
                            value={input.student?.profileCode || ''}
                            error={error.student?.profileCode}
                            onChange={(e) => onChange(e, 'student')}
                            disabled={input.student?.useCurrentStudID === false}
                        >
                            <CheckBox
                                styleLabel={{ width: 'fit-content' }}
                                onChange={(e) => onCheckBoxChange(e, 'student', 'checked')}
                                options={[
                                    {
                                        label: 'Use current student ID',
                                        name: 'useCurrentStudID',
                                        checked: input.student?.useCurrentStudID
                                    }
                                ]}
                            />
                        </Input>
                    </Grid>
                </Grid>

                <h2 className={classes.title} style={{ marginTop: '1em', marginBottom: 0 }}>Parent/Guardian Information</h2>

                <Grid container={true}>
                    <Grid item={true} xl={5} lg={5} md={6} sm={12} xs={12}>
                        <SearchParent
                            onSelect={(data) => {
                                if (!data) { return; }
                                const profileType = data.profileTypeString;

                                setInput(prevInput => {
                                    return {
                                        ...prevInput,
                                        [profileType]: {
                                            ...prevInput[profileType],
                                            ...data,
                                            resultFromSearch: true
                                        }
                                    }
                                });

                                setShowField(prevInput => { return { ...prevInput, [profileType]: true } });
                                setError(initialError);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} direction="column">
                    <Grid item={true}>
                        <Grid container={true} justify="space-between" alignItems="center">
                            <Grid item={true}>
                                <CheckBox
                                    styleLabel={{ width: 'fit-content' }}
                                    onChange={onShowFields}
                                    options={[
                                        { label: 'Father', name: 'father', checked: showField.father }
                                    ]}
                                />
                            </Grid>

                            <Grid item={true}>
                                <span onClick={onClearFields('father')} className={classes.clearTxt}>Clear Fields</span>
                            </Grid>
                        </Grid>

                        {showField.father &&
                            <ParentGuardianForm
                                input={input.father}
                                error={error.father}
                                onChange={(e) => onChange(e, 'father')}
                                onDateChange={(opt) => onDateChange(opt, 'father')}
                                onCheckBoxChange={(e) => onCheckBoxChange(e, 'father', 'checked')}
                                onRadioChange={(e) => onRadioChange(e, 'father')}
                                type="father"
                            />
                        }

                        <Grid container={true} justify="space-between" alignItems="center">
                            <Grid item={true}>
                                <CheckBox
                                    styleLabel={{ width: 'fit-content' }}
                                    onChange={onShowFields}
                                    options={[
                                        { label: 'Mother', name: 'mother', checked: showField.mother }
                                    ]}
                                />
                            </Grid>

                            <Grid item={true}>
                                <span onClick={onClearFields('mother')} className={classes.clearTxt}>Clear Fields</span>
                            </Grid>
                        </Grid>

                        {showField.mother &&
                            <ParentGuardianForm
                                input={input.mother}
                                error={error.mother}
                                onChange={(e) => onChange(e, 'mother')}
                                onDateChange={(opt) => onDateChange(opt, 'mother')}
                                onCheckBoxChange={(e) => onCheckBoxChange(e, 'mother', 'checked')}
                                onRadioChange={(e) => onRadioChange(e, 'mother')}
                                type="mother"
                            />
                        }

                        <Grid container={true} justify="space-between" alignItems="center">
                            <Grid item={true}>
                                <CheckBox
                                    styleLabel={{ width: 'fit-content' }}
                                    onChange={onShowFields}
                                    options={[
                                        {
                                            label: 'Guardian 1',
                                            name: 'guardianOne',
                                            checked: showField.guardianOne
                                        }
                                    ]}
                                />
                            </Grid>

                            <Grid item={true}>
                                <span onClick={onClearFields('guardianOne')} className={classes.clearTxt}>Clear Fields</span>
                            </Grid>
                        </Grid>

                        {showField.guardianOne &&
                            <ParentGuardianForm
                                input={input.guardianOne}
                                error={error.guardianOne}
                                onChange={(e) => onChange(e, 'guardianOne')}
                                onDateChange={(opt) => onDateChange(opt, 'guardianOne')}
                                onCheckBoxChange={(e) => onCheckBoxChange(e, 'guardianOne', 'checked')}
                                onRadioChange={(e) => onRadioChange(e, 'guardianOne')}
                                type="guardianOne"
                            />
                        }

                        <Grid container={true} justify="space-between" alignItems="center">
                            <Grid item={true}>
                                <CheckBox
                                    styleLabel={{ width: 'fit-content' }}
                                    onChange={onShowFields}
                                    options={[
                                        {
                                            label: 'Guardian 2',
                                            name: 'guardianTwo',
                                            checked: showField.guardianTwo
                                        }
                                    ]}
                                />
                            </Grid>

                            <Grid item={true}>
                                <span onClick={onClearFields('guardianTwo')} className={classes.clearTxt}>Clear Fields</span>
                            </Grid>
                        </Grid>

                        {showField.guardianTwo &&
                            <ParentGuardianForm
                                input={input.guardianTwo}
                                error={error.guardianTwo}
                                onChange={(e) => onChange(e, 'guardianTwo')}
                                onDateChange={(opt) => onDateChange(opt, 'guardianTwo')}
                                onCheckBoxChange={(e) => onCheckBoxChange(e, 'guardianTwo', 'checked')}
                                onRadioChange={(e) => onRadioChange(e, 'guardianTwo')}
                                type="guardianTwo"
                            />
                        }
                    </Grid>
                </Grid>

                <h2 className={classes.title} style={{ marginTop: '1em' }}>Emergency Contact</h2>

                <Grid container={true} spacing={2} style={{ marginTop: '1em' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Name"
                            name="emergencyContactName"
                            id="student-emergencyContactName"
                            placeholder={t('placeholder.full_name_here')}
                            required={true}
                            value={input.student?.emergencyContactName || ''}
                            error={error.student?.emergencyContactName}
                            onChange={(e) => onChange(e, 'student')}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Input
                            label="Relationship with Student"
                            id="student-emergencyContactRelationship"
                            name="emergencyContactRelationship"
                            placeholder={t('placeholder.relationship')}
                            required={true}
                            value={input.student?.emergencyContactRelationship || ''}
                            error={error.student?.emergencyContactRelationship}
                            onChange={(e) => onChange(e, 'student')}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <PhoneNumber
                            input={input.student}
                            error={error.student}
                            onChange={(e) => onChange(e, 'student')}
                            additionalInputProps={{
                                countryCode: {
                                    id: 'student-emergencyContactCountryCode',
                                    name: 'emergencyContactCountryCode',
                                    value: input.student?.emergencyContactCountryCode
                                },
                                contactNumber: {
                                    id: 'student-emergencyContactNumber',
                                    name: 'emergencyContactNumber',
                                    value: input.student?.emergencyContactNumber,
                                    placeholder: t('placeholder.emergency_contact_number')
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: '1em' }}>
                    <FileUpload
                        multiple={true}
                        updateFilesCb={onHandleAttachments}
                    />

                    {!isEmpty(input.student.attachedDocuments) &&
                        <Grid item xl={6} lg={4} md={12} sm={12} xs={12}>
                            <h4 style={{
                                // fontSize: '14px',
                                // fontWeight: 'bold',
                                color: '#FDB714'
                            }}>
                                Attached Documents
                            </h4>

                            {map(input.student.attachedDocuments, (o, i) => {
                                return (
                                    <List key={i} style={{
                                        border: '1px solid #ddd',
                                        margin: '5px',
                                        color: '#756F86',
                                        background: '#DBE2EA',
                                        fontSize: '20px'
                                    }}>
                                        <ListItem button>
                                            <ListItemText primary={o.fileName} style={{ color: "#6386af", fontSize: '18px' }} />
                                            <CloseIcon onClick={() => removeFile(i, o.fileID)} />
                                        </ListItem>
                                    </List>
                                )
                            })}
                        </Grid>
                    }
                </div>

                <Grid container={true} style={{ marginTop: '5em' }} justify="center">
                    <Grid item>
                        <Button
                            label={!isEmpty(data) ? t('action.update') : t('action.register')}
                            type="primary"
                            size="large"
                            isLoading={loading}
                            style={{ width: '160px' }}
                            onClick={onSubmit}
                        />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default ProfileForm;