
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Button from 'components/common/button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from '@material-ui/core/Grid';
import eduAPI from "../../../../api/api";
import { Input, TextArea, Select } from '../../../common/form-unit';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MultiSelect from "react-multi-select-component";
import useToaster from 'hooks/use-toaster';
import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import SharedCommonMenu from 'components/common/menu';


const ITEM_HEIGHT = 48;


const GroupList = ({ status, resErr, onCreate, onReset, onUpdate, getGroups }) => {
    const session = useSession();

    let headersData = {
        headers: {
            Authorization: Session.BearerToken()
        }
    }
    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
    const initialInput = {
        "description": "",
        "name": "",
    };
    const { t } = useTranslation();
    const initialError = {};
    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [Years, setYears] = useState([])
    const [Teachers, setTeachers] = useState([])
    const [input, setInput] = useState(initialInput);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedRowObj, SetSelectedRow] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedIndex, setSelIndex] = useState();
    const [rows, setData] = useState([])
    const [acedCal, setAcademicCal] = useState([])
    const [fetchStatus, setFetchStatus] = useState(true)

    //options
    const [modulesList, setModulesList] = useState(
        [
            {
                value: 1, label: "Academic Setup"
            },
            {
                value: 2, label: "Account"
            },
            {
                value: 3, label: "Attendance"
            },
            {
                value: 4, label: "Announcement"
            },
            {
                value: 5, label: "Timetable"
            },
            {
                value: 6, label: "Homework"
            }
        ]


    )


        useEffect(() => {
            console.debug('selectedIndex', selectedIndex);
        }, [selectedIndex])

    //set Options
    const [selModulesList, setSelModulesList] = useState([])
    //options
    const [rolesList, setRolesList] = useState([])
    //set Options
    const [selRolesList, setSelRolesList] = useState([])

    let globalRoles = [];
    const fetchRoles = (key) => {
        try {
            eduAPI.get(`/sys_ad/module-roles?orgID=${session.orgID}&module=${key}`, headersData).then((data) => {

                data.data.data.map((item) => {
                    globalRoles.push({
                        label: item.role.name,
                        value: item.role.id
                    })
                });

                setRolesList(globalRoles);
            });
        } catch (error) {
            console.error("Fetch Roles Error", error);
        }
    }

    useEffect(() => {
        globalRoles = [];
        setSelRolesList([]);
        for (let i = 0; i < selModulesList.length; i++) {
            fetchRoles(selModulesList[i].label)
        }
    }, [selModulesList])


    const handleOpen = () => {
        setInput(initialInput);
        setSelModulesList([])
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenEdit = () => {


        let data = rows[selectedIndex];
        SetSelectedRow(rows[selectedIndex])

        if (data.fixed) {
            handleCloseMenu()
            let msg = "Fixed Group Cannot Editable..";
            setToastMsg(msg)
            onOpenToaster()
        } else {

            let obj = {
                name: data.name,
                description: data.description
            }

            setSelModulesList([
                {
                    value: 1, label: "Academic Setup"
                },
                {
                    value: 2, label: "Account"
                },
                {
                    value: 3, label: "Attendance"
                },
                {
                    value: 4, label: "Announcement"
                },
                {
                    value: 5, label: "Timetable"
                },
                {
                    value: 6, label: "Homework"
                }
            ])

            let rolesArray = [];
            data.roles.map((item) => {
                rolesArray.push({
                    label: item.name,
                    value: item.roleID
                })
            })

            setInput(obj)
            handleCloseMenu()
            setTimeout(() => {
                setSelRolesList(rolesArray)
            }, 1000);

            setOpenEdit(true);
        };
    }


    const handleCloseEdit = () => {
        setOpenEdit(false);
        setInput(initialInput)
    };


    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelIndex(index)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onSubmit = () => {
        let roleIds = [];
        selRolesList.map((item) => {
            roleIds.push(item.value)
        })
        let value = {};
        value.orgID = session.orgID;
        value.name = input.name;
        value.description = input.description;
        value.roleIDs = roleIds;
        onCreate(value);
    }

    const onUpdateGroup = () => {
        let backupValues = selectedRowObj;
        let roleIds = [];
        selRolesList.map((item) => {
            roleIds.push(item.value)
        })

        let obj = {
            "groupID": backupValues.groupID,
            "name": input.name,
            "description": input.description,
            "roleIDs": roleIds
        }
        onUpdate(obj);
    }

    const fetchGroups = async () => {
        try {
            await eduAPI.get(`/sys_ad/groups?orgID=${session.orgID}`, headersData).then((data) => {
                setData(data.data.data);
            });
        } catch (error) {
            console.error("fetch Groups error", error);
        }
    }


    useEffect(() => {
        if (session.orgID && fetchStatus) {
            setFetchStatus(false)
            fetchGroups();
            onReset()
        }
    }, [session]);


    useEffect(() => {
        if (resErr?.create?.success == false) {
            setToastMsg("Error: " + resErr.create?.message)
            onOpenToaster();
            onReset();
        }
        if (resErr?.update?.success == false) {
            setToastMsg("Error: " + resErr.update?.message)
            onOpenToaster();
            onReset();
        }

    }, [resErr])

    useEffect(() => {
        setButtonDisabled(false)
        if (status.fetch === 'success') {
            fetchGroups();
            setInput(initialInput);
        } else if (status.create === 'success') {
            handleClose();
            fetchGroups();
            setInput(initialInput);
            let msg = "Group Created Successfully..";
            setToastMsg(msg)
            onOpenToaster()
            onReset();
        } else if (status.update === 'success') {
            handleCloseEdit();
            fetchGroups();
            setInput(initialInput);
            let msg = "Group Updated Successfully..";
            setToastMsg(msg)
            onOpenToaster();
            onReset();
        }
    }, [status]);

    const [groupSearchTerm, setGroupSearchTerm] = useState('');
    const filterSearch = (event, type) => {
        let searchVal = event.target.value.toLowerCase();
        setGroupSearchTerm(searchVal)
    }
    return (
        <>
            <Toaster />
            <div>
                <div>
                    <Grid container style={{ marginBottom: '' }}>
                        <Grid item lg={10} md={10} sm={10} xs={12}>
                        </Grid>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                            <div className={classes.inputButtonContainer} style={{ textAlign: 'right !important', marginBottom: '10px' }}>
                                <Button
                                    // variant="contained"
                                    // className={classes.inputButton}
                                    label={t('action.new_group')}
                                    startIcon={<AddCircleOutlineOutlinedIcon />}
                                    type="primary"
                                    size="large"
                                    style={{ marginLeft: '25px' }}
                                    onClick={handleOpen}>
                                    {/* <img src={process.env.PUBLIC_URL + "/addcircle_outline.svg"} alt='add' className={classes.iconStyle} /> */}
                                    New Group
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Paper>
                    <Grid container style={{ marginBottom: '', padding: '10px' }}>
                        <Grid item lg={8} md={6} sm={6} xs={12}>
                            <div style={{ marginBottom: '20px', padding: '10px' }}>
                                <Button label="Groups" style={{ fontWeight: 'bold', color: "#25335a", textTransform: 'capitalize', fontSize: '18px', background: "transparent" }}>Groups</Button>
                                {/* <Button><img src={process.env.PUBLIC_URL + "/sort.svg"} alt='sort' className={classes.iconStyle} />Sort</Button>
                            <Button><img src={process.env.PUBLIC_URL + "/filter.svg"} alt='filter' className={classes.iconStyle} />Filter</Button> */}
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Grid container >
                                <Grid item lg={9} style={{ textAlign: 'right' }}>
                                    <Input onKeyUp={(event) => filterSearch(event, 'receive')} placeholder="Search By Group Name/Code.." />
                                </Grid>
                                <Grid item lg={3} style={{ textAlign: 'left' }}>
                                    <IconButton
                                        color="#FFC000"
                                    >
                                        <SearchOutlinedIcon
                                            style={{
                                                color: "black",
                                                background: 'rgba(253, 183, 20, 1)',
                                                padding: 7,
                                                width: 55,
                                                height: 40,
                                                marginTop: -4,
                                                marginLeft: -14,
                                                borderRadius: 4
                                            }} />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} className={classes.tableContainer} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>

                                <TableRow>
                                    <TableCell align="left" className={classes.titles}>Group Code</TableCell>
                                    <TableCell align="left" className={classes.titles}>Group Name</TableCell>
                                    <TableCell align="left" className={classes.titles}>Description</TableCell>
                                    <TableCell align="left" className={classes.titles}>Role Name</TableCell>
                                    <TableCell align="left" className={classes.titles}></TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                rows.length ?
                                    <TableBody>
                                        {rows.filter(item => {
                                            return (
                                                item.name.toLowerCase().indexOf(groupSearchTerm) > -1 || item.code.toLowerCase().indexOf(groupSearchTerm) > -1
                                            );
                                        }).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left" className={classes.dataText}>{row.code}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.name}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>{row.description}</TableCell>
                                                <TableCell align="left" className={classes.dataText}>

                                                    {row.roles.map((row) => (
                                                        <div>{row.name},</div>
                                                    ))}

                                                </TableCell>
                                                <TableCell align="center">
                                                    <SharedCommonMenu
                                                        selectedIndex={setSelIndex}
                                                        actionElement={(onOpenMenu) => (
                                                            <IconButton 
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true" 
                                                                onClick={(event) => { onOpenMenu(event, index); }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        )}
                                                        items={[
                                                            {
                                                                content: 'Edit',
                                                                onClick: () => {
                                                                    handleOpenEdit();
                                                                }
                                                            }
                                                        ]}
                                                        menuProps={{
                                                            PaperProps: {
                                                                elevation: 1,
                                                                style: {
                                                                    maxHeight: ITEM_HEIGHT * 3.5,
                                                                    width: "20ch",
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    {/* <div>
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => { handleClick(event, index) }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id={index}
                                                            key={index}
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={openMenu}
                                                            onClose={handleClose}
                                                            PaperProps={{
                                                                elevation: 1,
                                                                style: {
                                                                    maxHeight: ITEM_HEIGHT * 3.5,
                                                                    width: "20ch",
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    handleOpenEdit();
                                                                }}
                                                            >
                                                                {'Edit'}
                                                            </MenuItem>
                                                        </Menu>
                                                    </div> */}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    :
                                    <TableRow style={{ textAlign: 'center', fontSize: '20px', margin: '10px 0px' }}>
                                        Records Empty
                                    </TableRow>}
                        </Table>
                    </TableContainer>


                    <div className={classes.headerContainer}>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                        >
                            <Fade in={open}>
                                <div className={[classes.paper, classes.modalHeight].join(' ')} >

                                    <h2 id="transition-modal-title" className={classes.popupTitle}>New Group</h2>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Group Name: <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                name="class"
                                                required={true}
                                                error={input.name === ""}
                                                value={input.name || ''}
                                                onChange={(e) => {
                                                    setInput({ ...input, name: e.target.value })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Description : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextArea
                                                name="description"
                                                required={true}
                                                error={input.description === ""}
                                                value={input.description || ''}
                                                multiline={true}
                                                onChange={(e) => {
                                                    setInput({ ...input, description: e.target.value })
                                                }}
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Modules : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MultiSelect
                                                options={modulesList}
                                                value={selModulesList}
                                                onChange={setSelModulesList}
                                                labelledBy={"Select Modules"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Roles : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MultiSelect
                                                options={rolesList}
                                                value={selRolesList}
                                                onChange={setSelRolesList}
                                                labelledBy={"Select Roles"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Button label="Create" className={classes.buttonSubmit} onClick={onSubmit}>Create</Button>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                        <div>

                        </div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openEdit}
                            onClose={handleCloseEdit}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                        >
                            <Fade in={openEdit}>
                                <div className={[classes.paper, classes.modalHeight].join(' ')} >
                                    <h2 id="transition-modal-title" className={classes.popupTitle}>Edit Group</h2>


                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Group Name: <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                name="class"
                                                required={true}
                                                error={input.name === ""}
                                                value={input.name || ''}
                                                onChange={(e) => {
                                                    setInput({ ...input, name: e.target.value })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Description : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextArea
                                                name="description"
                                                required={true}
                                                error={input.description === ""}
                                                value={input.description || ''}
                                                multiline={true}
                                                onChange={(e) => {
                                                    setInput({ ...input, description: e.target.value })
                                                }}
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Modules : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MultiSelect
                                                options={modulesList}
                                                value={selModulesList}
                                                onChange={setSelModulesList}
                                                labelledBy={"Select Modules"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={4} style={{ margin: "auto" }}>
                                            <Typography>Roles : <span className={classes.asterik}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MultiSelect
                                                options={rolesList}
                                                value={selRolesList}
                                                onChange={setSelRolesList}
                                                labelledBy={"Select Roles"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                        <Button label="Update" className={classes.buttonSubmit} onClick={onUpdateGroup}>Update</Button>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </Paper>
            </div>
        </>

    )
};

const useStyles = makeStyles((theme) => ({
    popupTitle: {
        marginBottom: '10px'
    },
    dataText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    titles: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#25335A',
        fontSize: 18,
        textAlign: 'center'
    },
    asterik: {
        color: 'red'
    },
    breadcrum: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    headerContainer: {
        marginBottom: '5%',
        marginLeft: '1%',
        marginTop: '5%',
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        color: 'purple'

    },
    container: {
        marginTop: '5%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: '100%'
    },
    table: {
        minWidth: 650,

    },
    tableContainer: {

    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '1%',
        marginTop: '1%',
        width: '100%'
    },
    button: {
        background: 'linear-gradient(181.47deg, #25335A 53.33%, #141824 117.4%)',
        border: 5,
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 15,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 60,
        padding: '0 30px',
        width: "auto !important",
        //textTransform: 'capitalize !important'

    },
    buttonSubmit: {
        background: '#FDB714',
        borderWidth: 15,
        borderColor: '#25335A',
        borderRadius: 5,
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: 40,
        padding: '0 30px',
        width: 150,
        textTransform: 'none',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        width: '40%',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
    ,
    modalHeight: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 10px)'
    },
    inputButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '2%',
        paddingLeft: '1%',
        paddingTop: '0.3%'
    },
    inputButton: {
        display: 'flex',
        width: 200,
        borderRadius: 7,
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        // display: 'flex',
        background: '#FDB714',
        textTransform: 'none',
    },
}));

export default GroupList;


// import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import FlatList from 'flatlist-react';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import moment from 'moment';
// import Divider from '@material-ui/core/Divider';
// import { useHistory } from "react-router-dom";
// import Paper from '@material-ui/core/Paper';
// import eduAPI from "../../../../api/api";
// import { Input } from 'components/common/form-unit'
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// import IconButton from "@material-ui/core/IconButton";

// const GroupList = ({ status, resErr, onCreate }) => {
//     const history = useHistory();
//     const [spacing, setSpacing] = React.useState(2);
//     const classes = useStyles();

//     const [DATA, setData] = useState([{
//         "roleCode": "1234432123411",
//         "roleName": "Attendance Takers",
//         "permissions": ["View Attendance Screens", "Take Attendance ", "View MC/Reason Submitted", "Approve/Reject  MC/Reason Submitted", "View High Absentees "],
//         "description": "For all Class teachers to take attendance",
//         "assignedTo": "Class Teachers"
//     }, {
//         "roleCode": "1234432123412",
//         "roleName": "Announcement Makers",
//         "permissions": ["Edit Announcements", "Make Announcements ", "Delete Announcements"],
//         "description": "-",
//         "assignedTo": "Parents"
//     }, {
//         "roleCode": "1234432123413",
//         "roleName": "Homework Givers",
//         "permissions": ["Edit or Delete Homeowork Posted"],
//         "description": "-",
//         "assignedTo": "Students"
//     }, {
//         "roleCode": "1234432123413",
//         "roleName": "Registrations",
//         "permissions": ["Register Students", "Register Teachers"],
//         "description": "-",
//         "assignedTo": "Teachers and Students"
//     }]);



//     const handleClickButton = () => {
//         history.push("/systemadmin/newrole");
//     };

//     return (
//         <div>
//             <Grid container style={{ marginBottom: '', padding: '10px' }}>
//                 <Grid item lg={9} md={9} sm={9} xs={12}>
//                 </Grid>
//                 <Grid item lg={3} md={3} sm={3} xs={12}>
//                     <div className={classes.inputButtonContainer} style={{ textAlign: 'right !important' }}>
//                         <Button className={classes.inputButton} onClick={handleClickButton}>
//                             <AddCircleIcon style={{ marginLeft: '-10%', marginRight: '10%', color: "black" }} />
//                                         New Group
//                         </Button>
//                     </div>
//                 </Grid>
//             </Grid>
//             <Paper className={classes.listContainer}>

//             </Paper>
//         </div>
//     )
// }

// const useStyles = makeStyles(() => ({
//     createNewDropDownContainer: {
//         display: 'flex',
//         alignItems: 'center',
//         maxWidth: 295,
//         paddingLeft: 15,

//     },

//     createNewDropDown: {
//         width: 295,
//     },
//     createNewContainer: {
//         display: 'flex',
//         alignItems: 'center',
//         paddingLeft: 10,

//     },

//     createNewText: {
//         fontFamily: 'Poppins',
//         fontSize: 20,
//         fontWeight: 500,
//     },

//     headerMenuContainer: {
//         display: 'flex',
//         // flexDirection: 'row',
//         width: '100%',
//         marginTop: '-2%'
//     },

//     listContainer: {

//         // borderLeftStyle: 'solid',
//         // borderLeftWidth: '10px',
//         // borderLeftColor: 'rgba(37, 7, 85, 0.89)',
//         borderRadius: 10
//     },
//     iconStyle: {
//         marginRight: '5%'
//     },
//     buttonContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'flex-start',
//         marginRight: '1%',

//     },

//     titleContainer: {
//         marginLeft: '5%'
//     },
//     dataContainer: {
//         marginLeft: '7%'
//     },

//     titles: {
//         fontFamily: 'Poppins',
//         fontWeight: 500,
//         color: '#9FA2B4',
//         fontSize: 14,
//         // textAlign: 'center'
//     },
//     dataText: {
//         fontFamily: 'Poppins',
//         fontWeight: 500,
//         fontSize: 14,
//         // textAlign: 'center',
//         paddingTop: '2%',
//         paddingBottom: '2%'
//     },
//     input: {
//         width: '65%',
//         height: '50%',
//         paddingBottom: '5%',
//         paddintTop: '5%',
//         fontSize: 12
//     },
//     inputContainer: {
//         display: 'flex',
//         justifyContent: 'center',
//         paddingRight: '1%',
//         paddingLeft: '1%'

//     },
//     inputButtonContainer: {
//         alignItems: 'center',
//         display: 'flex',
//         paddingBottom: '2%',
//         marginTop: '0.4%',
//         paddingLeft: '1%',
//         paddingTop: '0.3%'
//     },
//     inputButton: {
//         height: 50,
//         width: 261,
//         borderRadius: 6,
//         fontSize: 14,
//         fontFamily: 'Poppins',
//         fontWeight: 500,
//         // display: 'flex',
//         background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)'
//     },


// }));

// export default GroupList;