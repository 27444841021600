import { createApi, fetchBaseQuery } from '@rtk-incubator/rtk-query';
import { forEach, filter, isEmpty, omitBy, isNil, first } from 'lodash';
import Session from 'services/local/session';
import normalize from 'utils/normalizers';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/attendance`,
    // prepareHeaders: (headers, { getState }) => { // TODO need to put later
    //     const { accessToken = {} } = Session.getSession();
    //     console.log(session);
    // }

    prepareHeaders: (headers, { getState }) => {
        if (Session.BearerToken()) {
            headers.set('Authorization', Session.BearerToken());
        }
        return headers;
    },


});

/* 
    /org
    /org/high
    /reason/status
    /:attendanceID

    /student PUT
*/

export const attendanceApi = createApi({
    reducerPath: 'attendances',
    baseQuery,
    entityTypes: ['Attendances'],
    endpoints: (build) => ({
        getClassesAttendance: build.query({
            query: ({ date }) => {
                const { orgID } = Session.getOrg();

                return {
                    url: '/org',
                    params: { orgID, date }
                }
            },
            transformResponse: (res) => {
                const { attendance, ...data } = res.data;

                let newAttendance = [];
                if (!isEmpty(attendance)) {
                    forEach(attendance, o => {
                        const absents = filter(o.students, s => s.status === 'ABSENT' || s.status === 'ABSENT_WITH_REASON');
                        const presents = filter(o.students, ['status', 'PRESENT']);
                        // const absentWithReason = filter(o.students, ['status', 'ABSENT_WITH_REASON']);

                        newAttendance.push(omitBy({ ...o, absents, presents }, isNil));
                    });
                }

                return omitBy({ ...data, attendance: newAttendance }, isNil);
            }
        }),
        getHighAbsentees: build.query({
            query: () => {
                const { orgID } = Session.getOrg();

                return {
                    url: '/org/high',
                    params: { orgID }
                }
            },
            transformResponse: (res) => res.data
        }),
        getAttendanceByID: build.query({
            query: ({ attendanceID }) => ({ url: `/${attendanceID}` }),
            transformResponse: (res) => {
                // const data = first(res.data);

                const data = res?.data;

                let absentStudents = [], presentStudents = [];
                if (!isEmpty(data.students)) {
                    forEach(data.students, o => {
                        const student = omitBy({ ...o, attendanceID: data.attendance?.ID }, isNil);

                        if (o.status === 'PRESENT')
                            presentStudents.push(student);

                        if (o.status === 'ABSENT' || o.status === 'ABSENT_WITH_REASON')
                            absentStudents.push(student);
                    });
                }

                return omitBy({ ...data, absentStudents, presentStudents }, isEmpty);
            },
        }),
        markStudentAttendance: build.mutation({
            query: (data) => ({
                url: '/student',
                method: 'PUT',
                body: data
            }),
            invalidates: ['Attendances']
            // invalidates: (_, { messageTemplateID: id }) => [{ type: 'Messages', id }]
        }),
        reasonStatus: build.mutation({
            query: (data) => ({
                url: '/reason/status',
                method: 'PUT',
                body: data
            }),
            invalidates: ['Attendances']
            // invalidates: (_, { messageTemplateID: id }) => [{ type: 'Messages', id }]
        })
    })
});

export const {
    useGetClassesAttendanceQuery,
    useGetHighAbsenteesQuery,
    useGetAttendanceByIDQuery,
    useMarkStudentAttendanceMutation,
    useReasonStatusMutation
} = attendanceApi;