import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';

//import GlobalStyles from '../styles/globalStyles';
import { schema } from './reset-password-form.validation';
import normalize from '../../../utils/normalizers';

// Assets
import ProductLogo from '../../../assets/img/loginFormIcon.png'
import LoginImg from '../../../assets/img/loginBg.png';

import { useHistory } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
// Componennts
import Grid from "@material-ui/core/Grid";
import { Input, Password } from "../../common/form-unit";
import Button from '../../common/button';
import { makeStyles } from '@material-ui/core/styles';

import { useLocation } from "react-router-dom";
import AlertBox from '../../common/alert-box';
// import TextField from '@material-ui/core/TextField';
// import PasswordStrengthBar from 'react-password-strength-bar';


import useToaster from 'hooks/use-toaster';

const PaaswordSettingForm = ({ status, resErr, passwordResetting }) => {
    const styles = useStyles();
    // const global = GlobalStyles();
    const location = useLocation();
    const initialError = {}, initialLoading = false;
    const initialInput = {
        emailAddress: '',
        password: ''
    };


    const [toastMsg, setToastMsg] = useState("")
    const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })


    // const [username, setUsername] = useState();
    // const [password, setPassword] = useState();
    const [input, setInput] = useState(initialInput);
    const [error, setError] = useState(initialError);
    const [isLoading, setIsLoading] = useState(initialLoading);
    const history = useHistory();
    useEffect(() => {
        if (status.passwordStatus === 'loading') {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
        if (status.passwordStatus == 'success') {
            let msg = "Password Setting Successfully..";
            setToastMsg(msg)
            onOpenToaster();
            setTimeout(() => {
                history.push('/login');
            }, 1000);
        }
    }, [status]);

    useEffect(() => {
        if (resErr?.length !== 0 || !_.isUndefined(resErr)) {
            setError({ ...error, 'response': resErr });
        }
    }, [resErr]);

    const onChange = (e) => {
        const { name, value } = e.target;

        setInput({ ...input, [name]: value });
        setError(initialError);
    }

    const onSubmit = async () => {

        try {
            if (isLoading) { return; }

            const value = await schema.validateAsync(_.omitBy(_.omitBy({ ...input }, _.isNull), _.isUndefined));
            if (!_.isEmpty(value) && passwordResetting) {
                let obj = {
                    email: location.state.emailHash,
                    password: value.password,
                    token: location.state.token
                }
                passwordResetting(obj);
            }
        }
        catch (err) {
            const { key, value } = normalize.error.validation(err);

            setError({ ...error, [key]: value });

            if (document.querySelector(`input[name=${key}]`)) {
                document.querySelector(`input[name=${key}]`).focus();
            }
        }
    }

    const goToLogin = () => {
        history.push('/login');
    }

    return (
        <>
            <Toaster></Toaster>
            <div className={styles.container}>
                {/* <div className={styles.left__col}>
                    <img
                        className={styles.backgroundImage}
                        alt="LoginBg Image"
                        src={LoginImg}
                    />
                </div> */}
                <div className={styles.right__col}>
                    <Grid
                        className={styles.wrapper__inner}
                        container={true}
                        justify="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '1em', marginBottom: '2em', display: 'flex', justifyContent: 'center' }}>
                            <img alt="Product Logo" src={ProductLogo} />
                        </Grid>

                        {/* <Grid
                        item={true}
                        lg={12} md={12} sm={12} xs={12}
                        style={{ padding: '1.5em 0' }}
                    >
                        <h2 style={{ color: '#424241' }}>Sign In</h2>
                    </Grid> */}

                        <Grid
                            container={true}
                            item={true}
                            lg={12} md={12} sm={12} xs={12}
                        >
                            {!_.isUndefined(error?.response) && error?.response.length !== 0 &&
                                <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                                    <AlertBox
                                        message={error?.response}
                                        type="error"
                                    />
                                </Grid>
                            }

                            <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '.5em' }}>
                                <Input
                                    label="Username"
                                    placeholder="Username"
                                    errorLabel="Email Address"
                                    name="emailAddress"
                                    value={input.emailAddress || ''}
                                    error={error?.emailAddress}
                                    onChange={onChange}
                                    required={true}
                                />
                            </Grid>
                            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                                <Password
                                    placeholder="Password"
                                    label="New Password"
                                    errorLabel="Password"
                                    name="password"
                                    value={input.password || ''}
                                    error={error?.password}
                                    onChange={onChange}
                                    required={true}
                                />
                                <div className={styles.passwordStrengthBar}>
                                    <PasswordStrengthBar password={input.password} />
                                </div>
                            </Grid>


                            <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                                <Button
                                    onClick={onSubmit}
                                    label="Set Password"
                                    isLoading={isLoading}
                                    type="primary"
                                    style={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid item={true} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '30px' }}>
                                <div onClick={goToLogin}>Back to Login? <span style={{ color: 'rgb(66, 66, 65)', textDecoration: 'underline', cursor: 'pointer' }}>Click Here.</span></div>
                            </Grid>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    left__col: {
        width: '120%',

        '@media screen and (max-width: 1024px)': {
            display: 'none'
        }
    },
    right__col: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        padding: '1em 2em',
        margin: 'auto',
        '@media screen and (max-width: 1024px)': {
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'center'
        }
    },
    wrapper__inner: {
        width: '80%',

        '@media screen and (max-width: 1024px)': {
            width: '100%'
        },

        '@media screen and (min-width: 1600px)': {
            width: '60%'
        }
    },
    backgroundImage: {
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundSize: 'cover',
    }
    // button: {
    //     background: 'linear-gradient(45deg, #FFC000 100%, #FF8A00 100%)',
    //     border: 5,
    //     borderWidth: 15,
    //     borderColor: '#25335A',
    //     borderRadius: 3,
    //     //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //     color: 'black',
    //     height: 48,
    //     padding: '0 30px',
    //     width: "100%"
    // },
    // passwordStrengthBar: {
    //     marginTop: '2%'
    // },
    // passwordContainer: {
    //     width: '70%'
    // },
    // inputTitleContainer: {
    //     marginTop: '20%'
    // },
    // titleCotainer: {
    //     marginLeft: '10%'
    // },
    // container: {
    //     display: "flex",
    // },
    // img: {
    //     display: "flex",
    //     width: '50%',
    //     padding: 0,
    //     marginLeft: '-1%',
    //     marginTop: '-1%'
    // },
    // inputContainer: {
    //     marginTop: '5%',
    //     marginLeft: '10%',
    //     width: '100%',
    //     height: '100%'
    // },
    // title: {
    //     fontWeight: 'bold',
    //     fontSize: 48,
    //     fontFamily: 'Poppins',
    // },
    // input: {
    //     fontFamily: 'Poppins',
    // }
});

export default PaaswordSettingForm;