const parseJwt = (token) => {
    try {
        const encStr = token.split('.')[1];
        const buff = new Buffer.from(encStr, 'base64');
        const dataStr = buff.toString('ascii');
        
        return JSON.parse(dataStr);
    } 
    catch (e) {
        return null;
    }
};

export default parseJwt;