/**
 * This is for VIEW-ing TIMETABLE only, cannot resize/drag n drop
 */
import React, { useState, useEffect, useCallback, Fragment, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Button from 'components/common/button';
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { Resizable } from 're-resizable';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { TimetableTemplateContext } from '../../../context/TimetableContext';
import useSession from 'hooks/use-session';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useToaster from 'hooks/use-toaster';
import * as _ from "lodash";


const generateDefaultRows = (day, timeStart) => {
  let slots = [];
  let count = 0
  for (let i = 0; i < timeStart.length; i++) {
    slots.push({ day, startTime: timeStart[i].startTime, endTime: timeStart[i].endTime, id: uuid(), type: "", width: 120, height: 52, isEmpty: true });
  }
  return slots;
};



// TODO:
const generateInitialDayRowsFromTTTemplate = (startDay, noOfDays, DAYS_OF_WEEK, tempTime) => {
  let validDays = getValidDays(startDay, noOfDays, DAYS_OF_WEEK);
  let dayRows = [];
  for (let vD of validDays) {
    let dayStr = DAYS_OF_WEEK[vD];
    let day = {
      day: dayStr,
      slots: generateDefaultRows(dayStr, tempTime),
    };
    dayRows.push(day);
  }
  return dayRows;
};

const getValidDays = (startDay, numberOfDays, DAYS_OF_WEEK) => {
  let weekCount = DAYS_OF_WEEK.length;
  let validDays = [startDay];
  let count = 1;

  while (count !== numberOfDays) {
    count++;
    startDay++;
    // loop it around
    if (startDay === weekCount) {
      startDay = 0;
    };
    validDays.push(startDay);
  }
  return validDays;
};

const createNewDroppedSubjectSlot = (day, subjectInfo) => {
  return { day, ...subjectInfo, id: subjectInfo.id ? subjectInfo.id : uuid() };
};

const TimetableTemplate = ({ status, tStatus, resErr, tError, onResetStatus, onFetchClassbyYear, availableYears, availableClasses, onCreateTimeTable, onPublishTimeTable, onFetchClassbySemester, availableSemesters, onFetchSubjectbyLevel, subjects }) => {
  const [state, setState] = useState({ [uuid()]: [] });
  const location = useLocation();
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState();
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [days, setDays] = useState();
  const [subjectsInfo, setSubjects] = useState();
  const [disables, setDisables] = useState({
    class: true,
    semester: true,
  });
  const [doneButton, setDoneButton] = useState(false);
  ;
  const GridTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const [rows, setRows] = useState([
    { time: "07:00 - 08:00", id: uuid() },
    { time: "08:00 - 09:00", id: uuid() },
    { time: "09:00 - 10:00", id: uuid() },
    { time: "10:00 - 11:00", id: uuid() },
    { time: "11:00 - 12:00", id: uuid() },
    { time: "12:00 - 13:00", id: uuid() },
    { time: "13:00 - 14:00", id: uuid() },
    { time: "14:00 - 15:00", id: uuid() },
  ]);
  const [DAYS_OF_WEEK, setDAYS_OF_WEEK] = useState([
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ]);
  const [input, setInput] = useState({
    template: "Template 40",
    year: "2020",
    semester: "Sem 1",
    class: "class 1",
  });



  const [toastMsg, setToastMsg] = useState("")
  const { onOpenToaster, Toaster } = useToaster({ message: toastMsg })
  const [timetableTemplates, setTimetableTemplates] = useContext(TimetableTemplateContext);
  const [MinuteWidth, setMinuteWidth] = useState();
  const minMinuteIncrement = 5;

  const session = useSession();
  const history = useHistory();

  const [times, setTimes] = useState();
  const minWidth = 20;

  const [clsData, setClsData] = useState({});
  const [tempData, setTempData] = useState({});
  const [timTabData, setTimTabData] = useState({});

  const [draftData, setDraftData] = useState({})

  const getName = (key) => {
    let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
    let teachersList = sessionObject.teachers;
    let name = '';
    teachersList.map((obj) => {
      if (obj.subjectID == key) {
        name = obj.subjectName == 'Empty' ? '-' : obj.subjectName;
      }
    })
    return name;
  }

  const getColor = (key) => {
    let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
    let teachersList = sessionObject.teachers;
    let color = '';
    teachersList.map((obj) => {
      if (obj.subjectID == key) {
        color = obj.subjectName == 'Empty' ? 'white' : obj.subjectColor;
      }
    })
    return color;
  }

  const getTeacher = (key) => {
    let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
    let teachersList = sessionObject.teachers;
    let color = '';
    teachersList.map((obj) => {
      if (obj.subjectID == key) {
        color = obj.subjectName == 'Empty' ? '-' : obj.teacherName;
      }
    })
    return color;
  }

  const [action, setAction] = useState('')
  useEffect(() => {
    if (location?.state?.actionType) {
      setAction(location?.state?.actionType)
    }
  }, [location])

  useEffect(() => {
    const tempDays = [];
    const tempTime = [];
    const dragabbleSnap = [120];
    let sessionObject = JSON.parse(sessionStorage.getItem('tempObj'));
    let timetableTemplates = sessionObject.templateData;
    setClsData(sessionObject.classData);
    setTempData(sessionObject.timetableData);
    setTimTabData(sessionObject.templateData);
    setDraftData(sessionObject.draft == null ? null : sessionObject.draft)


    const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
    const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
    const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
    const condition = (e) => e === timetableTemplates.startDay;
    let time = timetableTemplates.startTime;
    const duration = timetableTemplates.durationOfPeriods;
    let i = DAYS_OF_WEEK.findIndex(condition);
    for (let h = 0; h < timetableTemplates.numberOfPeriods; h++) {
      tempTime.push({ startTime: moment(time, 'HH:mm').format('HH:mm'), endTime: moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm'), id: uuid() });
      time = moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm');
    }
    for (let j = 0; j < timetableTemplates.numberOfDays; j++) {
      if (i < DAYS_OF_WEEK.length) {
        tempDays.push(DAYS_OF_WEEK[i]);
        i++;
      }
      else {
        i = 0;
        tempDays.push(DAYS_OF_WEEK[i]);
      }
    }
    setDAYS_OF_WEEK(tempDays);
    setRows(tempTime);


    let rowsList = []
    sessionObject.slotsData.map((data, index) => {
      let slots = [];

      _.sortBy(data.timeSlots, "index").map((slot, subIndex) => {

        let diff = getTime(slot.end, slot.start);
        let x = (totalDailyWidth * diff) / totalDailyMinute;

        slots.push({
          day: data.day,
          startTime: slot.start,
          endTime: slot.end,
          height: 52,
          id: slot.subjectID,
          isEmpty: true,
          type: '',
          width: x !== 0 ? x : 120,
          subject: getName(slot.subjectID),
          color: getColor(slot.subjectID),
          teacherName: getTeacher(slot.subjectID)
        })
      })
      rowsList.push({
        day: data.day,
        slots: slots
      })
    })
    setDays(rowsList)
    setMinuteWidth(minuteWidth);

    for (let i = 0; i < timetableTemplates.numberOfPeriods; i++) {
      dragabbleSnap.push(dragabbleSnap[i] + MinuteWidth);
    }

  }, [sessionStorage.getItem('tempObj')]);

  const getTime = (strtTme, stpTme) => {
    var timeStart =
      new Date('01/01/2007 ' + strtTme).getHours() * 60 +
      new Date('01/01/2007 ' + strtTme).getMinutes();
    var timeEnd =
      new Date('01/01/2007 ' + stpTme).getHours() * 60 +
      new Date('01/01/2007 ' + stpTme).getMinutes();

    var hourDiff = timeStart - timeEnd;
    return hourDiff;
  }

  useEffect(() => {
    setState(
      { ...state });
  }, [days])

  const [tempId, setTempId] = useState('')
  const [clsName, setClsName] = useState("")
  useEffect(() => {
    if (tStatus.create == 'success') {
      setDoneButton(true);
      setTempId(tStatus?.data?.timetableData?.timetableID);
      setClsName(tStatus?.data?.classData?.name)
      setToastMsg("Time Table Saved Succsessfully...")
      onOpenToaster();
      onResetStatus()
    }
    if (tStatus.publish == 'success') {
      history.push(
        {
          pathname: '/timetable-publish',
          state: { templateName: clsData?.name }
        }
      );
      onResetStatus()
    }
  }, [tStatus])


  useEffect(() => {
    if (tError?.create?.message) {
      setToastMsg("Error " + tError?.create?.message)
      onOpenToaster();
    };
    if (tError?.publish?.message) {
      setToastMsg("Error " + tError?.publish?.message)
      onOpenToaster();
    };
  }, [tError])


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  //this is to clone
  const copy = (source, destination, droppableSource, droppableDestination) => {

    // const sourceClone = Array.from(source);
    // const destClone = Array.from(destination || []);
    // const item = sourceClone[droppableSource.index];
    // destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
    // return destClone;
  };

  //this is to move
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  // this is to drag
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      let dayOfDropped = days.find((d) =>
        d.slots.find((s) => s.id === destination.droppableId)
      );
      if (dayOfDropped === undefined) {
        alert('error');
      } else {
        let newSlots = dayOfDropped.slots.concat();
        newSlots = newSlots.map((n) => {
          if (n.id === destination.droppableId) {
            return {
              ...createNewDroppedSubjectSlot(
                dayOfDropped.day,
                subjectsInfo[source.index],
              ), width: n.width, height: n.height, startTime: n.startTime, endTime: n.endTime
            }
          }
          return n;
        });
        dayOfDropped.slots = newSlots;
      }
      switch (source.droppableId) {
        case destination.droppableId:
          setState({
            ...state,
            [destination.droppableId]: reorder(
              state[source.droppableId],
              source.index,
              destination.index
            ),
          });
          break;
        case "ITEMS":
          setState({
            ...state
          });
          break;
        default:
          setState(
            ...state,
            move(
              state[source.droppableId],
              state[destination.droppableId],
              source,
              destination
            )
          );
          break;
      }
    },
    [state, subjectsInfo]
  );

  const onResize = (e, direction, ref, d, index, subindex) => {
    let daysCopy = days;
  }

  const onResizeStop = (e, direction, ref, d, index, subindex) => {
    let daysCopy = _.cloneDeep(days);
    const slotCopy = days[index].slots
    const totalDailyMinute = timetableTemplates?.durationOfPeriods * timetableTemplates?.numberOfPeriods;
    const totalDailyWidth = 120 * timetableTemplates?.numberOfPeriods;
    const minuteWidth = (totalDailyWidth * minMinuteIncrement) / totalDailyMinute;
    const minuteIncrement = Math.round(d.width / minuteWidth) * 5;
    const _count = count + 1;
    const totalPairWidth = days[index].slots[subindex].width + days[index].slots[subindex + 1].width;
    const totalPairMinutes = timetableTemplates?.durationOfPeriods * 2;
    const minuteConstrainX = Math.round((totalDailyMinute * 20) / totalDailyWidth);
    const minuteConstrain = Math.round(minuteConstrainX / 10) * 10;

    const pairConstrain = totalPairWidth - 20;
    const minutePairConstrain = totalPairMinutes - minuteConstrain;
    if (subindex + 1 < days[index].slots.length) {
      if (days[index].slots[subindex].width + d.width >= pairConstrain) {
        daysCopy[index].slots[subindex].width = pairConstrain;  // this is correct
        daysCopy[index].slots[subindex + 1].width = minWidth; // this is also correct
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex + 1].endTime, 'HH:mm').add(-5, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = daysCopy[index].slots[subindex].endTime;
      }
      else if (days[index].slots[subindex].width + d.width <= minWidth) {
        daysCopy[index].slots[subindex].width = minWidth;
        daysCopy[index].slots[subindex + 1].width = pairConstrain;
        const reducedMinuteIncrement = Math.round(minWidth / minuteWidth) * 5;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex].startTime, 'HH:mm').add(reducedMinuteIncrement, 'minutes').format('HH:mm');
      }
      else {
        daysCopy[index].slots[subindex].width = daysCopy[index].slots[subindex].width + d.width;
        daysCopy[index].slots[subindex + 1].width = daysCopy[index].slots[subindex + 1].width - d.width;
        daysCopy[index].slots[subindex].endTime = moment(daysCopy[index].slots[subindex].endTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');
        daysCopy[index].slots[subindex + 1].startTime = moment(daysCopy[index].slots[subindex + 1].startTime, 'HH:mm').add(minuteIncrement, 'minutes').format('HH:mm');
      }
    }
    else {
      // console.log('Condition Successfully False');
    }
    setDays([
      ...daysCopy,
    ]);
  }

  const onPublish = () => {
    let obj = {
      timetableID: location.state.timeTableId,
      publishedBy: session.profile._key
    }
    onPublishTimeTable(obj)
  }

  const backToListing = () => {
    history.push('./timetable')
  }

  return (
    <>
      <Toaster />
      <DragDropContext onDragEnd={onDragEnd} className={classes.listContainer}>

        <div className={classes.grdContainer}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0px', fontSize: '20px', fontFamily: 'Poppins' }}>
              {clsData?.year} | Semester No {tempData?.semesterNo} | {clsData?.year}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '10px', flexWrap: 'nowrap' }}>
            <div className={classes.timetableFirstColumn}>
              <Typography>Day/Time</Typography>
            </div>
            {rows.map((row, index) => (
              <Grid item>
                <div className={classes.gridHeaderContainer}>
                  <Typography className={classes.grdLabel}>{row.startTime} - {row.endTime}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>

          {days?.map((day, i) => (
            <Grid container key={day.day} style={{ flexWrap: 'nowrap' }}>
              <div className={classes.timetableFirstColumn}>
                <Typography>{day.day}</Typography>
              </div>
              {day?.slots?.map((row, j) => {
                return (
                  <Droppable key={row.id} droppableId={row.id}>
                    {(provided, snapshot) => {
                      return (
                        <GridTooltip
                          title=
                          {
                            <div>
                              <Typography>{row.teacherName}</Typography>
                              {/* <Typography>End Time: {row.endTime}</Typography> */}
                            </div>
                          }
                        >

                          <Resizable
                            size={{ height: row.height, width: row.width }}
                            grid={[MinuteWidth, MinuteWidth]}
                            snap={{ x: times }}
                            snapGap={MinuteWidth}
                            enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                            onResizeStop={(e, direction, ref, d) => { 
                              return; // not resizable for view only
                              if (j === day?.slots?.length - 1) return;
                              onResizeStop(e, direction, ref, d, i, j) 
                            }}
                            onResize={(e, direction, ref, d) => { onResize(e, direction, ref, d, i, j); }}
                          >
                            <div
                              className={classes.gridContainer}
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                              style={{
                                width: row.width,
                                height: row.height,
                                backgroundColor: row.color
                                  ? row.color
                                  : snapshot.isDraggingOver
                                    ? "grey"
                                    : "white",
                              }}
                            >
                              {row.subject}
                            </div>
                          </Resizable>
                        </GridTooltip>
                      );
                    }}
                  </Droppable>
                );
              })}
            </Grid>
          ))}
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '20px 0px', fontSize: '18px', fontFamily: 'Poppins' }}>
              <div>Created By : {tempData?.createdByName} </div>
              <div>Last Updated on : {moment(tempData?.updatedAt).format('DD.MM.YYYY')} </div>
            </Grid>
          </Grid>
        </div>

        <br />

        <div className={classes.doneButtonContainer}>
          {/* {tempData.status !== 'PUBLISHED' ? <Button
            variant="contained"
            color="primary"
            className={classes.doneButton}
            type="submmit"
            onClick={onPublish}
          >
            Publish
          </Button>
            : ''} */}

          {action == 'View Draft' ? 
          <Button
          label="Publish"
            variant="contained"
            color="primary"
            className={classes.doneButton}
            type="submmit"
            onClick={onPublish}
          >
            Publish
          </Button>
            : ''}


        </div>
        <div
          onClick={backToListing}
          style={{ textAlign: 'center', cursor: 'pointer', color: '#0172B1', textDecoration: 'underline', marginTop: '10px' }}>Back to listing page</div>
        <br />
      </DragDropContext>
    </>
  );
};

const useStyles = makeStyles(() => ({
  subjectContainer: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 800,
    marginLeft: "2%",
    // flexDirection: 'row',
  },

  subjectButton: {
    // height: 10,
    width: "100%",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -10
  },
  subject: {
    paddingTop: 4,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginRight: "1%",
    marginTop: "1%",
    display: "flex",
    height: 40,
    width: 120,
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    // alignItems: 'center'
  },
  dragNdropTxtContainer: {
    marginLeft: "1%",
    marginTop: "1%",
  },
  dragNdropTxt: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
  },
  grdLabel: {
    textAlign: "center",
  },
  grdContainer: {
    marginLeft: 20,
    marginTop: 5,
    // overflowX: "scroll",
    // width: '200vw'
    // display: 'inline-block',
    // height: 1000,
    // width:  1000,
    // whiteSpace: 'nowrap'

  },
  grdtxt1: {
    marginTop: "15px",
    marginLeft: "0px",
    color: "#756F86",
    fontSize: "14px",
    fontWeight: "bold",
  },
  gridContainer: {
    // width: 120,
    // height: 52,
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  gridHeaderContainer: {
    width: 120,
    height: 52,
    borderRadius: 6,
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  subjectBox: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    padding: "5px 0px",
    margin: "10px",
    textAlign: "center",
    textTransform: "capitalize",
  },

  listContainer: {
    borderLeftStyle: "solid",
    borderLeftWidth: "10px",
    borderLeftColor: "rgba(61, 58, 214, 0.9)",
    borderRadius: 10,
  },
  iconStyle: {
    marginRight: "5%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: "1%",
  },

  titleContainer: {
    marginLeft: "5%",
  },
  dataContainer: {
    marginLeft: "7%",
  },

  titles: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#9FA2B4",
    fontSize: 14,
    textAlign: "center",
  },
  dataText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  input: {
    width: "65%",
    height: "50%",
    paddingBottom: "5%",
    paddintTop: "5%",
    fontSize: 12,
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "1%",
    paddingLeft: "1%",
  },
  inputButtonContainer: {
    alignItems: "center",
    display: "flex",
    paddingBottom: "2%",
    marginTop: "0.4%",
    paddingLeft: "1%",
    paddingTop: "0.3%",
  },
  inputButton: {
    height: "120%",
    width: "100%",
    borderRadius: 15,
    fontSize: 12,
    fontWeight: 400,
    // display: 'flex',
    background: "linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)",
  },
  doneButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },

  doneButton: {
    height: 40,
    width: 175,
    color: "black",
  },

  timetableFirstColumn: {
    width: 120,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));

export default TimetableTemplate;
