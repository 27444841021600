import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AcademicCaledarAction from '../../../redux/actions/acedemic-calendar';
import TTtemplateAction from '../../../redux/actions/time-table';
import View from './timetable-view';
import useSession from 'hooks/use-session';
import { useLocation } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        status: state.acedemicCalendar?.status,
        error: state.acedemicCalendar?.error,
        template: state.timeTable?.list,
        tableLst: state.timeTable?.tList,
    }
}

const mapDispatchToProps = (dispatch) => {
    const AcademicCaledarActions = bindActionCreators(AcademicCaledarAction, dispatch);
    const TTtemplateActions = bindActionCreators(TTtemplateAction, dispatch);

    return {
        onCreate$: (data) => {
            AcademicCaledarActions.createacCalendar(data);
        },
        ttTemplateFetch$: (data) => {
            TTtemplateActions.getTableList(data);
        },
        fetchTimetable$: () => {
            TTtemplateActions.getTimeTableList()
        }
    }
}

const Container = ({ status, error, onCreate$, ttTemplateFetch$, fetchTimetable$, template, tableLst }) => {
    const session = useSession();
    const location = useLocation();
    const onCreate = (data) => {
        onCreate$(data);
    };
    useEffect(() => {
        if (location?.state?.fetch === 'success') {
            fetchTimetable$();
        }
    }, [location]);

    useEffect(() => {
        if (session.orgID) {
            ttTemplateFetch$();
            fetchTimetable$();
        }
    }, [session.orgID])
    return (
        <View status={status} resErr={error} onCreate={onCreate} template={template} tableLst={tableLst} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);