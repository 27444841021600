import _ from 'lodash';

import departmentAPI from '../apis/department';
import profileAPI from '../apis/profile';
import normalize from 'utils/normalizers';

export const RESET_STATUS = 'RESET_STATUS';

export const DEPARTMENTS_FETCH = 'DEPARTMENTS_FETCH';
export const DEPARTMENTS_FETCH_SUCCESS = 'DEPARTMENTS_FETCH_SUCCESS';
export const DEPARTMENTS_FETCH_FAILED = 'DEPARTMENTS_FETCH_FAILED';

export const DEPARTMENT_CREATE = 'DEPARTMENT_CREATE';
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS';
export const DEPARTMENT_CREATE_FAILED = 'DEPARTMENT_CREATE_FAILED';

export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';

export const DEPARTMENT_ASSIGN = 'DEPARTMENT_ASSIGN';
export const DEPARTMENT_ASSIGN_SUCCESS = 'DEPARTMENT_ASSIGN_SUCCESS';
export const DEPARTMENT_ASSIGN_FAILED = 'DEPARTMENT_ASSIGN_FAILED';

export const DEPARTMENT_UNASSIGN = 'DEPARTMENT_UNASSIGN';
export const DEPARTMENT_UNASSIGN_SUCCESS = 'DEPARTMENT_UNASSIGN_SUCCESS';
export const DEPARTMENT_UNASSIGN_FAILED = 'DEPARTMENT_UNASSIGN_FAILED';

let dev = process.env.NODE_ENV === 'development';

const resetStatus = () => (dispatch) => { dispatch({ type: RESET_STATUS }); }

const getDepartments = (orgID) => async (dispatch) => {
    dispatch({ type: DEPARTMENTS_FETCH });

    try {
        const res = await departmentAPI.get(orgID);
        if (!res.success) {
            return dispatch({
                type: DEPARTMENTS_FETCH_FAILED,
                error: normalize.error.response(res)
            });
        }

        let departments = [];
        _.map(res.data, async (o) => {
            const profile = normalize.client.profile(o?.headOfDepartment);
            const department = normalize.client.department({
                ...o,
                headOfDepartmentID: profile.profileID,
                headOfDepartmentName: profile.fullName
            });

            departments.push(department);
        })

        dispatch({
            type: DEPARTMENTS_FETCH_SUCCESS,
            payload: departments
        });
    }
    catch (err) {
        if (dev) { console.error(err); }
        dispatch({ type: DEPARTMENTS_FETCH_FAILED, error: {} });
    }
}

const createDepartment = (data) => async (dispatch) => {
    try {
        dispatch({ type: DEPARTMENT_CREATE });

        data = normalize.server.department(data);
        _.unset(data, 'status');

        const res = await departmentAPI.create(data);
        if (!res.success) {
            return dispatch({
                type: DEPARTMENT_CREATE_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: DEPARTMENT_CREATE_SUCCESS,
            payload: normalize.client.department(res.data)
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: DEPARTMENT_CREATE_FAILED,
            error: err
        });
    }
}

const updateDepartment = (data) => async (dispatch) => {
    try {
        dispatch({ type: DEPARTMENT_UPDATE });

        data = normalize.server.department(data);

        const res = await departmentAPI.update(data);
        if (!res.success) {
            return dispatch({
                type: DEPARTMENT_UPDATE_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: DEPARTMENT_UPDATE_SUCCESS,
            payload: normalize.client.department(res.data?.new)
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: DEPARTMENT_UPDATE_FAILED,
            error: err
        })
    }
}

const assignDepartment = (data) => async (dispatch) => {
    try {
        dispatch({ type: DEPARTMENT_ASSIGN })

        // const departmentData = normalize.server.payload(data);
        const payload = _.omitBy(data, _.isNil);

        const res = await departmentAPI.assign(payload);
        if (!res.success) {
            return dispatch({
                type: DEPARTMENT_ASSIGN_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: DEPARTMENT_ASSIGN_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: DEPARTMENT_ASSIGN_FAILED,
            error: err
        });
    }
}

const unAssignDepartments = (data) => async (dispatch) => {
    try {
        dispatch({ type: DEPARTMENT_UNASSIGN })

        // const departmentData = normalize.server.payload(data);
        const payload = _.omitBy(data, _.isNil);

        const res = await departmentAPI.unassign(payload);
        if (!res.success) {
            return dispatch({
                type: DEPARTMENT_UNASSIGN_FAILED,
                error: res.message
            });
        }

        dispatch({
            type: DEPARTMENT_UNASSIGN_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        // console.log(err);
        dispatch({
            type: DEPARTMENT_UNASSIGN_FAILED,
            error: err
        });
    }
}

export default {
    resetStatus,
    getDepartments,
    createDepartment,
    updateDepartment,
    assignDepartment,
    unAssignDepartments
}