import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AcademicCaledarAction from '../../../redux/actions/acedemic-calendar';
import SubjectAction from '../../../redux/actions/subject';
import TimeTableAction from '../../../redux/actions/time-table';
import useSession from 'hooks/use-session';
import View from './template-view';


const mapStateToProps = (state) => {
    // console.log(state);
    return {
        status: state.acedemicCalendar?.status,
        error: state.acedemicCalendar?.error,
        availableYears: state.acedemicCalendar?.availableYears,
        classes: state.acedemicCalendar?.availableClasses,
        semesters: state.acedemicCalendar?.availableSemesters,
        subject: state.subject?.subjects,
        tStatus: state.timeTable?.status,
        tError: state.timeTable?.error,
    }
}

const mapDispatchToProps = (dispatch) => {
    const AcademicCaledarActions = bindActionCreators(AcademicCaledarAction, dispatch);
    const SubjectActions = bindActionCreators(SubjectAction, dispatch);
    const TimeTableActions = bindActionCreators(TimeTableAction, dispatch);


    return {
        onFetchClassbyYear$: (orgID, year) => {
            AcademicCaledarActions.getClassesBasedOnAvailableYear(orgID, year);
        },

        onFetchYears$: (orgID) => {
            AcademicCaledarActions.getAvailableYears(orgID);
        },
        onFetchClassbySemester$: (classID) => {
            AcademicCaledarActions.getClassesBasedOnAavailableSemester(classID);
        },
        onFetchSubjectbyLevel$: (level, orgID) => {
            SubjectActions.getSubjectsforTimetable(level, orgID);
        },
        onCreateTimeTable$: (data) => {
            TimeTableActions.createTimeTable(data);
        },
        onPublishTimeTable$: (data) => {
            TimeTableActions.publishTimeTable(data);
        },
        onResetStatus$: () => {
            TimeTableActions.resetStatus();
        },

    }
}

const Container = ({ status, tStatus, error, tError, onResetStatus$, onFetchClassbyYear$, availableYears, classes, onFetchYears$, onFetchClassbySemester$, semesters, onFetchSubjectbyLevel$, subject, onCreateTimeTable$, onPublishTimeTable$ }) => {
    const session = useSession();
    const onFetchClassbyYear = (orgID, year) => {
        onFetchClassbyYear$(orgID, year)
    };
    const onFetchClassbySemester = (classID) => {
        onFetchClassbySemester$(classID);
    };
    const onFetchSubjectbyLevel = (level, orgID) => {
        onFetchSubjectbyLevel$(level, orgID);
    };
    const onCreateTimeTable = (data) => {
        onCreateTimeTable$(data);
    };
    const onPublishTimeTable = (data) => {
        onPublishTimeTable$(data);
    }
    //console.log("----------Subjects------------", subject);

    useEffect(() => {
        // if (session.orgID !== undefined) {
        //     onFetchYears$(session.orgID)
        // }
    }, [session.orgID]);

    const onReload = () => {
        if (session.orgID !== undefined) {
            onFetchYears$(session.orgID)
        }
    }


    return (
        <View
            status={status}
            tStatus={tStatus}
            resErr={error}
            tError={tError}
            onResetStatus={onResetStatus$}
            onFetchClassbyYear={onFetchClassbyYear}
            onFetchClassbySemester={onFetchClassbySemester}
            onFetchSubjectbyLevel={onFetchSubjectbyLevel}
            subjects={subject}
            availableYears={availableYears}
            availableClasses={classes}
            availableSemesters={semesters}
            onCreateTimeTable={onCreateTimeTable}
            onPublishTimeTable={onPublishTimeTable}
            reload={onReload}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);