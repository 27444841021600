import React from "react";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

import countries from "utils/countries.json";

// Components
import { Input, Select, InputError } from "../form-unit";
import Grid from "@material-ui/core/Grid";
import Label from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";

const PhoneNumber = ({ 
    input, 
    error, 
    onChange, 
    required = true, 
    additionalInputProps, 
    extraLabelProps, 
    label,
}) => {
    const cx = useStyles();
    const { t } = useTranslation();
 
    return (
        <Grid container={true} spacing={0} direction="column">
            <Grid item={true} container={true} spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Label
                        classes={{
                            asterisk: cx.label,
                        }}
                        required={required}
                        error={(error?.countryCode || error?.contactNumber || error?.fullNumber) || extraLabelProps?.error}
                        focused={(error?.countryCode || error?.contactNumber || error?.fullNumber) || extraLabelProps?.error}
                        style={{ fontSize: "14px", ...extraLabelProps?.style }}
                        {...extraLabelProps}
                    >
                        {label}
                    </Label>
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Select
                        name={`countryCode` || additionalInputProps?.countryCode?.name}
                        value={input?.countryCode || additionalInputProps?.countryCode?.value || ''}
                        // error={error?.countryCode || additionalInputProps?.countryCode?.error}
                        required={required}
                        onChange={onChange}
                        options={_.map(countries, (o) => {
                            return {
                                label: `${o.name} (+${o.countryCode})`,
                                value: o.countryCode.toString(),
                            };
                        })}
                        {...additionalInputProps?.countryCode}
                    />
                </Grid>

                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Input
                        name={`contactNumber` || additionalInputProps?.contactNumber?.name}
                        value={input?.contactNumber || additionalInputProps?.contactNumber?.value || ''}
                        // error={error?.contactNumber || additionalInputProps?.contactNumber?.error}
                        onChange={onChange}
                        required={required}
                        placeholder={t('placeholder.emergency_contact_number')}
                        {...additionalInputProps?.contactNumber}
                    />
                </Grid>
            </Grid>
            
            <Grid>
                <InputError error={error?.fullNumber || error} label={label} />
            </Grid>
        </Grid>
  );
};

PhoneNumber.defaultProps = {
    label: 'Mobile Number'
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "14px",
        color: "red",
    },
}));

export default PhoneNumber;
